import React, { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

function Powerbiredirect() {
  const navigate = useNavigate();
  const params = useParams();
  const anchorRef = useRef();
  const licenseCode = JSON.parse(
    localStorage.getItem("cargowiseAppLicenseCode")
  );

  useEffect(() => {
    // previous url http://localhost:3000/bi/shipment/2cb804b4-8fd1-4456-865f-f6e712d6bdc1
    // new url http://localhost:3000/bi/JobShipment/2cb804b4-8fd1-4456-865f-f6e712d6bdc1
    if (params.ControllerID && params.BusinessEntityPK && licenseCode?.code) {
      // PROD COMMAND
      //query params = 2cb804b4-8fd1-4456-865f-f6e712d6bdc1
      anchorRef.current.href = `edient:Command=ShowEditForm&LicenceCode=${licenseCode.code}&ControllerID=${params.ControllerID}&BusinessEntityPK=${params.BusinessEntityPK}&Domain=wisecloud.zone&Instance=FRAGBL`;
      console.log("command shipment", anchorRef.current.href);
      anchorRef.current.click();
      navigate(-1);
      // DEV COMMAND
      // anchorRef.current.href = `edient:Command=ShowEditForm&LicenceCode=${process.env.REACT_APP_CARGOWISE_APP_LICENSECODE}&ControllerID=JobShipment&BusinessEntityPK=${params.query}&Domain=wisecloud.zone&Instance=${process.env.REACT_APP_CARGOWISE_APP_LICENSECODE}`
    }
  }, [params]);

  return (
    <>
      <a
        ref={anchorRef}
        href={`${process.env.REACT_APP_ENV}/shipment/analytics`}
        target="_self"
        rel="noreferrer"
        hidden
        data-testid="anchorRef"
      >
        Google
      </a>
    </>
  );
}

export default Powerbiredirect;
