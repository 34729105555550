import {
  GET_SHIPMENT_DRAFT_DETAIL_FAILURE,
  GET_SHIPMENT_DRAFT_DETAIL_SUCCESS,
  RESET_SHIPMENT_DRAFT_DETAIL,
  RESET_SHIPMENT_DRAFT_DETAIL_FAILURE,
  RESET_SHIPMENT_DRAFT_DETAIL_SUCCESS,
  SET_LOADING,
  UPDATE_BREADCRUMB_DATA,
  GET_SHIPPING_NOTE_DETAILS_SUCCESS,
  GET_SHIPPING_NOTE_DETAILS_FAILURE,
  SET_SHIPPING_NOTE_DETAILS_LOADING,
  RESET_SHIPPING_NOTE_DETAILS,
} from "./actionTypes";

const initialState = {
  shipmentDraftDetails: {
    organizationId: "",
    organizationName: "",
    referenceNumber: "",
    shipmentMode: null,
    estimatedPickup: "",
    pickupRequiredBy: "",
    estimatedDelivery: "",
    deliveryRequiredBy: "",
    notes: [],
    shippingAddresses: [],
    documents: [],
    products: [],
    fclProducts: [],
    consigneeName: null,
    country: null,
    direction: null,
    loadType: null,
    createdBy: "",
    createdDate: "",
    updatedBy: "",
    updatedDate: "",
    isRecordLocked: true,
    lockedUser: "",
    isActive: true,
    id: "",
  },
  loading: false,
  error: false,
  errorMessage: "",
  customBreadCrumbData: [],
  shippingNotesDetailsData: [],
  shippingDetailLoading: false,
};

const ShipmentHistory = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_SHIPMENT_DRAFT_DETAIL_SUCCESS: {
      console.log("shipmentDraftDetails GET_SHIPMENT_DRAFT_DETAIL_SUCCESS");
      return {
        ...state,
        loading: false,
        shipmentDraftDetails: action.payload,
      };
    }
    case GET_SHIPMENT_DRAFT_DETAIL_FAILURE: {
      console.log("shipmentDraftDetails GET_SHIPMENT_DRAFT_DETAIL_FAILURE");

      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: "Unable to get shipment draft details",
      };
    }

    case RESET_SHIPMENT_DRAFT_DETAIL:
      return {
        ...state,
        loading: true,
        availableCapacityLoading: true,
      };
    case RESET_SHIPMENT_DRAFT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        availableCapacityLoading: false,
        shipmentDraftDetails: {
          organizationId: "",
          organizationName: "",
          referenceNumber: "",
          shipmentMode: null,
          estimatedPickup: "",
          pickupRequiredBy: "",
          estimatedDelivery: "",
          deliveryRequiredBy: "",
          notes: [
            {
              pickupInstructions: "",
              deliveryInstructions: "",
            },
          ],
          shippingAddresses: [
            {
              shippingAddressId: "",
              organizationName: null,
              organizationId: "",
              address1: "",
              address2: "",
              city: "",
              stateProvince: "",
              zipCode: "",
              country: "",
              countryCode: null,
              addressType: "",
              parentOrganizationId: "",
            },
            {
              shippingAddressId: "",
              organizationName: null,
              organizationId: "",
              address1: "",
              address2: "",
              city: "",
              stateProvince: "",
              zipCode: "",
              country: "",
              countryCode: null,
              addressType: "",
              parentOrganizationId: "",
            },
            {
              shippingAddressId: "",
              organizationName: null,
              organizationId: "",
              address1: "",
              address2: "",
              city: "",
              stateProvince: "",
              zipCode: "",
              country: "",
              countryCode: null,
              addressType: "",
              parentOrganizationId: "",
            },
            {
              shippingAddressId: "",
              organizationName: null,
              organizationId: "",
              address1: "",
              address2: "",
              city: "",
              stateProvince: "",
              zipCode: "",
              country: "",
              countryCode: null,
              addressType: "",
              parentOrganizationId: "",
            },
            {
              shippingAddressId: "",
              organizationName: null,
              organizationId: "",
              address1: "",
              address2: "",
              city: "",
              stateProvince: "",
              zipCode: "",
              country: "",
              countryCode: null,
              addressType: "",
              parentOrganizationId: "",
            },
          ],
          documents: [
            {
              documentId: null,
              documentName: "",
              documentType: "",
              uploadedDate: "",
              content: "",
              documentExtension: "",
              documentSize: "",
              documentCode: "",
            },
          ],
          products: [],
          consigneeName: null,
          country: null,
          direction: null,
          loadType: null,
          createdBy: "",
          createdDate: "",
          updatedBy: "",
          updatedDate: "",
          isRecordLocked: false,
          lockedUser: "",
          isActive: true,
          id: "",
        },
      };
    case RESET_SHIPMENT_DRAFT_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        availableCapacityLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case UPDATE_BREADCRUMB_DATA:
      return {
        ...state,
        customBreadCrumbData: action.data,
      };
    // Shipping Notes Section
    case SET_SHIPPING_NOTE_DETAILS_LOADING:
      return {
        ...state,
        shippingDetailLoading: true,
      };
    case GET_SHIPPING_NOTE_DETAILS_SUCCESS: {
      // console.log("Shipping Notes Details   GET_SHIPPING_NOTE_DETAILS_SUCCESS");
      return {
        ...state,
        shippingDetailLoading: false,
        shippingNotesDetailsData: action.payload,
      };
    }

    case GET_SHIPPING_NOTE_DETAILS_FAILURE: {
      // console.log("Shipping Notes Details  GET_SHIPPING_NOTE_DETAILS_FAILURE");
      return {
        ...state,
        shippingDetailLoading: false,
        error: true,
        errorMessage: "Unable to get shipping notes details",
      };
    }
    case RESET_SHIPPING_NOTE_DETAILS: {
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
        shippingNotesDetailsData: [],
        shippingDetailLoading: false,
      };
    }

    default:
      return state;
  }
};

export default ShipmentHistory;
