import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { widgetStyles } from "../widgetStyles";
import { CircularProgress } from "@mui/material";

export default function StatusCard({ statusIcon, statusText, count }) {
  const classes = widgetStyles();
  return (
    <Card className={classes.statusCard}>
      <CardContent className={classes.statusCardContent}>
        <img src={statusIcon} className={classes.statusIcon} alt={statusText} />
        <div className={classes.statusTextContainer}>
          <Typography
            variant="body22"
            className={classes.statusText}
            color="color.primary"
          >
            {statusText}
          </Typography>
          <Typography
            variant="h2A"
            className={classes.statusCount}
            color="#001737"
          >
            {count === -1 && <CircularProgress />}
            {count >= 0 && count}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
}
