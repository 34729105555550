import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Chart, {
  CommonAxisSettings,
  Format,
  Label,
  Series,
} from "devextreme-react/chart";
import { CircularProgress } from "@mui/material";
import { getDeliveryPurchaseOrder } from "../../../redux/widgets/action";

const DeliveryPurchaseOrder = () => {
  const [barData, setBarData] = useState([]);
  const { organizationId } = useSelector((state) => state.LoginUser);
  const [isLoading, setisLoading] = useState(true);

  const { deliveryPurchaseOrder } = useSelector(
    (state) => state.WidgetsReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (organizationId && deliveryPurchaseOrder === null)
      dispatch(getDeliveryPurchaseOrder(organizationId));
    //`/1/shipment-fourthpl/organization/${organizationId}/countrywise/deliveredcount?returnTopNRecords=${topRecords}`
  }, [organizationId]);

  useEffect(() => {
    if (deliveryPurchaseOrder) {
      setBarData(deliveryPurchaseOrder);
      setisLoading(false);
    }
  }, [deliveryPurchaseOrder]);

  const customizePoint = useCallback((arg) => {
    return { color: "url(#Gradient2)", hoverStyle: { color: "#5168db" } };
  }, []);

  return (
    <div>
      {isLoading && <CircularProgress />}
      {barData?.length === 0 && isLoading === false && (
        <div className="noData">
          <div className="countryName">
            No data available to generate the Report
          </div>
        </div>
      )}
      {barData?.length > 0 && (
        <>
          <Chart
            id="PurchaseOrderChart"
            dataSource={barData}
            style={{ height: "250px" }}
            rotated={true}
            customizePoint={customizePoint}
          >
            <CommonAxisSettings grid={false} maxValueMargin="0.10" />
            <Series
              valueField="deliveredOrdersCount"
              argumentField="country"
              name="Count of POs"
              type="bar"
              color="#826AF9"
              barWidth={40}
            >
              <Label
                visible={true}
                backgroundColor="#9E86FF"
                position="outside"
              >
                <Format type="fixedPoint" precision={0} />
              </Label>
            </Series>
          </Chart>

          <svg className="svg-patterns">
            <defs>
              <linearGradient id="Gradient2" x1="0" x2="1" y1="0" y2="0">
                <stop offset="10%" stopColor="#F7D2FF" />
                <stop offset="100%" stopColor="#475FD9" />
              </linearGradient>
            </defs>
          </svg>
        </>
      )}
    </div>
  );
};

export default DeliveryPurchaseOrder;
