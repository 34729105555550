import { takeEvery, put, all } from "redux-saga/effects";
import {
  GET_PAGEHEAD,
  GET_PAGEHEAD_FAILURE,
  GET_PAGEHEAD_SUCCESS,
  SET_LOADING,
  SET_PAGEHEAD,
  SET_PAGEHEAD_FAILURE,
  SET_PAGEHEAD_SUCCESS,
} from "./actionTypes";

function* getPageHeadSaga() {
  try {
    yield put({ type: SET_LOADING });
    yield put({ type: GET_PAGEHEAD_SUCCESS, payload: "" });
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_PAGEHEAD_FAILURE,
      payload: { errorMessage: "Unable to get pagehead details" },
    });
  }
}

function* setPageHeadSaga(pageHeadDetailsData) {
  try {
    yield put({ type: SET_LOADING });
    yield put({
      type: SET_PAGEHEAD_SUCCESS,
      payload: { ...pageHeadDetailsData.payload },
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: SET_PAGEHEAD_FAILURE,
      payload: { errorMessage: "Unable to set pagehead details" },
    });
  }
}

function* PageHeadDetailsSaga() {
  yield all([yield takeEvery(GET_PAGEHEAD, getPageHeadSaga)]);
  yield all([yield takeEvery(SET_PAGEHEAD, setPageHeadSaga)]);
}

export default PageHeadDetailsSaga;
