import { Grid, Typography, Paper, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: "14px",
    width: "100%",
    height: "80px",
    margin: "20px 0px 0px 0px",
  },
  cardContent: {
    position: "relative",
    height: "100%",
    width: "100%",
    backgroundColor: "#1F364E",
    borderRadius: "14px 140px 0px 14px",
    color: `${theme.palette.color.white}`,
    textAlign: "center",
    verticalAlign: "middle",
    fontWeight: 600,
    fontSize: "18px",
  },
  cardContentSelected: {
    position: "relative",
    height: "100%",
    width: "100%",
    backgroundColor: `${theme.palette.color.skyBlue}`,
    borderRadius: "14px 140px 0px 14px",
    color: `${theme.palette.color.white}`,
    textAlign: "center",
    verticalAlign: "middle",
    fontWeight: 600,
    fontSize: "18px",
  },
  cardText: {
    color: "#1F364E",
    textAlign: "center",
  },
}));

const FrachtStatusCard = ({ cardData, handleCardClick, selectedCard }) => {
  const classes = useStyles();
  const [status, setstatus] = useState([]);

  useEffect(() => {
    if (cardData) {
      const selectedCards = cardData.filter((card) => card.isSelected);
      let sorted_array = _.orderBy(selectedCards, ["index"], ["asc"]);
      setstatus([...sorted_array]);
    }
  }, [cardData]);

  return (
    <>
      <>
        <Grid container spacing={2.5}>
          {status.map((card, key) => {
            const { label, count, isSelected } = card;
            if (isSelected) {
              return (
                <>
                  <Grid item xs={12 / 5}>
                    <Paper
                      elevation={3}
                      key={key}
                      id={`card--${label}`}
                      className={classes.card}
                      data-testid={`${label}${key}`}
                      onClick={() => handleCardClick(card)}
                      style={{
                        border:
                          selectedCard?.some(
                            (selectedCard) => selectedCard === card
                          ) && "1px solid #007dba",
                      }}
                    >
                      <Grid
                        item
                        xs={6}
                        className={`${
                          selectedCard?.some(
                            (selectedCard) => selectedCard === card
                          )
                            ? classes.cardContentSelected
                            : classes.cardContent
                        }`}
                      >
                        <div
                          style={{
                            position: "absolute",
                            left: "40%",
                            top: "40%",
                          }}
                        >
                          {count}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className={classes.cardText} variant="h5">
                          {label}
                        </Typography>
                      </Grid>
                    </Paper>
                  </Grid>
                </>
              );
            }
          })}
        </Grid>
      </>
    </>
  );
};

export default FrachtStatusCard;
