import React, { useEffect, useMemo, useState } from "react";
import API from "../../utils/axios";
import moment from "moment";
import GridControl from "../../commonControls/devExGrid/gridControl";
import { DefaultGridConfiguration } from "../../commonControls/devExGrid/grid-settings";
import { Column } from "devextreme-react/data-grid";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

const POHistory = () => {
  const [dataLoading, setDataLoading] = useState(false);
  const [gridData, setGridData] = useState([]);
  const [rows, setRows] = useState([]);
  const { organizationId } = useSelector((state) => state.LoginUser);
  const childRef = React.useRef();
  const [noDataText, setnoDataText] = useState("");

  const gridConfig = useMemo(() => {
    return {
      ...DefaultGridConfiguration,
      defaultColums: false,
      height: "calc(100vh - 190px)",
      Id: "PO-History",
      columAutoWidth: true,
      columnMinWidth: 100,
    };
  }, []);

  useEffect(() => {
    let temp = rows?.map((obj, index) => ({
      ...obj,
      createdDate: new Date(obj.createdDate),
    }));
    setGridData(temp);
  }, [rows]);

  /**Very first call to get data */
  useEffect(() => {
    if (organizationId) {
      fetchPOHistoryData();
    }
  }, [organizationId]);

  const fetchPOHistoryData = () => {
    if (organizationId) {
      setDataLoading(true);
      API.get("/1/genericposhipmentreceive/shipmentporeceivehistory")
        .then((resp) => {
          if (resp?.data?.value && resp?.data?.value.length > 0) {
            setRows(resp?.data?.value);
          } else {
            setRows([]);
            setnoDataText("No data available");
          }
          setDataLoading(false);
        })
        .catch((error) => {
          setDataLoading(false);
        });
    }
  };

  const renderColumns = (head) => {
    switch (head.id) {
      case "createdDate":
        return (
          <Column
            allowSorting={true}
            dataField={head.id}
            caption={head.label.toLowerCase()}
            width={head.width}
            dataType="date"
            cellRender={(cell) => {
              if (cell.data[head.id]) {
                return (
                  <span>{moment(cell.data[head.id]).format(`MM/DD/YYYY`)}</span>
                );
              }
            }}
          />
        );
      default:
        return (
          <Column
            allowSorting={true}
            width={head.width}
            dataField={head.id}
            caption={head.label.toLowerCase()}
            dataType="string"
            cellRender={(cell) => {
              return (
                <span title={cell.data[head.id]}>{cell.data[head.id]}</span>
              );
            }}
          />
        );
    }
  };

  const onCellPrepared = (e) => {
    if (e.rowType === "header") {
      e.cellElement.id = e.column.dataField;
    }
  };

  return (
    <>
      <Helmet>
        <title>PO History</title>
      </Helmet>
      <GridControl
        showLoader={dataLoading}
        gridData={gridData}
        rowTemplate={null}
        onGridChange={() => {}}
        gridConfiguration={gridConfig}
        gridColums={[]}
        onCellClick={() => {}}
        childRef={childRef}
        noDataText={noDataText}
        onSelectionChanged={() => {}}
        onCellPrepared={(e) => onCellPrepared(e)}
      >
        {headCells.map((head) => {
          return renderColumns(head);
        })}
      </GridControl>
    </>
  );
};

export default POHistory;

const headCells = [
  {
    id: "createdDate",
    label: "Receiving Date",
    width: "20%",
  },
  {
    id: "receiveName",
    label: "Receiving ID",
    width: "40%",
  },
  {
    id: "createdBy",
    label: "Created By",
    width: "20%",
  },
  {
    id: "shipmentId",
    label: "Shipment ID",
    width: "20%",
  },
];
