import {
  REFRESH_SETTINGS_GRID,
  CLEAR_MILLERCOORS_SETTINGS,
} from "./actionTypes";

const initialState = {
  gridName: "",
};

const MillerCoorsSettings = (state = initialState, action) => {
  switch (action.type) {
    case REFRESH_SETTINGS_GRID:
      return {
        ...state,
        gridName: action.payload,
      };

    case CLEAR_MILLERCOORS_SETTINGS:
      return {
        ...state,
        gridName: "",
      };

    default:
      return state;
  }
};

export default MillerCoorsSettings;
