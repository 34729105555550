export const SET_LOADING = "SET_LOADING";

export const GET_READY_TO_PICKUP_DETAIL = "GET_READY_TO_PICKUP_DETAIL";
export const GET_READY_TO_PICKUP_DETAIL_SUCCESS =
  "GET_READY_TO_PICKUP_DETAIL_SUCCESS";
export const GET_READY_TO_PICKUP_DETAIL_FAILURE =
  "GET_READY_TO_PICKUP_DETAIL_FAILURE";

export const RESET_READY_TO_PICKUP_DETAIL = "RESET_READY_TO_PICKUP_DETAIL";
export const RESET_READY_TO_PICKUP_DETAIL_SUCCESS =
  "RESET_READY_TO_PICKUP_DETAIL_SUCCESS";
export const RESET_READY_TO_PICKUP_DETAIL_FAILURE =
  "RESET_READY_TO_PICKUP_DETAIL_FAILURE";
