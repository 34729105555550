export const GET_SHIPMENT_DETAIL = "GET_SHIPMENT_DETAIL";
export const GET_SHIPMENT_DETAIL_SUCCESS = "GET_SHIPMENT_DETAIL_SUCCESS";
export const GET_SHIPMENT_DETAIL_FAILURE = "GET_SHIPMENT_DETAIL_FAILURE";
export const SET_LOADING = "SET_LOADING";

export const RESET_SHIPMENT_DETAIL = "RESET_SHIPMENT_DETAIL";
export const RESET_SHIPMENT_DETAIL_SUCCESS = "RESET_SHIPMENT_DETAIL_SUCCESS";
export const RESET_SHIPMENT_DETAIL_FAILURE = "RESET_SHIPMENT_DETAIL_FAILURE";

export const GET_CLIENT_NAME = "GET_CLIENT_NAME";
export const GET_CLIENT_NAME_SUCCESS = "GET_CLIENT_NAME_SUCCESS";
export const GET_CLIENT_NAME_FAILURE = "GET_CLIENT_NAME_FAILURE";
