import { TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    fontWeight: 400,
    fontSize: "0.75rem",
    lineHeight: "1.66",
    textAlign: "left",
    marginTop: "3px",
    color: "#cc3629",
    marginLeft: "12px",
    marginRight: "0",
  },
  notes: {
    backgroundColor: `${theme.palette.color.white} !important`,
    boxShadow: "2px 2px 6px #0000000D",
    border: "none !important",
    borderRadius: "6px",
    width: "100%",
    "& label": {
      ...theme.typography.body2,
    },
    "&:hover": {
      backgroundColor: "#34CCFF0D !important",
      opacity: 1,
    },
  },
  disabledNotes: {
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    boxShadow: "2px 2px 6px #0000000D",
    border: "none !important",
    borderRadius: "6px",
    width: "100%",
    "& label": {
      ...theme.typography.body2,
    },
  },
  filledInput: {
    color: "#212529",
    ...theme.typography.body2,
  },
  filledRoot: {
    background: theme.palette.color.white,
    boxShadow: "2px 2px 6px #0000000D",
    borderRadius: "6px",
    opacity: 1,
    "&:hover": {
      background: "#34CCFF1A",
      border: "1px solid #34CCFF1A",
    },
  },
  errorFilledRoot: {
    background: theme.palette.color.white,
    border: "1px solid #CC3629",
    boxShadow: "2px 2px 6px #0000000D",
    borderRadius: "6px",
    opacity: 1,
  },
  underline: {
    "&:after": {
      borderBottom: "none !important",
    },
  },
  filledFocused: {
    border: `1px solid #34CCFF1A !important`,
    borderRadius: "3px !important",
    boxShadow: "0px 3px 6px #18365052",
  },
  error: {
    border: `1px solid #CC3629 !important`,
    borderRadius: "3px !important",
    boxShadow: "0px 3px 6px #18365052",
  },
  labelRoot: {
    ...theme.typography.body2,
    opacity: 1,
    color: "#9E9E9E",
    "&.MuiInputLabel-shrink": {
      ...theme.typography.small,
      marginTop: "5px",
    },
    "& .MuiFormLabel-asterisk": {
      color: "#CC3629",
    },
  },
  errorlabelRoot: {
    ...theme.typography.body2,
    opacity: 1,
    color: "#CC3629",
    "&.MuiInputLabel-shrink": {
      ...theme.typography.small,
      marginTop: "7px",
    },
    "& .MuiFormLabel-asterisk": {
      color: "#CC3629 !important",
    },
    "&:hover": {
      color: "#CC3629 !important",
    },
  },
  labelFocused: {
    ...theme.typography.small,
    color: "#9E9E9E !important",
    opacity: 1,
    marginTop: "0.1px",
  },
  labelFocusedError: {
    ...theme.typography.small,
    color: "#CC3629 !important",
  },
  helperTextRoot: {
    color: "#CC3629",
    ...theme.typography.small,
  },
}));

const FracgtTextArea = ({
  placeholder,
  onChange,
  value,
  maxRows,
  minRows,
  disabled = false,
  errorMsg = false,
  dataTestId = "noteInput1",
  ...rest
}) => {
  const classes = useStyles();

  return (
    <>
      <TextField
        {...rest}
        className={disabled ? classes.disabledNotes : classes.notes}
        inputProps={{ maxLength: 1024, "data-testid": dataTestId }}
        disabled={disabled}
        placeholder={placeholder}
        variant="outlined"
        value={value}
        onChange={onChange}
        multiline
        minRows={minRows || 6}
        maxRows={maxRows || 6}
        style={{ marginTop: "0 !important" }}
        InputProps={{
          classes: {
            root: Boolean(errorMsg) ? classes.errorFilledRoot : classes.notes,
            input: classes.filledInput,
            focused: Boolean(errorMsg) ? classes.error : classes.filledFocused,
            underline: classes.underline,
          },
          disableUnderline: true,
        }}
        FormHelperTextProps={{
          classes: {
            root: classes.helperTextRoot,
          },
        }}
      />
      {errorMsg && (
        <Typography className={classes.errorMessage}>{errorMsg}</Typography>
      )}
    </>
  );
};

export default FracgtTextArea;
