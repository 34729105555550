import {
  GET_PARENT_ORGID_LIST_FAILURE,
  GET_PARENT_ORGID_LIST_SUCCESS,
  GET_PARENT_ORGANIZATION_LIST_SUCCESS,
  GET_PARENT_ORGANIZATION_LIST_FAILURE,
  SET_LOADING,
  GET_LOCAL_BRANCH_LIST_SUCCESS,
  GET_LOCAL_BRANCH_LIST_FAILURE,
  SET_BRANCH_LOADING,
  CLEAR_LOCAL_BRANCH_LIST,
  GET_CUSTOMER_ID_LIST_SUCCESS,
  GET_CUSTOMER_ID_LIST_FAILURE,
} from "./actionTypes";

const initialState = {
  parentOrganizationList: [], // add organization and edit organization
  parentOrgIdListDetails: [], // In user tab
  localBranchList: [],
  custIdList: [],
  branchLoading: false,
  loading: false,
  error: false,
  errorMessage: "",
};

const NewOrganization = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    // In user tab
    case GET_PARENT_ORGID_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        parentOrgIdListDetails: action.payload,
      };
    case GET_PARENT_ORGID_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.errorMessage,
      };

    // add organization and edit organization
    case GET_PARENT_ORGANIZATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        parentOrganizationList: action.payload,
      };
    case GET_PARENT_ORGANIZATION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.errorMessage,
      };

    case SET_BRANCH_LOADING:
      return {
        ...state,
        branchLoading: true,
        error: false,
        errorMessage: "",
      };

    case GET_LOCAL_BRANCH_LIST_SUCCESS:
      return {
        ...state,
        branchLoading: false,
        localBranchList: action.payload,
      };
    case GET_LOCAL_BRANCH_LIST_FAILURE:
      return {
        ...state,
        branchLoading: false,
        error: true,
        errorMessage: action.payload.errorMessage,
      };
    case GET_CUSTOMER_ID_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        custIdList: action.payload,
      };
    case GET_CUSTOMER_ID_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.errorMessage,
      };
    case CLEAR_LOCAL_BRANCH_LIST:
      return {
        ...state,
        localBranchList: [],
      };

    default:
      return state;
  }
};

export default NewOrganization;
