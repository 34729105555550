import { takeEvery, put, all } from "redux-saga/effects";
import API from "../../utils/axios";

import { GET_APP_SETTINGS, GET_APP_SETTINGS_SUCCESS } from "./actionType";

function* getAppSettingSaga({ orgId }) {
  try {
    const { data } = yield API.get(
      `/1/organizationsetting/${orgId}/app/setting`
    );
    if (data) {
      yield put({ type: GET_APP_SETTINGS_SUCCESS, payload: data?.value });
    }
    //  else {
    //   yield put({
    //     type: GET_APP_SETTINGS_SUCCESS,
    //     payload: {
    //       showCarrierNameShipmentSummary: true,
    //       showCarrierNameTrackAndTrace: false,
    //     },
    //   });
    // }
  } catch (error) {
    console.log("error", error);
  }
}

function* AppSettingSaga() {
  yield all([yield takeEvery(GET_APP_SETTINGS, getAppSettingSaga)]);
}

export default AppSettingSaga;
