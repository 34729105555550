import { LogLevel } from "@azure/msal-browser";

export const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1A_SIGNUP_SIGNIN",
    forgotPassword: "B2C_1A_PASSWORDRESET",
  },
  authorities: {
    signUpSignIn: {
      authority:
        "https://myfracht.b2clogin.com/myfracht.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN",
    },
    forgotPassword: {
      authority:
        "https://myfracht.b2clogin.com/myfracht.onmicrosoft.com/B2C_1A_PASSWORDRESET",
    },
  },
  authorityDomain: "myfracht.b2clogin.com",
};

export const msalConfig = {
  auth: {
    clientId: `${process.env.REACT_APP_CLIENT_ID}`,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    redirectUri: `${process.env.REACT_APP_REDIRECT_URI}`,
    knownAuthorities: ["myfracht.b2clogin.com"],
    validateAuthority: false,
    postLogoutRedirectUri: "window.location.origin",
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            return;
          case LogLevel.Info:
            return;
          case LogLevel.Verbose:
            return;
          case LogLevel.Warning:
            return;
          default:
          // do nothing
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: [
    `https://myfracht.onmicrosoft.com/${process.env.REACT_APP_SCOPE}/access_as_user`,
  ],
  prompt: "login",
};

export const graphConfig = {
  graphMeEndpoint: "Enter_the_Graph_Endpoint_Herev1.0/me",
};
