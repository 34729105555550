import React from "react";
import Button from "../components/UI/Button/Button";
import FrachtButtonRibbon from "./frachtButtonRibbon";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  cancelButton: {
    ...theme.typography.h6,
    minWidth: "93px",
    height: "38px",
    color: `${theme.palette.color.skyBlue} !important`,
    background: `${theme.palette.color.white} !important`,
    border: "none",
    textTransform: "uppercase",
    letterSpacing: "0px",
    marginRight: "10px",
    "&:hover": {
      color: `${theme.palette.color.cyanBlueC1} !important`,
      background: `${theme.palette.color.white} !important`,
      opacity: 1,
    },
  },
  assignButton: {
    ...theme.typography.body2,
    minWidth: "127px",
    height: "38px",
    backgroundColor: `${theme.palette.color.skyBlue} !important`,
    color: `${theme.palette.color.white} !important`,
    border: `0.5px solid ${theme.palette.color.lightGrayishBlueB1}`,
    boxShadow: "0px 2px 7px #00000029",
    borderRadius: "4px",
    textTransform: "capitalize",
    opacity: 1,
    "&:hover": {
      backgroundColor: `${theme.palette.color.white} !important`,
      color: `${theme.palette.color.skyBlue} !important`,
      border: `1px solid ${theme.palette.color.skyBlue} !important`,
      opacity: 1,
    },
  },
  disabledButton: {
    ...theme.typography.body2,
    minWidth: "127px",
    height: "38px",
    backgroundColor: `${theme.palette.color.grayS6} !important`,
    border: `1px solid ${theme.palette.color.grayS7} !important`,
    color: `${theme.palette.color.lightGrayishBlueB3} !important`,
    boxShadow: "0px 2px 7px #00000029",
    borderRadius: "4px",
    textTransform: "capitalize",
    marginRight: "10px",
    cursor: "default",
    opacity: 1,
  },
}));

const FrachtButtonPanel = ({
  firstButtonText,
  firstHandler,
  secondButtonText,
  secondHandler,
  disabled = false,
  marginLeft = "-20px",
}) => {
  const classes = useStyles();

  return (
    <FrachtButtonRibbon marginLeft={marginLeft}>
      <Button
        className={classes.cancelButton}
        onClick={firstHandler}
        id={firstButtonText}
      >
        {firstButtonText}
      </Button>
      <Button
        className={disabled ? classes.disabledButton : classes.assignButton}
        onClick={secondHandler}
        disabled={disabled}
        id={secondButtonText}
      >
        {secondButtonText}
      </Button>
    </FrachtButtonRibbon>
  );
};

export default React.memo(FrachtButtonPanel);
