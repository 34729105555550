import { statusObject } from "../constants/routesBasedOnStatus";

export const getRouteBasedOnStatus = (userStatus) => {
  let route = "";
  statusObject.forEach((ele, ind) => {
    if (ele.code === userStatus) {
      route = ele.link;
    }
  });
  return route;
};

export const getFlagBasedOnStatus = (userStatus) => {
  let flagValue = "";
  statusObject.forEach((ele, ind) => {
    if (ele.code === userStatus) {
      flagValue = ele.flag;
    }
  });
  return flagValue;
};

export const getToolTipBasedOnStatus = (userStatus) => {
  let tooltip = "";
  statusObject.forEach((ele, ind) => {
    if (ele.code === userStatus) {
      tooltip = ele.toolTipText;
    }
  });
  return tooltip;
};
