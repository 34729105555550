import React, {
  Suspense,
  lazy,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import API from "../../../utils/axios";
import Toast from "../../UI/Toast/Toast";
import Loader from "../../UI/Loader/Loader";

import { Column } from "devextreme-react/data-grid";
import { useDSStyle } from "./dsStyles";
import GridControl from "../../../commonControls/devExGrid/gridControl";
import { DefaultGridConfiguration } from "../../../commonControls/devExGrid/grid-settings";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  getFlagBasedOnStatus,
  getToolTipBasedOnStatus,
} from "../../../utils/statusRouting";
import {
  resetAvailableCapacityDetailAction,
  resetReadyToPickupDetailAction,
} from "../../../redux/actions";
import {
  statusEnum,
  statusObject,
} from "../../../constants/routesBasedOnStatus";
import FrachtSearchControl from "../../../commonControls/frachtSearchControl";

import BlackLock from "../../../assets/images/BlackLock.png";
import BlackUnlock from "../../../assets/images/BlackUnlock.png";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import fileHandler from "../../../utils/fileHandler";
import FrachtSuspense from "../../../commonControls/FrachtSuspense";
import { DropDownButton } from "devextreme-react";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";

import FrachtCheckBox from "../../../commonControls/FrachtCheckBox";
import DocumentDialogBoxNew from "../../ShipmentHistory/DocumentDialogBoxNew";
const BookingCreationDialogBox = lazy(() =>
  import("../BookingCreationDialogBox")
);

const FrachtDeleteDialogBox = React.lazy(() =>
  import("../../../commonControls/frachtDeleteDialogBox")
);

const options = ["Accepted", "Rejected"];

const DirectSummaryClient = ({
  url = "/1/direct-summary/page/records?isPaginationOn=false",
  isCompleted = false,
}) => {
  const classes = useDSStyle();
  const navigate = useNavigate();
  const { canDeleteLock } = useSelector((state) => state.MenuVisibility);

  const { user } = useSelector((state) => state.LoginUser);
  const [isDownloading, setIsDownloading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [gridData, setGridData] = useState([]);
  const [rows, setRows] = useState([]);
  const [token, setToken] = useState("");
  const [deleteLockMessage, setDeleteLockMessage] = useState("");
  const [modelMessage, setModelMessage] = useState("");
  const [directSummaryDetails, setDirectSummaryDetails] = useState();
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState(false);
  const [selected, setSelected] = useState([]);
  const [searchField, setSearchField] = useState("");
  const pageSize = 30;
  const childRef = useRef();
  const [noDataText, setnoDataText] = useState("");
  const [contextMenu, setContextMenu] = useState(null);
  const [dialogResponseMesage, setDialogResponseMesage] = useState("");
  // document dialog box
  const [docDialogMessage, setDocDialogMessage] = useState("");
  const [docRowData, setDocRowData] = useState([]);
  const [docDataLoading, setDocDataLoading] = useState(false);
  const [isShipmentDetailsSet, setIsShipmentDetailsSet] = React.useState(false);

  const handleClose = () => {
    setContextMenu(null);
  };

  const dispatch = useDispatch();

  const gridConfig = useMemo(() => {
    return {
      ...DefaultGridConfiguration,
      defaultColums: false,
      height: `calc(100vh - ${isCompleted ? "265" : "165"}px)`,
      // Id: "directSummary",
      Id: !isCompleted
        ? "directSummary-LiveData"
        : "directSummary-CompletedData",
      columAutoWidth: true,
      allowColumnResizing: true,
      allowColumnReordering: true,
      statePersistence: true,
      filterRow: true,
      columnMinWidth: 70,
      headerFilter: true,
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = (
    continuationToken = "",
    columnName = "",
    order = "",
    searchKeyword = "",
    multiSearchColumns = {}
  ) => {
    setDataLoading(true);
    API.post(url, {
      pageSize: pageSize,
      continuationToken: continuationToken ? btoa(continuationToken) : "",
      columnName,
      order,
      searchKeyword,
      multiSearchColumns,
    })
      .then((resp) => {
        const _rows = [...rows];
        if (
          resp?.data?.value?.directSummary &&
          resp?.data?.value?.directSummary.length > 0
        ) {
          if (searchField) {
            setRows(resp.data.value.directSummary);
          } else {
            if (continuationToken === "") {
              setRows(resp.data.value.directSummary);
            } else {
              const rowArray = _rows.concat(resp.data.value.directSummary);
              setRows(rowArray);
            }
            setToken(resp.data.value.continuationToken);
          }
        } else {
          setRows([]);
          setToken("");
          setnoDataText(!dataLoading ? "No data available" : "");
        }
        setDataLoading(false);
      })
      .catch((error) => {
        console.log("history error", error);
        setDataLoading(false);
      });
  };
  /**
   * This Function is used to remove the sorting Icon
   * when order of data is changed
   */
  const clearSort = () => {
    childRef.current.clearSort();
  };

  const clearFilter = () => {
    childRef.current.clearFilter();
  };

  /**
   * This Function is used set scroll position to 0
   * this require when your at page no N and via search data is reset
   */
  const resetScroll = () => {
    childRef.current.resetGridScroll();
  };

  /**
   * Before sending data to grid format your data
   */
  useEffect(() => {
    let temp = rows.map((obj) => ({
      ...obj,
      erd: obj.erd ? new Date(obj.erd) : "",
      documentCutOffDate: obj.documentCutOffDate
        ? new Date(obj.documentCutOffDate)
        : "",
      cutOffDate: obj.cutOffDate ? new Date(obj.cutOffDate) : "",
      etd: obj.etd ? new Date(obj.etd) : "",
      eta: obj.eta ? new Date(obj.eta) : "",
      droppedBreweryDate: obj.droppedBreweryDate
        ? new Date(obj.droppedBreweryDate)
        : "",
      loadedDate: obj.loadedDate ? new Date(obj.loadedDate) : "",
      emptyOutDate: obj.emptyOutDate ? new Date(obj.emptyOutDate) : "",
      pickedUpDate: obj.pickedUpDate ? new Date(obj.pickedUpDate) : "",
      storageYardDateIn: obj.storageYardDateIn
        ? new Date(obj.storageYardDateIn)
        : "",
      storageYardDateOut: obj.storageYardDateOut
        ? new Date(obj.storageYardDateOut)
        : "",
      ingateDate: obj.ingateDate ? new Date(obj.ingateDate) : "",
      cobDate: obj.cobDate ? new Date(obj.cobDate) : "",
      departedDate: obj.departedDate ? new Date(obj.departedDate) : "",
      ataDate: obj.ataDate ? new Date(obj.ataDate) : "",
      updatedDate: obj.updatedDate ? new Date(obj.updatedDate) : "",
    }));
    setGridData(temp);
  }, [rows]);

  const onGridChange = (e) => {
    try {
      if (e.fullName === "paging.pageIndex") {
      } else if (e.fullName.indexOf(".sortOrder") > 0) {
        resetScroll();
      } else if (e.fullName.indexOf(".filterValue") > 0) {
        // resetScroll();
      }
    } catch (error) {}
  };

  const handleClear = () => {
    clearSort();
    clearFilter();
    resetScroll();
    setSearchField("");
    setToken("");
    setGridData([]);
    fetchData();
  };

  const handleRefresh = () => {
    setGridData([]);
    fetchData();
  };
  const renderCutoffWeekCell = (cell) => {
    const row = cell.data;
    if (row.isRecordLocked) {
      return (
        <Grid style={{ display: "inline-flex", justifyContent: "flex-end" }}>
          <img
            id="lockIcon"
            //remove lock Icon & show unlock icon
            onMouseOver={({ target }) => {
              if (canDeleteLock) {
                target.src = BlackUnlock;
                target.style.transform = "scale(1.5)";
              }
            }}
            //remove Unlock Icon & show lock icon
            onMouseOut={({ target }) => {
              target.src = BlackLock;
              target.style.transform = "scale(1.0)";
            }}
            onClick={() => {
              canDeleteLock && checkIfLockExists(row);
            }}
            src={BlackLock}
            alt="lock"
            className={classes.lockIcon}
          />
          <p style={{ marginTop: "0px" }}>{row.week}</p>
        </Grid>
      );
    }
    return row.week;
  };

  const callRespectiveAPI = async (status, id) => {
    if (status === 2) {
      // call ready-to-pickup
      return await API.get(`/1/direct-summary/${id}/details/ready-to-pickup`);
    } else if (status === 1) {
      // call assign booked
      return await API.get(`/1/direct-summary/${id}/details/booked`);
    } else return false;
  };

  const updateDispatchStatus = (row, selectedStatus) => {
    setIsDownloading(true);
    API.put(`/1/direct-summary/update-trucker-status?isManualUpdate=true`, {
      id: row.id,
      status: selectedStatus,
      trucker: row.trucker,
      updatedBy: user?.userName,
    })
      .then(async (resp) => {
        if (resp.status === 200) {
          try {
            const res = await callRespectiveAPI(2, row.id);
            if (res.status === 200) {
              if (selectedStatus === "Accepted") {
                navigate("/editDetails", {
                  state: {
                    readyToBookDetails: res.data.value,
                    status: 4,
                  },
                });
              } else {
                navigate("/editDetails", {
                  state: {
                    readyToBookDetails: res.data.value,
                    status: 5,
                  },
                });
              }
            }
          } catch (err) {
            console.log("RTP error", err);
          }
        }
        setIsDownloading(true);
      })
      .catch((error) => {
        console.log("handleUpdateDispatched error", error);
        setToastType(false);
        setToastMessage(
          "Something went wrong at server. Please contact administrator"
        );
        setIsDownloading(true);
      });
    handleClose();
  };

  const assignNavigation = (status, id, data, statusId) => {
    if (status === 2) {
      // call ready-to-pickup
      navigate("/editDetails", {
        state: {
          readyToBookDetails: data,
          status: statusId,
        },
      });
    } else if (status === 1) {
      // call assign booked
      return navigate("/assignContainer", {
        state: {
          loadNumberDetails: data,
          id: id,
          status: statusId,
        },
      });
    } else return false;
  };

  const checkIfLockExists = async (row) => {
    const status = statusEnum[row.status.replace(/ +/g, "").toLowerCase()];
    try {
      const res = await callRespectiveAPI(getFlagBasedOnStatus(status), row.id);
      console.log("called", res);
      if (res.status === 200 && res.data.value.isRecordLocked) {
        setModelMessage("Release Lock");
        setDeleteLockMessage(
          `Are you sure you want to release the Lock ?<${row.id}`
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  //call release lock api
  const releaseLock = async () => {
    setDataLoading(true);
    //getting the id from message
    if (deleteLockMessage && deleteLockMessage.split("<")[1]) {
      const id = deleteLockMessage.split("<")[1];
      if (id) {
        try {
          const res = await API.delete(`/1/lock/entity/${id}`);
          if (res) {
            dispatch(resetReadyToPickupDetailAction());
            dispatch(resetAvailableCapacityDetailAction());

            setDirectSummaryDetails({
              ...directSummaryDetails,
              isRecordLocked: "",
              id: "",
              lockedUser: "",
            });
            const temp = [...rows];
            temp.map((ele) => {
              if (ele.id === id) {
                ele.isRecordLocked = false;
                ele.lockedUser = "";
              }
              return ele;
            });
            setGridData(temp);
          }
        } catch (error) {
          console.log("error", error);
        } finally {
          setDeleteLockMessage("");
        }
      }
    }
    setDataLoading(false);
  };

  const onRowClick = (e) => {
    const { selectedRowsData } = e;
    selectedRowsData.length > 0 &&
      getLatestRowData(selectedRowsData[0].id, "8");
  };

  const getLatestRowData = async (id, _flagValue) => {
    const response = await API.get(`/1/direct-summary/${id}/details/booked`);
    if (response && response.status === 200) {
      setDirectSummaryDetails(response.data.value);
    }
  };
  const getLockText = (id) => {
    return directSummaryDetails?.isRecordLocked &&
      id === directSummaryDetails?.id
      ? `This record is being edited by ${directSummaryDetails?.lockedUser}`
      : "";
  };

  const getToolTipText = (row) => {
    const status = statusEnum[row.status.replace(/ +/g, "").toLowerCase()];
    return getToolTipBasedOnStatus(status);
  };

  const renderStatusCell = (cell) => {
    const row = cell.data;
    const status = statusEnum[row.status.replace(/ +/g, "").toLowerCase()];

    if (row.status !== "Dispatched") {
      return (
        <Grid
          align="left"
          className={`${
            classes[`${row.status.replace(/ +/g, "").toLowerCase()}Cursor`]
          }`}
          onClick={async () => {
            try {
              const res = await callRespectiveAPI(
                getFlagBasedOnStatus(status),
                row.id
              );
              if (
                res.status === 200 &&
                res.data.value.isRecordLocked === false
              ) {
                assignNavigation(
                  getFlagBasedOnStatus(status),
                  row.id,
                  res.data.value,
                  status
                );
              }
            } catch (error) {
              console.log("error", error);
            }
          }}
        >
          <Tooltip
            arrow
            placement="right"
            title={getToolTipText(row)}
            followCursor={true}
          >
            <Stack direction="row" pt={1} spacing={1}>
              <Grid className={classes.titleTypography}>
                <FiberManualRecordIcon
                  className={
                    classes[
                      `${row.status.replace(/ +/g, "").toLowerCase()}Icon`
                    ]
                  }
                />
              </Grid>
              {row.status}
            </Stack>
          </Tooltip>
        </Grid>
      );
    } else {
      return (
        <Box className={classes.dispatchedCell} style={{ cursor: "pointer" }}>
          <div className="dx-field">
            <div
              className="dx-field-value"
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <Grid
                className={classes.titleTypography}
                style={{ padding: "8px 0px 0 0" }}
              >
                <FiberManualRecordIcon
                  className={
                    classes[
                      `${row.status.replace(/ +/g, "").toLowerCase()}Icon`
                    ]
                  }
                />
              </Grid>
              <DropDownButton
                className={classes.dropDownCell}
                text={row.status}
                items={options}
                onItemClick={(e) => updateDispatchStatus(row, `${e.itemData}`)}
              />
            </div>
          </div>
        </Box>
      );
    }
  };
  const clearFilterVal = { showClearButton: true };

  function calculateFilterExpression(value, operation, target) {
    if (typeof value === "string") {
      value = value.trim();
    }
    return this.defaultCalculateFilterExpression(value, operation, target);
  }

  const handleDocumentBox = (id, loadNumber) => {
    setDataLoading(true);
    API.get(`/1/direct-summary/${id}/document`)
      .then((resp) => {
        if (resp.status === 200) {
          setDataLoading(false);
          setDocRowData(resp?.data?.value);
          setDocDialogMessage(loadNumber);
        }
      })
      .catch((err) => {
        console.log("handleGetDocumentData error: " + err);
        setToastType(false);
        setToastMessage(
          "Something went wrong at server. Please contact administrator"
        );
        setDataLoading(false);
      });
  };

  const renderCols = (head) => {
    switch (head.type || head.position) {
      case "date":
        return (
          <Column
            key={head.id}
            width={head.width}
            allowHeaderFiltering={false}
            dataField={head.id}
            dataType={"date"}
            caption={head.label}
            defaultSortIndex={head.defaultSortIndex}
            filterOperations={["="]}
            calculateFilterExpression={calculateFilterExpression}
            editorOptions={clearFilterVal}
            cellRender={(cell) => {
              if (cell.data[head.id]) {
                return (
                  <Tooltip title={getLockText(cell.data.id)}>
                    <span>
                      {moment(cell.data[head.id]).format(`MM/DD/YYYY`)}
                    </span>
                  </Tooltip>
                );
              }
            }}
          />
        );
      case "lock":
        return (
          <Column
            key={head.id}
            width={head.width}
            allowHeaderFiltering={true}
            alignment="center"
            dataField={head.id}
            dataType="number"
            caption={head.label}
            defaultSortIndex={head.defaultSortIndex}
            filterOperations={["contains"]}
            calculateFilterExpression={calculateFilterExpression}
            editorOptions={clearFilterVal}
            cellRender={(cell) => {
              return (
                <Tooltip title={getLockText(cell.data.id)}>
                  <span>{renderCutoffWeekCell(cell)}</span>
                </Tooltip>
              );
            }}
          />
        );
      case "document":
        return (
          <Column
            key={head.id}
            width={head.width}
            // allowHeaderFiltering={true}
            alignment="center"
            caption={head.label}
            cellRender={(cell) => {
              const row = cell.data;
              return (
                <BrowserUpdatedIcon
                  id="BrowserUpdatedIcon"
                  className={classes.iconButton}
                  onClick={() => {
                    handleDocumentBox(row.id, row.loadNumber);
                  }}
                />
              );
            }}
          />
        );
      case "fixed":
        return (
          <Column
            calculateFilterExpression={calculateFilterExpression}
            key={head.id}
            width={head.width}
            fixed={head.id === "status"}
            dataType={head.dataType ? head.dataType : "string"}
            fixedPosition="right"
            dataField={head.id}
            caption={head.label}
            defaultSortIndex={head.defaultSortIndex}
            filterOperations={["contains"]}
            editorOptions={clearFilterVal}
            allowResizing={false}
            cellRender={(cell) => {
              return (
                <Tooltip title={getLockText(cell.data.id)}>
                  <span>{renderStatusCell(cell)}</span>
                </Tooltip>
              );
            }}
          />
        );
      default:
        if (!isCompleted && head.id === "forwardReferenceNumber") {
          return null;
        }
        return (
          <Column
            key={head.id}
            width={head.width}
            dataField={head.id}
            dataType={head.dataType ? head.dataType : "string"}
            caption={head.label}
            filterOperations={head.dataType === "number" ? ["="] : ["contains"]}
            calculateFilterExpression={calculateFilterExpression}
            alignment={
              head.dataType === "number" || head.id === "customerId"
                ? "center"
                : "left"
            }
            editorOptions={clearFilterVal}
            allowHeaderFiltering={head.id === "etdWeek" ? true : false}
            cellRender={(cell) => {
              return (
                <Tooltip title={getLockText(cell.data.id)}>
                  <span>{cell.data[head.id]}</span>
                </Tooltip>
              );
            }}
          />
        );
    }
  };
  const header = useMemo(() => headCells, []);

  const onDeleteClick = () => {
    setModelMessage("Confirm Delete");
    setDeleteLockMessage(
      `Are you sure you want to delete the Load ? <${selected}`
    );
  };

  const handleDeleteRow = async () => {
    const selectedIds = selected?.map((i) => i.id);
    setIsDownloading(true);
    try {
      const resp = await API.put(`/1/direct-summary/cancel`, selectedIds);
      if (resp.status === 200) {
        if (resp.data.value === "Cancelled Successfully") {
          setToastType(true);
          handleClear();
          setToastMessage("Booking is deleted successfully.");
        } else if (
          resp.data.value ===
          "Booking Number is assigned to a Load, so cannot be cancelled."
        ) {
          setToastType(false);
          setToastMessage(
            "Booking cannot be deleted as Load is assigned to it."
          );
        }
      }
    } catch (err) {
      console.log("handleDeleteRow error: " + err);
      setToastType(false);
      setToastMessage(
        "Something went wrong at server. Please contact administrator"
      );
    } finally {
      setSelected([]);
      setIsDownloading(false);
      setDeleteLockMessage("");
    }
  };

  const onRowCheckBoxClick = (rowData) => {
    selected.findIndex((ele) => ele.id === rowData.id) === -1
      ? setSelected([
          ...selected,
          {
            id: rowData.id,
            status: rowData.status,
            bookingNumber: rowData.bookingNumber,
          },
        ])
      : setSelected([...selected].filter((ele) => ele.id !== rowData.id));
  };

  const isSelected = (name) =>
    selected.findIndex((ele) => ele.id === name) !== -1;

  const handleDownloadExcelData = () => {
    // read filter value from local storage
    let gridStateData = JSON.parse(
      localStorage.getItem(
        !isCompleted
          ? "grid-directSummary-LiveData"
          : "grid-directSummary-CompletedData"
      )
    );

    let newObject = {}; //To Check localStorage filterValue and set into setFilterObj
    let columnName = "";
    let order = "";
    let searchedStatus = [];
    let filteredStatus = [];
    let filteredETDWeek = [];
    let filteredCutOffWeek = [];
    let columnOrder = {};

    gridStateData?.columns?.forEach((column) => {
      let headSortObj = headCells.find((x) => x.id === column.dataField);
      if (column.filterValue && column.dataField !== "status") {
        // filterValue
        newObject[
          headSortObj?.searchColumnName
            ? headSortObj?.searchColumnName
            : column.dataField.charAt(0).toUpperCase() +
              column.dataField.slice(1)
        ] =
          typeof column.filterValue === "number"
            ? column.filterValue.toString()?.trim()
            : column.filterValue.includes("T18:30:00.000Z")
            ? `${moment(new Date(column.filterValue))
                .format("YYYY-MM-DD")
                ?.trim()}`
            : column.filterValue?.trim();
      }

      // sortOrder
      if (column.sortOrder) {
        columnName = headSortObj?.searchColumnName
          ? headSortObj?.searchColumnName
          : column.dataField.charAt(0).toUpperCase() +
            column.dataField.slice(1);
        order = column.sortOrder;
      }
      // columnOrder
      if (column.visibleIndex !== undefined && column.dataField) {
        columnOrder[column.visibleIndex?.toString()] = column.dataField;
      }
    });

    const statusColumn = gridStateData?.columns?.find(
      (column) => column.dataField === "status"
    );

    if (statusColumn?.filterType === "exclude") {
      // If filterType is "exclude," exclude specified filterValues
      const excludedFilterValues = statusColumn?.filterValues || [];
      filteredStatus = statusObject
        .filter((item) => !excludedFilterValues?.includes(item.status))
        .map((item) => item.status);
    } else {
      filteredStatus = statusColumn?.filterValues || [];
    }

    const searchStatusData = statusObject?.filter((item) =>
      item?.status
        .toLowerCase()
        .includes(statusColumn?.filterValue?.toLowerCase())
    );
    searchedStatus = searchStatusData?.map((item) => item.status);

    let filteredStatusValues = filteredStatus?.filter((status) =>
      searchedStatus?.some((search) => status.includes(search))
    );

    const etdWeekColumn = gridStateData?.columns?.find(
      (column) => column.dataField === "etdWeek"
    );

    if (etdWeekColumn?.filterValues != null) {
      filteredETDWeek =
        etdWeekColumn?.filterValues.map((item) => item.toString()) || [];
    }

    const cutOffWeekColumn = gridStateData?.columns?.find(
      (column) => column.dataField === "week"
    );
    if (cutOffWeekColumn?.filterValues != null) {
      filteredCutOffWeek =
        cutOffWeekColumn?.filterValues.map((item) => item.toString()) || [];
    }

    const payload = {
      columnName: columnName,
      order: order,
      searchStatus:
        filteredStatusValues?.length > 0
          ? filteredStatusValues
          : searchedStatus?.length > 0
          ? searchedStatus
          : filteredStatus?.length > 0
          ? filteredStatus
          : [],
      etdWeek: filteredETDWeek?.length > 0 ? filteredETDWeek : [],
      cutOffWeek: filteredCutOffWeek?.length > 0 ? filteredCutOffWeek : [],
      multiSearchColumns: newObject,
      columnOrder,
    };
    let requestURL = `1/direct-summary/download/iscompleted/${isCompleted}/records`;

    setDataLoading(true);
    API.post(requestURL, payload)
      .then((response) => {
        if (response?.status === 200) {
          const fileName = `DirectSummary-${moment(Date.now()).format(
            `MM-DD-YYYY`
          )}.xlsx`;
          fileHandler(response?.data, "application/csv", fileName);
          setDataLoading(false);
        } else {
          setToastType(false);
          setDataLoading(false);
          setToastMessage(
            "Something went wrong at server. Please contact administrator"
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
        setToastType(false);
        setDataLoading(false);
        setToastMessage(
          "Something went wrong at server. Please contact administrator"
        );
      });
  };

  const onCellPrepared = (e) => {
    if (e.rowType === "data") {
      if (e.column?.caption?.toLowerCase() === "load#") {
        e.cellElement.style.cssText =
          "color: #000000; background-color: #90EE90;font-weight:700";
        // or
        //e.cellElement.classList.add("my-class");
      }
      if (e.column?.dataField === "pickedUpDate") {
        if (e.data.isPickedUpDateSetByEDI === true) {
          e.cellElement.style.cssText = "color: DeepPink;";
        }
      }
      if (e.column?.dataField === "readyDate") {
        if (e.data.isReadyDateSetByEDI === true) {
          e.cellElement.style.cssText = "color: DeepPink;";
        }
      }
      if (e.column?.dataField === "storageYardDateIn") {
        if (e.data.isStagedInSetByEDI === true) {
          e.cellElement.style.cssText = "color: DeepPink;";
        }
      }
      if (e.column?.dataField === "storageYardDateOut") {
        if (e.data.isStagedOutSetByEDI === true) {
          e.cellElement.style.cssText = "color: DeepPink;";
        }
      }
      if (e.column?.dataField === "ingateDate") {
        if (e.data.isIngateSetByEDI === true) {
          e.cellElement.style.cssText = "color: DeepPink;";
        }
      }
      if (e.column?.dataField === "shippingInstructionReceivedDate") {
        if (e.data.isShippingInstructionReceivedDateSetByEDI === true) {
          e.cellElement.style.cssText = "color: DeepPink;";
        }
      }
      if (e.column?.dataField === "ataDate") {
        if (e.data.isATASetByEDI === true) {
          e.cellElement.style.cssText = "color: DeepPink;";
        }
      }
      if (e.column?.dataField === "departedDate") {
        if (e.data.isDepartedSetByEDI === true) {
          e.cellElement.style.cssText = "color: DeepPink;";
        }
      }
    }

    if (e.rowType === "header") {
      if (e.column.dataField) {
        let filter = e.cellElement.querySelector(".dx-header-filter");
        if (filter) {
          e.cellElement.querySelector(
            ".dx-header-filter"
          ).id = `hfltr${e.column.dataField}`;
        }
        e.cellElement.id = e.column.dataField;
      } else {
        e.cellElement.id = "checkboxColumn";
      }
    }
  };

  const HeaderComponent = () => {
    return (
      <>
        {selected.length > 0 &&
          (selected[0].status === "Booked" ? (
            <Tooltip title="Delete" followCursor placement="top">
              <DeleteForeverIcon
                className={classes.headerIconButton}
                onClick={() => {
                  selected.length > 0 && onDeleteClick();
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Create Shipment" followCursor placement="top">
              <LocalShippingIcon
                className={classes.headerIconButton}
                onClick={() => {
                  selected.length > 0 &&
                    setDialogResponseMesage(
                      `The following are the containers belonging to booking where Shipment will be created.`
                    );
                }}
              />
            </Tooltip>
          ))}
      </>
    );
  };

  return (
    <>
      {isDownloading && <Loader />}
      <Stack direction="row" spacing={3}>
        <FrachtSearchControl
          showText="Date fields highlighted in Pink are data received via EDI"
          searchValue={searchField}
          textId="millerCors-search"
          actionButtonText="Clear"
          handelActionButtonClick={handleClear}
          showActionButton={true}
          handleRefresh={handleRefresh}
          showRefreshButton={true}
          showDownloadButton={true}
          handleDownloadExcelData={handleDownloadExcelData}
          showSearchFilter={false}
          checkBoxComponent={true}
        >
          <HeaderComponent />
        </FrachtSearchControl>
      </Stack>
      {Boolean(toastMessage) && (
        <Toast
          open={Boolean(toastMessage)}
          message={toastMessage}
          handleClose={() => setToastMessage("")}
          success={toastType}
        />
      )}
      <DocumentDialogBoxNew
        open={Boolean(docDialogMessage)}
        message={docDialogMessage}
        handleClose={() => {
          setDocDialogMessage("");
        }}
        setDocRowData={setDocRowData}
        docRowData={docRowData}
        showMail={false}
        dataLoading={dataLoading}
        docDataLoading={dataLoading}
      />
      {Boolean(dialogResponseMesage) && (
        <FrachtSuspense>
          <BookingCreationDialogBox
            open={Boolean(dialogResponseMesage)}
            handleClose={() => {
              setDialogResponseMesage("");
              setSelected([]);
              handleClear();
            }}
            selected={selected}
            dialogContentText={dialogResponseMesage}
            changeDialogContentText={setDialogResponseMesage}
            setSelected={setSelected}
            handleRefresh={handleClear}
            setToastMessage={setToastMessage}
          />
        </FrachtSuspense>
      )}
      {Boolean(deleteLockMessage) && (
        <Suspense fallback={<Loader />}>
          <FrachtDeleteDialogBox
            open={Boolean(deleteLockMessage)}
            message={deleteLockMessage && deleteLockMessage.split("<")[0]}
            handleClose={() => {
              setDeleteLockMessage("");
            }}
            success={true}
            performAction={
              modelMessage === "Release Lock" ? releaseLock : handleDeleteRow
            }
            isDeleting={isDownloading}
            modalTitle={modelMessage}
          />
        </Suspense>
      )}
      <GridControl
        gridData={gridData}
        rowTemplate={null}
        onGridChange={onGridChange}
        gridConfiguration={gridConfig}
        gridColums={[]}
        onSelectionChanged={onRowClick}
        onCellClick={() => {}}
        childRef={childRef}
        noDataText={noDataText}
        showLoader={dataLoading}
        onCellPrepared={(e) => onCellPrepared(e)}
      >
        {!isCompleted && (
          <Column
            key="checkbox"
            width={40}
            fixed={true}
            fixedPosition="left"
            cellRender={(cell) => {
              const isItemSelected = isSelected(cell.data.id);
              return (
                <Tooltip title={getLockText(cell.data.id)}>
                  <>
                    <FrachtCheckBox
                      checked={isItemSelected}
                      changeHandler={() => {
                        onRowCheckBoxClick(cell.data);
                      }}
                      marginLeft="2px"
                      disabled={
                        !cell.data.isCheckboxEnable ||
                        cell.data.isRecordLocked ||
                        (() => {
                          if (selected.length > 0) {
                            const selectedStatus = selected[0]?.status;
                            const cellStatus = cell.data.status;
                            if (
                              selectedStatus === "Ingate" ||
                              selectedStatus === "Staged" ||
                              selectedStatus === "Picked Up" ||
                              selectedStatus === "Departed"
                            )
                              return cellStatus === "Booked";

                            if (selectedStatus === "Booked")
                              return (
                                cellStatus === "Ingate" ||
                                cellStatus === "Staged" ||
                                cellStatus === "Picked Up" ||
                                cellStatus === "Departed"
                              );
                          }
                        })()
                      }
                      style={{
                        padding: "0 0 0 12px",
                        margin: 0,
                        width: "12px",
                        height: "12px",
                      }}
                    />
                  </>
                </Tooltip>
              );
            }}
          />
        )}

        {header.map((head) => {
          return renderCols(head);
        })}
      </GridControl>
    </>
  );
};

export default DirectSummaryClient;

const headCells = [
  {
    id: "week",
    type: "lock",
    label: "CutOff Week",
    defaultSortIndex: 0,
    width: 180,
  },
  {
    id: "loadNumber",
    label: "Load#",
    defaultSortIndex: 1,
    width: 120,
  },
  {
    id: "containerNumber",
    label: "CTNR#",
    defaultSortIndex: 2,
    width: 160,
  },
  {
    id: "bookingNumber",
    label: "Booking #",
    defaultSortIndex: 3,
    width: 140,
  },
  {
    id: "etdWeek",
    label: "ETD Week",
    defaultSortIndex: 4,
    dataType: "number",
    width: 160,
    searchColumnName: "ETDWeek",
  },
  {
    id: "pol",
    label: "POL",
    defaultSortIndex: 5,
    width: 120,
    searchColumnName: "POL",
  },
  {
    id: "destination",
    label: "Destination",
    defaultSortIndex: 6,
    width: 140,
  },
  {
    id: "carrier",
    label: "Carrier",
    defaultSortIndex: 7,
    width: 120,
  },

  {
    id: "vessel",
    label: "Vessel",
    defaultSortIndex: 8,
    width: 120,
  },

  {
    id: "voyage",
    label: "Voyage",
    defaultSortIndex: 9,
    width: 120,
  },

  {
    id: "deliveryLocation",
    label: "Delivery Loc",
    defaultSortIndex: 10,
    width: 150,
  },
  {
    id: "containerQuantity",
    label: "Cont Qty",
    defaultSortIndex: 11,
    dataType: "number",
    width: 140,
  },
  {
    id: "erd",
    type: "date",
    label: "ERD",
    defaultSortIndex: 12,
    width: 120,
    searchColumnName: "ERD",
  },
  {
    id: "documentCutOffDate",
    type: "date",
    label: "Doc Cut",
    defaultSortIndex: 13,
    width: 120,
  },
  {
    id: "cutOffDate",
    type: "date",
    label: "Cut off",
    defaultSortIndex: 14,
    width: 120,
  },
  {
    id: "etd",
    type: "date",
    label: "ETD",
    defaultSortIndex: 15,
    width: 120,
    searchColumnName: "ETD",
  },
  {
    id: "eta",
    type: "date",
    label: "ETA",
    defaultSortIndex: 16,
    width: 120,
    searchColumnName: "ETA",
  },
  {
    id: "customerId",
    label: "Cust ID",
    defaultSortIndex: 17,
    width: 120,
  },
  {
    id: "loadedLocation",
    label: "Loaded Loc",
    defaultSortIndex: 18,
    width: 140,
  },
  {
    id: "readyDate",
    type: "date",
    label: "Ready Date",
    defaultSortIndex: 19,
    width: 140,
  },
  {
    id: "poNumber",
    label: "PO#",
    defaultSortIndex: 20,
    width: 120,
    searchColumnName: "PONumber",
  },

  {
    id: "molsonBookingReferenceNumber",
    label: "Molson Booking Ref#",
    defaultSortIndex: 21,
    width: 200,
  },

  {
    id: "forwardReferenceNumber",
    label: "Forward Reference #",
    defaultSortIndex: 22,
    width: 200,
  },

  {
    id: "droppedBreweryDate",
    type: "date",
    label: "Drpd Brewery",
    defaultSortIndex: 23,
    width: 140,
  },
  {
    id: "loadedDate",
    type: "date",
    label: "Loaded",
    defaultSortIndex: 24,
    width: 120,
  },
  {
    id: "trucker",
    label: "Trucker",
    defaultSortIndex: 25,
    width: 120,
  },
  {
    id: "emptyOutDate",
    label: "Empty Out",
    type: "date",
    defaultSortIndex: 26,
    width: 120,
  },
  {
    id: "bookingNumberEmptyPulledOut",
    label: "Booking # Empty",
    defaultSortIndex: 27,
    width: 180,
  },
  {
    id: "importPrePull",
    label: "Import/Pre-pull",
    defaultSortIndex: 28,
    width: 180,
  },
  {
    id: "fileNumber",
    label: "File#",
    defaultSortIndex: 29,
    width: 120,
  },
  {
    id: "pickedUpDate",
    type: "date",
    label: "Picked Up",
    defaultSortIndex: 30,
    width: 140,
  },
  {
    id: "shippingInstructionReceivedDate",
    type: "date",
    label: "Shipping Instructions Rcvd Date",
    defaultSortIndex: 31,
    width: 260,
  },
  {
    id: "storageYardDateIn",
    type: "date",
    label: "Storage Yard-In",
    defaultSortIndex: 32,
    width: 160,
  },
  {
    id: "storageYardDateOut",
    type: "date",
    label: "Storage Yard-Out",
    defaultSortIndex: 33,
    width: 160,
  },
  {
    id: "ingateDate",
    type: "date",
    label: "Ingate",
    defaultSortIndex: 34,
    width: 120,
  },
  {
    id: "missedCutDate",
    type: "date",
    label: "Missed Cut",
    defaultSortIndex: 35,
    width: 120,
  },

  {
    id: "departedDate",
    type: "date",
    label: "Deprtd Date",
    defaultSortIndex: 36,
    width: 120,
  },
  {
    id: "ataDate",
    type: "date",
    label: "ATA Date",
    defaultSortIndex: 37,
    width: 120,
    searchColumnName: "ATADate",
  },
  {
    id: "originFT",
    label: "Org FT",
    defaultSortIndex: 38,
    dataType: "number",
    width: 100,
  },
  {
    id: "notes",
    label: "Notes",
    defaultSortIndex: 39,
    width: 120,
  },
  {
    id: "updatedBy",
    label: "Updated By",
    defaultSortIndex: 40,
    width: 120,
  },
  {
    id: "updatedDate",
    type: "date",
    label: "Updated Date",
    defaultSortIndex: 41,
    width: 140,
  },
  {
    id: "",
    type: "document",
    label: "Documents",
    defaultSortIndex: 42,
    width: 140,
  },
  {
    id: "status",
    label: "status",
    position: "fixed",
    defaultSortIndex: 43,
    width: 160,
  },
];
