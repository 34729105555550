import {
  GET_ORG_DETAIL,
  GET_ORG_ID,
  GET_USER_DETAILS,
  LOGOUT_USER,
  POST_USER_DETAILS,
  SET_SWITCHED_ORG_DETAIL,
  SET_USER_DETAILS,
} from "./actionTypes";

export const getUserDetails = () => {
  return {
    type: GET_USER_DETAILS,
  };
};

export const postUserDetails = (payload) => {
  return {
    type: POST_USER_DETAILS,
    payload: payload,
  };
};

export const logoutUserAction = () => {
  return {
    type: LOGOUT_USER,
  };
};

export const setUserDetails = (userData) => {
  return {
    type: SET_USER_DETAILS,
    payload: userData,
  };
};
export const setSwitchedOrganization = (orgDetail) => {
  return {
    type: SET_SWITCHED_ORG_DETAIL,
    payload: orgDetail,
  };
};
export const getOrganizationId = () => {
  return {
    type: GET_ORG_ID,
  };
};

export const getOrganizationInfo = (orgId) => {
  return {
    type: GET_ORG_DETAIL,
    orgId,
  };
};
