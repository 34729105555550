import { takeEvery, put, all } from "redux-saga/effects";
import API from "../../utils/axios";
import {
  GET_PARENT_ORGID_LIST,
  GET_PARENT_ORGID_LIST_FAILURE,
  GET_PARENT_ORGID_LIST_SUCCESS,
  GET_PARENT_ORGANIZATION_LIST,
  GET_PARENT_ORGANIZATION_LIST_SUCCESS,
  GET_PARENT_ORGANIZATION_LIST_FAILURE,
  SET_LOADING,
  GET_LOCAL_BRANCH_LIST_SUCCESS,
  GET_LOCAL_BRANCH_LIST_FAILURE,
  GET_LOCAL_BRANCH_LIST,
  SET_BRANCH_LOADING,
  GET_CUSTOMER_ID_LIST,
  GET_CUSTOMER_ID_LIST_SUCCESS,
  GET_CUSTOMER_ID_LIST_FAILURE,
} from "./actionTypes";

// https://app-cus-myfracht-api-qa-001.azurewebsites.net/api/1/organization/list
function* getParentOrgIdListSaga() {
  try {
    yield put({ type: SET_LOADING });
    const { data } = yield API.get("/1/organization/list");
    yield put({ type: GET_PARENT_ORGID_LIST_SUCCESS, payload: data.value });
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_PARENT_ORGID_LIST_FAILURE,
      payload: { errorMessage: "Unable to get parent organization Id list" },
    });
  }
}

function* getParentOrganizationListSaga() {
  try {
    yield put({ type: SET_LOADING });
    const { data } = yield API.get("/1/organization/parent-organization/list");
    yield put({
      type: GET_PARENT_ORGANIZATION_LIST_SUCCESS,
      payload: data.value,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_PARENT_ORGANIZATION_LIST_FAILURE,
      payload: { errorMessage: "Unable to get parent organization Id list" },
    });
  }
}

// api/1/user-management/organization/list/branch
function* getLocalUserDetailSaga({ localBranchPayload }) {
  try {
    yield put({ type: SET_BRANCH_LOADING });
    const { data, status } = yield API.post(
      `/1/user-management/organization/list/branch`,
      localBranchPayload
    );
    if (status === 200) {
      yield put({
        type: GET_LOCAL_BRANCH_LIST_SUCCESS, payload: data
      });
    } else {
      yield put({
        type: GET_LOCAL_BRANCH_LIST_SUCCESS, payload: []
      });
    }
  } catch (error) {
    yield put({
      type: GET_LOCAL_BRANCH_LIST_FAILURE,
      errorMessage: "Unable to get local user details",
    });
  }
}

function* getControlCustIdSaga() {
  try {
    yield put({ type: SET_LOADING });
    const { data } = yield API.get(`/1/organization/client-organization/list`);
    yield put({ type: GET_CUSTOMER_ID_LIST_SUCCESS, payload: data.value });
  } catch (error) {
    yield put({
      type: GET_CUSTOMER_ID_LIST_FAILURE,
      errorMessage: "Unable to get Controlling Customer Id details",
    });
  }
}

function* NewOrganizationSaga() {
  yield all([yield takeEvery(GET_PARENT_ORGID_LIST, getParentOrgIdListSaga)]);
  yield all([
    yield takeEvery(
      GET_PARENT_ORGANIZATION_LIST,
      getParentOrganizationListSaga
    ),
  ]);
  yield all([yield takeEvery(GET_LOCAL_BRANCH_LIST, getLocalUserDetailSaga)]);
  yield all([yield takeEvery(GET_CUSTOMER_ID_LIST, getControlCustIdSaga)]);
}

export default NewOrganizationSaga;
