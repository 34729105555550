import React from "react";
import { useSelector } from "react-redux";

const ODQV2Widget = () => {
  const redirectURL = JSON.parse(
    localStorage.getItem("cargowiseAppLicenseCode")
  );
  const { user } = useSelector((state) => state.LoginUser);
  const operationalDataQualityV2WidgetURL =
    redirectURL?.operationalDataQualityV2WidgetURL
      ? redirectURL.operationalDataQualityV2WidgetURL.replace(
          "''",
          `'${user?.userEmail}'`
        )
      : null;
  console.log(
    "operationalDataQualityV2WidgetURL",
    operationalDataQualityV2WidgetURL
  );

  return (
    <>
      <iframe
        title="QBR Type 2"
        data-testid="type2_iframe"
        style={{
          border: "none",
          width: "100%",
          height: "calc(100vh - 175px)",
        }}
        src={operationalDataQualityV2WidgetURL}
        frameborder="0"
        allowFullScreen={true}
      />
    </>
  );
};

export default ODQV2Widget;
