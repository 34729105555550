import { AddressType } from "../../constants/enum";
import {
  GET_ADDRESSES_FAILURE,
  GET_ADDRESSES_SUCCESS,
  GET_COUNTRY_LIST_FAILURE,
  GET_COUNTRY_LIST_SUCCESS,
  GET_PRODUCTS_FAILURE,
  GET_PRODUCTS_SUCCESS,
  GET_QUANTITY_FAILURE,
  GET_QUANTITY_SUCCESS,
  SET_COUNTRY_LIST_LOADING,
  SET_LOADING,
  SET_ADDRESS_LOADING,
  GET_CONTAINERS_SUCCESS,
  GET_CONTAINERS_FAILURE,
  GET_DOCUMENTS_SUCCESS,
  GET_DOCUMENTS_FAILURE,
  GET_PRODUCT_TYPE_FAILURE,
  GET_PRODUCT_TYPE_SUCCESS,
  // GET_MANDATORY_FIELDS_SUCCESS,
  GET_MANDATORY_FIELDS_FAILURE,
  SET_ALL_ADDRESS_LOADING,
  GET_ALL_ADDRESSES_SUCCESS,
  GET_ALL_ADDRESSES_FAILURE,
  MANDATORY_FIELD_ROCHESTER,
} from "./actionTypes";

const initialState = {
  countryListDetails: [],
  loading: false,
  error: false,
  errorMessage: "",
  documentList: [],
  products: [],
  productTypeList: [],
  containerList: [],
  quantities: [],
  shippFromAddresses: [],
  shipperAddresses: [],
  shiptoAddresses: [],
  consigneeAddresses: [],
  notifyPartyAddresses: [],
  addressShipFromLoading: false,
  addressShipperLoading: false,
  addressShipToLoading: false,
  addressConsigneeLoading: false,
  addressNotifyPartyLoading: false,
  mandatoryFields: {
    referenceNo: false,
    direction: false,
    directionDefault: "",
    shippingMode: false,
    shippingModeDefault: "",
    loadType: false,
    shipFrom: {
      idDefault: "",
      organizationNameDefault: "",
      organizationIdDefault: "",
      name: false,
      address1: false,
      address1Default: "",
      address2: false,
      address2Default: "",
      city: false,
      cityDefault: "",
      state: false,
      stateDefault: "",
      zipCodeDefault: "",
      zip: false,
      country: false,
      countryDefault: "",
      countryCodeDefault: "",
    },
    shipTo: {
      idDefault: "",
      organizationNameDefault: "",
      organizationIdDefault: "",
      name: false,
      address1: false,
      address1Default: "",
      address2: false,
      address2Default: "",
      city: false,
      cityDefault: "",
      state: false,
      stateDefault: "",
      zipCodeDefault: "",
      zip: false,
      country: false,
      countryDefault: "",
      countryCodeDefault: "",
    },
    shipper: {
      idDefault: "",
      organizationNameDefault: "",
      organizationIdDefault: "",
      name: false,
      address1: false,
      address1Default: "",
      address2: false,
      address2Default: "",
      city: false,
      cityDefault: "",
      state: false,
      stateDefault: "",
      zipCodeDefault: "",
      zip: false,
      country: false,
      countryDefault: "",
      countryCodeDefault: "",
    },
    consignee: {
      idDefault: "",
      organizationNameDefault: "",
      organizationIdDefault: "",
      name: false,
      address1: false,
      address1Default: "",
      address2: false,
      address2Default: "",
      city: false,
      cityDefault: "",
      state: false,
      stateDefault: "",
      zipCodeDefault: "",
      zip: false,
      country: false,
      countryDefault: "",
      countryCodeDefault: "",
    },
    notifyParty: {
      idDefault: "",
      organizationNameDefault: "",
      organizationIdDefault: "",
      name: false,
      address1: false,
      address1Default: "",
      address2: false,
      address2Default: "",
      city: false,
      cityDefault: "",
      state: false,
      stateDefault: "",
      zipCodeDefault: "",
      zip: false,
      country: false,
      countryDefault: "",
      countryCodeDefault: "",
    },
    requiredDelivery: false,
    typeOfDocument: false,
    commercialInvoice: false,
    product: false,
    productName: false,
    productNameDefault: "",
    qty: false,
    qtyUnit: false,
    qtyUnitDefault: "",
    grossWeight: false,
    grossWeightUnit: false,
    grossWeightUnitDefault: "KG",
    length: false,
    width: false,
    height: false,
    dimUOM: false,
    dimUOMDefault: "IN",
    estPickup: false,
    requiredPickup: false,
    estDelivery: false,
    packingList: false,
    materialSafetyDataSheet: false,
    shippersLetterOfInstruction: false,
    certificateOfOrigin: false,
    pickupInstruction: false,
    deliveryInstruction: false,
    shippingInstructions: false,
    fclProduct: false,
    fclProductDefault: "",
    fclContainer: false,
    fclDimUOM: false,
    fclGrossWeight: false,
    fclGrossWeightUnit: false,
    fclQty: false,
    fclQtyUnit: false,
    fclLength: false,
    fclWidth: false,
    fclHeight: false,
    shipmentNotes: false,
    isNotifyAsConsignee: false,
    isShipFromAsShipper: false,
    isShipToAsConsignee: false,
  },
};

const CreateShipment = (state = initialState, action) => {
  switch (action.type) {
    case SET_COUNTRY_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };

    case SET_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };

    case SET_ADDRESS_LOADING:
      if (
        action.payload.toLowerCase() ===
        (AddressType.ConsignorPickupDeliveryAddress.toLowerCase() ||
          "Shipper".toLowerCase() ||
          "ConsignorPickupDeliveryAddress".toLowerCase())
      ) {
        return {
          ...state,
          addressShipFromLoading: true,
        };
      } else if (
        action.payload.toLowerCase() ===
        AddressType.ConsignorShipperAddress.toLowerCase()
      ) {
        return {
          ...state,
          addressShipperLoading: true,
        };
      } else if (
        action.payload.toLowerCase() ===
        (AddressType.ConsigneeDocumentaryAddress.toLowerCase() ||
          "Consignee".toLowerCase() ||
          "ConsigneeDocumentaryAddress".toLowerCase())
      ) {
        return {
          ...state,
          addressShipToLoading: true,
        };
      } else if (
        action.payload.toLowerCase() ===
        (AddressType.ConsigneePickupDeliveryAddress.toLowerCase() ||
          "ConsigneePickupDeliveryAddress".toLowerCase())
      ) {
        return {
          ...state,
          addressConsigneeLoading: true,
        };
      } else if (
        action.payload.toLowerCase() ===
        AddressType.NotifyPartyAddress.toLowerCase()
      ) {
        return {
          ...state,
          addressNotifyPartyLoading: true,
        };
      } else {
        console.log(
          `Address loading with the following status ${action.payload} is not handled.`
        );
        return state;
      }

    case GET_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        countryListDetails: action.payload,
      };
    case GET_COUNTRY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.errorMessage,
      };

    case SET_ALL_ADDRESS_LOADING:
      return {
        ...state,
        addressShipFromLoading: true,
        addressShipperLoading: true,
        addressShipToLoading: true,
        addressConsigneeLoading: true,
        addressNotifyPartyLoading: true,
      };

    case GET_ALL_ADDRESSES_SUCCESS:
      const shippFromAddresses = [];
      const shipperAddresses = [];
      const shiptoAddresses = [];
      const consigneeAddresses = [];
      const notifyPartyAddresses = [];

      action.payload?.forEach((ele) => {
        switch (ele.addressType.toLowerCase()) {
          case AddressType.ConsignorPickupDeliveryAddress.toLowerCase():
          case "Shipper".toLowerCase():
          case "ConsignorPickupDeliveryAddress".toLowerCase():
            shippFromAddresses.push(ele);
            return;
          case AddressType.ConsignorShipperAddress.toLowerCase():
            shipperAddresses.push(ele);
            return;
          case AddressType.ConsigneeDocumentaryAddress.toLowerCase():
          case "Consignee".toLowerCase():
          case "ConsigneeDocumentaryAddress".toLowerCase():
            shiptoAddresses.push(ele);
            return;
          case AddressType.ConsigneePickupDeliveryAddress.toLowerCase():
          case "ConsigneePickupDeliveryAddress".toLowerCase():
            consigneeAddresses.push(ele);
            return;
          case AddressType.NotifyPartyAddress.toLowerCase():
            notifyPartyAddresses.push(ele);
            return;
          default:
            return;
        }
      });
      return {
        ...state,
        shippFromAddresses,
        shipperAddresses,
        shiptoAddresses,
        consigneeAddresses,
        notifyPartyAddresses,
        addressShipFromLoading: false,
        addressShipperLoading: false,
        addressShipToLoading: false,
        addressConsigneeLoading: false,
        addressNotifyPartyLoading: false,
      };

    case GET_ALL_ADDRESSES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
        addressShipFromLoading: false,
        addressShipperLoading: false,
        addressShipToLoading: false,
        addressConsigneeLoading: false,
        addressNotifyPartyLoading: false,
      };

    case GET_ADDRESSES_SUCCESS:
      if (
        action.addressType.toLowerCase() ===
        (AddressType.ConsignorPickupDeliveryAddress.toLowerCase() ||
          "Shipper".toLowerCase() ||
          "ConsignorPickupDeliveryAddress".toLowerCase())
      ) {
        return {
          ...state,
          loading: false,
          addressShipFromLoading: false,
          shippFromAddresses: action.payload?.filter(
            (ele) =>
              ele.addressType.toLowerCase() ===
              (AddressType.ConsignorPickupDeliveryAddress.toLowerCase() ||
                "Shipper".toLowerCase() ||
                "ConsignorPickupDeliveryAddress".toLowerCase())
          ),
        };
      } else if (
        action.addressType.toLowerCase() ===
        AddressType.ConsignorShipperAddress.toLowerCase()
      ) {
        return {
          ...state,
          loading: false,
          addressShipperLoading: false,
          shipperAddresses: action.payload?.filter(
            (ele) =>
              ele.addressType.toLowerCase() ===
              AddressType.ConsignorShipperAddress.toLowerCase()
          ),
        };
      } else if (
        action.addressType.toLowerCase() ===
        (AddressType.ConsigneeDocumentaryAddress.toLowerCase() ||
          "Consignee".toLowerCase() ||
          "ConsigneeDocumentaryAddress".toLowerCase())
      ) {
        return {
          ...state,
          loading: false,
          addressShipToLoading: false,
          shiptoAddresses: action.payload?.filter(
            (ele) =>
              ele.addressType.toLowerCase() ===
              (AddressType.ConsigneeDocumentaryAddress.toLowerCase() ||
                "Consignee".toLowerCase() ||
                "ConsigneeDocumentaryAddress".toLowerCase())
          ),
        };
      } else if (
        action.addressType.toLowerCase() ===
        (AddressType.ConsigneePickupDeliveryAddress.toLowerCase() ||
          "ConsigneePickupDeliveryAddress".toLowerCase())
      ) {
        return {
          ...state,
          loading: false,
          addressConsigneeLoading: false,
          consigneeAddresses: action.payload?.filter(
            (ele) =>
              ele.addressType.toLowerCase() ===
              (AddressType.ConsigneePickupDeliveryAddress.toLowerCase() ||
                "ConsigneePickupDeliveryAddress".toLowerCase())
          ),
        };
      } else if (
        action.addressType.toLowerCase() ===
        (AddressType.NotifyPartyAddress.toLowerCase() ||
          "NotifyPartyAddress".toLowerCase())
      ) {
        return {
          ...state,
          loading: false,
          addressNotifyPartyLoading: false,
          notifyPartyAddresses: action.payload?.filter(
            (ele) =>
              ele.addressType.toLowerCase() ===
              (AddressType.NotifyPartyAddress.toLowerCase() ||
                "NotifyPartyAddress".toLowerCase())
          ),
        };
      } else {
        return {
          ...state,
          loading: false,
          addressShipFromLoading: false,
          addressShipperLoading: false,
          addressShipToLoading: false,
          addressConsigneeLoading: false,
          addressNotifyPartyLoading: false,
          shippFromAddresses: [],
          shipperAddresses: [],
          shiptoAddresses: [],
          consigneeAddresses: [],
          notifyPartyAddresses: [],
        };
      }
    case GET_ADDRESSES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
        addressShipFromLoading: false,
        addressShipperLoading: false,
        addressShipToLoading: false,
        addressConsigneeLoading: false,
        addressNotifyPartyLoading: false,
      };

    case GET_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        documentList: action.payload,
      };
    case GET_DOCUMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload,
      };
    case GET_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case GET_PRODUCT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        productTypeList: action.payload,
      };
    case GET_PRODUCT_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case GET_CONTAINERS_SUCCESS:
      return {
        ...state,
        loading: false,
        containerList: action.payload,
      };
    case GET_CONTAINERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case GET_QUANTITY_SUCCESS:
      return {
        ...state,
        loading: false,
        quantities: action.payload,
      };
    case GET_QUANTITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    // case GET_MANDATORY_FIELDS_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     mandatoryFields: action.payload,
    //   };

    // case GET_MANDATORY_FIELDS_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     mandatoryFields: {
    //       ...action.payload.data,
    //       ...action.payload.fieldSpecificMandatory,
    //     },
    //   };
    case GET_MANDATORY_FIELDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case MANDATORY_FIELD_ROCHESTER:
      return {
        ...state,
        mandatoryFields: {
          ...state.mandatoryFields,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default CreateShipment;
