import {
  SET_OPEN_ROLEPOPUP,
  GET_RESOURCE_LIST,
  GET_ROLE_DATA,
  RESET_ROLE_DATA,
} from "./actionTypes";

export const setOpenRolePopup = (clicked, dialogType) => {
  return {
    type: SET_OPEN_ROLEPOPUP,
    payload: {
      clicked: clicked,
      dialogType: dialogType,
    },
  };
};

export const getResourceList = () => {
  return {
    type: GET_RESOURCE_LIST,
  };
};

export const setRoleData = (id) => {
  return {
    type: GET_ROLE_DATA,
    id: id,
  };
};

export const resetRoleData = (id) => {
  return {
    type: RESET_ROLE_DATA,
    id: id,
  };
};
