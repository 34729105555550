export const GET_COUNTRY_LIST = "GET_COUNTRY_LIST";
export const GET_COUNTRY_LIST_SUCCESS = "GET_COUNTRY_LIST_SUCCESS";
export const GET_COUNTRY_LIST_FAILURE = "GET_COUNTRY_LIST_FAILURE";
export const SET_COUNTRY_LIST_LOADING = "SET_COUNTRY_LIST_LOADING";

export const SET_LOADING = "SET_LOADING";

export const GET_DOCUMENTS = "GET_DOCUMENTS";
export const GET_DOCUMENTS_SUCCESS = "GET_DOCUMENTS_SUCCESS";
export const GET_DOCUMENTS_FAILURE = "GET_DOCUMENTS_FAILURE";

export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAILURE = "GET_PRODUCTS_FAILURE";

export const GET_PRODUCT_TYPE = "GET_PRODUCT_TYPE";
export const GET_PRODUCT_TYPE_SUCCESS = "GET_PRODUCT_TYPE_SUCCESS";
export const GET_PRODUCT_TYPE_FAILURE = "GET_PRODUCT_TYPE_FAILURE";

export const GET_CONTAINERS = "GET_CONTAINERS";
export const GET_CONTAINERS_SUCCESS = "GET_CONTAINERS_SUCCESS";
export const GET_CONTAINERS_FAILURE = "GET_CONTAINERS_FAILURE";

export const GET_QUANTITY = "GET_QUANTITY";
export const GET_QUANTITY_SUCCESS = "GET_QUANTITY_SUCCESS";
export const GET_QUANTITY_FAILURE = "GET_QUANTITY_FAILURE";

export const SET_ADDRESS_LOADING = "SET_ADDRESS_LOADING";
export const SET_ALL_ADDRESS_LOADING = "SET_ALL_ADDRESS_LOADING";

export const GET_ADDRESSES = "GET_ADDRESSES";
export const GET_ADDRESSES_SUCCESS = "GET_ADDRESSES_SUCCESS";
export const GET_ADDRESSES_FAILURE = "GET_ADDRESSES_FAILURE";

export const GET_ALL_ADDRESSES = "GET_ALL_ADDRESSES";
export const GET_ALL_ADDRESSES_SUCCESS = "GET_ALL_ADDRESSES_SUCCESS";
export const GET_ALL_ADDRESSES_FAILURE = "GET_ALL_ADDRESSES_FAILURE";

export const SAVE_SHIPMENT = "SAVE_SHIPMENT";
export const SAVE_SHIPMENT_SUCCESS = "SAVE_SHIPMENT_SUCCESS";
export const SAVE_SHIPMENT_FAILURE = "SAVE_SHIPMENT_FAILURE";

export const GET_MANDATORY_FIELDS = "GET_MANDATORY_FIELDS";
export const GET_MANDATORY_FIELDS_SUCCESS = "GET_MANDATORY_FIELDS_SUCCESS";
export const GET_MANDATORY_FIELDS_FAILURE = "GET_MANDATORY_FIELDS_FAILURE";

export const MANDATORY_FIELD_ROCHESTER = "MANDATORY_FIELD_ROCHESTER";
