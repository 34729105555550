import { Button, Grid, Input, InputAdornment } from "@mui/material";
import React, { useEffect, useRef } from "react";
// import SearchIcon from "../assets/images/SearchIcon.png";
import SearchIcon from "../../assets/images/SearchIcon.png";
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
  searchIcon: {
    position: "relative",
    left: "5px",
    top: "-1px",
  },
  searchInput: {
    margin: "8px 0",
    width: "100%",
    height: "38px",
    boxShadow: "1px 2px 6px #0000000D",
    borderRadius: "6px",
    backgroundColor: theme.palette.color.white,
    border: "none",
    position: "inherit",
    paddingLeft: "4px",
    ...theme.typography.body2,
    "&::placeholder": {
      ...theme.typography.body2,
      color: theme.palette.color.lightGrayishBlueB3,
      opacity: 1,
    },
    "&:focus-within ": {
      outline: "none",
    },
    "& .MuiInputBase-root": {
      padding: "5px 0px",
    },
  },
  applyButton: {
    height: "38px",
    margin: "8px 0",
    backgroundColor: `${theme.palette.color.skyBlue} !important`,
    color: `${theme.palette.color.white} !important`,
    ...theme.typography.body22,
    border: "0.5px solid #CFD4D9",
    boxShadow: "0px 2px 7px #00000029",
    borderRadius: "4px",
    textTransform: "capitalize",
    opacity: 1,
    "&:hover": {
      backgroundColor: `${theme.palette.color.white} !important`,
      color: `${theme.palette.color.skyBlue} !important`,
      border: `1px solid ${theme.palette.color.skyBlue} !important`,
      opacity: 1,
    },
  },
  disabledButton: {
    ...theme.typography.body2,
    minWidth: "127px",
    height: "38px",
    backgroundColor: `${theme.palette.color.grayS6} !important`,
    border: `1px solid ${theme.palette.color.grayS7} !important`,
    color: `${theme.palette.color.lightGrayishBlueB3} !important`,
    boxShadow: "0px 2px 7px #00000029",
    borderRadius: "4px",
    textTransform: "capitalize",
    marginRight: "10px",
    cursor: "default",
    opacity: 1,
  },
}));

const ShipmentSearch = ({
  searchValue,
  setSearchValue,
  handleSearch,
  handleSearchClear,
}) => {
  const classes = useStyle();
  const searchControlRef = useRef(null);
  useEffect(() => {
    const searchInput = searchControlRef.current;
    if (searchInput) {
      searchInput.addEventListener("input", (e) => {
        const inputValue = e.target.value;
        if (!inputValue) {
          handleSearchClear();
        }
      });
    }
  }, [searchControlRef]);

  return (
    <Grid container spacing={2}>
      <Grid item md={4} xs={12}>
        <Input
          className={classes.searchInput}
          placeholder={searchValue ? "" : "search"}
          type="search"
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          data-testid="search"
          startAdornment={
            <InputAdornment position="start">
              <img
                src={SearchIcon}
                alt="SearchIcon"
                className={classes.searchIcon}
              />
            </InputAdornment>
          }
          id="shipmentSearchInput"
          ref={searchControlRef}
        />
      </Grid>
      <Grid item md={3} xs={6}>
        <Button
          disabled={!searchValue}
          className={classes.applyButton}
          onClick={handleSearch}
          classes={{ disabled: classes.disabledButton }}
          id="search-shipment"
        >
          Search Shipment
        </Button>
      </Grid>
      <Grid item md={5} xs={6}></Grid>
    </Grid>
  );
};

export default ShipmentSearch;
