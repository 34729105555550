export const SET_EDITORGANISATION_LOADING = "SET_EDITORGANISATION_LOADING";

export const GET_ORGANIZATION_DETAIL = "GET_ORGANIZATION_DETAIL";
export const GET_ORGANIZATION_DETAIL_SUCCESS =
  "GET_ORGANIZATION_DETAIL_SUCCESS";
export const GET_ORGANIZATION_DETAIL_FAILURE =
  "GET_ORGANIZATION_DETAIL_FAILURE";

export const RESET_ORGANIZATION_DETAIL = "RESET_ORGANIZATION_DETAIL";
export const RESET_ORGANIZATION_DETAIL_SUCCESS =
  "RESET_ORGANIZATION_DETAIL_SUCCESS";
export const RESET_ORGANIZATION_DETAIL_FAILURE =
  "RESET_ORGANIZATION_DETAIL_FAILURE";

export const GET_ORG_RESOURCES = "GET_ORG_RESOURCES";
export const GET_ORG_RESOURCES_SUCCESS = "GET_ORG_RESOURCES_SUCCESS";
export const GET_ORG_RESOURCES_FAILURE = "GET_ORG_RESOURCES_FAILURE";

export const GET_RESOURCE_MENU = "GET_RESOURCE_MENU";
export const GET_RESOURCE_MENU_SUCCESS = "GET_RESOURCE_MENU_SUCCESS";
export const GET_RESOURCE_MENU_FAILURE = "GET_RESOURCE_MENU_FAILURE";
export const CLEAR_RESOURCE_MENU = "CLEAR_RESOURCE_MENU";

export const GET_EMAIL_TYPE = "GET_EMAIL_TYPE";
export const GET_EMAIL_TYPE_SUCCESS = "GET_EMAIL_TYPE_SUCCESS";
export const GET_EMAIL_TYPE_FAILURE = "GET_EMAIL_TYPE_FAILURE";

export const SET_EMAIL_DATA = "SET_EMAIL_DATA";
export const UPDATE_EMAIL_DATA = "UPDATE_EMAIL_DATA";

export const GET_ORGANIZATION_LIST = "GET_ORGANIZATION_LIST";
export const GET_ORGANIZATION_LIST_SUCCESS = "GET_ORGANIZATION_LIST_SUCCESS";
export const GET_ORGANIZATION_LIST_FAILURE = "GET_ORGANIZATION_LIST_FAILURE";

export const GET_ACTIVE_ORG_LIST = "GET_ACTIVE_ORG_LIST";
export const GET_ACTIVE_ORG_LIST_SUCCESS = "GET_ACTIVE_ORG_LIST_SUCCESS";
export const GET_ACTIVE_ORG_LIST_FAILURE = "GET_ACTIVE_ORG_LIST_FAILURE";

export const ADD_CARD_DATA = "ADD_CARD_DATA";
export const CLEAR_ANALYTICS_CARD_LIST = "CLEAR_ANALYTICS_CARD_LIST";

export const GET_LOCAL_USER = "GET_LOCAL_USER";
export const GET_LOCAL_USER_SUCCESS = "GET_LOCAL_USER_SUCCESS";
export const GET_LOCAL_USER_FAILURE = "GET_LOCAL_USER_FAILURE";

export const GET_ADMIN_USER = "GET_ADMIN_USER";
export const GET_ADMIN_USER_SUCCESS = "GET_ADMIN_USER_SUCCESS";
export const GET_ADMIN_USER_FAILURE = "GET_ADMIN_USER_FAILURE";

export const GET_USER_ROLE_LIST = "GET_USER_ROLE_LIST";
export const GET_USER_ROLE_LIST_SUCCESS = "GET_USER_ROLE_LIST_SUCCESS";
export const GET_USER_ROLE_LIST_FAILURE = "GET_USER_ROLE_LIST_FAILURE";

export const GET_HISTORY_LOG = "GET_HISTORY_LOG";
export const GET_HISTORY_LOG_SUCCESS = "GET_HISTORY_LOG_SUCCESS";
export const GET_HISTORY_LOG_FAILURE = "GET_HISTORY_LOG_FAILURE";

export const GET_DATE_FORMAT = "GET_DATE_FORMAT";
export const GET_DATE_FORMAT_SUCCESS = "GET_DATE_FORMAT_SUCCESS";
export const GET_DATE_FORMAT_FAILURE = "GET_DATE_FORMAT_FAILURE";