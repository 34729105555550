import { GET_ADDRESSES_FAILURE } from "../createShipment/actionTypes";
import {
  GET_USER_DETAILS_SUCCESS,
  LOGOUT_USER_FAILURE,
  LOGOUT_USER_SUCCESS,
  SET_USER_LOADING,
  GET_USER_DETAILS_FAILURE,
  POST_USER_DETAILS,
  SET_USER_DETAILS,
  SET_SWITCHED_ORG_DETAIL,
  SET_ORG_ID,
  GET_USER_DETAILS_SERVER_ERROR,
  SET_ORG_DETAIL,
} from "./actionTypes";

const isToken = localStorage.getItem("token") || false;

const initialState = {
  organizationId: "",
  organizationName: "",
  userAuthenticated: isToken,
  loading: false,
  user: null,
  error: false,
  errorMessage: "",
  isFrachtAxis: false,
  userName: null,
  isPremiumSubscription: false,
  vendorOrganizationId: "",
  parentOrganizationId: "",
  controllingCustomerId: null,
};

const LoginUser = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_LOADING:
      return {
        ...state,
        loading: true,
      };

    case POST_USER_DETAILS:
      return {
        ...state,
        organizationId: action.payload.newOrganizationId
          ? action.payload.newOrganizationId
          : action.payload.organizationId,
        organizationName: action.payload.newOrganizationName
          ? action.payload.newOrganizationName
          : action.payload.organizationName,
        loading: false,
      };

    case GET_USER_DETAILS_SUCCESS:
      localStorage.setItem("USER_DETAIL", JSON.stringify(action.payload));
      return {
        ...state,
        userAuthenticated: true,
        organizationId: action.payload.newOrganizationId
          ? action.payload.newOrganizationId
          : action.payload.organizationId,
        organizationName: action.payload.newOrganizationName
          ? action.payload.newOrganizationName
          : action.payload.organizationName,
        parentOrganizationId: action.payload.parentOrganizationId,
        user: action.payload,
        isFrachtAxis: action?.payload?.isFrachtAxis,
        vendorOrganizationId: action?.payload?.vendorOrganizationId,
        controllingCustomerId: action?.payload?.controllingCustomerId,
        loading: false,
      };

    case GET_USER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        userAuthenticated: false,
      };

    case GET_USER_DETAILS_SERVER_ERROR:
      return {
        ...state,
        loading: false,
        userAuthenticated: true,
        user: undefined,
        organizationId: null,
        organizationName: null,
        parentOrganizationId: null,
        isFrachtAxis: null,
        error: true,
        errorMessage: "user not authenticated",
      };

    case GET_ADDRESSES_FAILURE:
      return {
        ...state,
        userAuthenticated: false,
        loading: false,
      };

    case LOGOUT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        userAuthenticated: false,
      };
    case LOGOUT_USER_FAILURE:
      return {
        ...state,
        userAuthenticated: false,
      };
    case SET_USER_DETAILS:
      return {
        ...state,
        userAuthenticated: true,
        organizationId: action.payload.newOrganizationId
          ? action.payload.newOrganizationId
          : action.payload.organizationId,
        organizationName: action.payload.newOrganizationName
          ? action.payload.newOrganizationName
          : action.payload.organizationName,
        parentOrganizationId: action.payload.parentOrganizationId,
        user: action.payload,
        isFrachtAxis: action?.payload?.isFrachtAxis,
      };

    case SET_SWITCHED_ORG_DETAIL:
      return {
        ...state,
        organizationId: action.payload.newOrganizationId,
        organizationName: action.payload.newOrganizationName,
      };

    case SET_ORG_ID:
      return {
        ...state,
        organizationId: action.payload,
      };
    case SET_ORG_DETAIL:
      return {
        ...state,
        userName: action.payload.userName,
        isFrachtAxis: action.payload.isFrachtAxis,
        isPremiumSubscription: action.payload.isPremiumSubscription,
      };

    default:
      return state;
  }
};

export default LoginUser;
