import {
  GET_AVAILABLE_CAPACITY_DETAIL_FAILURE,
  GET_AVAILABLE_CAPACITY_DETAIL_SUCCESS,
  GET_BOOKING_LIST_FAILURE,
  GET_BOOKING_LIST_SUCCESS,
  GET_LOAD_NUMBER_DETAIL_FAILURE,
  GET_LOAD_NUMBER_DETAIL_SUCCESS,
  RESET_AVAILABLE_CAPACITY_DETAIL,
  RESET_AVAILABLE_CAPACITY_DETAIL_FAILURE,
  RESET_AVAILABLE_CAPACITY_DETAIL_SUCCESS,
  RESET_LOAD_NUMBER_DETAIL,
  RESET_LOAD_NUMBER_DETAIL_FAILURE,
  RESET_LOAD_NUMBER_DETAIL_SUCCESS,
  SET_AVAILABLE_CAPACITY_DETAIL_LOADING,
  SET_LOADING,
} from "./actionTypes";

const initialState = {
  // loadNumberDetails: [],
  loadNumberDetails: {
    loadNumber: "",
    notes: "",
    document: [
      {
        documentName: "",
        documentType: "",
        uploadedDate: "",
        content: "",
        documentExtension: "",
        documentSize: "",
      },
    ],
    containerNumber: null,
    bookingNumber: null,
    week: null,
    pol: null,
    destination: null,
    vessel: null,
    carrier: null,
    containerQuantity: 0,
    erd: null,
    documentCutOffDate: null,
    cutOffDate: null,
    etd: null,
    eta: null,
  },
  bookingListDetails: [],
  availableCapacityDetails: {
    id: "",
    week: "",
    etdWeek: "",
    pol: "",
    destination: "",
    carrier: "",
    vessel: "",
    bookingNumber: "",
    containerQuantity: "",
    erd: "",
    documentCutOffDate: "",
    cutOffDate: "",
    etd: "",
    eta: "",
    notes: "",
    document: [],
    isRecordLocked: null,
    lockedUser: "",
    erdDisplay: "",
    documentCutOffDateDisplay: "",
    cutOffDateDisplay: "",
    etdDisplay: "",
    etaDisplay: "",
    deliveryLocation: "",
    originFT: null,
    isActive: null,
  },
  availableCapacityLoading: false,
  loading: false,
  error: false,
  errorMessage: "",
};

const AssignContainer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_LOAD_NUMBER_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        loadNumberDetails: { ...action.payload },
      };
    case GET_LOAD_NUMBER_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case RESET_LOAD_NUMBER_DETAIL:
      return {
        ...state,
        loading: true,
      };
    case RESET_LOAD_NUMBER_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        loadNumberDetails: {
          loadNumber: "",
          notes: "",
          document: [
            {
              documentName: "",
              documentType: "",
              uploadedDate: "",
              content: "",
              documentExtension: "",
              documentSize: "",
            },
          ],
          containerNumber: null,
          bookingNumber: null,
          week: null,
          pol: null,
          destination: null,
          vessel: null,
          carrier: null,
          containerQuantity: 0,
          erd: null,
          documentCutOffDate: null,
          cutOffDate: null,
          etd: null,
          eta: null,
        },
      };
    case RESET_LOAD_NUMBER_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case GET_BOOKING_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        bookingListDetails: action.payload,
      };
    case GET_BOOKING_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: "Unable to get booking list",
      };

    case SET_AVAILABLE_CAPACITY_DETAIL_LOADING:
      return {
        ...state,
        availableCapacityLoading: true,
      };
    case GET_AVAILABLE_CAPACITY_DETAIL_SUCCESS:
      return {
        ...state,
        availableCapacityLoading: false,
        availableCapacityDetails: action.payload,
      };
    case GET_AVAILABLE_CAPACITY_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        availableCapacityLoading: false,
        error: true,
        errorMessage: "Unable to get available capacity details",
      };

    case RESET_AVAILABLE_CAPACITY_DETAIL:
      return {
        ...state,
        loading: true,
        availableCapacityLoading: true,
      };
    case RESET_AVAILABLE_CAPACITY_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        availableCapacityLoading: false,
        availableCapacityDetails: {
          bookingNumber: "",
          carrier: "",
          containerQuantity: "",
          cutOffDate: "",
          destination: "",
          documentCutOffDate: "",
          erd: "",
          eta: "",
          etd: "",
          pol: "",
          vessel: "",
        },
      };
    case RESET_AVAILABLE_CAPACITY_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        availableCapacityLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    default:
      return state;
  }
};

export default AssignContainer;
