import { GET_MENU_DETAILS, SET_MENU_VISIBILITY } from "./actionTypes";

export const getMenuDetails = (orgId = null) => {
  return {
    type: GET_MENU_DETAILS,
    orgId,
  };
};

export const setMenuVisibility = (visibilityData) => {
  return {
    type: SET_MENU_VISIBILITY,
    payload: visibilityData,
  };
};
