import { takeEvery, put, all } from "redux-saga/effects";
import {
  SET_NOTIFICATION_LOADING,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  GET_WORK_REQUEST_DATA,
  GET_WORK_REQUEST_DATA_SUCCESS,
  GET_WORK_REQUEST_DATA_FAILURE,
} from "./actionTypes";
import API from "../../utils/axios";

function* getNotificationsSaga(organizationId) {
  try {
    yield put({ type: SET_NOTIFICATION_LOADING });
    const response = yield API.get(
      `/1/notification/${organizationId.payload}/notificationlist`
    );
    const { status, data } = response;
    if (status === 204)
      yield put({ type: GET_NOTIFICATIONS_SUCCESS, payload: [] });
    else yield put({ type: GET_NOTIFICATIONS_SUCCESS, payload: data });
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_NOTIFICATIONS_FAILURE,
      payload: { errorMessage: "Unable to get notification details" },
    });
  }
}

function* getWorkRequestSaga() {
  try {
    yield put({ type: SET_NOTIFICATION_LOADING });
    const response = yield API.get(`/1/servicerequest/me/localadmin`);
    const { status, data } = response;
    if (status === 204)
      yield put({ type: GET_WORK_REQUEST_DATA_SUCCESS, payload: [] });
    else
      yield put({ type: GET_WORK_REQUEST_DATA_SUCCESS, payload: data?.value });
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_WORK_REQUEST_DATA_FAILURE,
      payload: { errorMessage: "Unable to get work items details" },
    });
  }
}


function* NotificationsSaga() {
  yield all([
    yield takeEvery(GET_NOTIFICATIONS, getNotificationsSaga),
    yield takeEvery(GET_WORK_REQUEST_DATA, getWorkRequestSaga),
  ]);
}

export default NotificationsSaga;
