export const SET_MILLERCOORS_LOADING = "SET_MILLERCOORS_LOADING";

export const SHOW_EMPTY_CONTAINER = "SHOW_EMPTY_CONTAINER";
export const SHOW_AVAILABLE_CAPACITY = "SHOW_AVAILABLE_CAPACITY";
export const SET_BUTTON_CLICKED = "SET_BUTTON_CLICKED";

export const GET_DOCUMENT_TYPE = "GET_DOCUMENT_TYPE";
export const GET_DOCUMENT_TYPE_SUCCESS = "GET_DOCUMENT_TYPE_SUCCESS";
export const GET_DOCUMENT_TYPE_FAILURE = "GET_DOCUMENT_TYPE_FAILURE";

export const GET_CUSTOMER_LIST = "GET_CUSTOMER_LIST";
export const GET_CUSTOMER_LIST_SUCCESS = "GET_CUSTOMER_LIST_SUCCESS";
export const GET_CUSTOMER_LIST_FAILURE = "GET_CUSTOMER_LIST_FAILURE";

export const GET_TRUCKER_LIST = "GET_TRUCKER_LIST";
export const GET_TRUCKER_LIST_SUCCESS = "GET_TRUCKER_LIST_SUCCESS";
export const GET_TRUCKER_LIST_FAILURE = "GET_TRUCKER_LIST_FAILURE";

export const GET_IMPORTPREPULL_LIST = "GET_IMPORTPREPULL_LIST";
export const GET_IMPORTPREPULL_LIST_SUCCESS = "GET_IMPORTPREPULL_LIST_SUCCESS";
export const GET_IMPORTPREPULL_LIST_FAILURE = "GET_IMPORTPREPULL_LIST_FAILURE";

export const GET_CARRIER_LIST = "GET_CARRIER_LIST";
export const GET_CARRIER_LIST_SUCCESS = "GET_CARRIER_LIST_SUCCESS";
export const GET_CARRIER_LIST_FAILURE = "GET_CARRIER_LIST_FAILURE";

export const GET_POL_LIST = "GET_POL_LIST";
export const GET_POL_LIST_SUCCESS = "GET_POL_LIST_SUCCESS";
export const GET_POL_LIST_FAILURE = "GET_POL_LIST_FAILURE";

export const GET_DESTINATION_LIST = "GET_DESTINATION_LIST";
export const GET_DESTINATION_LIST_SUCCESS = "GET_DESTINATION_LIST_SUCCESS";
export const GET_DESTINATION_LIST_FAILURE = "GET_DESTINATION_LIST_FAILURE";

export const GET_LOADED_LOCATION = "GET_LOADED_LOCATION";
export const GET_LOADED_LOCATION_SUCCESS = "GET_LOADED_LOCATION_SUCCESS";
export const GET_LOADED_LOCATION_FAILURE = "GET_LOADED_LOCATION_FAILURE";

export const GET_DELIVERY_LOCATION_LIST = "GET_DELIVERY_LOCATION_LIST";
export const GET_DELIVERY_LOCATION_LIST_SUCCESS =
  "GET_DELIVERY_LOCATION_LIST_SUCCESS";
export const GET_DELIVERY_LOCATION_LIST_FAILURE =
  "GET_DELIVERY_LOCATION_LIST_FAILURE";

export const GET_POLUNLOC_LIST = "GET_POLUNLOC_LIST";
export const GET_POLUNLOC_LIST_SUCCESS = "GET_POLUNLOC_LIST_SUCCESS";
export const GET_POLUNLOC_LIST_FAILURE = "GET_POLUNLOC_LIST_FAILURE";

export const SAVE_NOTE_DATA = "SAVE_NOTE_DATA";
export const SAVE_NOTE_DATA_SUCCESS = "SAVE_NOTE_DATA_SUCCESS";
export const SAVE_NOTE_DATA_FAILURE = "SAVE_NOTE_DATA_FAILURE";
