export const officeAddresses = [
  {
    coordinates: [-84.38953, 33.65941], // LONG,LAT
    attributes: {
      name: "0",
    },
    officeName: "Fracht FWO Inc",
    address: "703 N Central Avenue Atlanta, GA 30354, United States",
    city: "ATLANTA",
    contactNumber: "+1-404-761-1988",
    email: "chris.douglas@fracht.com",
  },
  {
    coordinates: [-71.06673, 42.50129],
    attributes: {
      name: "1",
    },
    officeName: "Fracht FWO Inc",
    address: "591 North Avenue Wakefield, MS 01880, United States",
    city: "BOSTON",
    contactNumber: "+1-781-486-9200",
    email: "doug.mullett@fracht.com",
  },
  {
    coordinates: [-87.89859, 41.99862],
    attributes: {
      name: "2",
    },
    officeName: "Fracht FWO Inc",
    address:
      "2860 South River Road Suite 350Des Plaines, IL 60018, United States",
    city: "CHICAGO",
    contactNumber: "+1-847-827-0202",
    email: "peter.jones@fracht.com ",
  },
  {
    coordinates: [-95.396, 29.93402],
    attributes: {
      name: "3",
    },
    officeName: "Fracht FWO Inc",
    address:
      "16701 Greenspoint Park Drive Suite 300, Houston, TX 77060, United States",
    city: "HOUSTON",
    contactNumber: "+1-281-227-1700",
    email: "reiner.wiederkehr@fracht.com ",
  },
  {
    coordinates: [-118.31354, 33.83024],
    attributes: {
      name: "4",
    },
    officeName: "Fracht FWO Inc",
    address: "703 N Central Avenue Atlanta, GA 30354, United States",
    city: "LOS ANGELES",
    contactNumber: "+1-404-761-1988",
    email: "chris.douglas@fracht.com",
  },
  {
    coordinates: [-73.67253, 40.65781], // LONG,LAT
    attributes: {
      name: "5",
    },
    officeName: "Fracht FWO Inc",
    address: "50 Broadway Lynbrook, NY 11563, United States",
    city: "LYNBROOK",
    contactNumber: "+1-516-599-0200",
    email: "markus.benz@fracht.com",
  },
  {
    coordinates: [-80.30074, 25.79707],
    attributes: {
      name: "6",
    },
    officeName: "Fracht FWO Inc",
    address: "8400 N.W. 25th Street Suite 104 Doral, FL 33122, United States",
    city: "MIAMI",
    contactNumber: "+1-305-994-7753",
    email: "christian.rivera@fracht.com",
  },
  {
    coordinates: [-73.99612, 40.7508],
    attributes: {
      name: "7",
    },
    officeName: "Fracht FWO Inc. Corporate Office",
    address: "29 W 30th Street, 3rd Floor New York, NY 10001, United States",
    city: "NEW YORK (MANHATTAN)",
    contactNumber: "+1-212-897-5830",
    email: "markus.benz@fracht.com",
  },
  {
    coordinates: [-74.01333, 40.70793],
    attributes: {
      name: "8",
    },
    officeName: "Fracht FWO Inc",
    address: "9 W 30th Street 12th Floor New York, NY 10001, United States",
    city: "NEW YORK (MANHATTAN)",
    contactNumber: "+1-646-278-1710",
    email: "r_campbell@nntc.com",
  },
  {
    coordinates: [-73.7728, 40.66216],
    attributes: {
      name: "9",
    },
    officeName: "Omnitrans Corporation LTD",
    address: "29 W 30th Street 12th Floor New York, NY 10006 United States",
    city: "NEW YORK (MANHATTAN)",
    contactNumber: "+1-212-962-1956",
    email: "markus@omnitrans-usa.com",
  },
  {
    coordinates: [-76.29416, 36.8522], // LONG,LAT
    attributes: {
      name: "10",
    },
    officeName: "Fracht FWO Inc",
    address: "150 Boush Street Suite 203 Norfolk, VA 23510, United States",
    city: "NORFOLK",
    contactNumber: "+1-757-355-5420",
    email: "amy.cash@fracht.com",
  },
  {
    coordinates: [-116.99026, 32.56785], // LONG,LAT
    attributes: {
      name: "11",
    },
    officeName: "Fracht FWO Inc",
    address: "10025 Siempre Viva Rd Suite BSan Diego, CA 92154, United States",
    city: "SAN DIEGO",
    contactNumber: "+1-619-241-7690",
    email: "daniel.corona@fracht.com",
  },
  {
    coordinates: [-79.59682, 43.66135],
    attributes: {
      name: "12",
    },
    officeName: "Fracht Canada Freight Inc.",
    address: "1022680 Matheson Blvd East Mississauga, ON L4W 0A5 Canada",
    city: "MISSISSAUGA",
    contactNumber: "+365-657-667",
    email: "paul.moon@fracht.com",
  },
  {
    coordinates: [-123.01107, 49.26583],
    attributes: {
      name: "13",
    },
    officeName: "Fracht Canada Freight Inc.",
    address: "3074180 Lougheed Hwy. Vancouver-Burnaby, BC V5C 6A7 Canada",
    city: "VANCOUVER",
    contactNumber: "+604-291-8009",
    email: "paul.moon@fracht.com",
  },
  {
    coordinates: [-99.60254, 20.40682],
    attributes: {
      name: "14",
    },
    officeName: "Fracht Logistics de México SA de CV",
    address:
      "lvd. Adolfo López Mateos 2235, Piso 10, Torre Distrito SurCol. Las Aguilas, Del. Alvaro Obregón, CDMX, CP 01710 Mexico",
    city: "MEXICO",
    contactNumber: "+52-55-1559-2960",
    email: "karina.lerma@fracht.com",
  },

  {
    coordinates: [31.02267, -29.861], // LONG,LAT
    attributes: {
      name: "15",
    },
    officeName: "Polytra SACL (Pty) Ltd.",
    address:
      "Suite 6, 8th Floor Standard Bank Building, 135 Musgrave Road, Durban, Berea 4001 South Africa",
    city: "DURBAN",
    contactNumber: "+27(0)31-306-3147",
    email: "jchugh@polytra.be",
  },
  {
    coordinates: [28.02409, -26.08934], // LONG,LAT
    attributes: {
      name: "16",
    },
    officeName: "Polytra SACL (Pty) Ltd.",
    address:
      "Block G, Hurlingham Office Park 59 Woodlands Drive Sandton, Hurlingham 2070 South Africa",
    city: "SANDTON",
    contactNumber: "+27-11-285-0009",
    email: "rpateras@polytra.be",
  },
  {
    coordinates: [72.88317, 19.10707],
    attributes: {
      name: "17",
    },
    officeName: "Fracht India Pvt. Ltd",
    address:
      "307, Town Centre 2 Marol, Andheri East Mumbai, Maharashtra 400059 India",
    city: "MUMBAI",
    contactNumber: "+91-91-22-42449300",
    email: "rajesh.vp@frachtindia.in",
  },
  {
    coordinates: [106.79636, -6.18649],
    attributes: {
      name: "18",
    },
    officeName: "PT Fracht Artha Global",
    address:
      "isma Slipi 14th Floor Jalan Letjen. S. Parman, Kav 12 Jakarta, Jakarta 11480 Indonesia",
    city: "JAKARTA",
    contactNumber: "+62-21-536-24-94",
    email: "info@fracht.co.id",
  },
  {
    coordinates: [139.750086, 35.6432],
    attributes: {
      name: "19",
    },
    officeName: "Fracht FWO Ltd.",
    address:
      "11-8, Shibaura, 3-Chome Minatoya Building 601 Minato-Ku, Tokyo, Tokyo 108-0075 Japan",
    city: "TOKYO",
    contactNumber: "+81-3-379-816-01-2",
    email: "fwotyorw@msg.biglobe.ne.jp",
  },
  {
    coordinates: [138.54468, -34.90312],
    attributes: {
      name: "20",
    },
    officeName: "Fracht Australia (SA) Pty Ltd",
    address: "22 Crittenden Road Findon, SA 5023",
    city: "ADELAIDE",
    contactNumber: "+61-8-8244-1755",
    email: "jacob@frachtadl.com.au",
  },
  {
    coordinates: [116.52352, 40.390939],
    attributes: {
      name: "21",
    },
    officeName: "Fracht (Shanghai) Ltd. - Beijing Branch",
    address:
      "21st Fl. - Room No. 2108, Beijing Jingtai Tower No. 24C, Jianguomen Wei Street, Chaoyang Beijing 100022 China",
    city: "BEIJING",
    contactNumber: "+86-10-65156531-32-33",
    email: "joe.zhou@cn.fracht.com",
  },
  {
    coordinates: [121.6619, 38.921686],
    attributes: {
      name: "22",
    },
    officeName: "Fracht Ltd. Basel",
    address:
      "Shanghai-Ningbo-Shenzhen-Guangzhou-Beijing-Xiamen-Qingdao Dalian Shi, Liaoning Sheng 116007 China",
    city: "DALIAN SHI",
    contactNumber: "+86-13701805670",
    email: "leila.duan@cn.fracht.com",
  },
  {
    coordinates: [113.31838, 23.121237],
    attributes: {
      name: "23",
    },
    officeName: "Fracht (Shanghai) Ltd. - Guangzhou Branch",
    address:
      "Room 810, Poly Plaza Clover (Phase II) No. 406-2, Huasui Road Guangzhou Shi, Tianhe Qu 510623 China",
    city: "GUANGZHOU SHI",
    contactNumber: "+86-20-2211-9565",
    email: "info@cn.fracht.com",
  },
  {
    coordinates: [114.156908, 22.331455],
    attributes: {
      name: "24",
    },
    officeName: "Fracht (Hong Kong) Ltd.",
    address:
      "1209-10 Laford Centre, 838 Lai Chi Kok Road Kowloon City, Kowloon Hong Kong SAR China",
    city: "KOWLOON CITY",
    contactNumber: "+852-2803-4373",
    email: "peter.bachmann@hk.fracht.com",
  },
  {
    coordinates: [121.0115036, 14.54383], // LONG,LAT
    attributes: {
      name: "25",
    },
    officeName: "Fracht Ltd. c/o Stamm International Inc.",
    address:
      "3407 Gen. Lim Street, Bangkal Stamm House Makati City, Rizal 1233 Philippines",
    city: "MAKATI CITY",
    contactNumber: "+63-2-843-55-21-24",
    email: "stamm@stamm.com.ph",
  },
  {
    coordinates: [100.539954, 13.73593],
    attributes: {
      name: "26",
    },
    officeName: "Dextra Industry and Transport Co. Ltd.",
    address:
      "247 Sarasin Road 5th Floor, Lumpini II Building Bangkok 10330 Thailand",
    city: "BANGKOK",
    contactNumber: "+66-2-119-4400",
    email: "fpagniez@dextragroup.com",
  },
  {
    coordinates: [106.72223, 10.799785], // LONG,LAT
    attributes: {
      name: "27",
    },
    officeName: "Dextra Transport Vietnam Co. Ltd",
    address:
      "R. 02, Fl., New Port Building No. 400/3 Ung Van Khiem Street, Ward 25, Binh Thanh District Ho Chi Minh City Vietnam",
    city: "HO CHI MINH CITY",
    contactNumber: "+84-2835-126-050-051-308",
    email: "averdoni@dextragroup.com",
  },
  {
    coordinates: [16.641523, 48.050743],
    attributes: {
      name: "28",
    },
    officeName: "Billitz GmbH",
    address: "Hauswiesenweg 3-5 Gallbrunn 2463 Austria",
    city: "GALLBRUNN",
    contactNumber: "",
    email: "stephan.hofbauer@billitz.com",
  },
  {
    coordinates: [16.55661, 48.12304],
    attributes: {
      name: "29",
    },
    officeName: "Fracht FWO GmbH",
    address: "Hauswiesenweg 3-5 Gallbrunn 2463 Austria",
    city: "GALLBRUNN",
    contactNumber: "+43-2230-29030-0",
    email: "juergen.storch@fracht-austria.com",
  },
  {
    coordinates: [4.406098, 51.19447], // LONG,LAT
    attributes: {
      name: "30",
    },
    officeName: "Polytra N.V.",
    address: "Potvlietlaan 7 Berchem (Antwerp) 2600 Belgium",
    city: "NORBERCHEM (ANTWERP)",
    contactNumber: "+32-3-205-05-01",
    email: "aluyckx@polytra.be",
  },
  {
    coordinates: [24.928105, 60.29456], // LONG,LAT
    attributes: {
      name: "31",
    },
    officeName: "Fracht Finland OY",
    address: "Ansatie 6a A Vantaa 01740 Finland",
    city: "VANTAA",
    contactNumber: "+358 50 598 7787",
    email: "kari.maki@fi.fracht.com",
  },

  {
    coordinates: [-58.4668, -34.53612],
    attributes: {
      name: "32",
    },
    officeName: "Fracht Argentina S.A.",
    address:
      "Av. Del Libertador 8630, Piso 14 C1429BNT Capital Federal Buenos Aires Argentina",
    city: "BUENOS AIRES",
    contactNumber: "+54-9-11-5244-2338",
    email: "federico.luedecke@ar.fracht.com",
  },
  {
    coordinates: [-47.04886, -22.9022],
    attributes: {
      name: "33",
    },
    officeName: "CTI Cargo, Campinas/SP",
    address:
      "Rua Antonio Lapa, 280 – Sala 402 Cambui, Ed.América. Campinas, SP 13025-240 Brazil",
    city: "CAMPINAS",
    contactNumber: "+55-19-3295-2466",
    email: "maria.carmem@cticargo.com.br",
  },
  {
    coordinates: [-70.564464, -33.40735],
    attributes: {
      name: "34",
    },
    officeName: "Fracht Chile SpA",
    address: "Rosario Sur 570 Las Condes Santiago de Chile Chile",
    city: "SANTIAGO DE CHILE",
    contactNumber: "+56-9-2606-3222",
    email: "beat.wimmer@fracht.cl",
  },
  {
    coordinates: [-76.97077, -12.105616], // LONG,LAT
    attributes: {
      name: "35",
    },
    officeName: "Fracht Peru S.A.C",
    address: "Av. La Encalada 1090 of. 707 Surco, Lima Peru",
    city: "LIMA",
    contactNumber: "+51-984331791",
    email: "maria-eugenia.paredes@fracht.com",
  },
  {
    coordinates: [-38.45511, -12.9826],
    attributes: {
      name: "36",
    },
    officeName: "CTI Cargo, Salvador",
    address:
      "Rua Ewerton Visco, no. 290 Ed. Boulevard Side Empresarial - Sala 802 Caminho das Árvores El Salvador",
    city: "CAMINHO DAS ÁRVORES",
    contactNumber: "+55-71-991-185650",
    email: "flavia.vedovato@cticargo.com.br",
  },
  {
    coordinates: [66.99919, 24.8436],
    attributes: {
      name: "37",
    },
    officeName: "Fracht Pakistan",
    address: "34-A/3, Lalazar DriveM.T. Khan Road Karachi Pakistan",
    city: "KARACHI",
    contactNumber: "+92-21-5610319Email",
    email: "fracht@cyber.net.pk",
  },
  {
    coordinates: [151.21605, -33.946571],
    attributes: {
      name: "38",
    },
    officeName: "Fracht Australia Pty Ltd",
    address: "19 Baker Street Botany NSW 2019 Botany, NSW 1455 Australia",
    city: "SYDNEY",
    contactNumber: "+61-2-8336-8100",
    email: "michelscheuble@frachtsyd.com.au",
  },
  {
    coordinates: [115.9697, -31.967908],
    attributes: {
      name: "39",
    },
    officeName: "Fracht Australia (WA) Pty Limited",
    address: "Unit C26 Tarlton Crescent Perth Airport, WA 6105 Australia",
    city: "PERTH",
    contactNumber: "+61-8-9478-3880",
    email: "marcobrunzu@frachtper.com.au",
  },
  {
    coordinates: [144.84835, -37.720347], // LONG,LAT
    attributes: {
      name: "40",
    },
    officeName: "Fracht Australia Pty Ltd",
    address: "25-27 Concorde Drive Keilor Park, VIC 3042 Australia",
    city: "MELBOURNE",
    contactNumber: "+61-406-554-319",
    email: "neelufabuksh@frachtmel.com.au",
  },
  {
    coordinates: [151.869133, -23.54873],
    attributes: {
      name: "41",
    },
    officeName: "Fracht Australia (QLD) Ltd.",
    address:
      "341 Fison Avenue EastP.O. Box 1397 Eagle Farm, QLD 4009 Australia",
    city: "BRISBANE",
    contactNumber: "+61-427-360-467",
    email: "craigburcher@frachtbne.com.au",
  },
  {
    coordinates: [121.55118, 25.023733],
    attributes: {
      name: "42",
    },
    officeName: "Fracht FWO Logistic Co., Ltd. (Taiwan)",
    address: "10F-1, No. 296, Hsin Yi Road, Sec. 4Taipei City 106Taiwan",
    city: "TAIPEI CITY",
    contactNumber: "+886-2-2704-0239",
    email: "joy.wei@fracht.com.tw",
  },
  {
    coordinates: [103.791579, 1.306167], // LONG,LAT
    attributes: {
      name: "43",
    },
    officeName: "Fracht Logistics (SG) Pte. Ltd",
    address:
      "Level 8-09, The Metropolis Tower 211 North Buona Vista Drive 138589 Singapore",
    city: "SINGAPORE",
    contactNumber: "+65-961-60-484",
    email: "marcusleong@frachtsg.com",
  },

  {
    coordinates: [118.081721, 24.49098],
    attributes: {
      name: "44",
    },
    officeName: "Frach (Shanghai) Ltd. - Xiamen Branch",
    address:
      "#215-216, Kaji Building CATIC Plaza No. 4 Yue Hua Road Xiamen Shi, Huli Qu 361000 China",
    city: "XIAMEN SHI",
    contactNumber: "+86-15980777232",
    email: "jimmy.ou@cn.fracht.com",
  },
  {
    coordinates: [114.12283, 22.5438],
    attributes: {
      name: "45",
    },
    officeName: "Fracht (Shanghai) Ltd. - Shenzhen Branch",
    address:
      "Building A, 21- OP Fortune Plaza Shennan Road 7002 Shenzhen Shi, Futian Qu 518040 China",
    city: "SHENZHEN SHI",
    contactNumber: "+86-755-8831-8448",
    email: "info@cn.fracht.com",
  },
  {
    coordinates: [121.50981, 31.23693], // LONG,LAT
    attributes: {
      name: "46",
    },
    officeName: "Fracht (Shanghai) Ltd.",
    address:
      "166 Lu Jia Zui Road (E), Pudong 2608 China Insurance Bldg.Shanghai 200120 China",
    city: "SHANGHAI",
    contactNumber: "+86-21-6100-78-28",
    email: "info@cn.fracht.com",
  },
  {
    coordinates: [121.59741, 29.86849],
    attributes: {
      name: "47",
    },
    officeName: "Fracht (Shanghai) Ltd. - Ningbo Branch",
    address:
      "Room 301, 7# Building 2, Yinchen International No. 668 Jingjia Road Ningbo Shi, Jiangdong Qu 315040 China",
    city: "NINGBO SHI",
    contactNumber: "+86-574-8797-62-07",
    email: "info@cn.fracht.com",
  },
  {
    coordinates: [120.37583, 36.07796],
    attributes: {
      name: "48",
    },
    officeName: "Fracht (Shanghai) Ltd. - Qingdao Branch",
    address:
      "Room 1018, JinFu Building No. 22, Shandong Road Qingdao Shi, Shinan Qu 266071 China",
    city: "QINGDAO SHI",
    contactNumber: "+86-18669775717",
    email: "dannie.shan@cn.fracht.com",
  },
  {
    coordinates: [28.19738, -12.80713],
    attributes: {
      name: "49",
    },
    officeName: "Polytra Zambia Ltd.",
    address: "Plot No. 840, Nyere Road Kitwe Zimbabwe",
    city: "KITWE",
    contactNumber: "+260-961-124-452",
    email: "rpatel@polytra.be",
  },
  {
    coordinates: [28.31536, -15.39308],
    attributes: {
      name: "50",
    },
    officeName: "Polytra Zambia Ltd.",
    address:
      "Pangaea Office Park, First Floor, 58 Plot 2374, Great East Road Lusaka Zambia",
    city: "LUSAKA",
    contactNumber: "+260-961-124-452",
    email: "rpatel@polytra.be",
  },
  {
    coordinates: [32.63346, 0.350772],
    attributes: {
      name: "51",
    },
    officeName: "Fracht Uganda Ltd.",
    address: "Plot 13 Norfolk Road Kyambogo Kampala Uganda",
    city: "KAMPALA",
    contactNumber: "+256-784-234051",
    email: "philip.vantilburg@ug.fracht.com",
  },
  {
    coordinates: [1.24591, 6.19635],
    attributes: {
      name: "52",
    },
    officeName: "Fracht Togo SAS",
    address: "Boulevard Jean Paul 2 Hedjranawoé, 04 BP 803 Lomé Togo",
    city: "LOMÉ",
    contactNumber: "+228-70-31-98-98",
    email: "dieudonne.atsu@tg.fracht.africa",
  },
  {
    coordinates: [39.2902, -6.844509],
    attributes: {
      name: "53",
    },
    officeName: "Polytra Tanzania Ltd.",
    address:
      "New TPA Stuffing Yard, 1- Bandari Road, Kurasini Dar es Salaam Tanzania",
    city: "DAR ES SALAAM",
    contactNumber: "+255-685-701-862",
    email: "sbawa@polytra.be",
  },
  {
    coordinates: [-17.47408, 14.70339],
    attributes: {
      name: "54",
    },
    officeName: "Fracht Senegal",
    address:
      "Immeuble Saphir, Stelle Mermoz Avenue Cheikh Anto Diop - BP, 5714 Dakar 10200 Senegal",
    city: "DAKAR",
    contactNumber: "+221-773-339-494",
    email: "mohamed.kane@sn.fracht.africa",
  },
  {
    coordinates: [30.078123, -1.94717],
    attributes: {
      name: "55",
    },
    officeName: "Fracht Rwanda Ltd.",
    address: "KN 14 Av. No. 37 P.O. Box 3322 Kigali Rwanda",
    city: "KIGALI",
    contactNumber: "+250-78-83-011-95",
    email: "bastian.schmitz@rw.fracht.com",
  },
  {
    coordinates: [2.0908, 13.53921],
    attributes: {
      name: "56",
    },
    officeName: "NAT International - Fracht Group",
    address: "627 Rue du Progres ZIBP 10699 Niamey Niger",
    city: "NIAMEY",
    contactNumber: "+22786380191",
    email: "christophe.boulant@fracht.africa",
  },
  {
    coordinates: [-7.62481, 33.58064],
    attributes: {
      name: "57",
    },
    officeName: "Fracht Maroc SAS",
    address:
      "301, Angle Abdel moumen/Anoual Bureau 64 Casablanca 20250 Morocco",
    city: "CASABLANCA",
    contactNumber: "+212 5226 67202",
    email: "facal.rabia@ma.fracht.africa",
  },
];

export const clockZones = [
  {
    name: "Europe/Vatican",
    displayName: "Europe/Vatican - (CEST)",
  },
  {
    name: "Asia/Muscat",
    displayName: "Asia/Muscat - (+04)",
  },
  {
    name: "Asia/Kabul",
    displayName: "Asia/Kabul - (+0430)",
  },
  {
    name: "Pacific/Efate",
    displayName: "Pacific/Efate - (+11)",
  },
  {
    name: "Asia/Vientiane",
    displayName: "Asia/Vientiane - (+07)",
  },
  {
    name: "Asia/Ust-Nera",
    displayName: "Asia/Ust-Nera - (+10)",
  },
  {
    name: "Asia/Tashkent",
    displayName: "Asia/Tashkent - (+05)",
  },
  {
    name: "America/Montevideo",
    displayName: "America/Montevideo - (-03)",
  },
  {
    name: "America/New_York",
    displayName: "America/New_York - (EDT)",
  },
  {
    name: "Asia/Aden",
    displayName: "Asia/Aden - (+03)",
  },
  {
    name: "Antarctica/Troll",
    displayName: "Antarctica/Troll - (+02)",
  },
  {
    name: "Asia/Omsk",
    displayName: "Asia/Omsk - (+06)",
  },
  {
    name: "Pacific/Midway",
    displayName: "Pacific/Midway - (SST)",
  },
  {
    name: "Australia/Lord_Howe",
    displayName: "Australia/Lord_Howe - (+1030)",
  },
  {
    name: "Australia/Lindeman",
    displayName: "Australia/Lindeman - (AEST)",
  },
  {
    name: "Australia/Darwin",
    displayName: "Australia/Darwin - (ACST)",
  },
  {
    name: "Australia/Perth",
    displayName: "Australia/Perth - (AWST)",
  },
  {
    name: "Australia/Eucla",
    displayName: "Australia/Eucla - (+0845)",
  },
  {
    name: "America/St_Thomas",
    displayName: "America/St_Thomas - (AST)",
  },
  {
    name: "Europe/Mariehamn",
    displayName: "Europe/Mariehamn - (EEST)",
  },
  {
    name: "America/Thule",
    displayName: "America/Thule - (ADT)",
  },
  {
    name: "Asia/Singapore",
    displayName: "Asia/Singapore - (+08)",
  },
  {
    name: "America/Caracas",
    displayName: "America/Caracas - (-04)",
  },
  {
    name: "America/Miquelon",
    displayName: "America/Miquelon - (-02)",
  },
  {
    name: "America/Lima",
    displayName: "America/Lima - (-05)",
  },
  {
    name: "America/Indiana/Vevay",
    displayName: "America/Indiana/Vevay - (EDT)",
  },
  {
    name: "Asia/Taipei",
    displayName: "Asia/Taipei - (CST)",
  },
  {
    name: "America/St_Johns",
    displayName: "America/St_Johns - (NDT)",
  },
  {
    name: "America/Cayman",
    displayName: "America/Cayman - (EST)",
  },
  {
    name: "America/North_Dakota/Beulah",
    displayName: "America/North_Dakota/Beulah - (CDT)",
  },
  {
    name: "America/Boise",
    displayName: "America/Boise - (MDT)",
  },
  {
    name: "America/Phoenix",
    displayName: "America/Phoenix - (MST)",
  },
  {
    name: "America/Los_Angeles",
    displayName: "America/Los_Angeles - (PDT)",
  },
  {
    name: "Asia/Yangon",
    displayName: "Asia/Yangon - (+0630)",
  },
  {
    name: "Africa/Lome",
    displayName: "Africa/Lome - (GMT)",
  },
  {
    name: "Pacific/Tahiti",
    displayName: "Pacific/Tahiti - (-10)",
  },
  {
    name: "Atlantic/Cape_Verde",
    displayName: "Atlantic/Cape_Verde - (-01)",
  },
  {
    name: "Africa/Tunis",
    displayName: "Africa/Tunis - (CET)",
  },
  {
    name: "Pacific/Galapagos",
    displayName: "Pacific/Galapagos - (-06)",
  },
  {
    name: "Africa/Casablanca",
    displayName: "Africa/Casablanca - (+01)",
  },
  {
    name: "Atlantic/Madeira",
    displayName: "Atlantic/Madeira - (WEST)",
  },
  {
    name: "Pacific/Wallis",
    displayName: "Pacific/Wallis - (+12)",
  },
  {
    name: "Europe/Jersey",
    displayName: "Europe/Jersey - (BST)",
  },
  {
    name: "Atlantic/Azores",
    displayName: "Atlantic/Azores - (+00)",
  },
  {
    name: "Pacific/Saipan",
    displayName: "Pacific/Saipan - (ChST)",
  },
  {
    name: "Asia/Hong_Kong",
    displayName: "Asia/Hong_Kong - (HKT)",
  },
  {
    name: "Asia/Pontianak",
    displayName: "Asia/Pontianak - (WIB)",
  },
  {
    name: "Asia/Makassar",
    displayName: "Asia/Makassar - (WITA)",
  },
  {
    name: "Asia/Jayapura",
    displayName: "Asia/Jayapura - (WIT)",
  },
  {
    name: "Asia/Kolkata",
    displayName: "Asia/Kolkata - (IST)",
  },
  {
    name: "Asia/Jerusalem",
    displayName: "Asia/Jerusalem - (IDT)",
  },
  {
    name: "Asia/Tehran",
    displayName: "Asia/Tehran - (+0330)",
  },
  {
    name: "Asia/Tokyo",
    displayName: "Asia/Tokyo - (JST)",
  },
  {
    name: "Indian/Mayotte",
    displayName: "Indian/Mayotte - (EAT)",
  },
  {
    name: "Pacific/Apia",
    displayName: "Pacific/Apia - (+13)",
  },
  {
    name: "Pacific/Kiritimati",
    displayName: "Pacific/Kiritimati - (+14)",
  },
  {
    name: "Asia/Seoul",
    displayName: "Asia/Seoul - (KST)",
  },
  {
    name: "Asia/Colombo",
    displayName: "Asia/Colombo - (+0530)",
  },
  {
    name: "Europe/Kaliningrad",
    displayName: "Europe/Kaliningrad - (EET)",
  },
  {
    name: "Africa/Harare",
    displayName: "Africa/Harare - (CAT)",
  },
  {
    name: "Africa/Niamey",
    displayName: "Africa/Niamey - (WAT)",
  },
  {
    name: "Asia/Kathmandu",
    displayName: "Asia/Kathmandu - (+0545)",
  },
  {
    name: "Pacific/Niue",
    displayName: "Pacific/Niue - (-11)",
  },
  {
    name: "Antarctica/McMurdo",
    displayName: "Antarctica/McMurdo - (NZDT)",
  },
  {
    name: "Pacific/Chatham",
    displayName: "Pacific/Chatham - (+1345)",
  },
  {
    name: "Pacific/Marquesas",
    displayName: "Pacific/Marquesas - (-0930)",
  },
  {
    name: "Pacific/Gambier",
    displayName: "Pacific/Gambier - (-09)",
  },
  {
    name: "Asia/Manila",
    displayName: "Asia/Manila - (PST)",
  },
  {
    name: "Asia/Karachi",
    displayName: "Asia/Karachi - (PKT)",
  },
  {
    name: "Pacific/Pitcairn",
    displayName: "Pacific/Pitcairn - (-08)",
  },
  {
    name: "Asia/Dili",
    displayName: "Asia/Dili - (+09)",
  },
  {
    name: "Europe/London",
    displayName: "Europe/London - (+01)",
  },
  {
    name: "Europe/Volgograd",
    displayName: "Europe/Volgograd - (MSK)",
  },
  {
    name: "America/Nome",
    displayName: "America/Nome - (AKDT)",
  },
  {
    name: "America/Adak",
    displayName: "America/Adak - (HDT)",
  },
  {
    name: "Pacific/Honolulu",
    displayName: "Pacific/Honolulu - (HST)",
  },
  {
    name: "Africa/Mbabane",
    displayName: "Africa/Mbabane - (SAST)",
  },
];
