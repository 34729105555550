import {
  GET_USER_VIEWS_FAILURE,
  GET_USER_VIEWS_SUCCESS,
  SET_AXIS_LOADING,
} from "./actionTypes";

const initialState = {
  viewsDetails: [],
  axisLoading: false,
  error: false,
  errorMessage: "",
};

const Axis = (state = initialState, action) => {
  switch (action.type) {
    case SET_AXIS_LOADING:
      return {
        ...state,
        axisLoading: true,
      };

    case GET_USER_VIEWS_SUCCESS:
      return {
        ...state,
        axisLoading: false,
        viewsDetails: action.payload,
      };
    case GET_USER_VIEWS_FAILURE:
      return {
        ...state,
        axisLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    default:
      return state;
  }
};

export default Axis;
