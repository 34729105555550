import { takeEvery, put, all } from "redux-saga/effects";
import API from "../../utils/axios";
import {
  GET_READY_TO_PICKUP_DETAIL,
  GET_READY_TO_PICKUP_DETAIL_FAILURE,
  GET_READY_TO_PICKUP_DETAIL_SUCCESS,
  RESET_READY_TO_PICKUP_DETAIL,
  RESET_READY_TO_PICKUP_DETAIL_FAILURE,
  RESET_READY_TO_PICKUP_DETAIL_SUCCESS,
  SET_LOADING,
} from "./actionTypes";

// api/{v}/Direct-Summary/{id}/Details/Ready-To-Pickup
function* getReadyToPickupDetailSaga({ id }) {
  try {
    yield put({ type: SET_LOADING });
    const { data } = yield API.get(
      `/1/direct-summary/${id}/details/ready-to-pickup`
    );
    yield put({
      type: GET_READY_TO_PICKUP_DETAIL_SUCCESS,
      payload: data.value,
    });
  } catch (error) {
    console.log("error", error);
    yield put({
      type: GET_READY_TO_PICKUP_DETAIL_FAILURE,
      errorMessage: "Unable to get ready to book details",
    });
  }
}

function* resetReadyToPickupDetailSaga() {
  try {
    yield put({ type: SET_LOADING });
    yield put({ type: RESET_READY_TO_PICKUP_DETAIL_SUCCESS });
  } catch (error) {
    console.log("error", error);
    yield put({
      type: RESET_READY_TO_PICKUP_DETAIL_FAILURE,
      errorMessage: "Unable to reset ready to pickup details",
    });
  }
}

function* EditDetailsSaga() {
  yield all([
    yield takeEvery(GET_READY_TO_PICKUP_DETAIL, getReadyToPickupDetailSaga),
    yield takeEvery(RESET_READY_TO_PICKUP_DETAIL, resetReadyToPickupDetailSaga),
  ]);
}

export default EditDetailsSaga;
