import { takeEvery, put, all } from "redux-saga/effects";
import API from "../../utils/axios";
import {
  GET_AVAILABLE_CAPACITY_DETAIL,
  GET_AVAILABLE_CAPACITY_DETAIL_FAILURE,
  GET_AVAILABLE_CAPACITY_DETAIL_SUCCESS,
  GET_BOOKING_LIST,
  GET_BOOKING_LIST_FAILURE,
  GET_BOOKING_LIST_SUCCESS,
  GET_LOAD_NUMBER_DETAIL,
  GET_LOAD_NUMBER_DETAIL_FAILURE,
  GET_LOAD_NUMBER_DETAIL_SUCCESS,
  RESET_AVAILABLE_CAPACITY_DETAIL,
  RESET_AVAILABLE_CAPACITY_DETAIL_FAILURE,
  RESET_AVAILABLE_CAPACITY_DETAIL_SUCCESS,
  RESET_LOAD_NUMBER_DETAIL,
  RESET_LOAD_NUMBER_DETAIL_FAILURE,
  RESET_LOAD_NUMBER_DETAIL_SUCCESS,
  SET_AVAILABLE_CAPACITY_DETAIL_LOADING,
  SET_LOADING,
} from "./actionTypes";

// api/{v}/Direct-Summary/{id}/Details/Booked
function* getLoadNumberDetailSaga({ id }) {
  try {
    yield put({ type: SET_LOADING });
    const { data } = yield API.get(`/1/direct-summary/${id}/details/booked`);
    yield put({ type: GET_LOAD_NUMBER_DETAIL_SUCCESS, payload: data.value });
  } catch (error) {
    console.log("error", error);
    yield put({
      type: GET_LOAD_NUMBER_DETAIL_FAILURE,
      errorMessage: "Unable to get load number details",
    });
  }
}

function* resetLoadNumberDetailSaga() {
  try {
    yield put({ type: SET_LOADING });
    yield put({ type: RESET_LOAD_NUMBER_DETAIL_SUCCESS });
  } catch (error) {
    console.log("error", error);
    yield put({
      type: RESET_LOAD_NUMBER_DETAIL_FAILURE,
      errorMessage: "Unable to reset load number details",
    });
  }
}

function* getBookingListSaga({ loadedLocation, customerId }) {
  try {
    yield put({ type: SET_LOADING });
    // api/{v}/Available-Capacity/Booking-Number/list
    const { status, data } = yield API.post(
      `/1/available-capacity/booking-number/list`,
      {
        loadedLocation,
        customerId,
      }
    );

    if (status === 204)
      yield put({ type: GET_BOOKING_LIST_SUCCESS, payload: [] });
    else yield put({ type: GET_BOOKING_LIST_SUCCESS, payload: data.value });
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_BOOKING_LIST_FAILURE,
      payload: { errorMessage: "Unable to get booking list" },
    });
  }
}

// API call after Booking number selected
// api/1/available-capacity/{id}/flag/9/details
function* getAvailableCapacityDetailSaga({ id, flagValue }) {
  try {
    yield put({ type: SET_AVAILABLE_CAPACITY_DETAIL_LOADING });
    const response = yield API.get(
      `/1/available-capacity/${id}/flag/${flagValue}/details`
    );
    switch (response.status) {
      case 204:
        yield put({
          type: GET_AVAILABLE_CAPACITY_DETAIL_SUCCESS,
          payload: {
            id: "",
            week: "",
            etdWeek: "",
            pol: "",
            destination: "",
            carrier: "",
            vessel: "",
            bookingNumber: "",
            containerQuantity: "",
            erd: "",
            documentCutOffDate: "",
            cutOffDate: "",
            etd: "",
            eta: "",
            notes: "",
            document: [],
            isRecordLocked: null,
            lockedUser: "",
            erdDisplay: "",
            documentCutOffDateDisplay: "",
            cutOffDateDisplay: "",
            etdDisplay: "",
            etaDisplay: "",
            deliveryLocation: "",
            originFT: null,
            isActive: null,
          },
        });
        break;
      case 200:
        yield put({
          type: GET_AVAILABLE_CAPACITY_DETAIL_SUCCESS,
          payload: response.data.value,
        });
        break;

      default:
        break;
    }
  } catch (error) {
    console.log("error", error);
    yield put({
      type: GET_AVAILABLE_CAPACITY_DETAIL_FAILURE,
      errorMessage: "Unable to get available capacity details",
    });
  }
}

function* resetAvailableCapacityDetailSaga() {
  try {
    yield put({ type: SET_AVAILABLE_CAPACITY_DETAIL_LOADING });
    yield put({ type: RESET_AVAILABLE_CAPACITY_DETAIL_SUCCESS });
  } catch (error) {
    console.log("error", error);
    yield put({
      type: RESET_AVAILABLE_CAPACITY_DETAIL_FAILURE,
      errorMessage: "Unable to reset available capacity details",
    });
  }
}

// function fetchProductsApi({ bookingNumber }) {
//   return API.fetch(`/1/availablecapacity/details/${bookingNumber}`)
//     .then((response) => ({ response }))
//     .catch((error) => ({ error }));
// }

// function* fetchProducts() {
//   const { response, error } = yield call(fetchProductsApi);
//   if (response) yield put({ type: "PRODUCTS_RECEIVED", products: response });
//   else yield put({ type: "PRODUCTS_REQUEST_FAILED", error });
// }

function* AssignContainerSaga() {
  yield all([yield takeEvery(GET_LOAD_NUMBER_DETAIL, getLoadNumberDetailSaga)]);
  yield all([
    yield takeEvery(RESET_LOAD_NUMBER_DETAIL, resetLoadNumberDetailSaga),
  ]);
  yield all([yield takeEvery(GET_BOOKING_LIST, getBookingListSaga)]);
  yield all([
    yield takeEvery(
      GET_AVAILABLE_CAPACITY_DETAIL,
      getAvailableCapacityDetailSaga
    ),
  ]);
  yield all([
    yield takeEvery(
      RESET_AVAILABLE_CAPACITY_DETAIL,
      resetAvailableCapacityDetailSaga
    ),
  ]);
}

export default AssignContainerSaga;
