import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

import React, { useCallback, useState, useReducer } from "react";

import DataGrid, { Column } from "devextreme-react/data-grid";
import { customers } from "./gridColums";
import CustomColumnChooser from "../../commonControls/devExGrid/customColumnChooser";
import { Button } from "devextreme-react";

const initialState = [
  {
    dataField: "CompanyName",
    visible: true,
  },
  {
    dataField: "City",
    visible: true,
  },
  {
    dataField: "State",
    visible: true,
  },
  {
    dataField: "Phone",
    visible: true,
  },
  {
    dataField: "Fax",
    visible: true,
  },
];

function reducer(state, changes) {
  if (Object.keys(changes).length === 0) return state;

  var newState = [...state];
  changes.forEach((change) => {
    var column = newState.find((c) => c.dataField === change.dataField);
    Object.keys(change).forEach((key) => {
      column[key] = change[key];
    });
  });
  return newState;
}

export default function GridDemo() {
  const [columns, setColumnsState] = useReducer(reducer, initialState);

  const [visible, setVisible] = useState(false);

  const onHiding = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const onApply = useCallback(
    (changes) => {
      setColumnsState(changes);
      setVisible(false);
    },
    [setColumnsState, setVisible]
  );

  return (
    <React.Fragment>
      <div id="customDiv">
        <Button id={"myColumnChooser"} onClick={() => setVisible(true)}>
          Choose Columns
        </Button>
        <CustomColumnChooser
          container="#customDiv"
          button="#myColumnChooser"
          visible={visible}
          onHiding={onHiding}
          columns={columns}
          onApply={onApply}
        />
      </div>
      <DataGrid id="demo-grid" dataSource={customers} showBorders={true}>
        {columns.map((column) => {
          return (
            <Column
              key={column.dataField}
              dataField={column.dataField}
              visible={column.visible}
            />
          );
        })}
      </DataGrid>
    </React.Fragment>
  );
}
