import {
  GET_COMPONENT_MODEL_FAILURE,
  GET_COMPONENT_MODEL_SUCCESS,
  GET_COMPONENT_TYPE_FAILURE,
  GET_COMPONENT_TYPE_SUCCESS,
  GET_LOCATION_LIST_FAILURE,
  GET_LOCATION_LIST_SUCCESS,
  GET_MCRS_DETAIL_FAILURE,
  GET_MCRS_DETAIL_SUCCESS,
  GET_PROJECT_LOGISTICS_LIST_FAILURE,
  GET_PROJECT_LOGISTICS_LIST_SUCCESS,
  GET_STATUS_LIST_FAILURE,
  GET_STATUS_LIST_SUCCESS,
  RESET_MCRS_DETAIL,
  RESET_MCRS_DETAIL_FAILURE,
  RESET_MCRS_DETAIL_SUCCESS,
  SET_LOADING,
} from "./actionTypes";

const initialState = {
  locationList: [],
  statusList: [],
  componentTypeList: [],
  componentModelList: [],
  projectLogisticsList: [],
  MCRSDetails: {
    id: null,
    status: null,
    customer: "",
    orderNumber: "",
    frachtFile: "",
    origin: null,
    destination: null,
    // component section
    componentType: null,
    model: null,
    caboose: "",
    // car section
    carNumber: null,
    exWorksDate: null,
    carShippedPlanned: null,
    carShippedActual: null,
    requiredAtPortDate: null,
    carDeliveredPlanned: null,
    carDeliveredActual: null,
    endOfLaycan: null,
    startOfReturn: null,
    transitTimeDaysLoaded: "",
    estimatedReturnDays: "",
    estimatedReturnDate: null,
    // DimensionSection
    length: "",
    width: "",
    height: "",
    factoryScaleWeight: "",
    factoryScaleWeightUOM: "KGS",
    shippingWeight: "",
    shippingWeightUOM: "KGS",
    // RouteDetailSection
    clearanceFileNumber: "",
    clearanceExpiration: null,
    route: "",
    routeApproved: "",
    specialTrainShipment: "",
    purchaseOrderNumber: "",
    projectLogisticsCoordinator: null,
  },
  lockOption: false,
  loading: false,
  error: false,
  errorMessage: "",
};

const RailCarScheduler = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_LOCATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        locationList: action.payload,
      };
    case GET_LOCATION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: "Unable to get location list",
      };

    case GET_STATUS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        statusList: action.payload,
      };
    case GET_STATUS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: "Unable to get status list",
      };

    case GET_COMPONENT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        componentTypeList: action.payload,
      };
    case GET_COMPONENT_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: "Unable to get component Type List",
      };

    case GET_COMPONENT_MODEL_SUCCESS:
      return {
        ...state,
        loading: false,
        componentModelList: action.payload,
      };
    case GET_COMPONENT_MODEL_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: "Unable to get component Model List",
      };

    case GET_PROJECT_LOGISTICS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        projectLogisticsList: action.payload,
      };
    case GET_PROJECT_LOGISTICS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: "Unable to get project Logistics List",
      };

    case GET_MCRS_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        MCRSDetails: action.payload,
      };
    case GET_MCRS_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: "Unable to get Rail Car Scheduler Detail",
      };

    case RESET_MCRS_DETAIL:
      return {
        ...state,
        loading: true,
      };
    case RESET_MCRS_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        MCRSDetails: {
          id: null,
          status: null,
          customer: "",
          orderNumber: "",
          frachtFile: "",
          origin: null,
          destination: null,
          // component section
          componentType: null,
          model: null,
          caboose: "",
          // car section
          carNumber: null,
          exWorksDate: null,
          carShippedPlanned: null,
          carShippedActual: null,
          requiredAtPortDate: null,
          carDeliveredPlanned: null,
          carDeliveredActual: null,
          endOfLaycan: null,
          startOfReturn: null,
          transitTimeDaysLoaded: "",
          estimatedReturnDays: "",
          estimatedReturnDate: null,
          // DimensionSection
          length: "",
          width: "",
          height: "",
          factoryScaleWeight: "",
          factoryScaleWeightUOM: "KGS",
          shippingWeight: "",
          shippingWeightUOM: "KGS",
          // RouteDetailSection
          clearanceFileNumber: "",
          clearanceExpiration: null,
          route: "",
          routeApproved: "",
          specialTrainShipment: "",
          purchaseOrderNumber: "",
          projectLogisticsCoordinator: null,
        },
      };
    case RESET_MCRS_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: "Unable to reset Rail Car Scheduler Detail",
      };

    default:
      return state;
  }
};

export default RailCarScheduler;
