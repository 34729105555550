import {
  GET_READY_TO_PICKUP_DETAIL,
  RESET_READY_TO_PICKUP_DETAIL,
} from "./actionTypes";

export const getReadyToPickupDetailAction = (id) => ({
  type: GET_READY_TO_PICKUP_DETAIL,
  id: id,
});

export const resetReadyToPickupDetailAction = () => ({
  type: RESET_READY_TO_PICKUP_DETAIL,
});
