import {
  GET_READY_TO_PICKUP_DETAIL_FAILURE,
  GET_READY_TO_PICKUP_DETAIL_SUCCESS,
  RESET_READY_TO_PICKUP_DETAIL,
  RESET_READY_TO_PICKUP_DETAIL_FAILURE,
  RESET_READY_TO_PICKUP_DETAIL_SUCCESS,
  SET_LOADING,
} from "./actionTypes";

const initialState = {
  readyToBookDetails: {
    pol: "",
    destination: "",
    vessel: "",
    bookingNumber: null,
    containerQuantity: "",
    erd: "",
    documentCutOffDate: "",
    cutOffDate: "",
    etd: "",
    eta: "",
    customerId: "",
    poNumber: "",
    forwardReferenceNumber: "",
    loadNumber: "",
    containerNumber: null,
    loadedDate: "",
    pickedUpDate: null,
    fileNumber: null,
    carrier: "",
    trucker: null,
    emptyOutDate: null,
    bookingNumberEmptyPulledOutDate: null,
    storageYardDate: null,
    ingateDate: null,
    cobDate: null,
    notes: "",
    document: [
      {
        documentName: "",
        documentType: "",
        uploadedDate: "",
        content: "",
        documentExtension: "",
        documentSize: "",
      },
    ],
  },
  pickupLoading: false,
  error: false,
  errorMessage: "",
};

const EditDetailContainer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        pickupLoading: true,
      };

    case GET_READY_TO_PICKUP_DETAIL_SUCCESS:
      return {
        ...state,
        pickupLoading: false,
        readyToBookDetails: { ...action.payload },
      };
    case GET_READY_TO_PICKUP_DETAIL_FAILURE:
      return {
        ...state,
        pickupLoading: false,
        error: true,
        errorMessage: "Unable to get ready to book details",
      };

    case RESET_READY_TO_PICKUP_DETAIL:
      return {
        ...state,
        pickupLoading: true,
      };
    case RESET_READY_TO_PICKUP_DETAIL_SUCCESS:
      return {
        ...state,
        pickupLoading: false,
        readyToBookDetails: {
          pol: "",
          destination: "",
          vessel: "",
          bookingNumber: null,
          containerQuantity: "",
          erd: "",
          documentCutOffDate: "",
          cutOffDate: "",
          etd: "",
          eta: "",
          customerId: "",
          poNumber: "",
          forwardReferenceNumber: "",
          loadNumber: "",
          containerNumber: null,
          loadedDate: "",
          pickedUpDate: null,
          fileNumber: null,
          carrier: "",
          trucker: null,
          emptyOutDate: null,
          bookingNumberEmptyPulledOutDate: null,
          storageYardDate: null,
          ingateDate: null,
          cobDate: null,
          notes: "",
          document: [
            {
              documentName: "",
              documentType: "",
              uploadedDate: "",
              content: "",
              documentExtension: "",
              documentSize: "",
            },
          ],
        },
      };
    case RESET_READY_TO_PICKUP_DETAIL_FAILURE:
      return {
        ...state,
        pickupLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    default:
      return state;
  }
};

export default EditDetailContainer;
