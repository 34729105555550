import {
  GET_AVAILABLE_CAPACITY_DETAIL,
  GET_BOOKING_LIST,
  GET_LOAD_NUMBER_DETAIL,
  RESET_AVAILABLE_CAPACITY_DETAIL,
  RESET_LOAD_NUMBER_DETAIL,
} from "./actionTypes";

export const getLoadNumberDetailAction = (id) => ({
  type: GET_LOAD_NUMBER_DETAIL,
  id: id,
});

export const resetLoadNumberDetailAction = () => ({
  type: RESET_LOAD_NUMBER_DETAIL,
});

export const getBookingListAction = (loadedLocation, customerId) => {
  return {
    type: GET_BOOKING_LIST,
    loadedLocation: loadedLocation,
    customerId: customerId,
  };
};

export const getAvailableCapacityDetailAction = (id, flagValue) => {
  return {
    type: GET_AVAILABLE_CAPACITY_DETAIL,
    id: id,
    flagValue: flagValue,
  };
};

export const resetAvailableCapacityDetailAction = () => ({
  type: RESET_AVAILABLE_CAPACITY_DETAIL,
});
