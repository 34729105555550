import React, { useState } from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import ClearIcon from "@mui/icons-material/Clear";
import Loader from "../components/UI/Loader/Loader";
import FracgtTextArea from "./frachtTextArea";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    padding: "16px 24px",
  },
  dialogActions: {
    padding: "16px 24px",
  },
  cancelButton: {
    ...theme.typography.h6,
    width: "93px",
    height: "38px",
    color: `${theme.palette.color.skyBlue} !important`,
    background: `${theme.palette.color.white} !important`,
    border: "none",
    letterSpacing: "0px",
    marginRight: "10px",
    "&:hover": {
      color: `${theme.palette.color.cyanBlueC1} !important`,
      background: `${theme.palette.color.white} !important`,
      opacity: 1,
    },
  },
  startProcessingButton: {
    ...theme.typography.body2,
    minWidth: "127px",
    height: "38px",
    backgroundColor: `${theme.palette.color.skyBlue} !important`,
    color: `${theme.palette.color.white} !important`,
    border: "0.5px solid #CFD4D9",
    boxShadow: "0px 2px 7px #00000029",
    borderRadius: "4px",
    textTransform: "capitalize",
    opacity: 1,
    "&:hover": {
      backgroundColor: `${theme.palette.color.white} !important`,
      color: `${theme.palette.color.skyBlue} !important`,
      border: `1px solid ${theme.palette.color.skyBlue} !important`,
      opacity: 1,
    },
  },
  disabledButton: {
    ...theme.typography.body2,
    minWidth: "127px",
    height: "38px",
    backgroundColor: `${theme.palette.color.grayS6} !important`,
    border: `1px solid ${theme.palette.color.grayS7} !important`,
    color: `${theme.palette.color.lightGrayishBlueB3} !important`,
    boxShadow: "0px 2px 7px #00000029",
    borderRadius: "4px",
    textTransform: "capitalize",
    marginRight: "10px",
    cursor: "default",
    opacity: 1,
  },
  clearIcon: {
    cursor: "pointer",
  },
  warningIcon: {
    color: theme.palette.color.red,
    height: "14px",
    margin: "0px !important",
  },
  imgIcon: {
    height: "20px !important",
    width: "20px !important",
    marginRight: "10px",
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FrachtURLPopup = ({
  open,
  handleClose,
  loading,
  displayName,
  organizationList,
  powerBIURL,
  setPowerBIURL,
  showNextField,
  setShowNextField,
  orgInfo,
  orgData,
  setOrgData,
  handleBIDataSubmit,
}) => {
  const classes = useStyles();

  const urlRegex = /^(http(s)?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?$/;

  const fetchOrganizationName = (id, list) => {
    const organization = list.find((org) => org.organizationId === id);
    return organization ? organization.organizationName : "";
  };

  const handleNextClick = () => {
    setShowNextField(true);
  };

  const handleBackClick = () => {
    setShowNextField(false);
  };

  const handleUrlChange = (event) => {
    setPowerBIURL(event.target.value);
  };

  const isUrlValid = () => {
    return urlRegex.test(powerBIURL);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            width: "80%",
            maxHeight: 550,
          },
        }}
        maxWidth="md"
      >
        {loading && <Loader />}
        <DialogTitle className={classes.dialogTitle}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h4">Power BI URL ({displayName})</Typography>
            <ClearIcon
              className={classes.clearIcon}
              onClick={handleClose}
              id="ClearIcon"
            />
          </Stack>
        </DialogTitle>
        <DialogContent dividers>
          {showNextField ? (
            <Autocomplete
              multiple
              required
              disableCloseOnSelect
              id="organizationField"
              value={orgData}
              onChange={(e, newValue) => {
                setOrgData(newValue);
              }}
              options={organizationList}
              getOptionLabel={(option) =>
                option.organizationName ||
                fetchOrganizationName(option.organizationId, organizationList)
              }
              getOptionDisabled={(option) =>
                orgData?.some(
                  (selectedOption) =>
                    selectedOption.organizationId === option.organizationId
                ) ||
                (orgInfo && orgInfo.organizationId === option.organizationId)
              }
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    key={option.organizationId}
                    label={
                      option.organizationName ||
                      fetchOrganizationName(
                        option.organizationId,
                        organizationList
                      )
                    }
                    {...getTagProps({ index })}
                    disabled={
                      orgInfo?.organizationId &&
                      orgInfo?.organizationId === option.organizationId
                    }
                  />
                ))
              }
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.organizationId}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={
                      selected ||
                      orgData?.some(
                        (org) => org.organizationId === option.organizationId
                      )
                    }
                  />
                  {option.organizationName ||
                    fetchOrganizationName(
                      option.organizationId,
                      organizationList
                    )}
                </li>
              )}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Organization"
                  placeholder="Select Organization"
                />
              )}
            />
          ) : (
            <FracgtTextArea
              placeholder="Add Power BI URL *"
              minRows={2}
              maxRows={3}
              sx={{ marginRight: "0px !important" }}
              value={powerBIURL}
              onChange={handleUrlChange}
            />
          )}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          {showNextField ? (
            <Button
              className={classes.cancelButton}
              autoFocus
              onClick={handleBackClick}
            >
              BACK
            </Button>
          ) : (
            <Button
              className={classes.cancelButton}
              autoFocus
              onClick={handleClose}
            >
              CANCEL
            </Button>
          )}

          {!showNextField ? (
            <LoadingButton
              className={
                !isUrlValid()
                  ? classes.disabledButton
                  : classes.startProcessingButton
              }
              autoFocus
              onClick={handleNextClick}
              disabled={!isUrlValid()}
            >
              NEXT
            </LoadingButton>
          ) : (
            <Button
              className={
                orgData.length === 0
                  ? classes.disabledButton
                  : classes.startProcessingButton
              }
              autoFocus
              onClick={() => handleBIDataSubmit()}
              disabled={orgData.length === 0}
            >
              SUBMIT
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FrachtURLPopup;
