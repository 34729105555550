import { takeEvery, put, all } from "redux-saga/effects";
import API from "../../utils/axios";

import {
  GET_CLIENT_NAME,
  GET_CLIENT_NAME_FAILURE,
  GET_CLIENT_NAME_SUCCESS,
  GET_SHIPMENT_DETAIL,
  GET_SHIPMENT_DETAIL_FAILURE,
  GET_SHIPMENT_DETAIL_SUCCESS,
  RESET_SHIPMENT_DETAIL,
  RESET_SHIPMENT_DETAIL_FAILURE,
  RESET_SHIPMENT_DETAIL_SUCCESS,
  SET_LOADING,
} from "./actionTypes";

// api/{v}/Shipment/Shipments/{id}/Details
function* getShipmentDetailSaga({ payload }) {
  try {
    yield put({ type: SET_LOADING });
    const { data } = yield API.get(
      `/1/shipment/shipments/${payload}/details`
    );
    yield put({ type: GET_SHIPMENT_DETAIL_SUCCESS, payload: data.value[0] });
  } catch (error) {
    console.log("error", error);
    yield put({
      type: GET_SHIPMENT_DETAIL_FAILURE,
      errorMessage: "Unable to get shipment details",
    });
  }
}

function* resetShipmentDetailSaga() {
  try {
    yield put({ type: SET_LOADING });
    yield put({ type: RESET_SHIPMENT_DETAIL_SUCCESS });
  } catch (error) {
    console.log("error", error);
    yield put({
      type: RESET_SHIPMENT_DETAIL_FAILURE,
      errorMessage: "Unable to reset shipment details",
    });
  }
}

function* getClientNameSaga() {
  try {
    yield put({ type: SET_LOADING });
    yield put({ type: GET_CLIENT_NAME_SUCCESS });
  } catch (error) {
    console.log("error", error);
    yield put({
      type: GET_CLIENT_NAME_FAILURE,
      errorMessage: "Unable to get client name",
    });
  }
}

function* ShipmentDetailSaga() {
  yield all([yield takeEvery(GET_SHIPMENT_DETAIL, getShipmentDetailSaga)]);
  yield all([yield takeEvery(RESET_SHIPMENT_DETAIL, resetShipmentDetailSaga)]);
  yield all([yield takeEvery(GET_CLIENT_NAME, getClientNameSaga)]);
}

export default ShipmentDetailSaga;
