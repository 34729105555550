import axios from "axios";

const url = `${process.env.REACT_APP_ENV}`;

const API = axios.create({
  baseURL: url,
});

axios.defaults.headers.common["Content-Type"] = "application/json";
API.interceptors.request.use((request) => {
  request.headers["Content-Type"] = "application/json";
  if (request.url.endsWith("auth")) return request;

  const token = window.localStorage.getItem("token");
  request.headers["Authorization"] = `Bearer ${token}`;
  // request.headers["Content-Type"] = "application/json";

  return request;
});
API.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      console.log("401 error", error);
      window.location.reload();
    }
    return error.response;
  }
);

export default API;
