import React, { useCallback, useEffect, useMemo, useState } from "react";
import DataGrid, {
  Column,
  Editing,
  KeyboardNavigation,
} from "devextreme-react/data-grid";
import GridControl from "../../commonControls/devExGrid/gridControl";
import { DefaultGridConfiguration } from "../../commonControls/devExGrid/grid-settings";
import { TextBox } from "devextreme-react";
import FrachtDeleteDialogBox from "../../commonControls/frachtDeleteDialogBox";
import { Stack } from "@mui/material";
import FrachtSearchControl from "../../commonControls/frachtSearchControl";
import overageIcon from "../../assets/images/overage-iconsv.svg";
const POgrid = ({
  rows,
  gridData,
  setGridData,
  noDataText,
  setnoDataText,
  dataLoading,
  handlePostPOData,
  dialogMessage,
  setDialogMessage,
  dialogType,
  setDialogType,
  disabled,
  setDisabled,
}) => {
  const clearFilterVal = { showClearButton: true };
  const gridConfig = useMemo(() => {
    return {
      ...DefaultGridConfiguration,
      defaultColums: false,
      height: "calc(100vh - 455px)",
      Id: "POgrid",
      columAutoWidth: false,
      allowColumnResizing: true,
      columnMinWidth: 70,
      filterRow: true,
    };
  }, []);

  useEffect(() => {
    let temp = rows.map((obj, index) => ({
      ...obj,
    }));
    setGridData(temp);
    if (rows?.length > 0) {
      setGridData(temp);
      setDialogType(true);
      setDisabled(false);
      setTimeout(() => {
        var textbox = document.getElementById(`txtQty0`);
        textbox.focus();
      }, 500);
    } else {
      setGridData([]);
      setnoDataText("Please enter a shipment id to search.");
      setDisabled(true);
    }
  }, [rows]);

  const onCellPrepared = (e) => {
    if (e.rowType === "header") {
      if (e.column.dataField) {
        e.cellElement.id = e.column.dataField;
      } else {
        e.cellElement.id = "actionColumn";
      }
    }
  };

  const renderBookingCell = (cell) => {
    const row = cell.data;
    return (
      <>
        {row?.qtyRemaining < 0 ? (
          <Stack direction="row">
            <span id={`qtyRemaining${cell.data.index}`}>
              {Math.abs(row.qtyRemaining).toFixed(2)}
            </span>
            <img
              id={`overageIcon${cell.data.index}`}
              src={overageIcon}
              alt="overageIcon"
              height="24px"
              width="20px"
              style={{ marginLeft: "4px" }}
            />
          </Stack>
        ) : (
          <>
            <Stack direction="row">
              <span id={`qtyRemaining${cell.data.index}`}>
                {Math.abs(row.qtyRemaining).toFixed(2)}
              </span>
              <img
                id={`overageIcon${cell.data.index}`}
                src={overageIcon}
                alt="overageIcon"
                height="24px"
                width="20px"
                style={{ marginLeft: "4px", display: "none" }}
              />
            </Stack>
          </>
        )}
      </>
    );
  };

  const handelCreateRcvng = () => {
    if (dialogType) {
      setDialogMessage("Are you sure you want to create a receipt?");
    }
  };

  const onChangesChange = (changes) => {
    changes.forEach((element) => {
      const qtyRem =
        element.key.quantity -
        (element.key.totalReceivedQtyForShipment +
          (parseFloat(element.data?.quantityReceived) || 0));
      //Update Grid original Data
      const updatedGridData = gridData?.map((rowData) => {
        if (rowData.index === element.key.index) {
          const updatedRowData = {
            ...rowData,
            quantityReceived: parseFloat(element.data?.quantityReceived) || 0,
            qtyRemaining: qtyRem,
          };
          return updatedRowData;
        }
        return rowData;
      });
      setGridData(updatedGridData);
      setTimeout(() => {
        const textbox = document.getElementById(
          `txtQty${element.key.index + 1}`
        );
        textbox.focus();
      }, 500);
    });
  };

  const renderQtyReceiveCell = (cell) => {
    const row = cell.data;
    const nameLabel = { "aria-label": "Name", id: `txtQty${row.index}` };
    return (
      <>
        <TextBox
          defaultValue={row?.quantityReceived?.toFixed(2)}
          inputAttr={nameLabel}
          showClearButton={true}
        />
      </>
    );
  };

  return (
    <>
      <FrachtDeleteDialogBox
        open={Boolean(dialogMessage)}
        message={dialogMessage}
        handleClose={() => {
          setDialogMessage("");
        }}
        success={dialogType}
        performAction={handlePostPOData}
        modalTitle="Create Receipt"
        modalType="Normal"
        isDeleting={dataLoading}
      />
      <GridControl
        gridData={gridData}
        rowTemplate={null}
        onGridChange={() => {}}
        gridConfiguration={gridConfig}
        gridColums={[]}
        onCellClick={(e) => {}}
        // onSaving={onSaving}
        onCellPrepared={(e) => onCellPrepared(e)}
        noDataText={noDataText}
        onFocusedCellChanging={() => {}}
      >
        <KeyboardNavigation
          editOnKeyPress={true}
          enterKeyAction={"moveFocus"}
          enterKeyDirection={"column"}
        />

        <Editing
          mode={"batch"}
          allowUpdating={true}
          onChangesChange={onChangesChange}
        />
        <Column
          dataField="poNumber"
          caption="po number"
          defaultSortIndex={0}
          width="10%"
          allowEditing={false}
          editorOptions={clearFilterVal}
        />
        <Column
          dataField="itemId"
          caption="Item Id"
          width="10%"
          defaultSortIndex={1}
          allowEditing={false}
          editorOptions={clearFilterVal}
        />
        <Column
          dataField="poItemLine"
          caption="po line"
          width="10%"
          defaultSortIndex={2}
          allowEditing={false}
          editorOptions={clearFilterVal}
        />
        <Column
          dataField="description"
          caption="description"
          width="13%"
          defaultSortIndex={3}
          allowEditing={false}
          editorOptions={clearFilterVal}
        />
        <Column
          dataField="supplierId"
          caption="supplier"
          width="10%"
          defaultSortIndex={4}
          allowEditing={false}
          editorOptions={clearFilterVal}
        />
        <Column
          dataField="quantity"
          alignment="left"
          caption="quantity ordered"
          width="10%"
          defaultSortIndex={5}
          allowEditing={false}
          editorOptions={clearFilterVal}
        />
        <Column
          dataField="totalReceivedQtyForShipment"
          alignment="left"
          caption="previously received"
          width="10%"
          defaultSortIndex={8}
          allowEditing={false}
          editorOptions={clearFilterVal}
        />
        <Column
          dataField="quantityReceived"
          alignment="left"
          caption="quantity received"
          width="10%"
          defaultSortIndex={6}
          editorOptions={clearFilterVal}
          cellRender={(cell) => {
            return <span>{renderQtyReceiveCell(cell)}</span>;
          }}
        />
        <Column
          dataField="qtyRemaining"
          alignment="left"
          caption="quantity remaining"
          width="10%"
          defaultSortIndex={7}
          allowEditing={false}
          editorOptions={clearFilterVal}
          cellRender={(cell) => {
            return <span>{renderBookingCell(cell)}</span>;
          }}
        />
        <Column
          dataField="status"
          alignment="left"
          caption="Status"
          width="7%"
          defaultSortIndex={8}
          allowEditing={false}
          editorOptions={clearFilterVal}
        />
      </GridControl>

      <Stack direction="row" spacing={3}>
        <FrachtSearchControl
          textId="po-search"
          showSearchFilter={false}
          showActionButton={true}
          handelActionButtonClick={handelCreateRcvng}
          actionButtonText="Create Receiving"
          actionButtonDisabled={disabled}
          background="none"
          btnStyle={{ marginRight: "-8px" }}
        />
      </Stack>
    </>
  );
};

export default POgrid;
