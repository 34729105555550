import React, { useEffect, useState } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { EventType, InteractionType } from "@azure/msal-browser";
import { b2cPolicies, loginRequest } from "./authConfig";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { v4 as uuid } from "uuid";
import { FlagsProvider } from "react-feature-flags";
import theme from "./theme";
import Home from "./containers/Home/Home";
import FeatureFlagData from "./featureFlag.json";
import API from "./utils/axios";
import Loader from "./components/UI/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";
import {
  getAnalyticData,
  getOrganizationId,
  getOrganizationInfo,
  getUserDetails,
  getWidgetOptions,
  postUserDetails,
  setMenuVisibility,
  setUserDetails,
  setWidgetOptions,
} from "./redux/actions";
import ErrorPage from "./errorPage/ErrorPage";
import { getAnalyticsVisibility } from "./redux/analytics/action";
import { getAppSettings } from "./redux/appSettings/action";

function App() {
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  useEffect(() => {
    if (accounts.length === 0)
      instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
      });
  }, [inProgress, isAuthenticated]);

  // this event triggers on click of forget password
  instance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_FAILURE) {
      if (event.error && event.error.errorMessage.indexOf("AADB2C90118") > -1) {
        if (event.interactionType === InteractionType.Redirect) {
          instance.loginRedirect(b2cPolicies.authorities.forgotPassword);
        } else if (event.interactionType === InteractionType.Popup) {
          instance
            .loginPopup(b2cPolicies.authorities.forgotPassword)
            .catch(() => {
              return;
            });
        }
      }
    }
  });

  return (
    <>
      <ThemeProvider theme={createTheme(theme)}>
        {isAuthenticated ? <AppContent /> : <Loader />}
      </ThemeProvider>
    </>
  );
}

function AppContent() {
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  const MenuVisibility = useSelector((state) => state.MenuVisibility);
  const { user, organizationId } = useSelector((state) => state.LoginUser);
  const { appSetting } = useSelector((state) => state.AppSetting);
  const dispatch = useDispatch();
  useEffect(() => {
    RequestAccessToken();
  }, []);

  useEffect(() => {
    try {
      const widgetData = localStorage.getItem("WIDGET_DATA");
      if (organizationId) {
        dispatch(getWidgetOptions(organizationId, ""));
        dispatch(getAnalyticData(organizationId));
        dispatch(getAnalyticsVisibility(organizationId));
        dispatch(getAppSettings(organizationId));
      }
      if (widgetData) {
        dispatch(setWidgetOptions(JSON.parse(widgetData)));
      }
    } catch (error) {}
  }, [organizationId, dispatch]);

  const getMenuItems = () => {
    const currentOrganization = JSON.parse(localStorage.getItem("current"));
    let URL = "1/users/me/menu/visibility";
    if (
      currentOrganization &&
      currentOrganization?.newOrganizationId !==
        currentOrganization?.parentOrganizationId
    ) {
      URL = `1/users/me/menu/visibility?organizationId=${currentOrganization?.newOrganizationId}`;
      API.get(URL).then((response) => {
        const newOrganizationId = currentOrganization.newOrganizationId;
        const newOrganizationName = currentOrganization.newOrganizationName;
        const updatedUserDetails = {
          ...user,
          newOrganizationId: newOrganizationId,
          newOrganizationName: newOrganizationName,
        };
        dispatch(postUserDetails(updatedUserDetails));
        dispatch(setMenuVisibility(response.data));
      });
    } else {
      API.get(URL).then((response) => {
        dispatch(setMenuVisibility(response.data));
      });
    }
  };

  useEffect(() => {
    const currentOrg = localStorage.getItem("current");
    if (currentOrg) {
      const currentUser = JSON.parse(currentOrg);
      dispatch(getOrganizationInfo(currentUser.newOrganizationId));
    } else {
      if (organizationId) dispatch(getOrganizationInfo(organizationId));
    }
  }, [organizationId]);

  useEffect(() => {
    if (accessToken) {
      let prevToken = localStorage.getItem("token");
      if (prevToken !== accessToken) {
        const unique_id = uuid();
        const small_id = unique_id.slice(0, 8);
        sessionStorage.setItem("unique_id", small_id);
      }
      localStorage.setItem("token", accessToken);
      dispatch(getOrganizationId());
      dispatch(getUserDetails());
      getMenuItems();
    }
  }, [accessToken]);

  function RequestAccessToken() {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };
    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setAccessToken(response.accessToken);
      })
      .catch(() => {
        localStorage.clear();
        sessionStorage.clear();
      });
  }

  const environment = process.env.REACT_APP_MY_ENV;
  let envConfig = FeatureFlagData[0][environment];

  const CheckVisibility = () => {
    envConfig.forEach((element) => {
      if (element.name === "userManagementMenu") {
        element.isActive = MenuVisibility?.userManagement;
      }
    });
  };

  useEffect(() => {
    if (MenuVisibility) {
      CheckVisibility();
    }
  }, [MenuVisibility]);

  return (
    <>
      {accessToken ? (
        <>
          <FlagsProvider value={envConfig}>
            <ErrorBoundary fallbackRender={(error) => Fallback(error)}>
              <Home />
            </ErrorBoundary>
          </FlagsProvider>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}
export default App;

function Fallback({ error }) {
  return (
    <ErrorPage
      secondText={`Something went wrong: ${error.message}`}
      isErrorPage={true}
      showRedirectBtn={false}
    />
  );
}
