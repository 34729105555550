import { Grid, Typography, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { updateBreadcrumData } from "../../../redux/actions";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: "14px",
    width: "506px",
    height: "248px",
    margin: "20px 0px 20px 20px",
  },
  cardContent: {
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.color.primary,
    borderRadius: "14px 140px 0px 14px",
  },
  cardText: {
    color: theme.palette.color.dimblack,
    textAlign: "center",
  },
  image: {
    height: "inherit",
    width: "inherit",
    borderRadius: "14px 140px 0px 14px",
  },
}));

const ChildAppCard = () => {
  const classes = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const [cardData, setcardData] = useState([]);
  const { analyticsVisibility } = useSelector((state) => state.Analytics);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!id) {
      navigate("shipment/analytics");
    }
    return () => {
      dispatch(updateBreadcrumData([]));
    };
  }, [id, navigate]);

  useEffect(() => {
    if (analyticsVisibility) {
      const data = analyticsVisibility.find(
        (item) => item.resourceDetails.subParentId.toString() === id
      );
      const breadCrumb = [
        { name: "Analytics Dashboard ", url: "/shipment/analytics" },
        { name: data?.resourceDetails?.displayName },
      ];
      dispatch(updateBreadcrumData(breadCrumb));
      if (data?.childDetails) setcardData(data.childDetails);
    }
  }, [analyticsVisibility, id]);

  return (
    <>
      <Grid container spacing={2.5} mt={1}>
        {cardData.map((card, key) => {
          const { name, displayName, content, subParentId } = card;
          const redirectURL = `/report/${subParentId}/${name}`;
          return (
            <>
              <Paper
                elevation={3}
                key={key}
                className={classes.card}
                onClick={() => navigate(redirectURL)}
                data-testid={`${name}${key}`}
              >
                <Grid xs={6} className={classes.cardContent}>
                  <img
                    src={content}
                    alt={displayName}
                    className={classes.image}
                  />
                </Grid>
                <Grid xs={6} p={1}>
                  <Typography className={classes.cardText} variant="h4">
                    {displayName.replace("Analytics - ", "")}
                  </Typography>
                </Grid>
              </Paper>
            </>
          );
        })}
      </Grid>
    </>
  );
};

export default ChildAppCard;
