import {
  GET_CLIENT_DETAIL_FAILURE,
  GET_CLIENT_DETAIL_SUCCESS,
  SET_CLIENT_DETAIL_LOADING,
} from "./actionTypes";

const initialState = {
  clientListDetails: [],
  loading: false,
  error: false,
  errorMessage: "",
};

const AutoRate = (state = initialState, action) => {
  switch (action.type) {
    case SET_CLIENT_DETAIL_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_CLIENT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        clientListDetails: action.payload,
      };
    case GET_CLIENT_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.errorMessage,
      };
    default:
      return state;
  }
};

export default AutoRate;
