import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import POTabPanel from "./POTabPanel";
import { updateBreadcrumData } from "../../redux/actions";

const useStyles = makeStyles((theme) => ({
  container: {
    "& .MuiDialog-paper": {
      padding: "32px",
      backgroundColor: theme.palette.color.darkGrey,
      boxShadow: "0px 3px 6px #0000000d",
      borderRadius: "6px",
    },
  },
  tabsBox: {},
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#1F364E",
      height: "1px",
    },
  },
  tab: {
    color: theme.palette.color.greyS3,
    fontWeight: "600",
    fontSize: "14px",
    letterSpacing: "0.01px",
    padding: "8px 20px",
    marginBottom: "-60px",
    "&.Mui-selected ": {
      color: theme.palette.color.primary,
      fontSize: "14px",
    },
  },
  tabPanel: {
    "& .table-container": {
      backgroundColor: theme.palette.color.greyS1,
    },
  },
  tabPanelBox: {
    margin: "24px 0 0 0",
  },
}));

function POTabs({ tabId }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (tabId === 1) {
      const breadCrumb = [
        { name: "PO Management", url: "/poManagement" },
        { name: "History" },
      ];
      dispatch(updateBreadcrumData(breadCrumb));
    } else {
      const breadCrumb = [{ name: "PO Management" }];
      dispatch(updateBreadcrumData(breadCrumb));
    }
    return () => {
      dispatch(updateBreadcrumData([]));
    };
  }, [tabId]);

  const handleChange = (event, newValue) => {
    if (newValue === 1) {
      navigate("/poManagement/history");
    } else {
      navigate("/poManagement");
    }
  };

  return (
    <Box sx={{ width: "100%" }} key={tabId}>
      <Box className={classes.tabsBox}>
        <Tabs
          value={tabId}
          onChange={handleChange}
          aria-label="POTabs"
          className={classes.tabs}
        >
          <Tab
            label="PO MANAGEMENT"
            id="POTabs0"
            aria-controls="POTabs0"
            className={classes.tab}
          />
          <Tab
            label="HISTORY"
            id="POTabs1"
            aria-controls="POTabs1"
            className={classes.tab}
          />
        </Tabs>
      </Box>
      <Box className={classes.tabPanelBox}>
        {tabId === 0 && (
          <POTabPanel value={tabId} index={0} className={classes.tabPanel} />
        )}
        {tabId === 1 && (
          <POTabPanel value={tabId} index={1} className={classes.tabPanel} />
        )}
      </Box>
    </Box>
  );
}

export default POTabs;
