import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import blackClose from "../assets/images/black-close.png";
import infoIcon from "../assets/images/info_icon.png";
import errorIcon from "../assets/images/error_icon.png";
import warningIcon from "../assets/images/warning_icon.png";
import successClose from "../assets/images/success_icon.png";

const useStyle = makeStyles((theme) => ({
  startProcessingButton: {
    width: "100px",
    height: "38px",
    backgroundColor: `${theme.palette.color.primary} !important`,
    color: `${theme.palette.color.white} !important`,
    font: "normal normal 600 14px/24px",
    letterSpacing: "0px",
    border: "0.5px solid #CFD4D9",
    boxShadow: "0px 2px 7px #00000029",
    borderRadius: "20px",
    textTransform: "capitalize",
    opacity: 1,
    "&:hover": {
      backgroundColor: "rgba(25, 118, 210, 0.04) !important",
      color: "#183650 !important",
      boxShadow: "none",
      border: "1px solid #34CCFF1A",
      opacity: 1,
    },
  },
  actionButton: {
    width: "60px",
    height: "34px",
    background: `${theme.palette.color.primary} 0% 0% no-repeat padding-box`,
    boxShadow: "0px 2px 7px #00000029",
    borderRadius: "20px",
    textAlign: "left",
    letterSpacing: "0px",
    color: theme.palette.color.white,
  },
  dialogActions: {
    padding: "8px 24px 16px 0px",
  },
  dialogTitle: {
    padding: "16px 0px 16px 12px",
    fontSize: "18px",
    fontWeight: "bold",
  },
  dialogIcons: {
    width: "24px",
    height: "24px",
  },
  dialogContent: {
    ...theme.typography.body22,
  },
  cancelButton: {
    width: "97px",
    height: "38px",
    borderRadius: "12px",
    letterSpacing: "0px",
    color: theme.palette.color.primary,
    textTransform: "capitalize",
  },
  parentDialog: {
    "& div.MuiPaper-root[role='dialog']": {
      width: "560px",
      minHeight: "190px",
      background: `${theme.palette.color.white} 0% 0% no-repeat padding-box`,
      boxShadow: "0px 3px 6px #0000000D",
      borderRadius: "6px",
    },
  },
  titleAlignment: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 24px",
  },
  errorClass: {
    color: theme.palette.color.red,
  },
  successClass: {
    color: theme.palette.color.success,
  },
  infoClass: {
    color: theme.palette.color.info,
  },
  warningClass: {
    color: theme.palette.color.warning,
  },
}));

const FrachtGeneralDialogBox = ({
  type,
  open = false,
  handleClose,
  modalContent = "",
  cancelButtonText = "",
  actionButtonText = "OK",
  performPositiveAction,
  performNegativeAction,
  ...rest
}) => {
  const classes = useStyle();

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="general-dialog-title"
        aria-describedby="general-dialog-description"
        className={classes.parentDialog}
      >
        <Stack
          className={`${classes.titleAlignment} ${(() => {
            switch (type.toLowerCase()) {
              case "error":
                return classes.errorClass;
              case "success":
                return classes.successClass;
              case "info":
                return classes.infoClass;
              case "warning":
                return classes.warningClass;
              default:
                return null;
            }
          })()}`}
        >
          <Box style={{ display: "flex", alignItems: "center" }}>
            <img
              src={(() => {
                switch (type.toLowerCase()) {
                  case "error":
                    return errorIcon;
                  case "success":
                    return successClose;
                  case "info":
                    return infoIcon;
                  case "warning":
                    return warningIcon;
                  default:
                    return null;
                }
              })()}
              alt="modalIcon"
              className={classes.dialogIcons}
            />
            <DialogTitle
              id="general-dialog-title"
              className={classes.dialogTitle}
            >
              {type}
            </DialogTitle>
          </Box>
          <span style={{ cursor: "pointer" }} onClick={handleClose}>
            <img src={blackClose} alt="icon" />
          </span>
        </Stack>
        <DialogContent>
          {Boolean(modalContent) && (
            <DialogContentText
              className={classes.dialogContent}
              id="general-dialog-description"
            >
              {modalContent}
            </DialogContentText>
          )}
          {!Boolean(modalContent) && <>{rest.children}</>}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          {Boolean(cancelButtonText) && (
            <Button
              onClick={performNegativeAction || handleClose}
              className={classes.cancelButton}
            >
              {cancelButtonText}
            </Button>
          )}
          <Button
            onClick={performPositiveAction || handleClose}
            autoFocus
            className={classes.startProcessingButton}
          >
            {actionButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FrachtGeneralDialogBox;
