import React, { useState, useEffect } from "react";
import moment from "moment";
import store from "../../redux";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import { PublicClientApplication } from "@azure/msal-browser";
import { loginRequest, msalConfig } from "../../authConfig";
import createActivityDetector from "activity-detector";
import privateRoutes from "../../routes/routes";
import publicRoutes from "../../routes/publicRoutes";
import Layout from "../../components/Layout/Layout";
import { getOrganizationName } from "../../redux/widgets/action";
import FrachtSuspense from "../../commonControls/FrachtSuspense";
import ErrorPage from "../../errorPage/ErrorPage";
//import { HubConnectionBuilder } from "@microsoft/signalr";
import { createSignalRContext } from "react-signalr/signalr";
let refresh_toke_timeout = null;
const HomePage = () => {
  const SignalRContext = createSignalRContext();
  const dispatch = useDispatch();
  const { user, parentOrganizationId, organizationId } = useSelector(
    (state) => state.LoginUser
  );
  const visibilityData = useSelector((state) => state.MenuVisibility);

  const [defaultActiveDateTime, setdefaultActiveDateTime] = useState(moment());
  const [privateRoutesEnabled, setPrivateRoutesEnabled] = useState([]);

  const msalInstance = new PublicClientApplication(msalConfig);
  useEffect(() => {
    setTokenInterval();
    addDynamicScripts();
    appendScriptToHead();
    executeGoogleAnalyticsScript();
    return () => {
      clearInterval(refresh_toke_timeout);
    };
  }, []);

  const addDynamicScripts = () => {
    var newScript = document.createElement("script");
    newScript.type = "text/javascript";
    newScript.setAttribute("async", "true");
    newScript.setAttribute(
      "src",
      `https://api.mapbox.com/mapbox-gl-js/v2.14.1/mapbox-gl.js`
    );
    document.documentElement.firstChild.appendChild(newScript);

    var fileref = document.createElement("link");
    fileref.rel = "stylesheet";
    fileref.type = "text/css";
    fileref.href = "https://api.mapbox.com/mapbox-gl-js/v2.14.1/mapbox-gl.css";
    document.getElementsByTagName("head")[0].appendChild(fileref);
  };

  // useEffect(() => {
  //   const connection = new HubConnectionBuilder()
  //     .withUrl("https://localhost:44394/offers")
  //     .build();

  //   connection.start();
  //   connection.on("SendOffersToUser", (data) => {
  //     console.log("SIGNALR", data);
  //   });
  // }, []);

  // useEffect(() => {
  //   if (organizationId) {
  //     appendScriptToHead();
  //     setTimeout(() => {
  //       executeGoogleAnalyticsScript();
  //     }, 500);
  //   }
  // }, [organizationId]);

  function appendScriptToHead() {
    var newScript = document.createElement("script");
    newScript.type = "text/javascript";
    newScript.setAttribute("async", "true");
    newScript.setAttribute(
      "src",
      `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS_KEY}`
    );
    document.documentElement.firstChild.appendChild(newScript);
  }

  function executeGoogleAnalyticsScript() {
    window["dataLayer"] = window["dataLayer"] || [];
    function gtag() {
      window["dataLayer"].push(arguments);
    }
    gtag("js", new Date());
    gtag("config", process.env.REACT_APP_GOOGLE_ANALYTICS_KEY, {
      user_id: organizationId,
      ad_user_data: "granted",
    });
  }

  useEffect(() => {
    const activityDetector = createActivityDetector({
      timeToIdle: process.env.REACT_APP_USER_INACTIVITY_INTERVAL,
      autoInit: false,
    });
    activityDetector.on("idle", handleUserIdle);
    activityDetector.on("active", handleUserActive);
    activityDetector.init();
    return () => {
      activityDetector.stop();
    };
  });

  const handleUserIdle = () => {
    //clearInterval(refresh_toke_timeout);
  };

  const handleUserActive = () => {
    const currentActiveDateTime = moment();
    const duration = currentActiveDateTime.diff(
      defaultActiveDateTime,
      "minutes"
    );
    // console.log("duration", duration);
    if (duration > 45) {
      refreshToken();
      setTokenInterval();
    }
  };

  const setTokenInterval = () => {
    refresh_toke_timeout = setInterval(() => {
      refreshToken();
    }, Number(process.env.REACT_APP_TOKEN_REFRESH_INTERVAL));
  };

  const refreshToken = async () => {
    try {
      console.log("Getting token refresh");
      const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: [],
      });
      if (response) {
        console.log(" token refreshed");
        let accessToken = response.accessToken;
        localStorage.setItem("token", accessToken);
        setdefaultActiveDateTime(moment());
      }
    } catch (error) {
      console.log("token update error", error);
    }
  };

  const isRouteEnabled = (apiField) => visibilityData[apiField];

  useEffect(() => {
    const enabledRoutes = privateRoutes.filter((route) => {
      if (
        route.apiField === "dashboard" &&
        route.path === "/" &&
        user === undefined
      )
        return true;
      return isRouteEnabled(route.apiField);
    });
    setPrivateRoutesEnabled(enabledRoutes);
    dispatch(getOrganizationName(parentOrganizationId));
  }, [visibilityData, user]);
  const hubURl = process.env.REACT_APP_SIGNALR_HUB;
  const renderPage = () => {
    return (
      <BrowserRouter>
        <Provider store={store}>
          <Layout>
            <SignalRContext.Provider
              connectEnabled={!!organizationId}
              accessTokenFactory={() => {}}
              dependencies={[organizationId]} //remove previous connection and create a new connection if changed
              url={hubURl}
            >
              <Routes>
                {privateRoutesEnabled.map((route, key) => {
                  return renderRoutes(route, key);
                })}
                {publicRoutes.map((route, key) => (
                  <Route
                    path={route.path}
                    exact={route.exact}
                    element={<route.component />}
                    key={key}
                  />
                ))}
              </Routes>
            </SignalRContext.Provider>
          </Layout>
        </Provider>
      </BrowserRouter>
    );

    function renderRoutes(route, key) {
      if (user === undefined) {
        return <Route path="*" exact={true} element={<ErrorPage />} />;
      } else if (visibilityData[route.apiField]) {
        return (
          <Route
            path={route.path}
            exact={route.exact}
            element={
              <FrachtSuspense>
                <route.component
                  tabId={route.tabId}
                  SignalRContext={SignalRContext}
                />
              </FrachtSuspense>
            }
            key={key}
          />
        );
      } else {
        return (
          <Route
            path={route.path}
            exact={route.exact}
            key={key}
            element={<Navigate to="/" replace />}
          />
        );
      }
    }
  };

  return <>{renderPage()}</>;
};

export default HomePage;
