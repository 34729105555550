import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  filledRoot: {
    height: "38px",
    backgroundColor: theme.palette.color.white,
    boxShadow: "2px 2px 6px #0000000D",
    borderRadius: "6px",
    top: "5px",
    opacity: 1,
    "&:hover": {
      background: "#34CCFF1A 0% 0% no-repeat padding-box",
      border: "1px solid #34CCFF1A",
    },
  },
  errorFilledRoot: {
    height: "38px",
    backgroundColor: theme.palette.color.white,
    border: "1px solid #CC3629",
    boxShadow: "2px 2px 6px #0000000D",
    borderRadius: "6px",
    top: "5px",
    opacity: 1,
    "&:hover": {
      backgroundColor: theme.palette.color.white,
    },
  },
  filledInput: {
    color: `${theme.palette.color.cyanBlueC1}`,
    ...theme.typography.body2,
  },
  underline: {
    "&:after": {
      borderBottom: "none !important",
    },
  },
  filledFocused: {
    border: `1px solid ${theme.palette.color.primary} !important`,
    borderRadius: "3px !important",
    boxShadow: "0px 3px 6px #18365052",
  },
  error: {
    border: `1px solid #CC3629 !important`,
    borderRadius: "3px !important",
    boxShadow: "0px 3px 6px #18365052",
  },
  labelRoot: {
    ...theme.typography.body2,
    opacity: 1,
    color: "#9E9E9E",
    "&.MuiInputLabel-shrink": {
      ...theme.typography.small,
      fontWeight: "normal",
      marginTop: "4px",
    },
    "& .MuiFormLabel-asterisk": {
      color: "#CC3629",
    },
    "&:hover": {
      color: "#9E9E9E",
      letterSpacing: "0px",
    },
  },
  errorlabelRoot: {
    ...theme.typography.body2,
    opacity: 1,
    color: "#CC3629",
    // marginTop: "-3px",
    "&.MuiInputLabel-shrink": {
      ...theme.typography.small,
      fontWeight: "normal",
      marginTop: "4px",
    },
    "& .MuiFormLabel-asterisk": {
      color: "#CC3629 !important",
    },
    "&:hover": {
      color: "#CC3629 !important",
      letterSpacing: "0px",
    },
  },
  labelFocused: {
    ...theme.typography.small,
    color: "#9E9E9E !important",
    opacity: 1,
    marginTop: "0.1px",
  },
  labelFocusedError: {
    ...theme.typography.small,
    color: "#CC3629 !important",
  },
  helperTextRoot: {
    color: "#CC3629",
    ...theme.typography.small,
  },
}));

const FracthTextField = ({ error, ...rest }) => {
  const classes = useStyles();
  return (
    <TextField
      {...rest}
      variant="filled"
      fullWidth
      size="small"
      autoComplete="off"
      InputProps={{
        ...rest?.InputProps,
        classes: {
          root: error ? classes.errorFilledRoot : classes.filledRoot,
          input: classes.filledInput,
          focused: error ? classes.error : classes.filledFocused,
          underline: classes.underline,
        },
        disableUnderline: true,
      }}
      FormHelperTextProps={{
        classes: {
          root: classes.helperTextRoot,
        },
      }}
      InputLabelProps={{
        classes: {
          root: error ? classes.errorlabelRoot : classes.labelRoot,
          focused: error ? classes.labelFocusedError : classes.labelFocused,
        },
      }}
      helperText={error}
    />
  );
};

export default FracthTextField;
