import {
  SET_MILLERCOORS_LOADING,
  GET_CARRIER_LIST_FAILURE,
  GET_CARRIER_LIST_SUCCESS,
  GET_CUSTOMER_LIST_FAILURE,
  GET_CUSTOMER_LIST_SUCCESS,
  GET_DESTINATION_LIST_FAILURE,
  GET_DESTINATION_LIST_SUCCESS,
  GET_DOCUMENT_TYPE_FAILURE,
  GET_DOCUMENT_TYPE_SUCCESS,
  GET_LOADED_LOCATION_FAILURE,
  GET_LOADED_LOCATION_SUCCESS,
  GET_POL_LIST_FAILURE,
  GET_POL_LIST_SUCCESS,
  GET_TRUCKER_LIST_FAILURE,
  GET_TRUCKER_LIST_SUCCESS,
  GET_DELIVERY_LOCATION_LIST_SUCCESS,
  GET_DELIVERY_LOCATION_LIST_FAILURE,
  SHOW_EMPTY_CONTAINER,
  SHOW_AVAILABLE_CAPACITY,
  SET_BUTTON_CLICKED,
  GET_IMPORTPREPULL_LIST_FAILURE,
  GET_IMPORTPREPULL_LIST_SUCCESS,
  GET_POLUNLOC_LIST_SUCCESS,
  GET_POLUNLOC_LIST_FAILURE,
  SAVE_NOTE_DATA_SUCCESS,
  SAVE_NOTE_DATA_FAILURE,
} from "./actionTypes";

const initialState = {
  documentTypeDetails: [],
  customerIdList: [],
  truckerList: [],
  importPrePullList: [],
  carrierList: [],
  POLList: [],
  destinationList: [],
  loadedLocationList: [],
  deliveryLocationList: [],
  POLUNLOCList: [],
  notesData: {
    id: "",
    notes: "",
    updatedBy: "",
  },
  molsonCoorsloading: false,
  error: false,
  errorMessage: "",
  showAvailableCapacity: false,
  showEmptyContainer: false,
  clicked: false,
};

const MillerCoors = (state = initialState, action) => {
  switch (action.type) {
    case SET_MILLERCOORS_LOADING:
      return {
        ...state,
        molsonCoorsloading: true,
      };

    case GET_DOCUMENT_TYPE_SUCCESS:
      return {
        ...state,
        molsonCoorsloading: false,
        documentTypeDetails: action.payload,
      };
    case GET_DOCUMENT_TYPE_FAILURE:
      return {
        ...state,
        molsonCoorsloading: false,
        error: true,
        errorMessage: "Unable to get document type list",
      };

    case GET_CUSTOMER_LIST_SUCCESS:
      return {
        ...state,
        molsonCoorsloading: false,
        customerIdList: action.payload,
      };
    case GET_CUSTOMER_LIST_FAILURE:
      return {
        ...state,
        molsonCoorsloading: false,
        error: true,
        errorMessage: "Unable to get customer list",
      };

    case GET_TRUCKER_LIST_SUCCESS:
      return {
        ...state,
        molsonCoorsloading: false,
        truckerList: action.payload,
      };
    case GET_TRUCKER_LIST_FAILURE:
      return {
        ...state,
        molsonCoorsloading: false,
        error: true,
        errorMessage: "Unable to get trucker list",
      };

    case GET_IMPORTPREPULL_LIST_SUCCESS:
      return {
        ...state,
        molsonCoorsloading: false,
        importPrePullList: action.payload,
      };
    case GET_IMPORTPREPULL_LIST_FAILURE:
      return {
        ...state,
        importPrePullList: [],
        molsonCoorsloading: false,
        error: true,
        errorMessage: action.payload.errorMessage,
      };

    case GET_CARRIER_LIST_SUCCESS:
      return {
        ...state,
        molsonCoorsloading: false,
        carrierList: action.payload,
      };
    case GET_CARRIER_LIST_FAILURE:
      return {
        ...state,
        molsonCoorsloading: false,
        error: true,
        errorMessage: "Unable to get carrier list",
      };

    case GET_POL_LIST_SUCCESS:
      return {
        ...state,
        molsonCoorsloading: false,
        POLList: action.payload,
      };
    case GET_POL_LIST_FAILURE:
      return {
        ...state,
        molsonCoorsloading: false,
        error: true,
        errorMessage: "Unable to get POL list",
      };

    case GET_DESTINATION_LIST_SUCCESS:
      return {
        ...state,
        molsonCoorsloading: false,
        destinationList: action.payload,
      };
    case GET_DESTINATION_LIST_FAILURE:
      return {
        ...state,
        molsonCoorsloading: false,
        error: true,
        errorMessage: "Unable to get destination list",
      };
    case GET_LOADED_LOCATION_SUCCESS:
      return {
        ...state,
        molsonCoorsloading: false,
        loadedLocationList: action.payload,
      };
    case GET_LOADED_LOCATION_FAILURE:
      return {
        ...state,
        molsonCoorsloading: false,
        error: true,
        errorMessage: action.payload.errorMessage,
      };

    case GET_DELIVERY_LOCATION_LIST_SUCCESS:
      return {
        ...state,
        molsonCoorsloading: false,
        deliveryLocationList: action.payload,
      };
    case GET_DELIVERY_LOCATION_LIST_FAILURE:
      return {
        ...state,
        molsonCoorsloading: false,
        error: true,
        errorMessage: "Unable to get delivery Location list",
      };

    case SHOW_EMPTY_CONTAINER:
      return {
        ...state,
        showEmptyContainer: action.show,
      };

    case SHOW_AVAILABLE_CAPACITY:
      return {
        ...state,
        showAvailableCapacity: action.show,
      };
    case SET_BUTTON_CLICKED:
      return {
        ...state,
        clicked: action.clicked,
      };

    case GET_POLUNLOC_LIST_SUCCESS:
      return {
        ...state,
        molsonCoorsloading: false,
        POLUNLOCList: action.payload,
      };

    case GET_POLUNLOC_LIST_FAILURE:
      return {
        ...state,
        molsonCoorsloading: false,
        error: true,
        errorMessage: "Unable to get POL UNLOC list",
      };

    case SAVE_NOTE_DATA_SUCCESS:
      return {
        ...state,
        molsonCoorsloading: false,
        notesData: action.payload,
      };
    case SAVE_NOTE_DATA_FAILURE:
      return {
        ...state,
        molsonCoorsloading: false,
        error: true,
        errorMessage: "Unable to save note data",
      };

    default:
      return state;
  }
};

export default MillerCoors;
