const myriad = "'myriad-pro', sans-serif";
const montserrat = "'Montserrat', sans-serif";
// font-family: "myriad-pro", sans-serif;
// font-weight: 700;
// font-style: normal;

const theme = {
  palette: {
    color: {
      primary: "#183650",
      secondary: "#FAFAFA33",
      success: "#06AC47",
      info: "#001089",
      warning: "#E6A54C",
      white: "#FFFFFF",
      blue: "#3f51b5",
      blueS2: "#34CCFF",
      greyS1: "#F5F5F5",
      greyS2: "#EEEEEE",
      greyS3: "#9E9E9E",
      greyS4: "#2E2E2E",
      grayS5: "#F7F7F7",
      grayS6: "#E6E6E6",
      grayS7: "#B9B9B9",
      grayS8: "#E0E0E0",
      darkGrey: "#212121",
      cyanBlueC1: "#212529",
      cyanBlueC2: "#34CCFF0D",
      slateGray: "#8D98A5",
      lightGrayishBlueB1: "#CFD4D9",
      lightGrayishBlueB2: "#DEE2E6",
      lightGrayishBlueB3: "#6C757D",
      lightGrayishBlueB4: "#CED4DA80",
      appleGreen: "#06AC47",
      green: "#28a745",
      red: "#dc3545",
      black: "#000000",
      orange: "#FF9E1B",
      dimblack: "#3F4443",
      error: "#CC3629",
      skyBlue: "#007DBA",
      skyBlue1: "#d3e7fb",
      honeyDew: "#F0FFF0",
      grayShadow1: "#0000000d",
      grayShadow2: "#0000001A",
      grayBorder1: "#CED4DA",
      grayBorder2: "#34CCFF1A",
    },
  },
  typography: {
    fontFamily: montserrat,
    fontTitillium: {
      fontFamily: myriad,
    },
    h1: {
      fontSize: "42px",
      fontWeight: "bold",
      lineHeight: "50px",
      fontStyle: "normal",
    },
    h2: {
      fontSize: "36px",
      lineHeight: "43px",
      fontWeight: "bold",
      fontStyle: "normal",
    },
    h32: {
      fontSize: "32px",
      lineHeight: "43px",
      fontWeight: "bold",
      fontStyle: "normal",
    },
    h2A: {
      fontSize: "30px",
      lineHeight: "43px",
      fontWeight: "bold",
      fontStyle: "normal",
    },
    h3: {
      fontSize: "24px",
      fontWeight: "bold",
      lineHeight: "32px",
      fontStyle: "normal",
    },
    h20: {
      fontSize: "20px",
      fontWeight: "bold",
      lineHeight: "32px",
      fontStyle: "normal",
    },
    h4: {
      // card header
      fontSize: "18px",
      fontWeight: "bold",
      lineHeight: "24px",
      fontStyle: "normal",
      color: "#183650",
    },
    h5: {
      fontSize: "16px",
      fontWeight: "bold",
      lineHeight: "24px",
      fontStyle: "normal",
    },
    h6: {
      // cancel button
      fontSize: "14px",
      fontWeight: "bold",
      lineHeight: "24px",
      fontStyle: "normal",
    },
    body1: {
      // nav/ body large
      fontSize: "18px",
      lineHeight: "30px",
      fontWeight: "bold",
      fontFamily: myriad,
      fontStyle: "normal",
    },
    body11: {
      fontSize: "18px",
      lineHeight: "30px",
      fontWeight: "normal",
      fontFamily: myriad,
      fontStyle: "normal",
    },
    body2: {
      // Subheading/ Button/ Body medium
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: "normal",
      fontFamily: myriad,
      fontStyle: "normal",
    },
    body22: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: "bold",
      fontFamily: myriad,
      fontStyle: "normal",
    },
    body3: {
      // Button/Body small
      fontSize: "14px",
      lineHeight: "24px",
      fontWeight: "normal",
      fontFamily: myriad,
      fontStyle: "normal",
    },
    body4: {
      // Button/Body small
      fontSize: "14px",
      lineHeight: "24px",
      fontWeight: "600",
      fontFamily: myriad,
      fontStyle: "normal",
    },
    small: {
      fontSize: "12px",
      lineHeight: "24px",
      fontWeight: 500,
      fontFamily: myriad,
      fontStyle: "normal",
    },
    small2: {
      fontSize: "12px",
      lineHeight: "24px",
      fontWeight: 600,
      fontStyle: "normal",
    },
    small3: {
      fontSize: "10px",
      lineHeight: "24px",
      fontWeight: 600,
      fontStyle: "normal",
    },
  },
};

export default theme;
