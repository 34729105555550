import React from "react";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const CustomSwitch = styled(Switch)(({ theme, switchColor }) => ({
  "& .MuiSwitch-thumb": {
    color: switchColor, // Color for the thumb
  },
  "& .MuiSwitch-track": {
    backgroundColor: `${switchColor} !important`, // Color for the track
  },
  "&.Mui-checked .MuiSwitch-thumb": {
    color: switchColor, // Color for the thumb when checked
  },
  "&.Mui-checked .MuiSwitch-track": {
    backgroundColor: switchColor, // Color for the track when checked
  },
}));

const SwitchWithLabel = ({
  switchColor = "green",
  label1 = "",
  label2 = "",
  defaultChecked = false,
  value,
  onChange,
}) => {
  return (
    <Box display="flex" alignItems="center">
      <Typography
        style={{
          fontFamily: "Montserrat,sans-serif",
          color: "#183650",
          lineHeight: "24px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        {label1}
      </Typography>
      <CustomSwitch
        inputProps={{ "aria-label": "switch" }}
        switchColor={switchColor}
        defaultChecked={defaultChecked}
        value={value}
        onChange={onChange}
      />
      <Typography
        style={{
          fontFamily: "Montserrat,sans-serif",
          color: "#183650",
          lineHeight: "24px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        {label2}
      </Typography>
    </Box>
  );
};

export default SwitchWithLabel;
