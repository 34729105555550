import saveAs from "file-saver";
const fileHandler = (data, type, fileName = "file.xlsx") => {
  const blob = converBase64toBlob(data, type);
  const blobURL = URL.createObjectURL(blob);
  saveAs(blobURL, fileName);
};

function converBase64toBlob(content, contentType) {
  contentType = contentType || "";
  let sliceSize = 512;
  let byteCharacters = window.atob(content); //method which converts base64 to binary
  let byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize);
    let byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  let blob = new Blob(byteArrays, {
    type: contentType,
  }); //statement which creates the blob
  return blob;
}

export default fileHandler;
