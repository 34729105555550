import { takeEvery, put, all } from "redux-saga/effects";
import API from "../../utils/axios";
import {
  GET_COMPONENT_MODEL,
  GET_COMPONENT_MODEL_FAILURE,
  GET_COMPONENT_MODEL_SUCCESS,
  GET_COMPONENT_TYPE,
  GET_COMPONENT_TYPE_FAILURE,
  GET_COMPONENT_TYPE_SUCCESS,
  GET_LOCATION_LIST,
  GET_LOCATION_LIST_FAILURE,
  GET_LOCATION_LIST_SUCCESS,
  GET_MCRS_DETAIL,
  GET_MCRS_DETAIL_FAILURE,
  GET_MCRS_DETAIL_SUCCESS,
  GET_PROJECT_LOGISTICS_LIST,
  GET_PROJECT_LOGISTICS_LIST_FAILURE,
  GET_PROJECT_LOGISTICS_LIST_SUCCESS,
  GET_STATUS_LIST,
  GET_STATUS_LIST_FAILURE,
  GET_STATUS_LIST_SUCCESS,
  RESET_MCRS_DETAIL,
  RESET_MCRS_DETAIL_FAILURE,
  RESET_MCRS_DETAIL_SUCCESS,
  SET_LOADING,
} from "./actionTypes";

function* getLocationListSaga() {
  try {
    yield put({ type: SET_LOADING });
    //api/1/rail-car-scheduler-utility/origin-destination
    const { data } = yield API.get(
      `/1/rail-car-scheduler-utility/origin-destination`
    );
    yield put({
      type: GET_LOCATION_LIST_SUCCESS,
      payload: data.value,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_LOCATION_LIST_FAILURE,
      payload: { errorMessage: "Unable to get location list" },
    });
  }
}

function* getStatusListSaga() {
  try {
    yield put({ type: SET_LOADING });
    // api/1/rail-car-scheduler-utility/status
    const { data } = yield API.get(`/1/rail-car-scheduler-utility/status`);
    yield put({ type: GET_STATUS_LIST_SUCCESS, payload: data.value });
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_STATUS_LIST_FAILURE,
      payload: { errorMessage: "Unable to get status list" },
    });
  }
}

function* getComponentTypeSaga() {
  try {
    yield put({ type: SET_LOADING });
    // api/1/rail-car-scheduler-utility/component-type
    const { data } = yield API.get(
      `/1/rail-car-scheduler-utility/component-type`
    );
    yield put({ type: GET_COMPONENT_TYPE_SUCCESS, payload: data.value });
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_COMPONENT_TYPE_FAILURE,
      payload: { errorMessage: "Unable to get component type list" },
    });
  }
}

function* getComponentModelSaga() {
  try {
    yield put({ type: SET_LOADING });
    // api/1/rail-car-scheduler-utility/model
    const { data } = yield API.get(`/1/rail-car-scheduler-utility/model`);
    yield put({ type: GET_COMPONENT_MODEL_SUCCESS, payload: data.value });
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_COMPONENT_MODEL_FAILURE,
      payload: { errorMessage: "Unable to get component model list" },
    });
  }
}

function* getProjectLogisticsSaga() {
  try {
    yield put({ type: SET_LOADING });
    // 1/rail-car-scheduler-utility/project-logistics-co-ordinator
    const { data } = yield API.get(
      `/1/rail-car-scheduler-utility/project-logistics-co-ordinator`
    );
    yield put({
      type: GET_PROJECT_LOGISTICS_LIST_SUCCESS,
      payload: data.value,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_PROJECT_LOGISTICS_LIST_FAILURE,
      payload: { errorMessage: "Unable to get Project Logistics list" },
    });
  }
}

// api/1/rail-car-scheduler/f4081efc-2c00-49b8-95eb-e6f773c1ef64/details
function* getMCRSDetailSaga({ payload }) {
  try {
    yield put({ type: SET_LOADING });
    const { data } = yield API.get(`/1/rail-car-scheduler/${payload}/details`);
    yield put({ type: GET_MCRS_DETAIL_SUCCESS, payload: data.value });
  } catch (error) {
    console.log("error", error);
    yield put({
      type: GET_MCRS_DETAIL_FAILURE,
      errorMessage: "Unable to get Rail Car Scheduler Detail",
    });
  }
}

function* resetMCRSDetailSaga() {
  try {
    yield put({ type: SET_LOADING });
    yield put({ type: RESET_MCRS_DETAIL_SUCCESS });
  } catch (error) {
    console.log("error", error);
    yield put({
      type: RESET_MCRS_DETAIL_FAILURE,
      errorMessage: "Unable to reset Rail Car Scheduler Detail",
    });
  }
}

function* RailCarSchedulerSaga() {
  yield all([yield takeEvery(GET_LOCATION_LIST, getLocationListSaga)]);
  yield all([yield takeEvery(GET_STATUS_LIST, getStatusListSaga)]);
  yield all([yield takeEvery(GET_COMPONENT_TYPE, getComponentTypeSaga)]);
  yield all([yield takeEvery(GET_COMPONENT_MODEL, getComponentModelSaga)]);
  yield all([
    yield takeEvery(GET_PROJECT_LOGISTICS_LIST, getProjectLogisticsSaga),
  ]);
  yield all([yield takeEvery(GET_MCRS_DETAIL, getMCRSDetailSaga)]);
  yield all([yield takeEvery(RESET_MCRS_DETAIL, resetMCRSDetailSaga)]);
}

export default RailCarSchedulerSaga;
