export const BOOKEDROUTE = "/assignContainer";
export const LOADINGROUTE = "/assignContainer";
export const READYTOPICKUPROUTE = "/editDetails";
export const DISPATCHEDROUTE = "";
export const REJECTEDROUTE = "/editDetails";
export const ACCEPTEDROUTE = "/editDetails";
export const PICKEDUPROUTE = "/editDetails";
export const STAGEDROUTE = "/editDetails";
export const INGATEROUTE = "/editDetails";
export const COBROUTE = "/editDetails";
export const DEPARTEDDATE = "/editDetails";
export const CANCELLED = "";

export const statusEnum = {
  booked: 0,
  loading: 1,
  readytopickup: 2,
  dispatched: 3,
  accepted: 4,
  rejected: 5,
  pickedup: 6,
  staged: 7,
  ingate: 8,
  cancelled: 10,
  departed: 11,
  arrived: 12,
};
// flag 1 for assign page, 2 for edir page, 0= no navigation
export const statusObject = [
  {
    flag: 1,
    color: "#DAA520 !important", // Goldenrod
    link: BOOKEDROUTE,
    status: "Booked",
    code: statusEnum["booked"],
    toolTipText: "Assign Container",
    cursorType: "pointer",
  },
  {
    flag: 1,
    color: "#4682B4 !important", // Steel Blue
    link: LOADINGROUTE,
    status: "Loading",
    code: statusEnum["loading"],
    toolTipText: "Add Loaded Date",
    cursorType: "pointer",
  },
  {
    flag: 2,
    color: "#FFA500 !important", // orange
    link: READYTOPICKUPROUTE,
    status: "Ready to Pickup",
    code: statusEnum["readytopickup"],
    toolTipText: "Assign Trucker",
    cursorType: "pointer",
  },
  {
    flag: 0,
    color: "#FFFF00 !important", // Yellow
    link: DISPATCHEDROUTE,
    status: "Dispatched",
    code: statusEnum["dispatched"],
    toolTipText: "",
    cursorType: "default",
  },
  {
    flag: 2,
    color: "#00FFFF !important", // Aqua
    link: ACCEPTEDROUTE,
    status: "Accepted",
    code: statusEnum["accepted"],
    toolTipText: "Add Picked-up date",
    cursorType: "pointer",
  },
  {
    flag: 2,
    color: "#FF0000 !important", // red
    link: REJECTEDROUTE,
    status: "Rejected",
    code: statusEnum["rejected"],
    toolTipText: "",
    cursorType: "pointer",
  },
  {
    flag: 2,
    color: "#FFB6C1 !important", // light pink
    link: PICKEDUPROUTE,
    status: "Picked Up",
    code: statusEnum["pickedup"],
    toolTipText: "Add Terminal Details",
    cursorType: "pointer",
  },
  {
    flag: 2,
    color: "#FF1493 !important", // DeepPink
    link: STAGEDROUTE,
    status: "Staged",
    code: statusEnum["staged"],
    toolTipText: "Add Ingate Date",
    cursorType: "pointer",
  },
  {
    flag: 2,
    color: "#32CD32 !important", // LimeGreen
    link: INGATEROUTE,
    status: "Ingate",
    code: statusEnum["ingate"],
    toolTipText: "Add Departed Date",
    cursorType: "pointer",
  },
  {
    flag: 0,
    color: "black !important",
    link: CANCELLED,
    status: "CANCELLED",
    code: statusEnum["cancelled"],
    toolTipText: "",
    cursorType: "default",
  },
  {
    flag: 2,
    color: "#6F3BAC !important", // Purple
    link: DEPARTEDDATE,
    status: "Departed",
    code: statusEnum["departed"],
    toolTipText: "Add ATA Date",
    cursorType: "pointer",
  },
  {
    flag: 2,
    color: "#D8BFD8 !important", // Jamun Color
    link: "",
    status: "Arrived",
    code: statusEnum["arrived"],
    toolTipText: "",
    cursorType: "pointer",
  },
];

export const statusEnumMCRS = {
  approved: 0,
  pending: 1,
  renewed: 2,
  rejected: 3,
  cancelled: 4,
};

export const statusObjectMCRS = [
  {
    color: "#22BB33 !important", // kind of forest green
    status: "Approved",
    code: statusEnumMCRS["approved"],
  },
  {
    color: "#F0AD4E !important", // kind of LightSalmon
    status: "Pending",
    code: statusEnumMCRS["pending"],
  },
  {
    color: "#AAAAAA !important", // DarkGray
    status: "Renewed",
    code: statusEnumMCRS["renewed"],
  },
  {
    color: "#BB2124 !important", // kind of FireBrick Red
    status: "Rejected",
    code: statusEnumMCRS["rejected"],
  },
  {
    color: "#2E2E2E !important", // shade of Black
    status: "CANCELLED",
    code: statusEnumMCRS["cancelled"],
  },
];
