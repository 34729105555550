import { makeStyles } from "@mui/styles";
import {
  statusEnum,
  statusObject,
} from "../../../constants/routesBasedOnStatus";

export const useDSStyle = makeStyles((theme) => ({
  lockIcon: {
    height: "16px",
    margin: "-1px 2px 0px 0px",
  },
  iconButton: {
    cursor: "pointer",
    color: "#183650",
  },
  headerIconButton: {
    cursor: "pointer",
    color: theme.palette.color.white,
    padding: "22px 0px 0px 32px",
  },
  disabledButton: {
    color: "rgba(0, 0, 0, 0.26) !important",
    cursor: "default",
  },
  bookedCursor: {
    cursor: getCursor("booked"),
  },
  loadingCursor: {
    cursor: getCursor("loading"),
  },
  readytopickupCursor: {
    cursor: getCursor("readytopickup"),
  },
  pickedupCursor: {
    cursor: getCursor("pickedup"),
  },
  stagedCursor: {
    cursor: getCursor("staged"),
  },
  ingateCursor: {
    cursor: getCursor("ingate"),
  },
  cobCursor: {
    cursor: getCursor("cob"),
  },
  cancelledCursor: {
    cursor: getCursor("cancelled"),
  },
  dispatchedCursor: {
    cursor: getCursor("dispatched"),
  },
  acceptedCursor: {
    cursor: getCursor("accepted"),
  },
  rejectedCursor: {
    cursor: getCursor("rejected"),
  },
  departedCursor: {
    cursor: getCursor("departed"),
  },
  arrivedCursor: {
    cursor: getCursor("arrived"),
  },

  bookedIcon: {
    color: getColor("booked"),
    marginTop: "-4px",
  },
  loadingIcon: {
    color: getColor("loading"),
    marginTop: "-4px",
  },
  readytopickupIcon: {
    color: getColor("readytopickup"),
    marginTop: "-4px",
  },
  pickedupIcon: {
    color: getColor("pickedup"),
    marginTop: "-4px",
  },
  stagedIcon: {
    color: getColor("staged"),
    marginTop: "-4px",
  },
  ingateIcon: {
    color: getColor("ingate"),
    marginTop: "-4px",
  },
  cobIcon: {
    color: getColor("cob"),
    marginTop: "-4px",
  },
  cancelledIcon: {
    color: getColor("cancelled"),
    marginTop: "-4px",
  },
  dispatchedIcon: {
    color: getColor("dispatched"),
    marginTop: "-2px",
  },
  acceptedIcon: {
    color: getColor("accepted"),
    marginTop: "-4px",
  },
  rejectedIcon: {
    color: getColor("rejected"),
    marginTop: "-4px",
  },
  departedIcon: {
    color: getColor("departed"),
    marginTop: "-4px",
  },
  arrivedIcon: {
    color: getColor("arrived"),
    marginTop: "-4px",
  },
  dispatchedCell: {
    "& .dx-button-mode-outlined": {
      borderColor: "transparent",
    },
  },
  dropDownCell: {
    "& .dx-dropdownbutton-action.dx-button .dx-button-content": {
      paddingLeft: "0px",
    },
  },
  customHighlight: {
    fontWeight: 700,
    color: "#32CD32 ",
  },
}));

const getCursor = (data) => {
  let cursor;
  statusObject.forEach((ele) => {
    if (ele.code === statusEnum[data]) {
      cursor = ele.cursorType;
    }
  });
  return cursor;
};

const getColor = (data) => {
  let color;
  statusObject.forEach((ele) => {
    if (ele.code === statusEnum[data]) {
      color = ele.color;
    }
  });
  return color;
};
