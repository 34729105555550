import { SELECT_TAB } from "./actionTypes";

const initialState = {
  selectedTab: null,
};

const Layout = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_TAB:
      return {
        ...state,
        selectedTab: action.payload,
      };
    default:
      return state;
  }
};

export default Layout;
