export const SET_LOADING = "SET_LOADING";

export const GET_LOCATION_LIST = "GET_LOCATION_LIST";
export const GET_LOCATION_LIST_SUCCESS = "GET_LOCATION_LIST_SUCCESS";
export const GET_LOCATION_LIST_FAILURE = "GET_LOCATION_LIST_FAILURE";

export const GET_STATUS_LIST = "GET_STATUS_LIST";
export const GET_STATUS_LIST_SUCCESS = "GET_STATUS_LIST_SUCCESS";
export const GET_STATUS_LIST_FAILURE = "GET_STATUS_LIST_FAILURE";

export const GET_COMPONENT_TYPE = "GET_COMPONENT_TYPE";
export const GET_COMPONENT_TYPE_SUCCESS = "GET_COMPONENT_TYPE_SUCCESS";
export const GET_COMPONENT_TYPE_FAILURE = "GET_COMPONENT_TYPE_FAILURE";

export const GET_COMPONENT_MODEL = "GET_COMPONENT_MODEL";
export const GET_COMPONENT_MODEL_SUCCESS = "GET_COMPONENT_MODEL_SUCCESS";
export const GET_COMPONENT_MODEL_FAILURE = "GET_COMPONENT_MODEL_FAILURE";

export const GET_PROJECT_LOGISTICS_LIST = "GET_PROJECT_LOGISTICS_LIST";
export const GET_PROJECT_LOGISTICS_LIST_SUCCESS =
  "GET_PROJECT_LOGISTICS_LIST_SUCCESS";
export const GET_PROJECT_LOGISTICS_LIST_FAILURE =
  "GET_PROJECT_LOGISTICS_LIST_FAILURE";

export const GET_MCRS_DETAIL = "GET_MCRS_DETAIL";
export const GET_MCRS_DETAIL_SUCCESS = "GET_MCRS_DETAIL_SUCCESS";
export const GET_MCRS_DETAIL_FAILURE = "GET_MCRS_DETAIL_FAILURE";

export const RESET_MCRS_DETAIL = "RESET_MCRS_DETAIL";
export const RESET_MCRS_DETAIL_SUCCESS = "RESET_MCRS_DETAIL_SUCCESS";
export const RESET_MCRS_DETAIL_FAILURE = "RESET_MCRS_DETAIL_FAILURE";
