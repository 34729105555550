import React, { useEffect, useState } from "react";
import { makeStyles, styled } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import PdfViewerTitle from "./PdfViewerTitle";
import API from "../utils/axios";
import Loader from "../components/UI/Loader/Loader";
const allowedImageView = [
  "png",
  "jpg",
  "jpeg",
  "gif",
  "raw",
  "bmp",
  "ico",
  "svg",
];
const PdfViewerDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const useStyles = makeStyles((theme) => ({
  pdfViewerDialog: {
    "& .MuiDialog-paper": {
      maxWidth: "none",
      width: "80%",
      maxHeight: "calc(100% - 120px)",
    },
    "& .MuiDialogContent-dividers": {
      padding: "0px",
      overflow: "hidden",
    },
  },
}));

function PdfViewer({
  openPdfViewer,
  setSelectedPDF,
  selectedPDF,
  isBase64String = false,
}) {
  const classes = useStyles();
  const [pdfData, setpdfData] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [isPDFType, setisPDFType] = useState(true);
  const handleClose = () => {
    setSelectedPDF(null);
    setisPDFType(true);
    setpdfData("");
  };

  useEffect(() => {
    if (
      allowedImageView.includes(selectedPDF?.documentExtension?.toLowerCase())
    ) {
      setisPDFType(false);
      setpdfData(selectedPDF?.content);
    } else {
      if (selectedPDF?.content.length > 0 && !isBase64String) {
        setisLoading(true);
        getBase64(selectedPDF.content);
      }
      if (selectedPDF?.content.length > 0 && isBase64String) {
        //setpdfData(selectedPDF?.content);
        getBlob(selectedPDF?.content);
      }
    }
  }, [selectedPDF, isBase64String]);

  const getBase64 = async (url) => {
    const response = await API.post("/1/azureurl-to-base64", url);
    if (response) {
      const data = `data:application/pdf;base64, ${response.data.value}`;
      const res = await fetch(data).then((res) => res.blob());
      const url = window.URL.createObjectURL(res, { type: "application/pdf" });
      setisLoading(false);
      setpdfData(url);
    }
  };

  const getBlob = async (data) => {
    const res = await fetch(data).then((res) => res.blob());
    const url = window.URL.createObjectURL(res, { type: "application/pdf" });
    setisLoading(false);
    setpdfData(url);
  };

  return (
    <div>
      <PdfViewerDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openPdfViewer}
        style={{ width: "100%" }}
        className={classes.pdfViewerDialog}
      >
        <PdfViewerTitle id="customized-dialog-title" onClose={handleClose}>
          {selectedPDF?.documentName && selectedPDF?.documentName.split(".")[0]}
        </PdfViewerTitle>
        <DialogContent dividers={isPDFType ? true : false}>
          {isPDFType && (
            <>
              {isLoading && <Loader />}
              <embed
                src={pdfData}
                title="title"
                width="100%"
                style={{
                  height: "70vh",
                }}
              />
            </>
          )}
          {!isPDFType && (
            <div
              style={{
                height: "70vh",
                width: "100%",
              }}
            >
              <img
                src={pdfData}
                alt="image"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          )}
        </DialogContent>
      </PdfViewerDialog>
    </div>
  );
}

export default PdfViewer;
