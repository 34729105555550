import { takeEvery, put, all } from "redux-saga/effects";
import API from "../../utils/axios";
import {
  GET_CLIENT_DETAIL,
  GET_CLIENT_DETAIL_FAILURE,
  GET_CLIENT_DETAIL_SUCCESS,
  SET_CLIENT_DETAIL_LOADING,
} from "./actionTypes";

function* getClientDetailSaga() {
  try {
    yield put({ type: SET_CLIENT_DETAIL_LOADING });
    // https://app-cus-myfracht-api-qa-001.azurewebsites.net/api/1/clientdetails
    const { data } = yield API.get("1/auto-rate/client-details");
    yield put({ type: GET_CLIENT_DETAIL_SUCCESS, payload: data.value });
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_CLIENT_DETAIL_FAILURE,
      payload: { errorMessage: "Unable to get client details list" },
    });
  }
}

function* RatesSaga() {
  yield all([yield takeEvery(GET_CLIENT_DETAIL, getClientDetailSaga)]);
}

export default RatesSaga;
