import * as React from "react";
import Box from "@mui/material/Box";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { Menu } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useDispatch } from "react-redux";
import { updateClockData } from "../../redux/widgets/action";
import { clockZones } from "./widgets/timezone2-list";
import LazyLoad from "react-lazyload";

const ITEM_HEIGHT = 70;

function Clock(props) {
  const { clockNumber, setSelectedData } = props;
  const [openList, setOpenList] = React.useState(false);
  const zones = clockZones?.sort((a, b) => {
    let fa = a.name.toLowerCase(),
      fb = b.name.toLowerCase();
    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });

  const handleItemClick = (name) => {
    setOpenList(false);
    const sendData = {
      clockNumber: clockNumber,
      name: name,
    };

    // Filter out any existing data for the same clockNumber
    setSelectedData((prevData) =>
      prevData
        .filter((data) => data.clockNumber !== clockNumber)
        .concat(sendData)
    );
  };

  return (
    <div sx={{ display: "flex" }}>
      <Paper elevation={0} sx={{ maxWidth: 356 }}>
        <Box
          sx={{
            pb: openList ? 2 : 0,
          }}
        >
          <ListItemButton
            alignItems="flex-start"
            onClick={() => setOpenList(!openList)}
            sx={{
              px: 3,
            }}
          >
            <ListItemText
              primary={`Clock ${clockNumber}`}
              primaryTypographyProps={{
                fontSize: 16,
                fontWeight: "bold",
                lineHeight: "20px",
                color: "#6C757D",
                mb: "2px",
              }}
              sx={{ my: 0 }}
            />
            <KeyboardArrowDown
              sx={{
                mr: -1,
                opacity: 1,
                color: "#6C757D",
                transform: openList ? "rotate(-180deg)" : "rotate(0deg)",
                transition: "0.2s",
              }}
            />
          </ListItemButton>
          <LazyLoad once>
            {openList &&
              zones?.map((item, id) => {
                const primary = `${item.displayName}`;
                return (
                  <ListItemButton
                    key={id}
                    sx={{ py: 0, minHeight: 32, color: "black" }}
                    onClick={() => handleItemClick(item.name)}
                  >
                    <ListItemText
                      primary={primary}
                      primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "medium",
                      }}
                    />
                  </ListItemButton>
                );
              })}
          </LazyLoad>
        </Box>
      </Paper>
    </div>
  );
}

export default function TimeZoneMenu({ anchorEl, open, handleClose }) {
  const initialSelectedData =
    JSON.parse(localStorage.getItem("clockData")) || [];

  const [selectedData, setSelectedData] = React.useState(initialSelectedData);

  const dispatch = useDispatch();

  const saveSelectedData = () => {
    const cleanedData = selectedData.reduce((acc, data) => {
      if (!acc.some((item) => item.clockNumber === data.clockNumber)) {
        acc.push(data);
      }
      return acc;
    }, []);
    localStorage.setItem("clockData", JSON.stringify(cleanedData));
    dispatch(updateClockData(cleanedData));
  };

  React.useEffect(() => {
    if (selectedData.length > 0) saveSelectedData();
  }, [selectedData]);

  return (
    <div>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "auto",
            maxWidth: 356,
            minWidth: "20ch",
          },
        }}
      >
        <Clock clockNumber={1} setSelectedData={setSelectedData} />
        <Divider />
        <Clock clockNumber={2} setSelectedData={setSelectedData} />
        <Divider />
        <Clock clockNumber={3} setSelectedData={setSelectedData} />
      </Menu>
    </div>
  );
}
