import React, { useEffect } from "react";
import errorImage from "./errorImage.json";
import { useLottie } from "lottie-react";
import { Button, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import HomeIcon from "@mui/icons-material/Home";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReplyIcon from "@mui/icons-material/Reply";
import RefreshIcon from "@mui/icons-material/Refresh";
import API from "../utils/axios";
import { useMsal } from "@azure/msal-react";
const style = {
  height: "100%",
  width: "100%",
};

const useStyles = makeStyles((theme) => ({
  "@global": {
    "@keyframes textShine": {
      "0%": {
        backgroundPosition: "0% 50%",
      },
      "100%": {
        backgroundPosition: "100% 50%",
      },
    },
  },

  firstText: {
    // color: theme.palette.color.primary,
    font: "normal normal bold clamp(56px, 5vw, 66px) Open Sans",
    animation: "textShine 5s ease-in-out infinite alternate",
    background:
      "linear-gradient(to right, #483D8B 20%, #4169E1 30%, #B0C4DE 70%, #183650 80%)",
    backgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundSize: "500% auto",
  },

  secondText: {
    color: theme.palette.color.black,
    font: "normal normal 600 clamp(14px, 2vw, 22px) Open Sans",
    marginBottom: "20px",
  },
  buttonClass: {
    ...theme.typography.body2,
    width: "auto",
    height: "38px",
    background: "transparent",
    color: `${theme.palette.color.primary}`,
    border: `1px solid transparent`,
    textTransform: "capitalize",
    opacity: 1,
    "&:hover": {
      background: "transparent",
      border: `1px solid ${theme.palette.color.primary}`,
      opacity: 1,
    },
  },
  redirectBtn: {
    ...theme.typography.body2,
    width: "auto",
    height: "38px",
    backgroundColor: `${theme.palette.color.skyBlue} !important`,
    color: `${theme.palette.color.white} !important`,
    border: `0.5px solid ${theme.palette.color.lightGrayishBlueB1}`,
    boxShadow: "0px 2px 7px #00000029",
    borderRadius: "4px",
    textTransform: "capitalize",
    opacity: 1,
    "&:hover": {
      backgroundColor: `${theme.palette.color.white} !important`,
      color: `${theme.palette.color.skyBlue} !important`,
      border: `1px solid ${theme.palette.color.skyBlue} !important`,
      opacity: 1,
    },
  },
}));

const ErrorPage = ({
  secondText = "Please contact your local administrator for onboarding/additional access.",
  isErrorPage = false,
  showRedirectBtn = true,
}) => {
  const { instance } = useMsal();
  const options = {
    animationData: errorImage,
    loop: true,
    autoplay: true,
  };

  const { View, setSpeed } = useLottie(options, style);
  const classes = useStyles();

  useEffect(() => {
    setSpeed(1);
  });

  const handleLogout = () => {
    const userSessionId = sessionStorage.getItem("unique_id");
    API.delete(`/1/lock/user-session/${userSessionId}`)
      .then((resp) => {
        if (resp.status === 200) {
          localStorage.clear();
          sessionStorage.clear();
          sessionStorage.clear();
          window.location.href = "/";
          instance.logoutRedirect({
            postLogoutRedirectUri: "/",
            mainWindowRedirectUri: "/",
          });
        }
      })
      .catch((error) => {
        localStorage.clear();
        sessionStorage.clear();
        console.log("ERROR", error);
      });
  };

  return (
    <Grid
      container
      style={{
        height: "calc(100vh - 280px)",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Grid item md={0.5} xs={12} />
      <Grid
        item
        md={4}
        xs={12}
        style={{
          display: "flex",
          flexFlow: "column wrap",
          alignItems: "flex-start",
          placeContent: "center",
          margin: "10px",
        }}
      >
        {isErrorPage && (
          <Typography className={classes.firstText}>Ooops!</Typography>
        )}
        <br />
        {secondText.split("\n").map((line, index) => (
          <Typography key={index} className={classes.secondText}>
            {line}
          </Typography>
        ))}
        {isErrorPage && (
          <div>
            <Button
              className={classes.buttonClass}
              onClick={() => (window.location.href = window.location.origin)}
              startIcon={<HomeIcon />}
              style={{
                marginRight: "10px",
              }}
            >
              Home
            </Button>

            <Button
              className={classes.buttonClass}
              onClick={() => window.history.back()}
              startIcon={<ArrowBackIcon />}
              style={{
                marginRight: "10px",
              }}
            >
              Previous Page
            </Button>

            <Button
              className={classes.buttonClass}
              onClick={() => window.location.reload()}
              startIcon={<RefreshIcon />}
            >
              Refresh Page
            </Button>
          </div>
        )}
        {showRedirectBtn && (
          <Button
            className={classes.redirectBtn}
            onClick={() => {
              handleLogout();
            }}
            endIcon={<ReplyIcon />}
          >
            Redirect Back
          </Button>
        )}
      </Grid>
      <Grid
        item
        md={7.2}
        xs={12}
        style={{
          display: "flex",
        }}
      >
        {View}
      </Grid>
    </Grid>
  );
};

export default ErrorPage;
