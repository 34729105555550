import { put, all, takeLatest } from "redux-saga/effects";
import {
  GET_USER_VIEWS,
  GET_USER_VIEWS_FAILURE,
  GET_USER_VIEWS_SUCCESS,
  SET_AXIS_LOADING,
} from "./actionTypes";
import API from "../../utils/axios";

function* getViewDetailSaga({ gridType }) {
  try {
    yield put({ type: SET_AXIS_LOADING });
    const { data } = yield API.get(`/1/userviews/me/getuserviews/${gridType}`);
    yield put({ type: GET_USER_VIEWS_SUCCESS, payload: data.value });
  } catch (error) {
    console.log("error", error);
    yield put({
      type: GET_USER_VIEWS_FAILURE,
      errorMessage: "Unable to get user view details",
    });
  }
}

function* AxisSaga() {
  yield all([yield takeLatest(GET_USER_VIEWS, getViewDetailSaga)]);
}

export default AxisSaga;
