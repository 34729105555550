export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAILURE = "GET_USER_DETAILS_FAILURE";

export const POST_USER_DETAILS = "POST_USER_DETAILS";

export const SET_USER_LOADING = "SET_USER_LOADING";

export const LOGOUT_USER = "LOGOUT _USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_FAILURE = "LOGOUT_USER_FAILURE";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const SET_SWITCHED_ORG_DETAIL = "SET_SWITCHED_ORG_DETAIL";
export const GET_ORG_ID = "GET_ORG_ID";
export const SET_ORG_ID = "SET_ORG_ID";

export const GET_USER_DETAILS_SERVER_ERROR = "GET_USER_DETAILS_SERVER_ERROR";

export const GET_ORG_DETAIL = "GET_ORG_DETAIL";
export const SET_ORG_DETAIL = "SET_ORG_DETAIL";
