import {
  ADD_LOCK_OPTION_FAILURE,
  ADD_LOCK_OPTION_SUCCESS,
  DELETE_LOCK_OPTION_FAILURE,
  DELETE_LOCK_OPTION_SUCCESS,
  SET_LOADING,
} from "./actionTypes";

const initialState = {
  lockOption: false,
  loading: false,
  error: false,
  errorMessage: "",
};

const ReleaseLock = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_LOCK_OPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        lockOption: action.payload,
      };
    case DELETE_LOCK_OPTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: "Unable to delete lock option",
      };

    case ADD_LOCK_OPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        lockOption: action.payload,
      };
    case ADD_LOCK_OPTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: "Unable to add lock option",
      };

    default:
      return state;
  }
};

export default ReleaseLock;
