import { takeEvery, put, all } from "redux-saga/effects";
import API from "../../utils/axios";
import {
  GET_CARRIER_LIST,
  GET_CARRIER_LIST_FAILURE,
  GET_CARRIER_LIST_SUCCESS,
  GET_CUSTOMER_LIST,
  GET_CUSTOMER_LIST_FAILURE,
  GET_CUSTOMER_LIST_SUCCESS,
  GET_DESTINATION_LIST,
  GET_DESTINATION_LIST_FAILURE,
  GET_DESTINATION_LIST_SUCCESS,
  GET_DOCUMENT_TYPE,
  GET_DOCUMENT_TYPE_FAILURE,
  GET_DOCUMENT_TYPE_SUCCESS,
  GET_LOADED_LOCATION,
  GET_LOADED_LOCATION_FAILURE,
  GET_LOADED_LOCATION_SUCCESS,
  GET_POL_LIST,
  GET_POL_LIST_FAILURE,
  GET_POL_LIST_SUCCESS,
  GET_TRUCKER_LIST,
  GET_TRUCKER_LIST_FAILURE,
  GET_TRUCKER_LIST_SUCCESS,
  GET_DELIVERY_LOCATION_LIST,
  GET_DELIVERY_LOCATION_LIST_SUCCESS,
  GET_DELIVERY_LOCATION_LIST_FAILURE,
  SET_MILLERCOORS_LOADING,
  GET_IMPORTPREPULL_LIST,
  GET_IMPORTPREPULL_LIST_FAILURE,
  GET_IMPORTPREPULL_LIST_SUCCESS,
  GET_POLUNLOC_LIST_FAILURE,
  GET_POLUNLOC_LIST_SUCCESS,
  GET_POLUNLOC_LIST,
  SAVE_NOTE_DATA_SUCCESS,
  SAVE_NOTE_DATA_FAILURE,
  SAVE_NOTE_DATA,
} from "./actionTypes";

function* getDocumentTypeSaga({ featureName, pageName }) {
  try {
    yield put({ type: SET_MILLERCOORS_LOADING });
    // 1/documenttype/features/Miller%20Coors/pages/Booking%20Request
    const { data } = yield API.get(
      `/1/documenttype/features/${featureName}/pages/${pageName}`
    );
    yield put({ type: GET_DOCUMENT_TYPE_SUCCESS, payload: data.value });
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_DOCUMENT_TYPE_FAILURE,
      payload: { errorMessage: "Unable to get document type list" },
    });
  }
}

function* getCustomerListSaga() {
  try {
    yield put({ type: SET_MILLERCOORS_LOADING });
    // api/1/mcutility/customer/list
    const { data } = yield API.get(`/1/mcutility/customer/list`);
    yield put({ type: GET_CUSTOMER_LIST_SUCCESS, payload: data.value });
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_CUSTOMER_LIST_FAILURE,
      payload: { errorMessage: "Unable to get customer Id list" },
    });
  }
}
function* getTruckerListSaga() {
  try {
    yield put({ type: SET_MILLERCOORS_LOADING });
    const { data } = yield API.get(`1/mcutility/trucker/list`);
    yield put({ type: GET_TRUCKER_LIST_SUCCESS, payload: data.value });
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_TRUCKER_LIST_FAILURE,
      payload: { errorMessage: "Unable to get trucker list" },
    });
  }
}
function* getImportPrePullListSaga() {
  try {
    yield put({ type: SET_MILLERCOORS_LOADING });
    const { data } = yield API.get(`/1/mcutility/import-pre-pull/list`);
    yield put({ type: GET_IMPORTPREPULL_LIST_SUCCESS, payload: data.value });
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_IMPORTPREPULL_LIST_FAILURE,
      payload: { errorMessage: "Unable to get Import/PrePull list" },
    });
  }
}
function* getCarrierListSaga() {
  try {
    yield put({ type: SET_MILLERCOORS_LOADING });
    const { data } = yield API.get(`1/mcutility/carrier/list`);
    yield put({ type: GET_CARRIER_LIST_SUCCESS, payload: data.value });
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_CARRIER_LIST_FAILURE,
      payload: { errorMessage: "Unable to get carrier list" },
    });
  }
}
function* getPOLListSaga() {
  try {
    yield put({ type: SET_MILLERCOORS_LOADING });
    const { data } = yield API.get(`1/mcutility/pol/list`);
    yield put({ type: GET_POL_LIST_SUCCESS, payload: data.value });
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_POL_LIST_FAILURE,
      payload: { errorMessage: "Unable to get POL list" },
    });
  }
}
function* getDestinationListSaga() {
  try {
    yield put({ type: SET_MILLERCOORS_LOADING });
    const { data } = yield API.get(`1/mcutility/destination/list`);
    yield put({ type: GET_DESTINATION_LIST_SUCCESS, payload: data.value });
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_DESTINATION_LIST_FAILURE,
      payload: { errorMessage: "Unable to get destination list" },
    });
  }
}
function* getLoadedLocationSaga() {
  try {
    yield put({ type: SET_MILLERCOORS_LOADING });
    const { data } = yield API.get(`1/mcutility/loadedlocation/list`);
    yield put({ type: GET_LOADED_LOCATION_SUCCESS, payload: data.value });
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_LOADED_LOCATION_FAILURE,
      payload: { errorMessage: "Unable to get loaded location list" },
    });
  }
}

function* getDeliveryLocationListSaga() {
  try {
    yield put({ type: SET_MILLERCOORS_LOADING });
    const { data } = yield API.get(`1/mcutility/deliverylocation/list`);
    yield put({
      type: GET_DELIVERY_LOCATION_LIST_SUCCESS,
      payload: data.value,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_DELIVERY_LOCATION_LIST_FAILURE,
      payload: { errorMessage: "Unable to get delivery Location list" },
    });
  }
}

function* getPOLUNLOCListSaga() {
  try {
    yield put({ type: SET_MILLERCOORS_LOADING });
    const { data } = yield API.get(`/1/mcutility/unloccode/list`);
    yield put({ type: GET_POLUNLOC_LIST_SUCCESS, payload: data.value });
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_POLUNLOC_LIST_FAILURE,
      payload: { errorMessage: "Unable to get POL list" },
    });
  }
}

function* postNoteDataSaga({ payload }) {
  try {
    yield put({ type: SET_MILLERCOORS_LOADING });
    const { data } = yield API.put(`/1/direct-summary/save-note`, payload);
    yield put({ type: SAVE_NOTE_DATA_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: SAVE_NOTE_DATA_FAILURE,
      errorMessage: "Unable to post notes data",
    });
  }
}

function* MillerCoorsSaga() {
  yield all([yield takeEvery(GET_DOCUMENT_TYPE, getDocumentTypeSaga)]);
  yield all([yield takeEvery(GET_CUSTOMER_LIST, getCustomerListSaga)]);
  yield all([yield takeEvery(GET_TRUCKER_LIST, getTruckerListSaga)]);
  yield all([
    yield takeEvery(GET_IMPORTPREPULL_LIST, getImportPrePullListSaga),
  ]);
  yield all([yield takeEvery(GET_CARRIER_LIST, getCarrierListSaga)]);
  yield all([yield takeEvery(GET_POL_LIST, getPOLListSaga)]);
  yield all([yield takeEvery(GET_DESTINATION_LIST, getDestinationListSaga)]);
  yield all([yield takeEvery(GET_LOADED_LOCATION, getLoadedLocationSaga)]);
  yield all([
    yield takeEvery(GET_DELIVERY_LOCATION_LIST, getDeliveryLocationListSaga),
  ]);
  yield all([yield takeEvery(GET_POLUNLOC_LIST, getPOLUNLOCListSaga)]);
  yield all([yield takeEvery(SAVE_NOTE_DATA, postNoteDataSaga)]);
}

export default MillerCoorsSaga;
