export const SET_ROLES_LOADING = "SET_ROLES_LOADING";

export const SET_OPEN_ROLEPOPUP = "SET_OPEN_ROLEPOPUP";

export const RESET_ROLE_DATA = "RESET_ROLE_DATA";

export const GET_RESOURCE_LIST = "GET_RESOURCE_LIST";
export const GET_RESOURCE_LIST_SUCCESS = "GET_RESOURCE_LIST_SUCCESS";
export const GET_RESOURCE_LIST_FAILURE = "GET_RESOURCE_LIST_FAILURE";

export const GET_ROLE_DATA = "GET_ROLE_DATA";
export const GET_ROLE_DATA_SUCCESS = "GET_ROLE_DATA_SUCCESS";
export const GET_ROLE_DATA_FAILURE = "GET_ROLE_DATA_FAILURE";
