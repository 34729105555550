import {
  SET_ROLES_LOADING,
  SET_OPEN_ROLEPOPUP,
  RESET_ROLE_DATA,
  GET_RESOURCE_LIST_SUCCESS,
  GET_RESOURCE_LIST_FAILURE,
  GET_ROLE_DATA_FAILURE,
  GET_ROLE_DATA_SUCCESS,
} from "./actionTypes";

const initialState = {
  resourceList: [],
  dialogType: "",
  rolesLoading: false,
  error: false,
  errorMessage: "",
  showRolePopup: false,
  id: "",
  name: "",
  hierarchy: "",
  organizationId: "",
  permission: [],
};

const Role = (state = initialState, action) => {
  switch (action.type) {
    case SET_ROLES_LOADING:
      return {
        ...state,
        rolesLoading: true,
      };

    case SET_OPEN_ROLEPOPUP:
      return {
        ...state,
        showRolePopup: action.payload.clicked,
        dialogType: action.payload.dialogType,
      };

    case RESET_ROLE_DATA:
      return {
        ...state,
        dialogType: "",
        rolesLoading: false,
        error: false,
        errorMessage: "",
        showRolePopup: false,
        id: "",
        name: "",
        permission: [],
      };

    case GET_RESOURCE_LIST_SUCCESS:
      return {
        ...state,
        rolesLoading: false,
        resourceList: action.payload,
      };

    case GET_RESOURCE_LIST_FAILURE:
      return {
        ...state,
        rolesLoading: false,
        error: true,
        errorMessage: "Unable to get resource list",
      };

    case GET_ROLE_DATA_SUCCESS:
      return {
        ...state,
        rolesLoading: false,
        ...action.payload,
      };

    case GET_ROLE_DATA_FAILURE:
      return {
        ...state,
        rolesLoading: false,
        error: true,
        errorMessage: "Unable to get resource list",
      };

    default:
      return state;
  }
};

export default Role;
