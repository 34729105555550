export const resourceEnum = {
  ANALYTICS: "Analytics",
  SHIPMENT: "Shipment",
  CREATESHIPMENT: "CreateShipment",
  HISTORY: "History",
  DOCUMENTUPLOAD: "DocumentUpload",
  RATES: "Rates",
  MILLERCOORS: "MillerCoors",
  DIRECTSUMMARY: "DirectSummary",
  AVAILABLECAPACITY: "AvailableCapacity",
  MCRS: "MCRS",
  DRAFT: "Draft",
};

export const AddressType = {
  ConsignorPickupDeliveryAddress: "1", //Shipper
  ConsignorShipperAddress: "2", //Shipper
  ConsigneeDocumentaryAddress: "3", //Consignee
  ConsigneePickupDeliveryAddress: "4", //Consginee
  NotifyPartyAddress: "5", //notify
};

export const NotesType = {
  PickupInstructions: "1",
  DeliveryInstructions: "2",
  ShipmentNotes: "3",
};

export const shipmentNavigation = {
  DRAFT: "draft",
  CLONE: "clone",
  SHIPMENT: "shipment",
};

export const trackNavigation = {
  TRACK_NALCO: "/Summary",
  TRACK_PO_SUMMARY: "/shipmentsummary/live",
  TRACK_DEFAULT: "/track",
  CONSTRUCTION_HISTORY: "/constructionBG/history",
  TRACK_MCBC: "/molsoncoorssummary",
};

export const truckingUtility = {
  EQUIPMENT: "Equipment",
  SERVICE: "Service",
};

export const statusEnumQuoteRequest = {
  pending: 0,
  received: 1,
  expired: 2,
  waiting: 3,
  tenderaccepted: 4,
  tenderrejected: 5,
  cancelled: 7,
};

export const statusObjQuoteRequest = [
  {
    color: "#E6A54C !important", // orange
    status: "Pending",
    label: "Pending",
    code: statusEnumQuoteRequest["pending"],
    disableTender: true,
  },
  {
    color: "#90EE90 !important", // little green
    status: "Received",
    label: "Quote Received",
    code: statusEnumQuoteRequest["received"],
    disableTender: false,
  },
  {
    color: "#94BB54 !important", // DarkGray
    status: "Expired",
    label: "Expired",
    code: statusEnumQuoteRequest["expired"],
    disableTender: true,
  },
  {
    color: "RoyalBlue !important", // blue
    status: "Waiting",
    label: "Waiting for acceptance",
    code: statusEnumQuoteRequest["waiting"],
    disableTender: true,
  },
  {
    color: "#008000 !important", // darkgreen
    status: "TenderAccepted",
    label: "Tender Accepted",
    code: statusEnumQuoteRequest["tenderaccepted"],
    disableTender: true,
  },
  {
    color: "#CC3629 !important", // Red
    status: "TenderRejected",
    label: "Tender Rejected",
    code: statusEnumQuoteRequest["tenderrejected"],
    disableTender: true,
  },
  {
    color: "#000000 !important", // black
    status: "Cancelled",
    label: "Cancelled - No Response",
    code: statusEnumQuoteRequest["cancelled"],
    disableTender: true,
  },
];

export const modeEnum = {
  1: "Over the road",
  2: "Drayage",
  3: "Air",
};

export const customerEnum = {
  ROCHESTERUSER: "ROCELEBOS",
};

export const accessLevelEnum = {
  Hidden: "1",
  Disable: "2",
  Enable: "3",
};

export const permissionDropdown = [
  {
    name: "Hidden",
  },
  {
    name: "Disable (View & Read)",
  },
  {
    name: "Enable (View & Edit)",
  },
];

export const hierarchyLevelEnum = {
  High: 1,
  Medium: 2,
  Low: 3,
};

export const hierarchyDropdown = [
  {
    name: "High",
  },
  {
    name: "Medium",
  },
  {
    name: "Low",
  },
];

export const acceptedFileTypes = {
  shipmentFileType: [
    "image/jpeg",
    "image/jpg",
    "image/gif",
    "image/svg+xml",
    "image/png",
    "application/pdf",
    "text/csv",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "text/*",
  ],
  advanceBookingFileType: [
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,",
    "message/rfc822",
  ],
  bookingSettingFileType: [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,",
  ],
};

export const getStatusColor = (status) => {
  switch (status) {
    case "new":
      return "#6CBED9";
    case "inactive":
      return "#dc3545";
    case "active":
      return "#06AC47";
    default:
      return null;
  }
};

export const shipmentNotificationType = {
  SHIPMENTNOTE: "ShipmentNote",
  DOCUMENT: "Document",
};

export const liveStatusLeprino = [
  {
    color: "#B9B9B9 !important", // gray
    status: "1",
    label: "Open",
    disableTracking: true,
    cursor: "default",
    index: 1,
  },
  {
    color: "#6C757D !important", // DarkGray
    status: "2",
    label: "Planned",
    disableTracking: true,
    cursor: "default",
    index: 2,
  },
  {
    color: "#3F4443 !important", // black
    status: "3",
    label: "Picked Up",
    disableTracking: false,
    cursor: "default",
    index: 3,
  },
  {
    color: "#B8E8FF !important", // cyan
    status: "4",
    label: "Gate-in at POL",
    disableTracking: false,
    cursor: "pointer",
    index: 4,
  },
  {
    color: "#00A3E0 !important", // skyblue
    status: "5",
    label: "On-Board",
    disableTracking: false,
    cursor: "pointer",
    index: 5,
  },
  {
    color: "#006BBA !important", // darkskyblue
    status: "6",
    label: "Departed",
    disableTracking: false,
    cursor: "pointer",
    index: 6,
  },
  {
    color: "#FFF61A !important", // darkskyblue
    status: "11",
    label: "In-Transit",
    disableTracking: false,
    cursor: "pointer",
    index: 7,
  },
  {
    color: "#FF6900 !important", // darkskyblue
    status: "12",
    label: "At Trans-ship",
    disableTracking: false,
    cursor: "pointer",
    index: 8,
  },
  {
    color: "#05A845 !important", // light green
    status: "8",
    label: "Discharged",
    disableTracking: false,
    cursor: "pointer",
    index: 10,
  },
];
export const historyStatusLeprino = [
  {
    color: "#08E35E !important", // seagreen
    status: "7",
    label: "Arrived",
    disableTracking: false,
    cursor: "pointer",
    index: 9,
  },
  {
    color: "#025925 !important", // dark green
    status: "9",
    label: "Delivered",
    disableTracking: false,
    cursor: "pointer",
    index: 11,
  },
  {
    color: "#000 !important", // dark green
    status: "10",
    label: "Cancelled",
    disableTracking: false,
    cursor: "pointer",
    index: 12,
  },
];

export const liveStatusMolsonCoors = [
  {
    color: "#DAA520 !important",
    status: "1",
    label: "Booked",
    disableTracking: true,
    cursor: "default",
  },
  {
    color: "#FFA500 !important", // orange
    status: "2",
    label: "Ready to Pickup",
    disableTracking: true,
    cursor: "default",
  },
  {
    color: "#FFB6C1 !important", // light pink
    status: "3",
    label: "Picked Up",
    disableTracking: false,
    cursor: "pointer",
  },
  {
    color: "#32CD32 !important", // Aqua
    status: "4",
    label: "Ingate",
    disableTracking: false,
    cursor: "pointer",
  },
  {
    color: "#6F3BAC !important", // red
    status: "5",
    label: "Departed",
    disableTracking: false,
    cursor: "pointer",
  },
  {
    color: "#32CD32 !important", // LimeGreen
    status: "6",
    label: "Customs Cleared",
    disableTracking: false,
    cursor: "pointer",
  },
  {
    color: "#FFFF00 !important",
    status: "8",
    label: "Discharged",
    disableTracking: false,
    cursor: "pointer",
  },

  {
    color: "#FFFF00 !important",
    status: "10",
    label: "Dispatched",
    disableTracking: false,
    cursor: "pointer",
  },
  {
    color: "#4682B4 !important",
    status: "11",
    label: "Loading",
    disableTracking: false,
    cursor: "pointer",
  },
  {
    color: "#00FFFF !important",
    status: "12",
    label: "Accepted",
    disableTracking: false,
    cursor: "pointer",
  },
  {
    color: "#FF1493 !important",
    status: "14",
    label: "Staged",
    disableTracking: false,
    cursor: "pointer",
  },
];
export const historyStatusMolsonCoors = [
  {
    color: "#D8BFD8 !important",
    status: "7",
    label: "Arrived",
    disableTracking: false,
    cursor: "pointer",
  },

  {
    color: "#FFFF00 !important",
    status: "9",
    label: "Delivered",
    disableTracking: false,
    cursor: "pointer",
  },
  {
    color: "#FF0000 !important",
    status: "13",
    label: "Rejected",
    disableTracking: false,
    cursor: "pointer",
  },
  {
    color: "#000 !important",
    status: "15",
    label: "Cancelled",
    disableTracking: false,
    cursor: "pointer",
  },
];

export const ParentOrgNames = {
  Lep_ID: "Track & Trace",
};

export const mcutility = {
  PORTOFLOADING: "PortOfLoading",
  DESTINATION: "Destination",
  CUSTOMER: "Customer",
  DELIVERYLOCATION: "DeliveryLocation",
  CARRIER: "Carrier",
  LOADEDLOCATION: "LoadedLocation",
};

export const ParentOrgConditions = {
  FRACHT_AXIS: {
    MCIINV_US: true,
    LEPFOODEN: false,
    NALCO_US: false,
  },
  NOT_FRACHT_AXIS: {
    POLYFLMAN: true,
    SOUCEL_US: true,
    ROCELEBOS: true,
    DATNET_US: true,
    MCIINV_US: true,
    "FRACHT AG": true,
    "Fracht FWO Inc.": true,
    MOSGLOTPA: true, // MOSAIC
    "Fracht France": true,
    "Fracht UK Limited": true,
    FRAAUSBNE: true,
    201952: true,
    201634: true,
    "Fracht Logistics Mexico": true,
    MEDDEVMEL: true,
  },
};

export const statusObjPO = [
  {
    status: "OPEN",
    label: "OPEN",
    cursor: "default",
    index: 1,
  },
  {
    status: "PARTIAL",
    label: "PARTIAL",
    cursor: "default",
    index: 2,
  },
  {
    status: "COMPLETE",
    label: "COMPLETE",
    cursor: "default",
    index: 3,
  },
];

export const EMAILSCENARIOTYPE = {
  TRACK_TRACE_DOCUMENTS: "TrackAndTraceViewDocument",
  BOOKING_HISTORY_EMAIL: "NotificationForBookingCancellation",
};

export const TABENUM = {
  FRACHT_AXIS_TAB: "FrachtAxisTab",
  FRACHT_360_TAB: "Fracht360Tab",
  FRACHT_MARKETPLACE_TAB: "FrachtMarketplaceTab",
};

export const bookingHistoryStatus = [
  {
    status: "Booked",
    label: "Booked",
    enableCancelIcon: true,
    enableUndoIcon: false,
    enableTracking: true,
    showNotes: true,
    index: 1,
  },
  {
    status: "Requested Cancellation",
    label: "Requested for Cancellation",
    enableCancelIcon: false,
    enableUndoIcon: true,
    enableTracking: false,
    showNotes: false,
    index: 2,
  },
  {
    status: "Requested",
    label: "Requested Data",
    enableCancelIcon: true,
    enableUndoIcon: false,
    enableTracking: true,
    showNotes: true,
    index: 3,
  },
];