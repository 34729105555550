import { makeStyles } from "@mui/styles";

const widgetStyles = makeStyles((theme) => ({
  heading: {
    ...theme.typography.h5,
  },
  widgetOptions: {
    "& span": {
      ...theme.typography.body2,
    },
  },
  row: {
    display: "flex",
    margin: "0px 4px",
  },
  column: {
    width: "40px",
    height: "60px",
    backgroundColor: "#E6E6E6",
  },
  selectedRow: {
    display: "flex",
    margin: "0px 4px",
    border: "1px solid grey",
  },

  // status card styles
  statusCard: {
    width: "100%",
    borderRadius: "22px",
    height: "100%",
    transition: "background-color 0.3s ease-in-out",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#007dba6e",
      "& $statusText, & $statusCount": {
        color: theme.palette.color.skyBlue,
      },
      "& $statusTextContainer": {
        filter: "drop-shadow(1px 1px 9px white)",
        textShadow:
          "-2px -2px 14px #ffffff54, 2px -2px 0 #ffffff54, -2px 2px 3px #ffffff54, 2px 2px 14px #ffffff54",
      },
      "& $statusIcon": {
        filter: "drop-shadow(1px 1px 19px white)",
        width: "110px",
        height: "110px",
      },
    },
  },

  statusCardContent: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    height: "-webkit-fill-available",
  },
  statusTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  statusIcon: {
    width: "96px",
    height: "96px",
    filter: "drop-shadow(2px 4px 6px grey)",
  },
  statusText: {
    textAlign: "center",
  },
  statusCount: {},
  thumb: {
    background: "#183650",
  },
  mark: {
    background: "#CED4DA",
  },
  rail: {
    background: "#CED4DA",
  },
  track: {
    background: "#CED4DA",
  },
  valueLabel: {
    "&>*": {
      background: "#CED4DA",
    },
  },

  // dwell tranship card
  dwellCardContent: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "250px",
    transition: "background-color 0.3s ease-in-out",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#007dba6e",
      "& $statusText, & $statusCount": {
        color: theme.palette.color.skyBlue,
      },
      "& $statusTextContainer": {
        filter: "drop-shadow(1px 1px 9px white)",
        textShadow:
          "-2px -2px 14px #ffffff54, 2px -2px 0 #ffffff54, -2px 2px 3px #ffffff54, 2px 2px 14px #ffffff54",
      },
      "& $statusIcon": {
        filter: "drop-shadow(1px 1px 19px white)",
        width: "110px",
        height: "110px",
      },
    },
  },

  // ODQ css
  odqContainer: {
    padding: "10px",
    backgroundColor: "white",
    boxShadow:
      "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
    borderRadius: "22px",
    cursor: "pointer",
    transition: "background-color 0.3s ease-in-out",
    "&:hover": {
      backgroundColor: "#F7EED2",
      "& $odqLabel": {
        filter: "drop-shadow(1px 1px 19px white)",
        backgroundColor: "#3388ff",
        color: theme.palette.color.primary,
      },
    },
  },
  odqCard: {
    width: "100%",
    borderRadius: "22px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  odqLabel: {
    textAlign: "center",
    backgroundColor: theme.palette.color.skyBlue,
    padding: "4px 16px 8px",
    transition: "background-color 0.3s ease-in-out",
  },
}));

export { widgetStyles };
