import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";

const useStyles = makeStyles((theme) => {
  const isWebKit = /AppleWebKit/.test(navigator.userAgent);
  const isMozilla = /Firefox/.test(navigator.userAgent);

  return {
    buttonDiv: {
      backgroundColor: theme.palette.color.greyS1,
      position: "fixed",
      bottom: "0",
      zIndex: "10",
      width: isWebKit
        ? "-webkit-fill-available"
        : isMozilla
        ? "-moz-available"
        : "100%",
    },
    buttonContainer: {
      backgroundColor: "white",
      opacity: 1,
      padding: "12px 28px",
      boxShadow: "0px -3px 6px #00000029",
    },
  };
});

const FrachtButtonRibbon = ({ children, marginLeft }) => {
  const classes = useStyles();

  return (
    <div className={classes.buttonDiv}>
      <Box
        className={classes.buttonContainer}
        spacing={2}
        sx={{
          justifyContent: "flex-end",
          display: "flex",
          marginLeft: marginLeft,
        }}
      >
        {children}
      </Box>
    </div>
  );
};

export default FrachtButtonRibbon;

