import { all } from "redux-saga/effects";
import loginSaga from "./login/saga";
import CountryListSaga from "./createShipment/saga";
import ShipmentDetailSaga from "./upload/saga";
import RatesSaga from "./rates/saga";
import menuSaga from "./menuVisibility/saga";
import AssignContainerSaga from "./assignContainer/saga";
import EditDetailsSaga from "./editDetailPage/saga";
import MillerCoorsSaga from "./millerCoors/saga";
import RailCarSchedulerSaga from "./railCarScheduler/saga";
import LockSaga from "./releaseLock/saga";
import PageHeadDetailsSaga from "./pageHeadDetails/saga";
import ShipmentHistorySaga from "./shipmentHistory/saga";
import NewOrganizationSaga from "./newOrganization/saga";
import EditOrganizationSaga from "./editOrganization/saga";
import TruckLoadSaga from "./truckload/saga";
import NotificationsSaga from "./notification/saga";
import EditUserSaga from "./editUser/saga";
import RolesSaga from "./roles/saga";
import WidgetSaga from "./widgets/saga";
import AxisSaga from "./axis/saga";
import AnalyticsSaga from "./analytics/saga";
import AppSettingSaga from "./appSettings/saga";

export default function* rootSaga() {
  yield all([
    loginSaga(),
    CountryListSaga(),
    ShipmentDetailSaga(),
    RatesSaga(),
    menuSaga(),
    AssignContainerSaga(),
    EditDetailsSaga(),
    MillerCoorsSaga(),
    RailCarSchedulerSaga(),
    LockSaga(),
    PageHeadDetailsSaga(),
    ShipmentHistorySaga(),
    NewOrganizationSaga(),
    EditOrganizationSaga(),
    TruckLoadSaga(),
    NotificationsSaga(),
    EditUserSaga(),
    RolesSaga(),
    WidgetSaga(),
    AxisSaga(),
    AnalyticsSaga(),
    AppSettingSaga(),
  ]);
}
