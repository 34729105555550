import { takeEvery, put, all, takeLatest } from "redux-saga/effects";
import API from "../../utils/axios";

import {
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAILURE,
  GET_USER_DETAILS,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAILURE,
  SET_USER_LOADING,
  GET_ORG_ID,
  SET_ORG_ID,
  GET_USER_DETAILS_SERVER_ERROR,
  SET_ORG_DETAIL,
  GET_ORG_DETAIL,
} from "./actionTypes";

function* getUserDetailsSaga() {
  try {
    yield put({ type: SET_USER_LOADING });
    const { data, status } = yield API.get("1/users/me/profile");
    if (status === 500) {
      yield put({
        type: GET_USER_DETAILS_SERVER_ERROR,
      });
    } else if (status === 204) {
      yield put({
        type: GET_USER_DETAILS_SERVER_ERROR,
      });
    } else if (status === 200) {
      yield put({
        type: GET_USER_DETAILS_SUCCESS,
        payload: data.value,
      });
    }
  } catch (error) {
    console.log("User error", error);
    yield put({
      type: GET_USER_DETAILS_FAILURE,
      payload: null,
    });
  }
}

function* logoutUserSaga() {
  try {
    yield put({ type: LOGOUT_USER_SUCCESS });
  } catch (error) {
    console.log(error);
    yield put({
      type: LOGOUT_USER_FAILURE,
    });
  }
}

function* getOrganizationId() {
  try {
    const { data } = yield API.get("1/users/organizationid");
    yield put({ type: SET_ORG_ID, payload: data.value });
  } catch (error) {
    console.log(error);
  }
}
function* getOrganizationInfo({ orgId }) {
  try {
    const { data } = yield API.get(
      `1/users/me/account/detail/organization/${orgId}`
    );
    yield put({ type: SET_ORG_DETAIL, payload: data.value });
  } catch (error) {
    console.log(error);
  }
}

function* loginSaga() {
  yield all([yield takeEvery(GET_USER_DETAILS, getUserDetailsSaga)]);
  yield all([yield takeEvery(LOGOUT_USER, logoutUserSaga)]);
  yield all([yield takeLatest(GET_ORG_ID, getOrganizationId)]);
  yield all([yield takeLatest(GET_ORG_DETAIL, getOrganizationInfo)]);
}

export default loginSaga;
