import { takeEvery, put, all } from "redux-saga/effects";
import API from "../../utils/axios";

import {
  GET_ADDRESSES,
  GET_ADDRESSES_FAILURE,
  GET_ADDRESSES_SUCCESS,
  GET_COUNTRY_LIST,
  GET_COUNTRY_LIST_FAILURE,
  GET_COUNTRY_LIST_SUCCESS,
  GET_PRODUCTS,
  GET_PRODUCTS_FAILURE,
  GET_PRODUCTS_SUCCESS,
  GET_QUANTITY,
  GET_QUANTITY_FAILURE,
  GET_QUANTITY_SUCCESS,
  SET_COUNTRY_LIST_LOADING,
  SET_LOADING,
  SAVE_SHIPMENT,
  SET_ADDRESS_LOADING,
  GET_CONTAINERS_SUCCESS,
  GET_CONTAINERS_FAILURE,
  GET_CONTAINERS,
  GET_DOCUMENTS_SUCCESS,
  GET_DOCUMENTS_FAILURE,
  GET_DOCUMENTS,
  GET_PRODUCT_TYPE_SUCCESS,
  GET_PRODUCT_TYPE_FAILURE,
  GET_PRODUCT_TYPE,
  // GET_MANDATORY_FIELDS_FAILURE,
  // GET_MANDATORY_FIELDS_SUCCESS,
  // GET_MANDATORY_FIELDS,
  GET_ALL_ADDRESSES,
  GET_ALL_ADDRESSES_FAILURE,
  GET_ALL_ADDRESSES_SUCCESS,
  SET_ALL_ADDRESS_LOADING,
} from "./actionTypes";

function* getCountryListSaga() {
  try {
    yield put({ type: SET_COUNTRY_LIST_LOADING });
    const { data } = yield API.get("/1/countries");
    yield put({ type: GET_COUNTRY_LIST_SUCCESS, payload: data.value });
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_COUNTRY_LIST_FAILURE,
      payload: { errorMessage: "Unable to get country list" },
    });
  }
}

function* getDocumentSaga() {
  try {
    yield put({ type: SET_LOADING });
    const { data } = yield API.get(`/1/shipment-utility/documents`);
    yield put({ type: GET_DOCUMENTS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: GET_DOCUMENTS_FAILURE,
      errorMessage: "Unable to get documents",
    });
  }
}

function* getProductsSaga({ payload }) {
  try {
    yield put({ type: SET_LOADING });
    const { data } = yield API.get(
      `/1/shipment/organizations/${payload}/products`
    );
    yield put({ type: GET_PRODUCTS_SUCCESS, payload: data.value });
  } catch (error) {
    yield put({
      type: GET_PRODUCTS_FAILURE,
      errorMessage: "Unable to get Products",
    });
  }
}

// {v}/shipment-utility/organizations/{organizationId}/product-type
function* getProductTypeSaga({ payload }) {
  try {
    yield put({ type: SET_LOADING });
    const { data } = yield API.get(
      `/1/shipment-utility/organizations/${payload}/product-type`
    );
    yield put({ type: GET_PRODUCT_TYPE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: GET_PRODUCT_TYPE_FAILURE,
      errorMessage: "Unable to get pruduct type list",
    });
  }
}

// /shipment-utility/organizations/{organizationId}/container-type
function* getContainerListSaga({ payload }) {
  try {
    yield put({ type: SET_LOADING });
    const { data } = yield API.get(
      `/1/shipment-utility/organizations/container-type`
    );
    yield put({ type: GET_CONTAINERS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: GET_CONTAINERS_FAILURE,
      errorMessage: "Unable to get container List",
    });
  }
}

function* getQuantitiesSaga() {
  try {
    yield put({ type: SET_LOADING });
    const { data } = yield API.get("/1/shipment/products/metrics");
    yield put({ type: GET_QUANTITY_SUCCESS, payload: data.value });
  } catch (error) {
    yield put({
      type: GET_QUANTITY_FAILURE,
      errorMessage: "Unable to get Quantity",
    });
  }
}

function* getAllAddressesSaga({ organizationId }) {
  try {
    yield put({ type: SET_ALL_ADDRESS_LOADING });
    const { data } = yield API.get(
      `/1/shipment/organizations/${organizationId}/addresses`
    );
    yield put({
      type: GET_ALL_ADDRESSES_SUCCESS,
      payload: data.value,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_ALL_ADDRESSES_FAILURE,
      errorMessage: "Unable to get Addresses",
      error: error,
    });
  }
}

function* getAddressesSaga({ country, organizationId, addressType }) {
  try {
    yield put({ type: SET_ADDRESS_LOADING, payload: addressType });
    const { data } = yield API.get(
      `/1/shipment/organizations/${organizationId}/addresses`
    );
    yield put({
      type: GET_ADDRESSES_SUCCESS,
      payload: data.value,
      addressType,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_ADDRESSES_FAILURE,
      errorMessage: "Unable to get Addresses",
      error: error,
    });
  }
}

function* saveShipmentSaga({ data: shippingData }) {
  try {
    yield put({ type: SET_LOADING });
    const { data } = yield API.post("/1/shipment", shippingData);
    console.log(data);
  } catch (error) {
    console.log(error);
  }
}

// https://app-cus-myfracht-api-qa-001.azurewebsites.net/api/1/organizationsetting/DATNET_US/shipment
// function* getMandatoryFieldSaga({ payload }) {
//   try {
//     yield put({ type: SET_LOADING });
//     const { data } = yield API.get(
//       `/1/organizationsetting/${payload}/shipment`
//     );
//     yield put({ type: GET_MANDATORY_FIELDS_SUCCESS, payload: data });
//   } catch (error) {
//     yield put({
//       type: GET_MANDATORY_FIELDS_FAILURE,
//       errorMessage: "Unable to get mandatory fields",
//     });
//   }
// }

// function* getMandatoryFieldSaga({ payload, type }) {
//   const { fieldSpecificMandatory, organizationId } = payload;
//   try {
//     yield put({ type: SET_LOADING });
//     const { data } = yield API.get(
//       `/1/organizationsetting/${organizationId}/shipment`
//     );
//     yield put({
//       type: GET_MANDATORY_FIELDS_SUCCESS,
//       payload: { data, fieldSpecificMandatory },
//     });
//   } catch (error) {
//     yield put({
//       type: GET_MANDATORY_FIELDS_FAILURE,
//       errorMessage: "Unable to get mandatory fields",
//     });
//   }
// }

function* CountryListSaga() {
  yield all([
    yield takeEvery(GET_COUNTRY_LIST, getCountryListSaga),
    yield takeEvery(GET_DOCUMENTS, getDocumentSaga),
    yield takeEvery(GET_PRODUCTS, getProductsSaga),
    yield takeEvery(GET_PRODUCT_TYPE, getProductTypeSaga),
    yield takeEvery(GET_CONTAINERS, getContainerListSaga),
    yield takeEvery(GET_QUANTITY, getQuantitiesSaga),
    yield takeEvery(GET_ALL_ADDRESSES, getAllAddressesSaga),
    yield takeEvery(GET_ADDRESSES, getAddressesSaga),
    yield takeEvery(SAVE_SHIPMENT, saveShipmentSaga),
    // yield takeEvery(GET_MANDATORY_FIELDS, getMandatoryFieldSaga),
  ]);
}

export default CountryListSaga;
