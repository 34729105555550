import React, { useEffect, useState } from "react";
import ShipmentSearch from "../../components/FrankeOM/ShipmentSearch";
import API from "../../utils/axios";
import PODetails from "../../components/FrankeOM/PODetails";
import POgrid from "../../components/FrankeOM/POgrid";
import Loader from "../../components/UI/Loader/Loader";
import { useSelector } from "react-redux";
import Toast from "../../components/UI/Toast/Toast";
import { Helmet } from "react-helmet";
import FrachtStatusCard from "../../commonControls/frachtCardFilter";
import { statusObjPO } from "../../constants/enum";
import { Grid } from "@mui/material";

const POManagement = () => {
  const [dataLoading, setDataLoading] = useState(false);
  const { organizationId } = useSelector((state) => state.LoginUser);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogType, setDialogType] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [statusCard, setStausCard] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [formData, setFormData] = useState({
    containerNumber: "",
    pONumber: 0,
    consoleNumber: "",
  });
  const [poData, setPOData] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [rows, setRows] = useState([]);
  const [noDataText, setnoDataText] = useState(
    "Please enter a shipment id to search."
  );
  const [poResp, setPOResp] = useState();
  const [selectedCard, setSelectedCard] = useState([]);

  const handleSearch = () => {
    setDataLoading(true);
    API.get(
      `/1/generic-po/organization/${organizationId}/shipment/${searchValue}/details`
    )
      .then((resp) => {
        setDataLoading(false);
        const { containers, ...newResp } = resp?.data?.value;
        if (containers == null) {
          setnoDataText("No data is available for the entered shipment id");
          setToastType(false);
        } else {
          newResp.containers = containers.map((container) => {
            const { items, ...restContainer } = container;
            return restContainer;
          });
          setPOResp(newResp);

          setFormData({
            ...formData,
            containerNumber: containers[0]?.containerNumber || "",
            pONumber: containers[0]?.purchaseOrderCount,
            consoleNumber: resp?.data?.value?.consoleNumber,
          });
          const itemsData = containers
            ?.flatMap((container) => container.items)
            .map((item) => ({
              ...item,
            }));
          setRows(itemsData);
          setPOData(itemsData);
          let tempCardData = [];
          if (itemsData.length > 0) {
            statusObjPO.filter((ele, index) => {
              const rowDetail = itemsData.filter((item) => {
                if (item.status === ele.status) {
                  return item;
                }
              });
              let obj = {
                status: ele.status,
                label: ele.label,
                count: rowDetail.length,
                isSelected: true,
                cardSelected: false,
                index: index,
              };
              tempCardData.push(obj);
            });
            setStausCard(tempCardData);
          }
        }
      })
      .catch((error) => {
        console.log("generic-po error", error);
        setToastMessage(
          "Something went wrong at server. Please contact administrator"
        );
        setDataLoading(false);
        setToastType(false);
      });
  };

  const handleSearchClear = () => {
    setFormData({
      containerNumber: "",
      pONumber: "",
      consoleNumber: "",
    });
    setGridData([]);
    setSearchValue("");
    setnoDataText("Please enter a Shipment ID to search.");
    setDialogType(false);
    setDialogMessage("");
    setDisabled(true);
    setStausCard([]);
    setPOData([]);
    setSelectedCard([]);
  };

  const handlePostPOData = () => {
    poResp?.containers?.forEach((container) => {
      container.items = [...gridData];
    });
    setDataLoading(true);
    API.post(`1/genericposhipmentreceive`, poResp)
      .then((resp) => {
        if (resp.status === 200 || resp.status === 201) {
          handleSearchClear();
          setToastMessage("Receipt Created Successfully");
          setToastType(true);
        } else {
          setToastType(false);
          setToastMessage(
            "Something went wrong at server. Please contact administrator"
          );
          setDialogMessage("");
        }
        setDataLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setToastMessage(
          "Something went wrong at server. Please contact administrator"
        );
        setDataLoading(false);
        setToastType(false);
        setDialogType(false);
        setDialogMessage("");
      });
  };

  const isAnyValuePresent = Object.values(formData).some((value) => !!value);

  const handleCardClick = (arg) => {
    const selectedIndex = selectedCard?.findIndex((card) => card === arg);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedCard, arg);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedCard.slice(1));
    } else if (selectedIndex === selectedCard.length - 1) {
      newSelected = newSelected.concat(selectedCard.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedCard.slice(0, selectedIndex),
        selectedCard.slice(selectedIndex + 1)
      );
    }
    setSelectedCard(newSelected);
  };

  useEffect(() => {
    if (selectedCard.length > 0) {
      let myRows = [...poData];
      let filter = [];
      selectedCard.forEach((item) => {
        const data = myRows.filter((ele) => {
          if (ele.status === item.status) {
            return ele;
          }
        });
        filter = [...filter, ...data];
      });
      setRows(filter);
    } else {
      setRows([...poData]);
    }
  }, [selectedCard]);

  return (
    <>
      {dataLoading && <Loader />}
      <Helmet>
        <title>PO Management</title>
      </Helmet>
      <Toast
        open={Boolean(toastMessage)}
        message={toastMessage}
        handleClose={() => setToastMessage("")}
        success={toastType}
      />
      <Grid container>
        <Grid item xs={12}>
          <ShipmentSearch
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            handleSearch={handleSearch}
            handleSearchClear={handleSearchClear}
          />
        </Grid>
        <Grid item xs={12}>
          {isAnyValuePresent && (
            <>
              <PODetails formData={formData} />
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <FrachtStatusCard
            cardData={statusCard}
            handleCardClick={(card) => {
              handleCardClick(card);
            }}
            selectedCard={selectedCard}
          />
        </Grid>
        <Grid item xs={12} style={{ marginTop: "20px" }}>
          <POgrid
            rows={rows}
            gridData={gridData}
            setGridData={setGridData}
            noDataText={noDataText}
            dataLoading={dataLoading}
            setnoDataText={setnoDataText}
            handlePostPOData={handlePostPOData}
            dialogMessage={dialogMessage}
            setDialogMessage={setDialogMessage}
            dialogType={dialogType}
            setDialogType={setDialogType}
            disabled={disabled}
            setDisabled={setDisabled}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default POManagement;
