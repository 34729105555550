import React from "react";
import POHistory from "../../containers/FrankeOM/POHistory";
import POManagement from "../../containers/FrankeOM/POManagement";

const POTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`POTabPanel${index}`}
      aria-labelledby={`poTabs${index}`}
      {...other}
    >
      {(() => {
        switch (index) {
          case 0:
            return <POManagement />;

          case 1:
            return <POHistory />;

          default:
            return "2";
        }
      })()}
    </div>
  );
};

export default POTabPanel;
