import React, { useEffect, useState } from "react";

import PieChart, { Series, Label, Connector } from "devextreme-react/pie-chart";
import { useDispatch, useSelector } from "react-redux";
import "./analog-clock.css";
import moment from "moment";
import { CircularProgress } from "@mui/material";
import { getCountryShipmentCount } from "../../../redux/widgets/action";

export function CountryShipmentCount() {
  const [pieData, setPieData] = useState([]);
  const { organizationId } = useSelector((state) => state.LoginUser);
  const [isLoading, setisLoading] = useState(true);

  const { countryShipmentCount } = useSelector((state) => state.WidgetsReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (organizationId && countryShipmentCount === null)
      dispatch(getCountryShipmentCount(organizationId, moment().year()));
  }, [organizationId]);

  useEffect(() => {
    if (countryShipmentCount) {
      setPieData(countryShipmentCount);
      setisLoading(false);
    }
  }, [countryShipmentCount]);

  return (
    <>
      {isLoading && <CircularProgress />}
      {pieData?.length === 0 && isLoading === false && (
        <div className="noData">
          <div className="countryName">
            No data available to generate the Report
          </div>
        </div>
      )}
      {pieData?.length > 0 && (
        <PieChart
          id="pie"
          dataSource={pieData}
          title=""
          style={{ height: "250px" }}
        >
          <Series argumentField="country" valueField="count">
            <Label visible={true}>
              <Connector visible={true} width={1} />
            </Label>
          </Series>
        </PieChart>
      )}
    </>
  );
}
