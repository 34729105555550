import React, { lazy, useEffect, useState } from "react";
import SidemenuToogleContext from "../../context/SidemenuToogleContext";
import { useLocation } from "react-router-dom";
import FrachtSuspense from "../../commonControls/FrachtSuspense";
import Loader from "../UI/Loader/Loader";
import { useSelector } from "react-redux";

const Appbar = lazy(() => import("./Appbar/Appbar"));
const Sidebar = lazy(() => import("./Sidebar/Sidebar"));
const DashboardTabBar = lazy(() => import("./Appbar/dashboardTabBar"));

const Layout = ({ children }) => {
  const location = useLocation();
  const [showDashboardAppBar, setshowDashboardAppBar] = useState(false);
  const { menuVisibilityLoading } = useSelector(
    (state) => state.MenuVisibility
  );
  const { loading } = useSelector((state) => state.LoginUser);
  useEffect(() => {
    if (
      location.pathname === "/dashboard" ||
      location.pathname === "/" ||
      location.pathname === "/dashboard/" ||
      location.pathname === "/widgetDashboard/" ||
      location.pathname === "/widgetDashboard"
    ) {
      setshowDashboardAppBar(true);
    } else {
      setshowDashboardAppBar(false);
    }
  }, [location?.pathname]);

  return (
    <>
      {loading && <Loader />}
      <SidemenuToogleContext>
        <FrachtSuspense>
          <div>
            <div
              style={{
                display: "flex",
              }}
            >
              <Sidebar />
              <div
                style={{
                  width: "100%",
                  height: "100vh",
                  overflow: "auto",
                  backgroundColor: "#F5F5F5",
                }}
              >
                {showDashboardAppBar ? (
                  <DashboardTabBar page="dashboard" />
                ) : (
                  <Appbar />
                )}
                {menuVisibilityLoading ? (
                  <Loader />
                ) : (
                  <div style={{ padding: "2px 10px 0px 20px" }}>{children}</div>
                )}
              </div>
            </div>
          </div>
        </FrachtSuspense>
      </SidemenuToogleContext>
    </>
  );
};

export default Layout;
