import {
  SET_NOTIFICATION_LOADING,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  GET_WORK_REQUEST_DATA_SUCCESS,
  GET_WORK_REQUEST_DATA_FAILURE,
} from "./actionTypes";

const initialState = {
  loading: false,
  notifications: [],
  workRequestData: [],
  error: false,
  errorMessage: "",
};

const Notifications = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATION_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };

    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        notifications: action.payload,
      };
    case GET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.errorMessage,
      };

    case GET_WORK_REQUEST_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        workRequestData: action.payload,
      };
    case GET_WORK_REQUEST_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.errorMessage,
      };
    default:
      return state;
  }
};

export default Notifications;
