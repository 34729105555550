import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Badge,
  Button,
  Chip,
  Grid,
  Input,
  InputAdornment,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import "react-datepicker/dist/react-datepicker.css";
import SearchIcon from "../assets/images/SearchIcon.png";
import CachedIcon from "@mui/icons-material/Cached";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FractDateTimeControl from "./fractDateTimeControl";
import FrachtCheckBox from "./FrachtCheckBox";
import TableViewIcon from "@mui/icons-material/TableView";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import CustomColumnChooser from "./devExGrid/customColumnChooser";
import TuneIcon from "@mui/icons-material/Tune";
import FrachtGeneralDialogBox from "./frachtGeneralDialogBox";
import LegendToggleIcon from "@mui/icons-material/LegendToggle";
import { Popover } from "devextreme-react";
const useStyle = makeStyles((theme) => ({
  showText: {
    color: theme.palette.color.white,

    left: "30px",
    position: "relative",
  },

  showLoading: {
    color: theme.palette.color.white,
    paddingLeft: "40px",
    position: "relative",
  },
  applyButton: {
    height: "38px",
    backgroundColor: `${theme.palette.color.skyBlue} !important`,
    color: `${theme.palette.color.white} !important`,
    ...theme.typography.body22,
    border: "0.5px solid #CFD4D9",
    boxShadow: "0px 2px 7px #00000029",
    borderRadius: "4px",
    textTransform: "capitalize",
    opacity: 1,
    "&:hover": {
      backgroundColor: `${theme.palette.color.white} !important`,
      color: `${theme.palette.color.skyBlue} !important`,
      border: `1px solid ${theme.palette.color.skyBlue} !important`,
      opacity: 1,
    },
  },
  disabledButton: {
    ...theme.typography.body2,
    height: "38px",
    backgroundColor: `${theme.palette.color.grayS6} !important`,
    border: `1px solid ${theme.palette.color.grayS7} !important`,
    color: `${theme.palette.color.lightGrayishBlueB3} !important`,
    boxShadow: "0px 2px 7px #00000029",
    borderRadius: "4px",
    textTransform: "capitalize",
    marginRight: "10px",
    cursor: "default",
    opacity: 1,
  },
  btnContainer: {
    alignItems: "center",
    justifyContent: "end",
    margin: "0 8px 0 0",
  },
  filterContainer: {
    height: "55px",
    backgroundColor: theme.palette.color.dimblack,
    borderRadius: "6px",
    justifyContent: "space-between",
  },
  searchIcon: {
    position: "relative",
    left: "5px",
    top: "-1px",
  },
  searchInput: {
    margin: "8px 16px 8px 24px",
    width: "340px",
    // width: "auto",
    height: "38px",
    boxShadow: "1px 2px 6px #0000000D",
    borderRadius: "6px",
    backgroundColor: theme.palette.color.white,
    border: "none",
    position: "inherit",
    paddingLeft: "4px",
    ...theme.typography.body2,
    "&::placeholder": {
      ...theme.typography.body2,
      color: theme.palette.color.lightGrayishBlueB3,
      opacity: 1,
    },
    "&:focus-within ": {
      outline: "none",
    },

    "& .MuiInputBase-root": {
      padding: "5px 0px",
    },
    "& .MuiInputBase-input.Mui-disabled": {
      backgroundColor: theme.palette.color.grayS6,
    },
  },
  refreshIcon: {
    marginTop: "10px !important",
    left: "10%",
    position: "relative",
    color: "#fff",
  },
  fileDownloadIcon: {
    marginTop: "10px !important",
    left: "10%",
    position: "relative",
    color: "#fff",
  },
}));

const FrachtSearchControl = ({
  showText = "",
  searchValue,
  onSearchChange,
  dateValue,
  handleSearchClear,
  textId = "history_search",
  placeholder,
  datePlaceHolder,
  actionButtonText = "Action Button",
  handelActionButtonClick,
  actionButtonDisabled = false,
  handleRefresh,
  handleDownloadExcelData,
  showDateFilter = false,
  showActionButton = false,
  showRefreshButton = false,
  showDownloadButton = false,
  onDateChange,
  btnStyle = {},
  showCheckBox = false,
  checkBoxValue,
  changeHandler,
  dataTestIdCheckBox,
  showSearchFilter = true,
  checkBoxComponent = false,
  refreshId = "refreshIcon",
  dwldId = "downloadIcon",
  clrId = "clearButton",
  background = "#3F4443",
  showGridViews = false,
  showColumnChooser = false,
  gridColums = [],
  showLoadingMsg = false,
  setColums = () => {},
  handleGridViews = () => {},
  handleSaveView = () => {},
  appliedFilters = [],
  showViewFilters = false,
  showLegend = false,
  ...rest
}) => {
  const classes = useStyle();
  const searchControlRef = useRef(null);
  const [, setselectedDate] = useState("");
  const [visible, setVisible] = useState(false);
  const [searchText, setsearchText] = useState("");

  useEffect(() => {
    setsearchText(searchValue);
  }, [searchValue]);

  const onHiding = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const onApply = useCallback(
    (changes) => {
      setColums(changes);
      setVisible(false);
    },
    [setColums, setVisible]
  );

  useEffect(() => {
    const searchInput = searchControlRef.current;
    if (searchInput) {
      searchInput.addEventListener("input", (e) => {
        const inputValue = e.target.value;
        if (!inputValue) {
          setsearchText("");
          handleSearchClear();
        }
      });
    }
  }, [searchControlRef]);

  const handelSearchChage = (e) => {
    const { value } = e.target;
    setsearchText(value);
  };

  useEffect(() => {
    if (searchText && searchText.length >= 3) {
      const delayInputTimeoutId = setTimeout(() => {
        onSearchChange(searchText);
      }, 500);
      return () => clearTimeout(delayInputTimeoutId);
    }
  }, [searchText, 500]);

  const handelDateChange = (value) => {
    setselectedDate(value);
    onDateChange(value);
    onSearchChange(searchValue);
  };

  useEffect(() => {
    setselectedDate(dateValue);
  }, [dateValue]);

  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const handelViewFilterClick = () => {
    setShowFilterDialog(true);
  };

  return (
    <>
      <Grid
        container
        className={classes.filterContainer}
        style={{ background: background }}
      >
        <Grid item xs={7} container alignItems="center" direction="row">
          <Grid> {checkBoxComponent && <>{rest.children}</>}</Grid>
          {showSearchFilter && (
            <Grid>
              <Grid item xs={showCheckBox || showDateFilter ? 4.5 : 9}>
                <Input
                  className={classes.searchInput}
                  placeholder={searchText ? "" : placeholder}
                  type="search"
                  value={searchText}
                  disabled={showLoadingMsg}
                  onChange={(e) => {
                    handelSearchChage(e);
                  }}
                  data-testid="search"
                  startAdornment={
                    <InputAdornment position="start">
                      <img
                        src={SearchIcon}
                        alt="SearchIcon"
                        className={classes.searchIcon}
                      />
                    </InputAdornment>
                  }
                  id={textId}
                  ref={searchControlRef}
                />
              </Grid>
            </Grid>
          )}
          {showText && (
            <Grid>
              <Typography variant="body22" className={classes.showText}>
                {showText}
              </Typography>
            </Grid>
          )}

          {showLoadingMsg && (
            <Grid>
              <Typography
                className={
                  Boolean(showText) ? classes.showLoading : classes.showText
                }
              >
                Loading data, please wait…
              </Typography>
            </Grid>
          )}
          {showCheckBox && (
            <Grid item xs={3.5} style={{ marginLeft: "45px" }}>
              <FrachtCheckBox
                changeHandler={changeHandler}
                checked={checkBoxValue}
                label="Include Inactive"
                dataTestId={dataTestIdCheckBox}
                theme="light"
              />
            </Grid>
          )}

          {showDateFilter && (
            <Grid
              item
              xs={3}
              style={{ marginLeft: "45px", marginBottom: "5px" }}
            >
              <FractDateTimeControl
                value={dateValue}
                onChange={handelDateChange}
                placeholder={datePlaceHolder}
                showTimeSelect={false}
                dateFormat="MM/dd/yyyy"
              />
            </Grid>
          )}
        </Grid>
        <Grid
          item
          xs={4}
          container
          className={classes.btnContainer}
          direction="row"
        >
          {showLegend && (
            <Grid id="legendPopOver">
              <LegendToggleIcon className={classes.refreshIcon} />
            </Grid>
          )}
          <Popover
            target="#legendPopOver"
            showEvent="mouseenter"
            hideEvent="mouseleave"
            position="bottom"
            width={300}
            showTitle={true}
            title="Legend"
          >
            {rest.children}
          </Popover>
          {showViewFilters && (
            <Grid onClick={handelViewFilterClick}>
              <Tooltip
                title="View Applied Filters"
                followCursor
                placement="top"
              >
                <Badge badgeContent={appliedFilters.length} color="primary">
                  <TuneIcon className={classes.refreshIcon} />
                </Badge>
              </Tooltip>
            </Grid>
          )}
          {showGridViews && (
            <>
              <Grid style={{ margin: "0 0 0 5px", cursor: "pointer" }}>
                <Tooltip title="Show Views" followCursor placement="top">
                  <TableViewIcon
                    data-testid="ShowViews"
                    className={classes.refreshIcon}
                    onClick={() => handleGridViews()}
                    id={"showViews"}
                  />
                </Tooltip>
              </Grid>
              <Grid style={{ margin: "0 0 0 5px", cursor: "pointer" }}>
                <Tooltip title="Save View" followCursor placement="top">
                  <SaveAsIcon
                    data-testid="Save View"
                    className={classes.refreshIcon}
                    onClick={() => handleSaveView()}
                    id={"saveView"}
                  />
                </Tooltip>
              </Grid>
            </>
          )}
          {showRefreshButton && (
            <Grid style={{ margin: "0 0 0 5px", cursor: "pointer" }}>
              <Tooltip title="Refresh Data" followCursor placement="top">
                <CachedIcon
                  data-testid={refreshId}
                  className={classes.refreshIcon}
                  onClick={() => handleRefresh()}
                  id={refreshId}
                />
              </Tooltip>
            </Grid>
          )}
          {showDownloadButton && (
            <Grid style={{ margin: "0 0 0 5px", cursor: "pointer" }}>
              <Tooltip title="Export Data" followCursor placement="top">
                <FileDownloadIcon
                  data-testid={dwldId}
                  className={classes.fileDownloadIcon}
                  onClick={() => handleDownloadExcelData()}
                  id={dwldId}
                />
              </Tooltip>
            </Grid>
          )}
          {showColumnChooser && (
            <Grid
              style={{ margin: "0 0 0 5px", cursor: "pointer" }}
              id="myColChooserDiv"
            >
              <Tooltip title="Choose Colum" followCursor placement="top">
                <ViewColumnIcon
                  data-testid="myColChooser"
                  className={classes.refreshIcon}
                  onClick={() => setVisible(true)}
                  id="myColChooser"
                />
              </Tooltip>
              <CustomColumnChooser
                container="#myColChooserDiv"
                button="#myColChooser"
                visible={visible}
                onHiding={onHiding}
                columns={gridColums}
                onApply={onApply}
              />
            </Grid>
          )}
          {showActionButton && (
            <Grid item style={{ margin: "0 0 0 5px" }}>
              <Button
                disabled={actionButtonDisabled}
                className={
                  actionButtonDisabled
                    ? classes.disabledButton
                    : classes.applyButton
                }
                onClick={() => {
                  setsearchText("");
                  handelActionButtonClick();
                }}
                id={clrId}
                style={btnStyle}
              >
                {actionButtonText}
              </Button>
            </Grid>
          )}
        </Grid>
        {appliedFilters.length > 0 && showViewFilters && (
          <FrachtGeneralDialogBox
            type={"info"}
            open={showFilterDialog}
            handleClose={() => {
              setShowFilterDialog(false);
            }}
            modalContent={""}
            actionButtonText="Okay"
          >
            {appliedFilters.filter((f) => f.type === "tiles").length > 0 && (
              <Typography>Tiles :</Typography>
            )}
            {appliedFilters.map((item, key) => {
              if (item.type === "tiles") {
                return (
                  <>
                    <Chip size="small" label={`${item.searchKey}`} />
                  </>
                );
              }
            })}
            {appliedFilters.filter((f) => f.type === "headerFilter").length >
              0 && <Typography>Header Filter :</Typography>}
            {appliedFilters.map((item, key) => {
              if (item.type === "headerFilter") {
                const label =
                  item.filterType === "exclude"
                    ? `${item.name} ≠ ${item.searchKey}`
                    : `${item.name} = ${item.searchKey}`;
                return <Chip key={key} size="small" label={label} />;
              }
            })}
            {appliedFilters.filter((f) => f.type === "columFilter").length >
              0 && <Typography>Colum Search :</Typography>}
            {appliedFilters.map((item, key) => {
              if (item.type === "columFilter") {
                return (
                  <Chip
                    size="small"
                    label={`${item.name} = ${item.searchKey}`}
                  />
                );
              }
            })}

            {appliedFilters.map((item, key) => {
              if (item.type === "view") {
                return (
                  <>
                    <Typography>View :</Typography>
                    <Chip
                      size="small"
                      label={`${item.name} : ${item.searchKey}`}
                    />
                  </>
                );
              }
            })}
            {appliedFilters.map((item, key) => {
              if (item.type === "widget") {
                return (
                  <>
                    <Typography>Widget :</Typography>
                    <Chip
                      size="small"
                      label={`${item.name} : ${item.searchKey}`}
                    />
                  </>
                );
              }
            })}
          </FrachtGeneralDialogBox>
        )}
      </Grid>
    </>
  );
};

export default FrachtSearchControl;
