export const SET_TRUCKING_LOADING = "SET_TRUCKING_LOADING";

export const GET_SERVICE_EQUIPMENTS_DETAIL = "GET_SERVICE_EQUIPMENTS_DETAIL";
export const GET_SERVICE_EQUIPMENTS_DETAIL_SUCCESS =
  "GET_SERVICE_EQUIPMENTS_DETAIL_SUCCESS";
export const GET_SERVICE_EQUIPMENTS_DETAIL_FAILURE =
  "GET_SERVICE_EQUIPMENTS_DETAIL_FAILURE";

export const GET_SERVICE_DETAIL = "GET_SERVICE_DETAIL";
export const GET_SERVICE_DETAIL_SUCCESS = "GET_SERVICE_DETAIL_SUCCESS";
export const GET_SERVICE_DETAIL_FAILURE = "GET_SERVICE_DETAIL_FAILURE";

export const GET_PRODUCT_NAME = "GET_PRODUCT_NAME";
export const GET_PRODUCT_NAME_SUCCESS = "GET_PRODUCT_NAME_SUCCESS";
export const GET_PRODUCT_NAME_FAILURE = "GET_PRODUCT_NAME_FAILURE";

export const GET_REF_TYPE = "GET_REF_TYPE";
export const GET_REF_TYPE_SUCCESS = "GET_REF_TYPE_SUCCESS";
export const GET_REF_TYPE_FAILURE = "GET_REF_TYPE_FAILURE";

export const GET_QUANTITY_LIST = "GET_QUANTITY_LIST";
export const GET_QUANTITY_LIST_SUCCESS = "GET_QUANTITY_LIST_SUCCESS";
export const GET_QUANTITY_LIST_FAILURE = "GET_QUANTITY_LIST_FAILURE";

export const SEARCH_TRUCK = "SEARCH_TRUCK";
export const SEARCH_TRUCK_SUCCESS = "SEARCH_TRUCK_SUCCESS";
export const SEARCH_TRUCK_FAILURE = "SEARCH_TRUCK_FAILURE";

export const QUOTE_CONFIRMATION = "QUOTE_CONFIRMATION";
export const QUOTE_CONFIRMATION_SUCCESS = "QUOTE_CONFIRMATION_SUCCESS";
export const QUOTE_CONFIRMATION_FAILURE = "QUOTE_CONFIRMATION_FAILURE";

export const RESET_REQUESTID = "RESET_REQUESTID";
export const RESET_REQUESTID_SUCCESS = "RESET_REQUESTID_SUCCESS";
export const RESET_REQUESTID_FAILURE = "RESET_REQUESTID_FAILURE";

export const SET_TRUCK_PAGE_DATA = "SET_TRUCK_PAGE_DATA";
export const RESET_TRUCK_PAGE_DATA = "RESET_TRUCK_PAGE_DATA";
