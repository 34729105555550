import { takeEvery, put, all } from "redux-saga/effects";
import API from "../../utils/axios";
import {
  SET_ROLES_LOADING,
  GET_RESOURCE_LIST,
  GET_RESOURCE_LIST_SUCCESS,
  GET_RESOURCE_LIST_FAILURE,
  GET_ROLE_DATA,
  GET_ROLE_DATA_SUCCESS,
  GET_ROLE_DATA_FAILURE,
} from "./actionTypes";

function* getResourceListSaga() {
  try {
    yield put({ type: SET_ROLES_LOADING });
    const { data } = yield API.get(`1/roles/resource`);
    yield put({ type: GET_RESOURCE_LIST_SUCCESS, payload: data });
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_RESOURCE_LIST_FAILURE,
      payload: { errorMessage: "Unable to get resource list" },
    });
  }
}

function* getRoleDataSaga({ id }) {
  try {
    yield put({ type: SET_ROLES_LOADING });
    const { data } = yield API.get(`/1/roles/${id}/role`);
    yield put({ type: GET_ROLE_DATA_SUCCESS, payload: data.value });
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_ROLE_DATA_FAILURE,
      payload: { errorMessage: "Unable to get roles data." },
    });
  }
}

function* RolesSaga() {
  yield all([yield takeEvery(GET_RESOURCE_LIST, getResourceListSaga)]);
  yield all([yield takeEvery(GET_ROLE_DATA, getRoleDataSaga)]);
}

export default RolesSaga;
