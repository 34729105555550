import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Series, Tooltip } from "devextreme-react/chart";
import { PieChart } from "devextreme-react";
import { CircularProgress } from "@mui/material";
import { getStatusCount } from "../../../redux/widgets/action";
const FrachtAxisStatusCount = () => {
  const [barData, setBarData] = useState([]);
  const { organizationId } = useSelector((state) => state.LoginUser);
  const [isLoading, setisLoading] = useState(true);
  const { statusWiseCount } = useSelector((state) => state.WidgetsReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (organizationId && statusWiseCount === null)
      dispatch(getStatusCount(organizationId));
  }, [organizationId]);

  useEffect(() => {
    if (statusWiseCount) {
      setBarData(statusWiseCount);
      setisLoading(false);
    }
  }, [statusWiseCount]);

  function customizePoint(point) {
    switch (point.argument) {
      case "Open":
        return { color: "#FFD05D" };
      case "Picked Up":
        return { color: "#5E76FD" };
      case "On-Board":
        return { color: "#83DBFF" };
      case "Arrived":
        return { color: "#BB88FF" };

      default:
        return { color: "#586ADD" };
    }
  }

  return (
    <div>
      {isLoading && <CircularProgress />}
      {barData?.length === 0 && isLoading === false && (
        <div className="noData">
          <div className="countryName">
            No data available to generate the Report
          </div>
        </div>
      )}
      {barData?.length > 0 && (
        <PieChart
          id="pie"
          dataSource={barData}
          title=""
          style={{ height: "250px" }}
          palette="Soft Pastel"
          paletteExtensionMode="extrapolate"
          customizePoint={customizePoint}
        >
          <Series argumentField="status" valueField="count"></Series>
          <Tooltip paddingLeftRight={10} paddingTopBottom={6} enabled={true} />
        </PieChart>
      )}
    </div>
  );
};

export default FrachtAxisStatusCount;

const statusCount = [
  {
    label: "Open",
    count: 0,
    status: "1",
  },
  {
    label: "Picked Up",
    count: 0,
    status: "3",
  },
  {
    label: "On-Board",
    count: 0,
    status: "5",
  },
  {
    label: "Arrived",
    count: 0,
    status: "7",
  },
];
