import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { officeAddresses } from "./timezone2-list";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import EmailIcon from "@mui/icons-material/Email";

export default function TooltipTemplate(info) {
  const name = info?.attribute("name");

  const marker = officeAddresses.find(
    (marker) => marker?.attributes.name === name
  );

  if (marker) {
    const officeAddress = marker;
    return (
      <Grid
        container
        style={{
          width: `${officeAddress?.address.length >= 90 ? "400px" : "350px"}`,
          height: "220px",
          // wordBreak: "break-all",
          whiteSpace: "wrap",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          border: "1px solid #183650",
          padding: "10px",
        }}
      >
        <Grid item xs={12}>
          <Typography
            style={{
              fontSize: "20px",
              fontWeight: "700",
              fontFamily: "Arial, Helvetica, sans-serif",
            }}
          >
            {officeAddress?.officeName}
          </Typography>

          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "900",
              marginBottom: "8px",
            }}
          >
            {officeAddress?.city}
          </Typography>

          <Typography
            style={{ fontSize: "14px", lineHeight: "1.5", whiteSpace: "wrap" }}
          >
            {officeAddress.address}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid
            style={{
              backgroundColor: "#183650",
              padding: "8px",
              bottom: "10px",
              top: "95%",
              position: "sticky",
            }}
          >
            <Stack direction="row">
              <PhoneRoundedIcon style={{ height: "20px", color: "white" }} />
              <Typography style={{ fontSize: "14px", color: "white" }}>
                {officeAddress?.contactNumber}
              </Typography>
            </Stack>
            <Stack direction="row">
              <EmailIcon style={{ height: "20px", color: "white" }} />
              <Typography style={{ fontSize: "14px", color: "white" }}>
                {officeAddress?.email}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
}
