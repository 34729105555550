// import PowerBIConsoleRedirect from "../containers/PowerBIRedirect/powerBIConsoleRedirect";
import Powerbiredirect from "../containers/PowerBIRedirect/Powerbiredirect";
import GridDemo from "../containers/test/test";

const publicRoutes = [
  {
    path: "/bi/:ControllerID/:BusinessEntityPK",
    exact: false,
    component: Powerbiredirect,
  },
  {
    path: "grid",
    exact: false,
    component: GridDemo,
  },
  // {
  //   path: "/bi/shipment/console/:query",
  //   exact: true,
  //   component: PowerBIConsoleRedirect,
  // },
];

export default publicRoutes;
