import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Chart, {
  CommonAxisSettings,
  Format,
  Label,
  Series,
} from "devextreme-react/chart";
import API from "../../../utils/axios";
import { CircularProgress, useStepContext } from "@mui/material";
import { getMostShippedContainerByOrigin } from "../../../redux/widgets/action";

const MostShippedContainer = ({ locationType }) => {
  const [barData, setBarData] = useState([]);
  const { organizationId } = useSelector((state) => state.LoginUser);
  const [isLoading, setisLoading] = useState(true);

  const { mostShippedOrigin, mostShippedDestination } = useSelector(
    (state) => state.WidgetsReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      organizationId &&
      mostShippedOrigin === null &&
      locationType === "orgin"
    )
      dispatch(getMostShippedContainerByOrigin(organizationId, 5));
  }, [organizationId]);
  useEffect(() => {
    if (
      organizationId &&
      mostShippedDestination === null &&
      locationType === "destination"
    )
      dispatch(getMostShippedContainerByOrigin(organizationId, 5));
  }, [organizationId]);

  useEffect(() => {
    if (mostShippedOrigin) {
      setBarData(mostShippedOrigin);
      setisLoading(false);
    }
  }, [mostShippedOrigin]);

  useEffect(() => {
    if (mostShippedDestination) {
      setBarData(mostShippedDestination);
      setisLoading(false);
    }
  }, [mostShippedDestination]);

  const customizePoint = useCallback((arg) => {
    return { color: "url(#Gradient2)", hoverStyle: { color: "#5168db" } };
  }, []);

  return (
    <>
      {isLoading && <CircularProgress />}
      {barData?.length === 0 && isLoading === false && (
        <>
          <div className="noData">
            <div className="countryName">
              No data available to generate the Report
            </div>
          </div>
        </>
      )}
      {barData.length > 0 && (
        <>
          <>
            <Chart
              id={`containerCount-${locationType}`}
              dataSource={barData}
              style={{ height: "250px" }}
              rotated={true}
              customizePoint={customizePoint}
            >
              <CommonAxisSettings grid={false} maxValueMargin="0.10" />
              <Series
                valueField="containerCount"
                argumentField="countryName"
                name={`container count by ${locationType}`}
                type="bar"
                color="#826AF9"
                barWidth={40}
              >
                <Label
                  visible={true}
                  backgroundColor="#9E86FF"
                  position="outside"
                >
                  <Format type="fixedPoint" precision={0} />
                </Label>
              </Series>
            </Chart>
          </>
          <svg className="svg-patterns">
            <defs>
              <linearGradient id="Gradient2" x1="0" x2="1" y1="0" y2="0">
                <stop offset="10%" stopColor="#F7D2FF" />
                <stop offset="100%" stopColor="#475FD9" />
              </linearGradient>
            </defs>
          </svg>
        </>
      )}
    </>
  );
};

export default MostShippedContainer;
