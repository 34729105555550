import React, { memo, useEffect, useMemo, useState } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

import makeStyles from "@mui/styles/makeStyles";
import DropArrowIcon from "../../assets/images/DropArrowIcon.png";
import { useDispatch, useSelector } from "react-redux";
import { addCardData, getResourceList } from "../../redux/actions";
import API from "../../utils/axios";
import Toast from "../UI/Toast/Toast";
import FrachtURLPopup from "../../commonControls/frachtURLPopup";
import FractAutoComplete from "../../commonControls/fractAutoComplete";
import { accessLevelEnum, permissionDropdown } from "../../constants/enum";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import theme from "../../theme";

const mapRes = new Map();

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginTop: "0px",
    height: "auto",
    overflow: "scroll",
    width: "calc(100% + 50px)",
  },
  gridItem: {
    padding: "0px 0px 0px 40px !important",
    "& .MuiPaper-root": {
      backgroundColor: "#FFFFFF",
    },
    "& div.MuiPaper-root:nth-of-type(even)": {
      backgroundColor: "#F7F7F7",
    },
  },
  accordian: {
    backgroundColor: theme.palette.color.secondary,
    boxShadow: "none",
  },
  accordianSummary: {
    flexDirection: "row-reverse",
    padding: "0px",
    minHeight: "auto !important",
    "& .MuiAccordionSummary-content": {
      margin: "0 !important",
    },
    "& .Mui-expanded": {
      border: "none",
      margin: "0px",
    },
    "& .MuiAccordionSummary-expandIconWrapper": {
      transform: "rotate(-90deg)",
      justifyContent: "center",
      alignItems: "center",
      height: "32px",
      width: "32px",
    },
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(0deg)",
    },
    "& img": {
      height: "20px",
      width: "20px",
    },
  },
  accordianDetails: {
    padding: "0px !important",
    margin: "0 0 0 4%",
    borderTop: "1px solid #CFD4D9",
  },
  pTypography: {
    ...theme.typography.body2,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0px",
    letterSpacing: "0.01px",
    color: theme.palette.color.cyanBlueC1,
    "& p": {
      letterSpacing: "0.01px",
      color: theme.palette.color.cyanBlueC1,
      ...theme.typography.body2,
    },
  },
  permissionDropdown: {
    fontSize: "14px",
    margin: "0px",
    fontWeight: "900",
    color: "DarkSlateGray",
    paddingLeft: "10px",
    cursor: "pointer",
    "&.Mui-focused": {
      background: "#EEEEEE !important",
    },
  },
}));

const RolesApplicationGrid = ({
  orgInfo,
  setOrgInfo,
  error,
  setError,
  isEdit = false,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.LoginUser);
  const { organizationList, analyticsCardList } = useSelector(
    (state) => state.EditOrganization
  );
  const [expanded, setExpanded] = useState(false);

  const { resourceList } = useSelector((state) => state.Role);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    resourceList.length === 0 && dispatch(getResourceList());
  }, [dispatch]);

  const resourceMenuList = useMemo(() => {
    mapRes.clear();

    if (resourceList.length > 0) {
      const subMenuArr = [];
      const finalArr = [];
      resourceList.forEach((ele) => {
        const { parentId, type } = ele;
        if (type === "Menu") mapRes.set(parentId, ele);

        if (type === "SubMenu") subMenuArr.push(ele);
      });
      subMenuArr.forEach((ele) => {
        const { parentId } = ele;
        const subMenuObj = mapRes.get(parentId);
        if (Array.isArray(subMenuObj))
          mapRes.set(parentId, [...subMenuObj, ele]);
        else mapRes.set(parentId, [subMenuObj, ele]);
      });

      for (const val of mapRes.values()) finalArr.push(val);

      return finalArr;
    }
    return [];
  }, [resourceList]);

  function childHasError(parentId) {
    return error?.permission.some((ele) => ele.parentId === parentId);
  }

  function getPermissionVal(type, resourceName) {
    if (!orgInfo || orgInfo?.permission?.length === 0) return "";

    const index = orgInfo.permission.filter(
      (ele) => ele.resourceName === resourceName && ele.type === type
    )[0]?.accessLevelId;

    return permissionDropdown[index - 1]?.name || "";
  }

  return (
    <Grid container spacing={5} className={classes.gridContainer}>
      <Grid item sm={12} className={classes.gridItem}>
        {resourceMenuList?.map((res, index) => {
          let resourceName = "";
          let resourceDisplayName = "";
          let type = "";
          let parentId = "";
          const childrenExist = Array.isArray(res);

          if (!childrenExist) {
            resourceName = res.name;
            resourceDisplayName = res.displayName;
            type = res.type;
            parentId = res.parentId;
          } else {
            const temp = res.filter((ele) => ele.type === "Menu")[0];
            resourceName = temp.name;
            resourceDisplayName = temp.displayName;
            type = temp.type;
            parentId = temp.parentId;
          }

          return (
            <Accordion
              id={resourceName}
              className={classes.accordian}
              expanded={
                (childrenExist && childHasError(parentId, resourceName)) ||
                (getPermissionVal(type, resourceName) !== "" &&
                  expanded === resourceName)
              }
              onChange={childrenExist && handleChange(resourceName)}
              key={index}
            >
              <AccordionSummary
                id={resourceName}
                key={index}
                className={classes.accordianSummary}
                aria-controls={resourceName}
                expandIcon={
                  <img
                    src={DropArrowIcon}
                    alt="DropArrowIcon"
                    id="DropArrowIcon"
                    style={{ visibility: !childrenExist && "hidden" }}
                  />
                }
              >
                <PermissionDropDown
                  id={resourceName}
                  index={index}
                  res={res}
                  resourceDisplayName={resourceDisplayName}
                  getPermissionVal={getPermissionVal}
                  type={type}
                  resourceName={resourceName}
                  orgInfo={orgInfo}
                  setOrgInfo={setOrgInfo}
                  parentId={parentId}
                  dispatch={dispatch}
                  organizationList={organizationList}
                  user={user}
                  isEdit={isEdit}
                />
              </AccordionSummary>
              {childrenExist &&
                res.map((child) => {
                  if (child.type === "SubMenu") {
                    const resourceName = child.name;
                    const resourceDisplayName = child.displayName;
                    const type = child.type;
                    const parentId = child.parentId;
                    const cardNameURL =
                      child?.name?.replace("Analytics", "") + "URL";

                    return (
                      <AccordionDetails className={classes.accordianDetails}>
                        <PermissionDropDown
                          id={resourceName}
                          index={index}
                          res={res}
                          resourceDisplayName={resourceDisplayName}
                          getPermissionVal={getPermissionVal}
                          type={type}
                          resourceName={resourceName}
                          orgInfo={orgInfo}
                          setOrgInfo={setOrgInfo}
                          parentId={parentId}
                          error={error}
                          setError={setError}
                          organizationList={organizationList}
                          dispatch={dispatch}
                          analyticsCardList={analyticsCardList}
                          cardNameURL={cardNameURL}
                          user={user}
                          isEdit={isEdit}
                        />
                      </AccordionDetails>
                    );
                  } else return null;
                })}
            </Accordion>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default memo(RolesApplicationGrid);

const PermissionDropDown = ({
  id,
  index,
  res,
  resourceDisplayName,
  getPermissionVal,
  type,
  resourceName,
  orgInfo,
  setOrgInfo,
  parentId,
  error = {},
  setError,
  organizationList,
  dispatch,
  analyticsCardList,
  cardNameURL,
  user,
  isEdit,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const encodedOrgId = btoa(orgInfo?.organizationId);
  // URL dialog box
  const [showURLPopup, setShowURLPopup] = useState(false);
  const [showNextField, setShowNextField] = useState(false);
  const [powerBIURL, setPowerBIURL] = useState("");
  const [orgData, setOrgData] = useState([]);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  const permissionError = error?.permission || [];

  function getColorForDropDown(option) {
    switch (option) {
      case "Hidden":
        return "PowderBlue";

      case "Disable (View & Read)":
        return "#c8f0f0";

      case "Enable (View & Edit)":
        return "Azure";

      default:
        return theme.palette.color.white;
    }
  }

  // Effect to set default organization if orgInfo.organizationId is provided
  useEffect(() => {
    if (showURLPopup && analyticsCardList?.length > 0) {
      const matchingCard = analyticsCardList?.find(
        (card) => card.analyticsCard === cardNameURL
      );

      if (matchingCard) {
        const { organization, analyticsCardURL } = matchingCard;
        setPowerBIURL(analyticsCardURL);
        setOrgData(organization);
      }
    } else if (orgInfo && orgInfo?.organizationId) {
      const matchingCard = orgInfo?.analyticsBIUrl?.find(
        (card) => card.analyticsCard === cardNameURL
      );
      if (matchingCard) {
        const { organization, analyticsCardURL } = matchingCard;
        setPowerBIURL(analyticsCardURL);
        setOrgData(organization);

        const payload = {
          organization: organization,
          analyticsCard: cardNameURL,
          analyticsCardURL: analyticsCardURL,
        };
        dispatch(addCardData(payload));
      } else {
        const defaultOrg = organizationList?.find(
          (org) => org.organizationId === orgInfo.organizationId
        );
        if (defaultOrg) {
          setOrgData([defaultOrg]);
        }
        setPowerBIURL("");

        const payload = {
          organization: [defaultOrg],
          analyticsCard: cardNameURL,
          analyticsCardURL: "",
        };
        dispatch(addCardData(payload));
      }
    } else return;
  }, [orgInfo, organizationList, analyticsCardList]);

  // power BI URL popup POST API: api/1/analytics
  const handleBIDataSubmit = () => {
    const simplifiedOrgData = orgData?.map((org) => ({
      organizationId: org.organizationId,
      organizationName: org.organizationName,
    }));

    const payload = {
      organization: simplifiedOrgData,
      analyticsCard: cardNameURL,
      analyticsCardURL: powerBIURL?.trim(),
    };
    setDataLoading(true);

    API.post("/1/analytics", payload)
      .then((resp) => {
        if (resp.status === 201 || resp.status === 200) {
          setToastMessage("URL updated successfully");
          setDataLoading(false);
          setToastType(true);
          dispatch(addCardData(payload));
          setShowURLPopup(false);
          setShowNextField(false);
        }
      })
      .catch((error) => {
        console.log("URL update error", error);
        setDataLoading(false);
      });
  };
  return (
    <>
      <Toast
        open={Boolean(toastMessage)}
        message={toastMessage}
        handleClose={() => setToastMessage("")}
        success={toastType}
      />
      <FrachtURLPopup
        open={showURLPopup}
        handleClose={() => {
          setShowURLPopup(false);
          setShowNextField(false);
          setPowerBIURL("");
          setOrgData([]);
        }}
        displayName={resourceDisplayName}
        organizationList={organizationList}
        showNextField={showNextField}
        setShowNextField={setShowNextField}
        powerBIURL={powerBIURL}
        setPowerBIURL={setPowerBIURL}
        orgInfo={orgInfo}
        orgData={orgData}
        setOrgData={setOrgData}
        handleBIDataSubmit={handleBIDataSubmit}
        loading={dataLoading}
      />
      <Box key={index + res.id} className={classes.pTypography}>
        <Box>
          <Typography key={index}>{resourceDisplayName}</Typography>
        </Box>
        <FractAutoComplete
          key={index}
          id={id}
          label="Permission"
          name={id}
          options={permissionDropdown || []}
          selectedValue={getPermissionVal(type, resourceName)}
          getOptionLabel={(option) => {
            if (option.name) return option.name;
            else if (option) return option;
            else return "";
          }}
          onChange={(e, value) => {
            e.preventDefault();
            e.stopPropagation();

            const temp = {
              accessLevelId: accessLevelEnum[value?.name.split("(")[0].trim()],
              resourceDisplayName: resourceDisplayName,
              resourceName: resourceName,
              type: type,
              parentId: parentId,
            };

            let indexToChange;

            //for organisation's with as null, index won't exist hence -1.
            if (!orgInfo) indexToChange = -1;
            else {
              //finding the index to modify from existing or new added data
              indexToChange = orgInfo.permission.findIndex(
                (ele) =>
                  ele.resourceName === temp.resourceName &&
                  ele.type === temp.type
              );
            }

            if (user?.isSuperAdmin) {
              if (
                isEdit &&
                temp.resourceName.includes("Analytics") &&
                !temp.resourceName.includes("WorkInProgress") &&
                temp.accessLevelId === "3" &&
                temp.type === "SubMenu"
              ) {
                setShowURLPopup(true);
              }
            }

            //permission won't exist for old records hence assigning [] else spreading
            const tempPermissionObj = orgInfo ? [...orgInfo.permission] : [];

            // case when creating new org or editing records which already have some roles.
            if (indexToChange > -1) {
              // accessLevelId becomes undefined when the permission dropdown is cleared
              if (temp.accessLevelId === undefined) {
                tempPermissionObj.splice(indexToChange, 1);
              } else tempPermissionObj[indexToChange] = temp;
            } else {
              // when no records exist against permission
              tempPermissionObj[orgInfo ? orgInfo.permission.length : 0] = temp;
            }

            const tempRoleObj = {
              name: orgInfo?.name || "",
              organizationId: orgInfo.organizationId || "",
              hierarchy: 3,
            };

            setOrgInfo({
              ...orgInfo,
              ...tempRoleObj,
              permission: [...tempPermissionObj],
            });

            type === "SubMenu" &&
              setError({
                ...error,
                orgInfo: {
                  ...error,
                  permission: permissionError.filter(
                    (ele) => ele.resourceName !== resourceName
                  ),
                },
              });
          }}
          error={permissionError.some((ele) =>
            ele.type === "SubMenu" && ele.resourceName === resourceName
              ? true
              : false
          )}
          style={{
            width: "250px",
            margin: "10px 20px 20px 0",
          }}
          //used to disable padding in the dropdown ul
          ListboxProps={{
            style: { padding: "0px" },
          }}
          renderOption={(props, option) => (
            <Box
              component="li"
              {...props}
              style={{
                background: getColorForDropDown(option.name),
              }}
              className={classes.permissionDropdown}
            >
              {option.name}
            </Box>
          )}
        />
      </Box>
    </>
  );
};
