import { takeEvery, put, all, takeLatest } from "redux-saga/effects";
import API from "../../utils/axios";
import {
  GET_PRODUCT_NAME,
  GET_PRODUCT_NAME_FAILURE,
  GET_PRODUCT_NAME_SUCCESS,
  GET_QUANTITY_LIST,
  GET_QUANTITY_LIST_FAILURE,
  GET_QUANTITY_LIST_SUCCESS,
  GET_REF_TYPE,
  GET_REF_TYPE_FAILURE,
  GET_REF_TYPE_SUCCESS,
  GET_SERVICE_DETAIL,
  GET_SERVICE_DETAIL_FAILURE,
  GET_SERVICE_DETAIL_SUCCESS,
  GET_SERVICE_EQUIPMENTS_DETAIL,
  GET_SERVICE_EQUIPMENTS_DETAIL_FAILURE,
  GET_SERVICE_EQUIPMENTS_DETAIL_SUCCESS,
  QUOTE_CONFIRMATION,
  QUOTE_CONFIRMATION_FAILURE,
  QUOTE_CONFIRMATION_SUCCESS,
  RESET_REQUESTID,
  RESET_REQUESTID_FAILURE,
  RESET_REQUESTID_SUCCESS,
  SEARCH_TRUCK,
  SEARCH_TRUCK_FAILURE,
  SEARCH_TRUCK_SUCCESS,
  SET_TRUCKING_LOADING,
} from "./actionTypes";

function* getServiceEquipmentSaga({ mode }) {
  try {
    yield put({ type: SET_TRUCKING_LOADING });
    // api/1/truckloadutility/type/Equipment/mode/1/list
    const { data } = yield API.get(
      `1/truckloadutility/type/Equipment/mode/${mode}/list`
    );
    yield put({
      type: GET_SERVICE_EQUIPMENTS_DETAIL_SUCCESS,
      payload: data.value,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_SERVICE_EQUIPMENTS_DETAIL_FAILURE,
      payload: { errorMessage: "Unable to get Services/Equipments list" },
    });
  }
}

function* getServiceSaga({ mode }) {
  try {
    yield put({ type: SET_TRUCKING_LOADING });
    const { data } = yield API.get(
      `1/truckloadutility/type/Service/mode/${mode}/list`
    );
    yield put({
      type: GET_SERVICE_DETAIL_SUCCESS,
      payload: data.value,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_SERVICE_DETAIL_FAILURE,
      payload: { errorMessage: "Unable to get Services list" },
    });
  }
}

function* getProductNameSaga({ organizationId }) {
  try {
    yield put({ type: SET_TRUCKING_LOADING });
    const { data } = yield API.get(
      `/1/truckloadutility/organizations/${organizationId}/product-type`
    );
    yield put({ type: GET_PRODUCT_NAME_SUCCESS, payload: data.value });
  } catch (error) {
    yield put({
      type: GET_PRODUCT_NAME_FAILURE,
      errorMessage: "Unable to get Product Name",
    });
  }
}

function* getReferenceTypeSaga() {
  try {
    yield put({ type: SET_TRUCKING_LOADING });
    const { data } = yield API.get(
      `/1/truckloadutility/type/Reference/mode/1/list`
    );
    yield put({ type: GET_REF_TYPE_SUCCESS, payload: data.value });
  } catch (error) {
    yield put({
      type: GET_REF_TYPE_FAILURE,
      errorMessage: "Unable to get reference type list",
    });
  }
}

function* getQuantityListSaga(serviceType) {
  try {
    yield put({ type: SET_TRUCKING_LOADING });
    const { data } = yield API.get("/1/commonutility/Quantitytype/list");
    yield put({ type: GET_QUANTITY_LIST_SUCCESS, payload: data.value });
  } catch (error) {
    yield put({
      type: GET_QUANTITY_LIST_FAILURE,
      errorMessage: "Unable to get Quantity List",
    });
  }
}

// call the API on click of search in truckload, then show data in summary page
function* getTruckListSaga({ truckloadPayload }) {
  try {
    yield put({ type: SET_TRUCKING_LOADING });
    const { data } = yield API.post(
      `/1/truck-load/truck/company/list`,
      truckloadPayload
    );
    yield put({ type: SEARCH_TRUCK_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: SEARCH_TRUCK_FAILURE,
      errorMessage: "Unable to get Truck Company List",
    });
  }
}

// call the API on click of confirm button in summary pop-up
function* postQuoteConfirmationSaga({ quoteConfirmation }) {
  try {
    yield put({ type: SET_TRUCKING_LOADING });
    const { data } = yield API.post(
      `/1/quote-history/request/quote`,
      quoteConfirmation
    );
    yield put({ type: QUOTE_CONFIRMATION_SUCCESS, payload: data.value });
  } catch (error) {
    yield put({
      type: QUOTE_CONFIRMATION_FAILURE,
      errorMessage: "Unable to post truck company confirmation list",
    });
  }
}

function* resetRequestIdSaga() {
  try {
    yield put({ type: SET_TRUCKING_LOADING });
    yield put({ type: RESET_REQUESTID_SUCCESS });
  } catch (error) {
    console.log("error", error);
    yield put({
      type: RESET_REQUESTID_FAILURE,
      errorMessage: "Unable to reset requestId",
    });
  }
}

function* TruckLoadSaga() {
  yield all([
    yield takeEvery(GET_PRODUCT_NAME, getProductNameSaga),
    yield takeLatest(GET_REF_TYPE, getReferenceTypeSaga),
    yield takeLatest(GET_SERVICE_DETAIL, getServiceSaga),
    yield takeLatest(GET_SERVICE_EQUIPMENTS_DETAIL, getServiceEquipmentSaga),
    yield takeLatest(GET_QUANTITY_LIST, getQuantityListSaga),
    yield takeLatest(SEARCH_TRUCK, getTruckListSaga),
    yield takeLatest(QUOTE_CONFIRMATION, postQuoteConfirmationSaga),
    yield takeLatest(RESET_REQUESTID, resetRequestIdSaga),
  ]);
}

export default TruckLoadSaga;
