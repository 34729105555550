import {
  GET_ORGANIZATION_DETAIL_FAILURE,
  GET_ORGANIZATION_DETAIL_SUCCESS,
  GET_RESOURCE_MENU_FAILURE,
  GET_RESOURCE_MENU_SUCCESS,
  GET_ORG_RESOURCES_FAILURE,
  GET_ORG_RESOURCES_SUCCESS,
  RESET_ORGANIZATION_DETAIL,
  RESET_ORGANIZATION_DETAIL_FAILURE,
  RESET_ORGANIZATION_DETAIL_SUCCESS,
  SET_EDITORGANISATION_LOADING,
  GET_EMAIL_TYPE_SUCCESS,
  GET_EMAIL_TYPE_FAILURE,
  GET_ORGANIZATION_LIST_FAILURE,
  GET_ORGANIZATION_LIST_SUCCESS,
  ADD_CARD_DATA,
  CLEAR_ANALYTICS_CARD_LIST,
  CLEAR_RESOURCE_MENU,
  SET_EMAIL_DATA,
  UPDATE_EMAIL_DATA,
  GET_ADMIN_USER_SUCCESS,
  GET_ADMIN_USER_FAILURE,
  GET_LOCAL_USER_SUCCESS,
  GET_LOCAL_USER_FAILURE,
  GET_USER_ROLE_LIST_SUCCESS,
  GET_USER_ROLE_LIST_FAILURE,
  GET_ACTIVE_ORG_LIST_SUCCESS,
  GET_ACTIVE_ORG_LIST_FAILURE,
  GET_HISTORY_LOG_SUCCESS,
  GET_HISTORY_LOG_FAILURE,
  GET_DATE_FORMAT_SUCCESS,
  GET_DATE_FORMAT_FAILURE,
} from "./actionTypes";

const initialState = {
  organizationDetails: {
    organizationId: "",
    organizationName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    countryCode: "",
    emailToCc: null,
    isActive: false,
    parentOrganizationId: "",
    address: "",
  },
  resourceMenu: [],
  rolesData: [],
  emailtypes: [],
  emailData: {
    organizationId: "",
    scenarios: [],
  },
  orgResourceList: {
    id: "",
    name: "",
    organizationId: "",
    organizationName: "",
    hierarchy: "",
    permission: [],
  },
  orgDetailsLoading: false,
  error: false,
  errorMessage: "",
  organizationList: [],
  activeOrgList: [],
  analyticsCardList: [],
  localUsers: [],
  adminUsers: [],
  userRoleList: [],
  historyLogData: [],
  dateFormats: [],
};

const EditOrganization = (state = initialState, action) => {
  switch (action.type) {
    case SET_EDITORGANISATION_LOADING:
      return {
        ...state,
        orgDetailsLoading: true,
      };
    case GET_ORGANIZATION_DETAIL_SUCCESS:
      return {
        ...state,
        orgDetailsLoading: false,
        organizationDetails: action.payload,
      };
    case GET_ORGANIZATION_DETAIL_FAILURE:
      return {
        ...state,
        orgDetailsLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case RESET_ORGANIZATION_DETAIL:
      return {
        ...state,
        orgDetailsLoading: true,
      };
    case RESET_ORGANIZATION_DETAIL_SUCCESS:
      return {
        ...state,
        orgDetailsLoading: false,
        organizationDetails: {
          organizationId: "",
          organizationName: "",
          address1: "",
          address2: "",
          city: "",
          state: "",
          zipCode: "",
          country: "",
          countryCode: "",
          emailToCc: null,
          isActive: false,
          parentOrganizationId: "",
          address: "",
        },
        rolesData: [],
        localUsers: [],
        adminUsers: [],
        userRoleList: [],
        orgResourceList: {
          id: "",
          name: "",
          organizationId: "",
          organizationName: "",
          hierarchy: "",
          permission: [],
        },
        error: false,
        errorMessage: "",
      };
    case RESET_ORGANIZATION_DETAIL_FAILURE:
      return {
        ...state,
        orgDetailsLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case GET_ORG_RESOURCES_SUCCESS:
      return {
        ...state,
        orgDetailsLoading: false,
        orgResourceList: action.payload,
      };

    case GET_ORG_RESOURCES_FAILURE:
      return {
        ...state,
        orgDetailsLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case GET_RESOURCE_MENU_SUCCESS:
      return {
        ...state,
        orgDetailsLoading: false,
        resourceMenu: action.payload,
      };
    case GET_RESOURCE_MENU_FAILURE:
      return {
        ...state,
        orgDetailsLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case CLEAR_RESOURCE_MENU:
      return {
        ...state,
        resourceMenu: [],
      };

    case GET_EMAIL_TYPE_SUCCESS:
      return {
        ...state,
        orgDetailsLoading: false,
        emailtypes: action.payload,
      };
    case GET_EMAIL_TYPE_FAILURE:
      return {
        ...state,
        orgDetailsLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case SET_EMAIL_DATA:
      return {
        ...state,
        emailData: action.payload,
      };
    case UPDATE_EMAIL_DATA:
      return {
        ...state,
        emailData: action.payload,
      };
    case GET_ORGANIZATION_LIST_SUCCESS:
      return {
        ...state,
        orgDetailsLoading: false,
        organizationList: action.payload,
      };
    case GET_ORGANIZATION_LIST_FAILURE:
      return {
        ...state,
        orgDetailsLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case GET_ACTIVE_ORG_LIST_SUCCESS:
      return {
        ...state,
        orgDetailsLoading: false,
        activeOrgList: action.payload,
      };
    case GET_ACTIVE_ORG_LIST_FAILURE:
      return {
        ...state,
        orgDetailsLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case ADD_CARD_DATA:
      // Extract existing card list from state
      const existingCardList = state.analyticsCardList;
      // Extract new card data from payload
      const { organization, analyticsCard, analyticsCardURL } = action.payload;
      // Check if a card with the same name already exists in the list
      const index = existingCardList.findIndex(
        (card) => card.analyticsCard === analyticsCard
      );
      // If a card with the same name exists, replace it with the new data
      if (index !== -1) {
        existingCardList[index] = {
          organization,
          analyticsCard,
          analyticsCardURL,
        };
      } else {
        // Otherwise, append the new card data to the list
        existingCardList.push({
          organization,
          analyticsCard,
          analyticsCardURL,
        });
      }
      return {
        ...state,
        analyticsCardList: existingCardList,
      };

    case CLEAR_ANALYTICS_CARD_LIST:
      return {
        ...state,
        analyticsCardList: [],
      };

    case GET_LOCAL_USER_SUCCESS:
      return {
        ...state,
        orgDetailsLoading: false,
        localUsers: action.payload,
      };
    case GET_LOCAL_USER_FAILURE:
      return {
        ...state,
        orgDetailsLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case GET_ADMIN_USER_SUCCESS:
      return {
        ...state,
        orgDetailsLoading: false,
        adminUsers: action.payload,
      };
    case GET_ADMIN_USER_FAILURE:
      return {
        ...state,
        orgDetailsLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case GET_USER_ROLE_LIST_SUCCESS:
      return {
        ...state,
        orgDetailsLoading: false,
        userRoleList: action.payload,
      };
    case GET_USER_ROLE_LIST_FAILURE:
      return {
        ...state,
        orgDetailsLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case GET_HISTORY_LOG_SUCCESS:
      return {
        ...state,
        orgDetailsLoading: false,
        historyLogData: action.payload,
      };
    case GET_HISTORY_LOG_FAILURE:
      return {
        ...state,
        orgDetailsLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case GET_DATE_FORMAT_SUCCESS:
      return {
        ...state,
        orgDetailsLoading: false,
        dateFormats: action.payload,
      };
    case GET_DATE_FORMAT_FAILURE:
      return {
        ...state,
        orgDetailsLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    default:
      return state;
  }
};

export default EditOrganization;
