import { IconButton, Snackbar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  snackBarRoot: {
    "& .MuiPaper-root": {
      borderRadius: "0",
      background: theme.palette.color.white,
      color: "black",
    },
    "& .MuiSnackbarContent-action ": {
      alignSelf: "flex-start",
    },
  },
  success: {
    "& .MuiPaper-root": {
      borderLeft: `10px solid ${theme.palette.color.green}`,
    },
  },
  failure: {
    "& .MuiPaper-root": { borderLeft: `10px solid ${theme.palette.color.red}` },
  },
}));

const Toast = ({
  message,
  open,
  handleClose,
  success,
  left = "unset !important",
}) => {
  const classes = useStyles();

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  const messagae = (
    <>
      <Typography variant="h4">{success ? "Success" : "Error"}</Typography>
      <Typography variant="body2">{message}</Typography>
    </>
  );
  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      classes={{
        root: `${classes.snackBarRoot} ${
          success ? classes.success : classes.failure
        }`,
      }}
      style={{ left: left }}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      message={messagae}
      action={action}
    />
  );
};

export default Toast;
