import React from "react";
import VectorMap, { Font, Layer, Tooltip } from "devextreme-react/vector-map";
import * as mapsData from "devextreme-dist/js/vectormap-data/world.js";
import { officeAddresses } from "./timezone2-list";
import TooltipTemplate from "./tooltipTemplate";
import { useEffect } from "react";

const bounds = [-180, 85, 180, -60];

const TimeZone2 = () => {
  const customizeTooltip = (arg) => {
    if (arg.layer.type === "marker") {
      return { color: "white" };
    }
    return null;
  };

  const markerClick = (e) => {
    if (e.target && e.target.layer.type === "marker") {
      e.component.center(e.target.coordinates()).zoomFactor(10);
    }
  };

  useEffect(() => {
    const images = document.querySelectorAll(".dxm-marker image");

    images.forEach((image) => {
      image.setAttributeNS(
        "http://www.w3.org/1999/xlink",
        "href",
        "https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/maps/map-marker.png"
      );
    });
  }, []);

  const customizeLayer = (elements) => {
    elements.forEach((element) => {
      element.applySettings({
        color: "#5DAFD8",
      });
    });
  };

  return (
    <VectorMap
      id="vector-map"
      bounds={bounds}
      onClick={markerClick}
      style={{ height: "250px" }}
    >
      <Layer
        dataSource={mapsData.world}
        hoverEnabled={false}
        customize={customizeLayer}
      />
      <Layer
        dataSource={officeAddresses}
        name="marker"
        elementType="image"
        size={18}
        opacity={1}
      />
      <Tooltip
        enabled={true}
        customizeTooltip={customizeTooltip}
        contentRender={TooltipTemplate}
      >
        <Font family="open-sans" weight="900" color="#183650"></Font>
      </Tooltip>
    </VectorMap>
  );
};

export default TimeZone2;
