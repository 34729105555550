import { takeEvery, put, all, takeLatest } from "redux-saga/effects";
import {
  GET_ANALYTICS_DATA,
  GET_ANALYTICS_DATA_FAILURE,
  GET_ANALYTICS_DATA_SUCCESS,
  GET_AVG_TRANSIT_TIME,
  GET_COUNTRY_WISE_SHIPMENT_COUNT,
  GET_DELAY_COUNT,
  GET_DELIVERY_COMPLIANCE,
  GET_DELIVERY_PURCHASE_ORDER_COUNT,
  GET_DESTINATION_CONTAINER_COUNT,
  GET_DWELL_TRANSSHIP,
  GET_DWELL_TRANSSHIP_FAILURE,
  GET_DWELL_TRANSSHIP_SUCCESS,
  GET_FOURTH_PL_DATA,
  GET_FOURTH_PL_DATA_FAILURE,
  GET_FOURTH_PL_DATA_SUCCESS,
  GET_MONTHLY_SHIPMENT_VOLUME,
  GET_MOST_SHIPPED_CONTAINER_BY_DESTINATION,
  GET_MOST_SHIPPED_CONTAINER_BY_ORIGIN,
  GET_ODQV1_COUNT,
  GET_ORDER_STATUS_COUNT,
  GET_ORIGIN_CONTAINER_COUNT,
  GET_STATUS_WISE_COUNT,
  GET_WIDGET_OPTIONS,
  GET_WIDGET_OPTIONS_FAILURE,
  GET_WIDGET_OPTIONS_SUCCESS,
  GET_YEARLY_SHIPMENT_COUNT,
  SET_AVG_TRANSIT_TIME,
  SET_CARD_LOADING,
  SET_COUNTRY_WISE_SHIPMENT_COUNT,
  SET_DELAY_COUNT,
  SET_DELIVERY_COMPLIANCE,
  SET_DELIVERY_PURCHASE_ORDER_COUNT,
  SET_DESTINATION_CONTAINER_COUNT,
  SET_MONTHLY_SHIPMENT_VOLUME,
  SET_MOST_SHIPPED_CONTAINER_BY_DESTINATION,
  SET_MOST_SHIPPED_CONTAINER_BY_ORIGIN,
  SET_ODQCARD_LOADING,
  SET_ODQV1_COUNT,
  SET_ORDER_STATUS_COUNT,
  SET_ORIGIN_CONTAINER_COUNT,
  SET_STATUS_WISE_COUNT,
  SET_VOLUME_CARD_LOADING,
  SET_WIDGET_LOADING,
  SET_YEARLY_SHIPMENT_COUNT,
} from "./actionTypes";
import API from "../../utils/axios";

function* getWidgetOptionSaga({ organizationId }) {
  try {
    if (!Boolean(localStorage.getItem("WIDGET_DATA"))) {
      yield put({ type: SET_WIDGET_LOADING });
    }
    const { data } = yield API.get(
      `/1/widget/me/widgets/organization/${organizationId}`
    );
    const existingData = localStorage.getItem("WIDGET_DATA");
    if (existingData) {
      const widgetData = JSON.stringify(data);
      if (existingData !== widgetData) {
        localStorage.setItem("WIDGET_DATA", JSON.stringify(data));
        yield put({ type: GET_WIDGET_OPTIONS_SUCCESS, payload: data });
      }
    } else {
      localStorage.setItem("WIDGET_DATA", JSON.stringify(data));
      yield put({ type: GET_WIDGET_OPTIONS_SUCCESS, payload: data });
    }
  } catch (error) {
    yield put({
      type: GET_WIDGET_OPTIONS_FAILURE,
      errorMessage: "Unable to get Widget Options",
    });
  }
}

function* getFourthPLSaga({ organizationId }) {
  try {
    const { data } = yield API.get(
      `/1/shipment-fourthpl/organization/${organizationId}/details`
    );
    yield put({ type: GET_FOURTH_PL_DATA_SUCCESS, payload: data.value });
  } catch (error) {
    yield put({
      type: GET_FOURTH_PL_DATA_FAILURE,
      errorMessage: "Unable to get fourth PL data",
    });
  }
}

function* getDwellTransShipSaga({ organizationId, count }) {
  // api/1/shipment-fourthpl/organization/LEPFOODEN/dwelltimedays/5/dwellattransship
  try {
    yield put({ type: SET_CARD_LOADING });
    const { data } = yield API.get(
      `/1/shipment-fourthpl/organization/${organizationId}/dwelltimedays/${count}/dwellattransship`
    );
    yield put({
      type: GET_DWELL_TRANSSHIP_SUCCESS,
      payload: { data: data?.value ? data?.value : 0, slider: count },
    });
  } catch (error) {
    yield put({
      type: GET_DWELL_TRANSSHIP_FAILURE,
      errorMessage: "Unable to get dwell at transship data",
    });
  }
}

function* getOrderStatusCount({ organizationId }) {
  try {
    const { data } = yield API.get(
      `/1/shipment-fourthpl/organization/${organizationId}/status/count`
    );
    yield put({
      type: SET_ORDER_STATUS_COUNT,
      payload: data?.value ? data.value : [],
    });
  } catch (error) {
    yield put({
      type: SET_ORDER_STATUS_COUNT,
      payload: [],
    });
  }
}
function* getDelayCount({ organizationId }) {
  try {
    const { data } = yield API.get(
      `/1/shipment-fourthpl/organization/${organizationId}/delay/count`
    );
    yield put({ type: SET_DELAY_COUNT, payload: data.value });
  } catch (error) {
    yield put({ type: SET_DELAY_COUNT, payload: [] });
  }
}
function* getODQV1Count({ countryCode }) {
  // https://app-cus-myfracht-api-dev-001.azurewebsites.net/api/1/widget/me/widget/odq?countryCode=US
  try {
    yield put({ type: SET_ODQCARD_LOADING });
    const { data } = yield API.get(
      `/1/widget/me/widget/odq?countryCode=${countryCode}`
    );
    yield put({ type: SET_ODQV1_COUNT, payload: data });
  } catch (error) {
    yield put({ type: SET_ODQV1_COUNT, payload: {} });
  }
}

function* getDeliveryCompliance({ organizationId }) {
  try {
    const { data } = yield API.get(
      `1/shipment-fourthpl/organization/${organizationId}/deliveryontimecount`
    );
    yield put({
      type: SET_DELIVERY_COMPLIANCE,
      payload: data?.value ? data.value : 0,
    });
  } catch (error) {
    yield put({
      type: SET_DELIVERY_COMPLIANCE,
      payload: 0,
    });
  }
}

function* getYearlyShipmentCount({ organizationId, year }) {
  try {
    const { data } = yield API.get(
      `1/widget/shipment/organization/${organizationId}/monthly/${year}`
    );
    yield put({ type: SET_YEARLY_SHIPMENT_COUNT, payload: data ? data : [] });
  } catch (error) {
    yield put({ type: SET_YEARLY_SHIPMENT_COUNT, payload: [] });
  }
}

function* getCountryShipmentCount({ organizationId, year }) {
  try {
    const { data } = yield API.get(
      `/1/widget/shipment/organization/${organizationId}/country-wise/${year}`
    );
    yield put({ type: SET_COUNTRY_WISE_SHIPMENT_COUNT, payload: data });
  } catch (error) {
    yield put({ type: SET_COUNTRY_WISE_SHIPMENT_COUNT, payload: [] });
  }
}
function* getDeliverOrderCount({ organizationId }) {
  try {
    const { data } = yield API.get(
      `/1/shipment-fourthpl/organization/${organizationId}/countrywise/deliveredcount?returnTopNRecords=${5}`
    );
    yield put({
      type: SET_DELIVERY_PURCHASE_ORDER_COUNT,
      payload: data?.value ? data.value : [],
    });
  } catch (error) {
    yield put({ type: SET_DELIVERY_PURCHASE_ORDER_COUNT, payload: [] });
  }
}

function* getmostShippedByOrigin({ organizationId, count }) {
  try {
    const { data } = yield API.get(
      `/1/shipment-external/organization/${organizationId}/widgettype/origin/containercount?returnTopNRecords=${count}`
    );
    yield put({
      type: SET_MOST_SHIPPED_CONTAINER_BY_ORIGIN,
      payload: data.value,
    });
  } catch (error) {
    yield put({
      type: SET_MOST_SHIPPED_CONTAINER_BY_ORIGIN,
      payload: [],
    });
  }
}

function* getmostShippedByDestination({ organizationId, count }) {
  try {
    const { data } = yield API.get(
      `/1/shipment-external/organization/${organizationId}/widgettype/destination/containercount?returnTopNRecords=${count}`
    );
    yield put({
      type: SET_MOST_SHIPPED_CONTAINER_BY_DESTINATION,
      payload: data.value,
    });
  } catch (error) {
    yield put({
      type: SET_MOST_SHIPPED_CONTAINER_BY_DESTINATION,
      payload: [],
    });
  }
}

function* getAverageTransitTime({ organizationId, count, days }) {
  try {
    const { data } = yield API.get(
      `/1/widget/average-transit-time/organization/${organizationId}/records-count/${count}/previousdays/${days}`
    );
    yield put({
      type: SET_AVG_TRANSIT_TIME,
      payload: data ? data : [],
    });
  } catch (error) {
    yield put({
      type: SET_AVG_TRANSIT_TIME,
      payload: [],
    });
  }
}

function* getShipmentVolume({ organizationId, year }) {
  try {
    yield put({ type: SET_VOLUME_CARD_LOADING });
    const response = yield API.get(
      `/1/widget/arrival-departure/container-volume/organization/${organizationId}/monthly/${year}`
    );
    const payload =
      response.status === 204 || !response.data || response.data.length === 0
        ? []
        : response.data;

    yield put({
      type: SET_MONTHLY_SHIPMENT_VOLUME,
      payload,
    });
  } catch (error) {
    yield put({
      type: SET_MONTHLY_SHIPMENT_VOLUME,
      payload: [],
    });
  }
}

function* getStatusCount({ organizationId }) {
  try {
    const { data } = yield API.get(
      `/1/widget/organization/${organizationId}/status/count`
    );
    yield put({
      type: SET_STATUS_WISE_COUNT,
      payload: data?.value,
    });
  } catch (error) {
    yield put({
      type: SET_STATUS_WISE_COUNT,
      payload: [],
    });
  }
}

function* getAnalyticDataSaga({ companyName }) {
  try {
    yield put({ type: SET_CARD_LOADING });
    const { data, status } = yield API.post(`/1/analytics/code`, {
      organizationId: companyName,
    });
    if (status === 200) {
      yield put({
        type: GET_ANALYTICS_DATA_SUCCESS,
        payload: data?.value,
      });
    } else {
      yield put({
        type: GET_ANALYTICS_DATA_SUCCESS,
        payload: null,
      });
    }
    localStorage.setItem(
      "cargowiseAppLicenseCode",
      JSON.stringify(data?.value)
    );
  } catch (error) {
    yield put({
      type: GET_ANALYTICS_DATA_FAILURE,
      errorMessage: "Unable to get analytics data",
    });
  }
}

function* getOriginContainerCount({ organizationIds, count }) {
  try {
    const { data } = yield API.post(
      `/1/widget/origin/container-count/records-count/${count}`,
      organizationIds
    );
    yield put({
      type: SET_ORIGIN_CONTAINER_COUNT,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: SET_ORIGIN_CONTAINER_COUNT,
      payload: [],
    });
  }
}

function* getDestinationContainerCount({ organizationIds, count }) {
  try {
    const { data } = yield API.post(
      `/1/widget/destination/container-count/records-count/${count}`,
      organizationIds
    );
    yield put({
      type: SET_DESTINATION_CONTAINER_COUNT,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: SET_DESTINATION_CONTAINER_COUNT,
      payload: [],
    });
  }
}

function* WidgetSaga() {
  yield all([
    yield takeEvery(GET_WIDGET_OPTIONS, getWidgetOptionSaga),
    yield takeLatest(GET_FOURTH_PL_DATA, getFourthPLSaga),
    yield takeLatest(GET_DWELL_TRANSSHIP, getDwellTransShipSaga),
    yield takeLatest(GET_ORDER_STATUS_COUNT, getOrderStatusCount),
    yield takeLatest(GET_DELAY_COUNT, getDelayCount),
    yield takeLatest(GET_ODQV1_COUNT, getODQV1Count),
    yield takeLatest(GET_DELIVERY_COMPLIANCE, getDeliveryCompliance),
    yield takeLatest(GET_YEARLY_SHIPMENT_COUNT, getYearlyShipmentCount),
    yield takeLatest(GET_COUNTRY_WISE_SHIPMENT_COUNT, getCountryShipmentCount),
    yield takeLatest(GET_DELIVERY_PURCHASE_ORDER_COUNT, getDeliverOrderCount),
    yield takeLatest(
      GET_MOST_SHIPPED_CONTAINER_BY_ORIGIN,
      getmostShippedByOrigin
    ),
    yield takeLatest(
      GET_MOST_SHIPPED_CONTAINER_BY_DESTINATION,
      getmostShippedByDestination
    ),
    yield takeLatest(GET_AVG_TRANSIT_TIME, getAverageTransitTime),
    yield takeLatest(GET_MONTHLY_SHIPMENT_VOLUME, getShipmentVolume),
    yield takeLatest(GET_STATUS_WISE_COUNT, getStatusCount),
    yield takeLatest(GET_ANALYTICS_DATA, getAnalyticDataSaga),
    yield takeLatest(GET_ORIGIN_CONTAINER_COUNT, getOriginContainerCount),
    yield takeLatest(
      GET_DESTINATION_CONTAINER_COUNT,
      getDestinationContainerCount
    ),
  ]);
}

export default WidgetSaga;
