import { takeEvery, put, all } from "redux-saga/effects";
import API from "../../utils/axios";
import {
  ADD_LOCK_OPTION,
  ADD_LOCK_OPTION_FAILURE,
  ADD_LOCK_OPTION_SUCCESS,
  DELETE_LOCK_OPTION,
  DELETE_LOCK_OPTION_FAILURE,
  DELETE_LOCK_OPTION_SUCCESS,
  SET_LOADING,
} from "./actionTypes";

// api/1/lock/entity/id/user-session/UserSessionId
function* releaseLockOption({ id }) {
  const userSessionId = sessionStorage.getItem("unique_id");
  try {
    yield put({ type: SET_LOADING });
    const { data } = yield API.delete(
      `/1/lock/entity/${id}/user-session/${userSessionId}`
    );
    // console.log(userSessionId, "releaseLockOption");
    yield put({ type: DELETE_LOCK_OPTION_SUCCESS, payload: data });
  } catch (error) {
    console.log("error", error);
    yield put({
      type: DELETE_LOCK_OPTION_FAILURE,
      errorMessage: "Unable to delete lock option",
    });
  }
}

function* addLockOption({ id, resource }) {
  const userSessionId = sessionStorage.getItem("unique_id");
  try {
    yield put({ type: SET_LOADING });
    const { data } = yield API.post(
      `/1/lock/entity/${id}/user-session/${userSessionId}/resource/${resource}`
    );
    // console.log(userSessionId, "addLockOption");
    yield put({ type: ADD_LOCK_OPTION_SUCCESS, payload: data });
  } catch (error) {
    console.log("error", error);
    yield put({
      type: ADD_LOCK_OPTION_FAILURE,
      errorMessage: "Unable to add lock option",
    });
  }
}

function* LockSaga() {
  yield all([yield takeEvery(DELETE_LOCK_OPTION, releaseLockOption)]);
  yield all([yield takeEvery(ADD_LOCK_OPTION, addLockOption)]);
}

export default LockSaga;
