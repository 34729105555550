import React from "react";
import { useState } from "react";
import { createContext } from "react";
export const Context = createContext();

const SidemenuToogleContext = (props) => {
  const [sideToggle, setSideToggle] = useState(true);

  return (
    <Context.Provider value={{ sideToggle, setSideToggle }}>
      {props.children}
    </Context.Provider>
  );
};

export default SidemenuToogleContext;
