import React, { useEffect, useState } from "react";
import "./analog-clock.css";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";

export const WorldClock = () => {
  const { clockDataList } = useSelector((state) => state.WidgetsReducer);
  const clockData = JSON.parse(localStorage.getItem("clockData"));
  const findClockData = (clockNumber) => {
    if (clockDataList) {
      const timezone = clockDataList.find(
        (clock) => clock.clockNumber === clockNumber
      );
      if (timezone) {
        return timezone.name;
      }
    }

    if (clockData) {
      const timezone = clockData.find(
        (clock) => clock.clockNumber === clockNumber
      );
      if (timezone) {
        return timezone.name;
      }
    }

    // Set default time zones
    if (clockNumber === 1) {
      return "America/New_York";
    } else if (clockNumber === 2) {
      return "Europe/London";
    } else if (clockNumber === 3) {
      return "Asia/Kolkata";
    }
    return "America/New_York";
  };

  return (
    <>
      <Grid item xs={3} style={{ display: "flex", flexDirection: "column" }}>
        <Clock id={`clock ${Math.random()}`} timeZone={findClockData(1)} />
      </Grid>
      <Grid item xs={3} style={{ display: "flex", flexDirection: "column" }}>
        <Clock id={`clock ${Math.random()}`} timeZone={findClockData(2)} />
      </Grid>
      <Grid item xs={3} style={{ display: "flex", flexDirection: "column" }}>
        <Clock id={`clock ${Math.random()}`} timeZone={findClockData(3)} />
      </Grid>
    </>
  );
};

const Clock = ({ timeZone, id = "1" }) => {
  const [time, setTime] = useState(new Date());
  const [zone, setzone] = useState("");
  function calcTime(offset) {
    const m = moment().tz(timeZone);
    return m;
  }
  const getTimeObj = () => {
    if (!timeZone) return;
    const m = moment().tz(timeZone);
    let hour = m.format("hh");
    let minutes = m.format("mm");
    let seconds = m.format("ss");
    setzone(m.zoneAbbr());
    return { hour: hour, minutes: minutes, seconds: seconds };
  };

  const setTimePerTimezone = () => {
    setTime(calcTime());
  };

  useEffect(() => {
    if (timeZone) {
      setTimePerTimezone();
    }
  }, [timeZone]);

  useEffect(() => {
    const interval = setInterval(() => setTimePerTimezone(), 1000);
    let timeObj = getTimeObj();
    if (!timeObj) return;
    const { hour, minutes, seconds } = timeObj;
    const hourHand = document.getElementById(`${id}hourHand`);
    const minuteHand = document.getElementById(`${id}minuteHand`);
    const secondHand = document.getElementById(`${id}secondHand`);
    hourHand.style.transform = `rotate(${hour * 30 + minutes * 0.5 - 180}deg)`;
    minuteHand.style.transform = `rotate(${minutes * 6 - 180}deg)`;
    secondHand.style.transform = `rotate(${seconds * 6 - 180}deg)`;
    return () => clearInterval(interval);
  }, [time]);

  const displayName = timeZone?.replace(/_/g, " ");

  return (
    <>
      <div className="countryName">
        {zone + " "}
        {moment(time).format("hh:mm:ss A")}
      </div>
      <div className="clock">
        <div className="face">
          <div id={`${id}secondHand`} className="hand second"></div>
          <div id={`${id}minuteHand`} className="hand minute"></div>
          <div id={`${id}hourHand`} className="hand hour"></div>
          <div className="hand center"></div>
          <span className="twelve">|</span>
          <span className="one"></span>
          <span className="two"></span>
          <span className="three">|</span>
          <span className="four"></span>
          <span className="five"></span>
          <span className="six">|</span>
          <span className="seven"></span>
          <span className="eight"></span>
          <span className="nine">|</span>
          <span className="ten"></span>
          <span className="eleven"></span>
        </div>
      </div>
      <div className="countryName">{displayName}</div>
    </>
  );
};

export default Clock;
