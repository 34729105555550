import { Grid, Stack, Typography } from "@mui/material";
import React from "react";

import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
  stackStyling: {
    margin: "6px 10px",
  },
  trackingHeading: {
    color: "#6C757D",
    ...theme.typography.body22,
  },

  summaryValues: {
    color: "#000000",
    ...theme.typography.body2,
  },
}));

const PODetails = ({ formData }) => {
  const classes = useStyle();
  const { containerNumber, pONumber, consoleNumber } = formData;

  return (
    <>
      <Grid container style={{ flexDirection: "row" }}>
        <Grid item xs={2} className={classes.stackStyling}>
          <Typography className={classes.trackingHeading} variant="body2">
            Container Number:{" "}
            <span className={classes.summaryValues}>{containerNumber}</span>
          </Typography>
        </Grid>
        <Grid item xs={2} className={classes.stackStyling}>
          <Typography className={classes.trackingHeading} variant="body2">
            Console Number:{" "}
            <span className={classes.summaryValues}>{consoleNumber}</span>
          </Typography>
        </Grid>

        <Grid item xs={2} className={classes.stackStyling}>
          <Typography className={classes.trackingHeading} variant="body2">
            Number of POs:{" "}
            <span className={classes.summaryValues}>{pONumber}</span>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default PODetails;
