import * as React from "react";
import Grid from "@mui/material/Grid";
import StatusCard from "./widgets/statusCard";
import OriginDelay from "../../assets/images/origindelay.svg";
import ArrivalDelay from "../../assets/images/arrivaldelay.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getDelayCount } from "../../redux/widgets/action";

export default function DelayWidgets() {
  let delayCard = [
    {
      statusIcon: OriginDelay,
      label: "Origin Delay",
      count: -1,
      delayType: "origindelay",
    },
    {
      statusIcon: ArrivalDelay,
      label: "Destination Delay",
      count: -1,
      delayType: "destinationdelay",
    },
  ];
  const { organizationId } = useSelector((state) => state.LoginUser);
  const { delayCount } = useSelector((state) => state.WidgetsReducer);
  const [statusData, setStatusData] = React.useState(delayCard);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (organizationId && delayCount === null)
      dispatch(getDelayCount(organizationId));
  }, [organizationId]);

  useEffect(() => {
    if (delayCount) {
      delayCard[0].count = delayCount.length === 0 ? 0 : delayCount[0].count;
      delayCard[1].count = delayCount.length === 0 ? 0 : delayCount[1].count;
      setStatusData([...delayCard]);
    }
  }, [delayCount]);

  const redirectToOrders = (card) => {
    const gridId = "POSummary";
    localStorage.removeItem(`${gridId}-viewName`);
    localStorage.removeItem(`grid-${gridId}`);
    localStorage.removeItem(`grid-${gridId}-colums`);
    localStorage.removeItem(`${gridId}-status-card`);
    navigate(`/shipmentsummary/live/${card.delayType}`);
  };

  return (
    <Grid container spacing={2}>
      {statusData?.map((card, index) => (
        <Grid
          item
          xs={6}
          key={index}
          style={{ height: statusData.length <= 2 ? "100%" : "50%" }}
          onClick={() => {
            redirectToOrders(card);
          }}
        >
          <StatusCard
            statusIcon={card.statusIcon}
            statusText={card.label}
            count={card.count}
          />
        </Grid>
      ))}
    </Grid>
  );
}
