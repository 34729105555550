import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  button: {
    border: "none",
    color: theme.palette.color.white,
    fontSize: "16px",
    lineHeight: "22px",
    height: "38px",
    padding: "0 10px",
    borderRadius: "2px",
    cursor: "pointer",
  },
}));

function Button({ children, className, onClick, disabled, id }) {
  const classes = useStyles();
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`${classes.button} ${className}`}
      id={id}
    >
      {children}
    </button>
  );
}

export default Button;
