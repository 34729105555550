import {
  GET_CLIENT_NAME,
  GET_CLIENT_NAME_FAILURE,
  GET_CLIENT_NAME_SUCCESS,
  GET_SHIPMENT_DETAIL_FAILURE,
  GET_SHIPMENT_DETAIL_SUCCESS,
  RESET_SHIPMENT_DETAIL,
  RESET_SHIPMENT_DETAIL_FAILURE,
  RESET_SHIPMENT_DETAIL_SUCCESS,
  SET_LOADING,
} from "./actionTypes";

const initialState = {
  uploadShipmentDetails: [],
  clientNames: ["Unimacts"],
  clientOrgName: "Unimacts",
  clientOrgId: "UNIGLOBOS",
  parentOrganizationId: "UNIGLOBOS",
  loading: false,
  error: false,
  errorMessage: "",
};

const ShipmentDetail = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_SHIPMENT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        uploadShipmentDetails: action.payload,
      };
    case GET_SHIPMENT_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case RESET_SHIPMENT_DETAIL:
      return {
        ...state,
        loading: true,
      };
    case RESET_SHIPMENT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        uploadShipmentDetails: [],
      };
    case RESET_SHIPMENT_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case GET_CLIENT_NAME:
      return {
        ...state,
        loading: true,
      };
    case GET_CLIENT_NAME_SUCCESS:
      return {
        ...state,
        loading: false,
        clientNames: ["Unimacts"],
        clientOrgName: "Unimacts",
        clientOrgId: "UNIGLOBOS",
        parentOrganizationId: "UNIGLOBOS",
      };
    case GET_CLIENT_NAME_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    default:
      return state;
  }
};

export default ShipmentDetail;
