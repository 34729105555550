import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { InputAdornment, TextField } from "@mui/material";
import dateCalendar from "../assets/images/dateCalendar.svg";

const useStyle = makeStyles((theme) => ({
  errorMessage: {
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: "0.75rem",
    lineHeight: "1.66",
    textAlign: "left",
    marginTop: "3px",
    color: "#cc3629",
    marginLeft: "12px",
    marginRight: "0",
  },
  filledRoot: {
    height: "38px",
    backgroundColor: `${theme.palette.color.white} !important`,
    border: "1px solid #18365073",
    boxShadow: `2px 2px 6px ${theme.palette.color.grayShadow2}`,
    borderRadius: "4px",
    marginTop: "5px",
    opacity: 1,
    "&:hover": {
      backgroundColor: theme.palette.color.white,
      border: `1px solid ${theme.palette.color.primary}`,
    },
  },
  disabledFilledRoot: {
    height: "38px",
    border: `1px solid ${theme.palette.color.grayS7}`,
    boxShadow: `2px 2px 6px ${theme.palette.color.grayShadow2}`,
    borderRadius: "4px",
    marginTop: "5px",
    backgroundColor: theme.palette.color.grayS6,
    opacity: 1,
  },
  errorFilledRoot: {
    height: "38px",
    backgroundColor: theme.palette.color.white,
    border: "1px solid #CC3629",
    boxShadow: "2px 2px 6px #00000033",
    borderRadius: "6px",
    top: "5px",
    opacity: 1,
    "&:hover": {
      backgroundColor: theme.palette.color.white,
    },
  },
  filledInput: {
    ...theme.typography.body2,
  },
  underline: {
    "&:after": {
      borderBottom: "none !important",
    },
  },
  filledFocused: {
    border: `1px solid ${theme.palette.color.primary} !important`,
    borderRadius: "3px !important",
    boxShadow: "0px 3px 6px #18365052",
  },
  error: {
    border: `1px solid #CC3629 !important`,
    borderRadius: "3px !important",
    boxShadow: "0px 3px 6px #18365052",
  },
  labelRoot: {
    ...theme.typography.body2,
    opacity: 1,
    color: "#9E9E9E",
    "&.MuiInputLabel-shrink": {
      ...theme.typography.small,
      fontWeight: "normal",
      marginTop: "5px",
    },
    "& .MuiFormLabel-asterisk": {
      color: "#CC3629",
    },
    "&:hover": {
      color: "#9E9E9E",
      letterSpacing: "0px",
    },
  },
  errorlabelRoot: {
    ...theme.typography.body2,
    opacity: 1,
    color: "#CC3629",
    // marginTop: "-3px",
    "&.MuiInputLabel-shrink": {
      ...theme.typography.small,
      fontWeight: "normal",
      marginTop: "7px",
    },
    "& .MuiFormLabel-asterisk": {
      color: "#CC3629 !important",
    },
    "&:hover": {
      color: "#CC3629 !important",
      letterSpacing: "0px",
    },
  },
  labelFocused: {
    textAlign: "left",
    ...theme.typography.fontTitillium,
    fontSize: "10px",
    letterSpacing: "0.01px",
    color: "#9E9E9E !important",
    opacity: 1,
    fontWeight: "600",
    marginTop: "0.1px",
  },
  labelFocusedError: {
    ...theme.typography.fontTitillium,
    color: "#CC3629 !important",
    letterSpacing: "0.01px",
    fontSize: "14px",
    fontWeight: "600",
  },
  helperTextRoot: {
    color: "#CC3629",
    ...theme.typography.small,
  },
  dateCalendarIcon: {
    cursor: "pointer",
  },
}));

const FractDateTimeControl = ({
  value = "",
  minDate = null,
  onChange,
  showTimeSelect = false,
  placeholder = "select",
  disabled = false,
  errorMsg = null,
  dateFormat = "MM/dd/yyyy",
  ...rest
}) => {
  const classes = useStyle();
  const [startDate, setStartDate] = useState("");
  useEffect(() => {
    if (value && value !== startDate) {
      setStartDate(new Date(value));
    } else if (!value) {
      setStartDate("");
    }
  }, [value]);

  return (
    <>
      <DatePicker
        selected={startDate}
        onChange={(data) => {
          setStartDate(data);
          onChange(data);
        }}
        customInput={
          <CustomDateInput
            placeholderText={value ? "" : placeholder}
            showTimeSelect={showTimeSelect}
            label={placeholder}
            classes={classes}
            error={errorMsg}
            autoComplete={rest?.autoComplete || "off"}
            aria-autocomplete="none"
          />
        }
        dateFormat={dateFormat}
        showTimeSelect={showTimeSelect}
        disabled={disabled}
        showPopperArrow={false}
        minDate={minDate}
      />
      {errorMsg && (
        <Typography className={classes.errorMessage}>{errorMsg}</Typography>
      )}
    </>
  );
};

const CustomDateInput = forwardRef(
  (
    {
      value,
      onClick,
      onChange,
      placeholderText,
      disabled,
      id,
      showTimeSelect,
      label,
      classes,
      error = false,
      autoComplete = "off",
    },
    ref
  ) => {
    let rootClass = classes.filledRoot;
    if (error) {
      rootClass = classes.errorFilledRoot;
    } else if (disabled) {
      rootClass = classes.disabledFilledRoot;
    }
    return (
      <TextField
        id={label}
        value={value}
        label={label}
        onClick={(e) => onClick(e)}
        onKeyPress={(e) => onClick(e)}
        onChange={(e) => onChange(e)}
        ref={ref}
        placeholder={placeholderText}
        disabled={disabled}
        variant="filled"
        fullWidth
        size="small"
        autoComplete={autoComplete}
        aria-autocomplete="none"
        inputProps={{ maxLength: showTimeSelect ? 15 : 10 }}
        InputProps={{
          classes: {
            root: rootClass,
            input: classes.filledInput,
            focused: error ? classes.error : classes.filledFocused,
            underline: classes.underline,
          },
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end">
              <img
                src={dateCalendar}
                className={classes.dateCalendarIcon}
                alt="dateCalendar"
              />
            </InputAdornment>
          ),
        }}
        FormHelperTextProps={{
          classes: {
            root: classes.helperTextRoot,
          },
        }}
        InputLabelProps={{
          classes: {
            root: error ? classes.errorlabelRoot : classes.labelRoot,
            focused: error ? classes.labelFocusedError : classes.labelFocused,
          },
        }}
      />
    );
  }
);

export default FractDateTimeControl;
