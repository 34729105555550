import React, { useEffect } from "react";
import groovyWalkAnimation from "./Port.json";
import { useLottie } from "lottie-react";
import { Stack } from "@mui/material";

import "./Loader.css";

const style = {
  height: "80px",
  width: "80px",
};

const Loader = ({ position = "absolute" }) => {
  const options = {
    animationData: groovyWalkAnimation,
    loop: true,
    autoplay: true,
  };

  const { View, setSpeed } = useLottie(options, style);

  useEffect(() => {
    setSpeed(4);
  }, []);

  return (
    <div className="loaderOverlay" style={{ position: position }}>
      <Stack>
        {View}
        <span className="loader">Loading...</span>
      </Stack>
    </div>
  );
};

export default Loader;
