import { GET_APP_SETTINGS_SUCCESS } from "./actionType";

const initialState = {
  appSetting: {
    showCarrierNameShipmentSummary: true,
    showCarrierNameTrackAndTrace: false,
  },
};
const AppSetting = (state = initialState, action) => {
  switch (action.type) {
    case GET_APP_SETTINGS_SUCCESS:
      return {
        ...state,
        appSetting: action.payload,
      };
    default:
      return state;
  }
};

export default AppSetting;
