export const SET_LOADING = "SET_LOADING";

export const GET_PARENT_ORGID_LIST = "GET_PARENT_ORGID_LIST";
export const GET_PARENT_ORGID_LIST_SUCCESS = "GET_PARENT_ORGID_LIST_SUCCESS";
export const GET_PARENT_ORGID_LIST_FAILURE = "GET_PARENT_ORGID_LIST_FAILURE";

export const GET_PARENT_ORGANIZATION_LIST = "GET_PARENT_ORGANIZATION_LIST";
export const GET_PARENT_ORGANIZATION_LIST_SUCCESS =
  "GET_PARENT_ORGANIZATION_LIST_SUCCESS";
export const GET_PARENT_ORGANIZATION_LIST_FAILURE =
  "GET_PARENT_ORGANIZATION_LIST_FAILURE";
  
export const SET_BRANCH_LOADING = "SET_BRANCH_LOADING";
export const GET_LOCAL_BRANCH_LIST = "GET_LOCAL_BRANCH_LIST";
export const GET_LOCAL_BRANCH_LIST_SUCCESS = "GET_LOCAL_BRANCH_LIST_SUCCESS";
export const GET_LOCAL_BRANCH_LIST_FAILURE = "GET_LOCAL_BRANCH_LIST_FAILURE";
export const CLEAR_LOCAL_BRANCH_LIST = "CLEAR_LOCAL_BRANCH_LIST";

export const GET_CUSTOMER_ID_LIST = "GET_CUSTOMER_ID_LIST";
export const GET_CUSTOMER_ID_LIST_SUCCESS = "GET_CUSTOMER_ID_LIST_SUCCESS";
export const GET_CUSTOMER_ID_LIST_FAILURE = "GET_CUSTOMER_ID_LIST_FAILURE";
