export const GridEvent = {
  OPTION_CHANGE: "onOptionChanged",
  PAGING: "paging",
  COLUMNS: "columns",
  FOCUSED_ROW_INDEX: "focusedRowIndex",
  SELECTED_ROW_KEYS: "selectedRowKeys",
  SORT_ORDER: "sortOrder",
  FILTER: "filterValue",
  COL_VISIBLE: "visible",
  SELECTED_FILTER_OPTION: "selectedFilterOperation",
  COLUMN_INDEX: "visibleIndex",
  FOCUSED_COLUM_INDEX: "focusedColumnIndex",
};

export const DefaultGridConfiguration = {
  gridId: "test_grid",
  showBorders: true,
  showColumnLines: false,
  showRowLines: true,
  rowAlternationEnabled: true,
  wordWrapEnabled: false,
  columnWidth: 160,
  height: "calc(100vh - 120px)",
  width: "100%",
  allowColumnResizing: true,
  allowColumnReordering: false,
  allowColumFixing: false,
  rowSeletion: false,
  filterRow: false,
  infiniteScrolling: true,
  headerFilter: false,
  columnChooser: false,
  defaultColums: false,
  statePersistence: false,
  sortingMode: "multiple",
  columAutoWidth: false,
  columnMinWidth: 0,
  selectionMode: "single",
  editMode: "batch",
  editUpdate: false,
  remoteOperations: false,
  pageSize: 10,
};
