import { takeEvery, put, all } from "redux-saga/effects";
import API from "../../utils/axios";
import {
  GET_MENU_DETAILS,
  GET_MENU_DETAILS_FAILURE,
  GET_MENU_DETAILS_SUCCESS,
  SET_MENU_LOADING,
} from "./actionTypes";

// https://app-cus-myfracht-api-qa-001.azurewebsites.net/api/1/users/me/menu/visibility

function* getUserDetailsSaga(params) {
  try {
    yield put({ type: SET_MENU_LOADING });
    const URL = params.orgId
      ? `1/users/me/menu/visibility?organizationId=${params.orgId}`
      : "1/users/me/menu/visibility";
    const { data } = yield API.get(URL);
    yield put({ type: GET_MENU_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_MENU_DETAILS_FAILURE,
    });
  }
}

function* menuSaga() {
  yield all([yield takeEvery(GET_MENU_DETAILS, getUserDetailsSaga)]);
}

export default menuSaga;
