import {
  GET_PRODUCT_NAME_FAILURE,
  GET_PRODUCT_NAME_SUCCESS,
  GET_QUANTITY_LIST_FAILURE,
  GET_QUANTITY_LIST_SUCCESS,
  GET_REF_TYPE_FAILURE,
  GET_REF_TYPE_SUCCESS,
  GET_SERVICE_DETAIL_FAILURE,
  GET_SERVICE_DETAIL_SUCCESS,
  GET_SERVICE_EQUIPMENTS_DETAIL_FAILURE,
  GET_SERVICE_EQUIPMENTS_DETAIL_SUCCESS,
  QUOTE_CONFIRMATION_FAILURE,
  QUOTE_CONFIRMATION_SUCCESS,
  RESET_REQUESTID,
  RESET_REQUESTID_FAILURE,
  RESET_REQUESTID_SUCCESS,
  RESET_TRUCK_PAGE_DATA,
  SEARCH_TRUCK_FAILURE,
  SEARCH_TRUCK_SUCCESS,
  SET_TRUCKING_LOADING,
  SET_TRUCK_PAGE_DATA,
} from "./actionTypes";

const initialState = {
  serviceEquipmentsLists: [],
  serviceLists: [],
  productNameList: [],
  referenceTypeList: [],
  quantityList: [],
  truckCompanyList: {
    approxBookingMinCost: 0,
    approxBookingMaxCost: 0,
    searchInput: {
      mode: "",
      searchBy: "",
      pickupDate: "",
      origin: [
        {
          city: "",
          state: "",
          stateCode: "",
          zipCode: "",
          countryCode: "",
        },
      ],
      destination: [
        {
          city: "",
          state: "",
          stateCode: "",
          zipCode: "",
          countryCode: "",
        },
      ],
      seaport: [],
      service: [],
      equipment: [],
      commodity: [
        {
          productName: "",
          productId: "",
          qty: 33,
          qtyType: "",
          weight: 34,
          weightType: "",
        },
      ],
      reference: null,
    },
    truckCompany: [],
    createdBy: "",
    createdDate: "",
    updatedBy: "",
    updatedDate: "",
    id: "",
  },
  requestId: null,
  truckingLoading: false,
  error: false,
  errorMessage: "",
  truckPageData: null,
};

const TruckLoad = (state = initialState, action) => {
  switch (action.type) {
    case SET_TRUCKING_LOADING:
      return {
        ...state,
        truckingLoading: true,
      };

    case GET_SERVICE_EQUIPMENTS_DETAIL_SUCCESS:
      return {
        ...state,
        truckingLoading: false,
        serviceEquipmentsLists: action.payload,
      };
    case GET_SERVICE_EQUIPMENTS_DETAIL_FAILURE:
      return {
        ...state,
        truckingLoading: false,
        error: true,
        errorMessage: action.payload.errorMessage,
      };

    case GET_SERVICE_DETAIL_SUCCESS:
      return {
        ...state,
        truckingLoading: false,
        serviceLists: action.payload,
      };
    case GET_SERVICE_DETAIL_FAILURE:
      return {
        ...state,
        truckingLoading: false,
        error: true,
        errorMessage: action.payload.errorMessage,
      };

    case GET_PRODUCT_NAME_SUCCESS:
      return {
        ...state,
        truckingLoading: false,
        productNameList: action.payload,
      };
    case GET_PRODUCT_NAME_FAILURE:
      return {
        ...state,
        truckingLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case GET_REF_TYPE_SUCCESS:
      return {
        ...state,
        truckingLoading: false,
        referenceTypeList: action.payload,
      };
    case GET_REF_TYPE_FAILURE:
      return {
        ...state,
        truckingLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case GET_QUANTITY_LIST_SUCCESS:
      return {
        ...state,
        truckingLoading: false,
        quantityList: action.payload,
      };
    case GET_QUANTITY_LIST_FAILURE:
      return {
        ...state,
        truckingLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case SEARCH_TRUCK_SUCCESS:
      return {
        ...state,
        truckingLoading: false,
        truckCompanyList: action.payload,
      };
    case SEARCH_TRUCK_FAILURE:
      return {
        ...state,
        truckingLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case QUOTE_CONFIRMATION_SUCCESS:
      return {
        ...state,
        truckingLoading: false,
        requestId: action.payload,
      };
    case QUOTE_CONFIRMATION_FAILURE:
      return {
        ...state,
        truckingLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case RESET_REQUESTID:
      return {
        ...state,
        truckingLoading: true,
      };
    case RESET_REQUESTID_SUCCESS:
      return {
        ...state,
        truckingLoading: false,
        requestId: null,
      };
    case RESET_REQUESTID_FAILURE:
      return {
        ...state,
        truckingLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case SET_TRUCK_PAGE_DATA:
      return {
        ...state,
        truckPageData: action.payload,
      };

    case RESET_TRUCK_PAGE_DATA:
      return {
        ...state,
        truckPageData: null,
      };

    default:
      return state;
  }
};

export default TruckLoad;
