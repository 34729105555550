import { takeEvery, put, all } from "redux-saga/effects";
import API from "../../utils/axios";
import {
  GET_SHIPMENT_DRAFT_DETAIL,
  GET_SHIPMENT_DRAFT_DETAIL_FAILURE,
  GET_SHIPMENT_DRAFT_DETAIL_SUCCESS,
  RESET_SHIPMENT_DRAFT_DETAIL,
  RESET_SHIPMENT_DRAFT_DETAIL_FAILURE,
  RESET_SHIPMENT_DRAFT_DETAIL_SUCCESS,
  SET_LOADING,
  GET_SHIPPING_NOTE_DETAILS,
  GET_SHIPPING_NOTE_DETAILS_SUCCESS,
  GET_SHIPPING_NOTE_DETAILS_FAILURE,
  SET_SHIPPING_NOTE_DETAILS_LOADING,
} from "./actionTypes";

// api/{v}/shipment-draft/{id}/details
function* getShipmentDraftDetailSaga({ id }) {
  try {
    yield put({ type: SET_LOADING });
    const { data } = yield API.get(`/1/shipment-draft/${id}/details`);
    // console.log("ShipmentHistory data saga", data);
    yield put({
      type: GET_SHIPMENT_DRAFT_DETAIL_SUCCESS,
      payload: data.value,
    });
  } catch (error) {
    console.log("error", error);
    yield put({
      type: GET_SHIPMENT_DRAFT_DETAIL_FAILURE,
      errorMessage: "Unable to get shipment draft details",
    });
  }
}

function* resetShipmentDraftDetailSaga() {
  try {
    yield put({ type: SET_LOADING });
    yield put({ type: RESET_SHIPMENT_DRAFT_DETAIL_SUCCESS });
  } catch (error) {
    console.log("error", error);
    yield put({
      type: RESET_SHIPMENT_DRAFT_DETAIL_FAILURE,
      errorMessage: "Unable to reset shipment draft details",
    });
  }
}

// Shipping Notes Section
function* getShippingNotesDetailsSaga({ id }) {
  try {
    yield put({ type: SET_SHIPPING_NOTE_DETAILS_LOADING });
    const { data } = yield API.get(`/1/shippingnotes/${id}/details`);
    yield put({
      type: GET_SHIPPING_NOTE_DETAILS_SUCCESS,
      payload: data.value,
    });
  } catch (error) {
    console.log("error", error);
    yield put({
      type: GET_SHIPPING_NOTE_DETAILS_FAILURE,
      errorMessage: "Unable to get shipping notes details.",
    });
  }
}

function* ShipmentHistorySaga() {
  yield all([
    yield takeEvery(GET_SHIPMENT_DRAFT_DETAIL, getShipmentDraftDetailSaga),
  ]);
  yield all([
    yield takeEvery(RESET_SHIPMENT_DRAFT_DETAIL, resetShipmentDraftDetailSaga),
  ]);
  yield all([
    yield takeEvery(GET_SHIPPING_NOTE_DETAILS, getShippingNotesDetailsSaga),
  ]);
}

export default ShipmentHistorySaga;
