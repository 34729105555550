import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  formControlCheckbox: {
    "&.MuiFormControlLabel-root": {
      "& .MuiFormControlLabel-label": {
        ...theme.typography.body2,
        color: theme.palette.color.cyanBlueC1,
      },
    },
  },

  checkboxStyle: {
    padding: "0 !important",
    marginRight: "5px !important",
    color: `${theme.palette.color.cyanBlueC1}`,
    "&.Mui-disabled": {
      cursor: "default",
      color: "rgba(0, 0, 0, 0.26) !important",
    },
  },
  formControlCheckboxLight: {
    "&.MuiFormControlLabel-root": {
      "& .MuiFormControlLabel-label": {
        ...theme.typography.body2,
        color: "#fff",
      },
    },
  },
  checkboxStyleLight: {
    padding: "0 !important",
    marginRight: "5px !important",
    color: "#fff !important",
    "&.Mui-disabled": {
      cursor: "default",
      color: "rgba(0, 0, 0, 0.26) !important",
    },
  },
}));

const FrachtCheckBox = ({
  changeHandler,
  checked,
  label,
  dataTestId,
  disabled = false,
  margin = "0 16px 0 24px",
  theme = "dark",
}) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      classes={{
        root:
          theme === "dark"
            ? classes.formControlCheckbox
            : classes.formControlCheckboxLight,
      }}
      style={{ margin: margin }}
      control={
        <Checkbox
          size="small"
          sx={{
            "&.Mui-checked": {
              color: theme === "dark" ? "#212529 !important" : "#fff",
            },
          }}
          checked={checked}
          onChange={changeHandler}
          classes={{
            root:
              theme === "dark"
                ? classes.checkboxStyle
                : classes.checkboxStyleLight,
          }}
          data-testid={dataTestId}
          disabled={disabled}
        />
      }
      label={label}
    />
  );
};

export default FrachtCheckBox;
