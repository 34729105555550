import {
  GET_PAGEHEAD_FAILURE,
  GET_PAGEHEAD_SUCCESS,
  SET_LOADING,
  SET_PAGEHEAD_FAILURE,
  SET_PAGEHEAD_SUCCESS,
} from "./actionTypes";

const initialState = {
  heading: [],
  breadCrumbsDetails: [],
  loading: false,
  error: false,
  errorMessage: "",
};

const PageHeadDetails = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };

    case SET_PAGEHEAD_SUCCESS:
      return {
        ...state,
        loading: false,
        heading: action.payload.heading,
        breadCrumbsDetails: action.payload.breadCrumbsDetails,
      };

    case SET_PAGEHEAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: "Unable to set pagehead details",
      };

    case GET_PAGEHEAD_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case GET_PAGEHEAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: "Unable to get pagehead details",
      };

    default:
      return state;
  }
};

export default PageHeadDetails;
