import {
  GET_SHIPMENT_DRAFT_DETAIL,
  RESET_SHIPMENT_DRAFT_DETAIL,
  UPDATE_BREADCRUMB_DATA,
  GET_SHIPPING_NOTE_DETAILS,
  RESET_SHIPPING_NOTE_DETAILS,
} from "./actionTypes";

export const getShipmentDraftDetailAction = (id) => {
  return {
    type: GET_SHIPMENT_DRAFT_DETAIL,
    id: id,
  };
};

export const resetShipmentDraftDetailAction = () => ({
  type: RESET_SHIPMENT_DRAFT_DETAIL,
});

export const updateBreadcrumData = (data) => ({
  type: UPDATE_BREADCRUMB_DATA,
  data,
});

//  Shipping Notes Section
export const getShippingNotesDetailsAction = (shipmentId) => {
  return {
    type: GET_SHIPPING_NOTE_DETAILS,
    id: shipmentId,
  };
};

//  Reset Shipping Notes Section
export const resetShippingNotesDetailsAction = () => {
  return {
    type: RESET_SHIPPING_NOTE_DETAILS,
  };
};
