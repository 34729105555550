export const SET_LOADING = "SET_LOADING";

export const GET_SHIPMENT_DRAFT_DETAIL = "GET_SHIPMENT_DRAFT_DETAIL";
export const GET_SHIPMENT_DRAFT_DETAIL_SUCCESS =
  "GET_SHIPMENT_DRAFT_DETAIL_SUCCESS";
export const GET_SHIPMENT_DRAFT_DETAIL_FAILURE =
  "GET_SHIPMENT_DRAFT_DETAIL_FAILURE";

export const RESET_SHIPMENT_DRAFT_DETAIL = "RESET_SHIPMENT_DRAFT_DETAIL";
export const RESET_SHIPMENT_DRAFT_DETAIL_SUCCESS =
  "RESET_SHIPMENT_DRAFT_DETAIL_SUCCESS";
export const RESET_SHIPMENT_DRAFT_DETAIL_FAILURE =
  "RESET_SHIPMENT_DRAFT_DETAIL_FAILURE";

export const UPDATE_BREADCRUMB_DATA = "UPDATE_BREADCRUMB_DATA";

//  Shipping Notes Section
export const SET_SHIPPING_NOTE_DETAILS_LOADING =
  "SET_SHIPPING_NOTE_DETAILS_LOADING";
export const GET_SHIPPING_NOTE_DETAILS = "GET_SHIPPING_NOTE_DETAILS";
export const GET_SHIPPING_NOTE_DETAILS_SUCCESS =
  "GET_SHIPPING_NOTE_DETAILS_SUCCESS";
export const GET_SHIPPING_NOTE_DETAILS_FAILURE =
  "GET_SHIPPING_NOTE_DETAILS_FAILURE";

export const RESET_SHIPPING_NOTE_DETAILS = "RESET_SHIPPING_NOTE_DETAILS";
