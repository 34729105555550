export const GET_BOOKING_LIST = "GET_BOOKING_LIST";
export const GET_BOOKING_LIST_SUCCESS = "GET_BOOKING_LIST_SUCCESS";
export const GET_BOOKING_LIST_FAILURE = "GET_BOOKING_LIST_FAILURE";

export const SET_LOADING = "SET_LOADING";

export const GET_LOAD_NUMBER_DETAIL = "GET_LOAD_NUMBER_DETAIL";
export const GET_LOAD_NUMBER_DETAIL_SUCCESS = "GET_LOAD_NUMBER_DETAIL_SUCCESS";
export const GET_LOAD_NUMBER_DETAIL_FAILURE = "GET_LOAD_NUMBER_DETAIL_FAILURE";

export const RESET_LOAD_NUMBER_DETAIL = "RESET_LOAD_NUMBER_DETAIL";
export const RESET_LOAD_NUMBER_DETAIL_SUCCESS =
  "RESET_LOAD_NUMBER_DETAIL_SUCCESS";
export const RESET_LOAD_NUMBER_DETAIL_FAILURE =
  "RESET_LOAD_NUMBER_DETAIL_FAILURE";

export const SET_AVAILABLE_CAPACITY_DETAIL_LOADING =
  "SET_AVAILABLE_CAPACITY_DETAIL_LOADING";
export const GET_AVAILABLE_CAPACITY_DETAIL = "GET_AVAILABLE_CAPACITY_DETAIL";
export const GET_AVAILABLE_CAPACITY_DETAIL_SUCCESS =
  "GET_AVAILABLE_CAPACITY_DETAIL_SUCCESS";
export const GET_AVAILABLE_CAPACITY_DETAIL_FAILURE =
  "GET_AVAILABLE_CAPACITY_DETAIL_FAILURE";

  export const RESET_AVAILABLE_CAPACITY_DETAIL =
    "RESET_AVAILABLE_CAPACITY_DETAIL";
  export const RESET_AVAILABLE_CAPACITY_DETAIL_SUCCESS =
    "RESET_AVAILABLE_CAPACITY_DETAIL_SUCCESS";
  export const RESET_AVAILABLE_CAPACITY_DETAIL_FAILURE =
    "RESET_AVAILABLE_CAPACITY_DETAIL_FAILURE";