import * as React from "react";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getODQV1Count } from "../../../redux/widgets/action";
import { Card, CardContent, CircularProgress, Typography } from "@mui/material";
import { widgetStyles } from "../widgetStyles";
import { Helmet } from "react-helmet";

export default function OperationalDataQualityV2Widget() {
  const { user } = useSelector((state) => state.LoginUser);
  const { odqV1Count, odqcardLoading } = useSelector(
    (state) => state.WidgetsReducer
  );

  const statusCardsData = [
    {
      label: "Triple E",
      count: -1,
      type: "tripleE",
    },
    {
      label: "Triple A",
      count: -1,
      type: "tripleA",
    },
    {
      label: "Tic Tac Toe",
      count: -1,
      type: "ticTacToe",
    },
    {
      label: "eDocs",
      count: -1,
      type: "eDocs",
    },
    {
      label: "File Closing Process",
      count: -1,
      type: "fileClosingProcess",
    },
    {
      label: "Billing & WIP",
      count: -1,
      type: "billingWIP",
    },
    {
      label: "AP Claims",
      count: -1,
      type: "apClaims",
    },
    {
      label: "Export Compliance",
      count: -1,
      type: "exportCompliance",
    },
  ];
  const dispatch = useDispatch();
  const [statusData, setStatusData] = React.useState(statusCardsData);
  const navigate = useNavigate();
  const classes = widgetStyles();

  useEffect(() => {
    if (user?.organizationCountryCode && odqV1Count === null)
      dispatch(getODQV1Count(user?.organizationCountryCode));
  }, [dispatch, user?.organizationCountryCode]);

  useEffect(() => {
    if (odqV1Count) {
      const updatedStatusData = statusCardsData.map((card) => ({
        ...card,
        count:
          odqV1Count[card.type] !== undefined
            ? odqV1Count[card.type]
            : card.count,
      }));
      setStatusData(updatedStatusData);
    }
  }, [odqV1Count]);

  return (
    <>
      <Helmet>
        <title>Widget-Dashboard</title>
      </Helmet>
      <Grid
        container
        className={classes.odqContainer}
        onClick={() => {
          !odqcardLoading && navigate(`/odqv2Widget`);
        }}
      >
        <Grid container ml={1.5}>
          <Typography variant="h4">ODQ V2 Summary</Typography>
        </Grid>
        {statusData?.map((card, index) => (
          <Grid
            item
            xs={3}
            key={index}
            style={{ height: "45%", padding: "8px" }}
          >
            <Card className={classes.odqCard}>
              <CardContent className={classes.odqLabel}>
                <Typography variant="body22" color="white">
                  {card.label}
                </Typography>
              </CardContent>
              <CardContent
                style={{ textAlign: "center", padding: "24px 16px 8px" }}
              >
                <Typography variant="h2A" color="#001737" component="div">
                  {odqcardLoading ? (
                    <CircularProgress />
                  ) : card.count === -1 ? (
                    0
                  ) : (
                    card.count
                  )}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
