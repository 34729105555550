import {
  GET_MENU_DETAILS_FAILURE,
  GET_MENU_DETAILS_SUCCESS,
  SET_MENU_LOADING,
  SET_MENU_VISIBILITY,
} from "./actionTypes";

const initialState = {
  analytics: false,
  analyticsCarrierPerformance: false,
  analyticsCarrierPerformanceWorkInProgress: false,
  analyticsExecutiveDashboard: false,
  analyticsExecutiveDashboardWorkInProgress: false,
  analyticsKeyAccountManagement: false,
  analyticsKeyAccountManagementWorkInProgress: false,
  analyticsOperationalDataQuality: false,
  analyticsOperationalDataQualityWorkInProgress: false,
  analyticsQuarterlyBusinessReview: false,
  analyticsQuarterlyBusinessReviewType1: false,
  analyticsQuarterlyBusinessReviewType1WorkInProgress: false,
  analyticsQuarterlyBusinessReviewType2: false,
  analyticsQuarterlyBusinessReviewType2WorkInProgress: false,
  analyticsQuarterlyBusinessReviewWorkInProgress: false,
  analyticsVolume: false,
  analyticsVolumeWorkInProgres: false,
  analyticsWorkInProgress: false,
  canDeleteLock: false,
  createShipment: false,
  createShipmentWorkInProgress: false,
  dashboard: false,
  dashboardISF: false,
  dashboardISFWorkInProgress: false,
  documentUpload: false,
  documentUploadWorkInProgress: false,
  findTruck: false,
  findTruckWorkInProgress: false,
  history: false,
  historyWorkInProgress: false,
  leprinoTrack: false,
  leprinoTrackWorkInProgress: false,
  menuVisibilityLoading: false,
  millerCoors: false,
  millerCoorsAnalytics: false,
  millerCoorsAnalyticsWorkInProgress: false,
  millerCoorsDashboard: false,
  millerCoorsDashboardWorkInProgress: false,
  millerCoorsSetting: false,
  millerCoorsSettingWorkInProgress: false,
  millerCoorsWorkInProgress: false,
  organization: false,
  organizationWorkInProgress: false,
  railCarScheduler: false,
  railCarSchedulerWorkInProgress: false,
  rates: false,
  ratesWorkInProgress: false,
  resources: false,
  resourcesWorkInProgress: false,
  roles: false,
  rolesWorkInProgress: false,
  serviceCenter: false,
  serviceCenterWorkInProgress: false,
  shipment: false,
  shipmentSummary: false,
  shipmentSummaryWorkInProgress: false,
  shipmentWorkInProgress: false,
  track: false,
  trackWorkInProgress: false,
  trucking: false,
  truckingQuoteRequests: false,
  truckingQuoteRequestsWorkInProgress: false,
  userManagement: false,
  userManagementWorkInProgress: false,
  users: false,
  usersWorkInProgress: false,
};

const MenuVisibility = (state = initialState, action) => {
  switch (action.type) {
    case SET_MENU_LOADING:
      return {
        ...state,
        menuVisibilityLoading: true,
      };

    case GET_MENU_DETAILS_SUCCESS:
      return {
        ...state,
        menuVisibilityLoading: false,
        ...action.payload,
      };

    case GET_MENU_DETAILS_FAILURE:
      return {
        ...state,
        menuVisibilityLoading: false,
      };
    case SET_MENU_VISIBILITY:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default MenuVisibility;
